import { Grid, Box, Tab, Tabs, Container, Paper, Stack } from "@mui/material";
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { CreateHeader } from 'pages/logged/admin/components'
import React, { useRef, useState } from 'react'
import { BreadcrumbsList } from 'components/data_display'
import { COLOR } from 'theme/Colors.js'
import { Text } from 'components/texts/index.js'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { TabContext } from '@mui/lab'
import { DatosEmpleadoDataForm, DataCurriculumCandidato, ListadosDataForm } from "components/forms/display";

export default function CreateEmpleado(empleado) {

    const navigationParams = useParams()
    const navigate = useNavigate()

    const [tab, setTab] = useState("1")
    const { register, handleSubmit, formState: { errors }, getValues, control, setValue } = useForm()
    const headerRef = useRef()


    const createEmpleadoAcademia = () => {
        //REQUEST EMPLEADO, CREA UN EMPLEADO QUE NO EXISTE

        // headerRef.current.button.setLoading(true)
        // new Requests().editAula(id_aula, getValues, (res) => {
        //   updateItem(res.item)
        //   updateHeaderAndAlert({ message: "Se ha editado el aula correctamente.", variant: "success" })
        // }, (res) => {
        //   updateHeaderAndAlert({ message: res })
        // })
        console.log('enviado...')
    }

    return (
        <Grid container p={3}>
            <Container maxWidth={false} sx={{ mt: 5 }}>
                <Stack direction="row" justifyContent={"space-between"} marginX={8}>
                    <BreadcrumbsList mx={0} breadcrumbsList={[
                        { to: "/", label: "Inicio" },
                        { to: "./../", label: "Empleado" },
                        { to: "./", label: "Crear Empleado", actualItem: true },
                    ]} />
                </Stack>

                <Paper elevation={2} sx={{ m: 2, mx: 8, my: 2, pb: 5 }}>
                    <Grid container display={"flex"} justifyContent={"end"} alignItems={"start"}>
                        <CreateHeader
                            ref={headerRef}
                            title={"Crear ficha del Empleado"}
                            handleSubmit={handleSubmit(createEmpleadoAcademia, (errors) => {
                                console.error(errors);
                            })} />
                    </Grid>

                    <TabContext value={tab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                            <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                                <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS PERSONALES</Text>} value={"1"} />
                                <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>CURRICULUM VITAE</Text>} value={"2"} />
                                <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>LISTADOS</Text>} value={"3"} />
                            </Tabs>
                        </Box>

                        <TabPanel value={"1"}>
                            <DatosEmpleadoDataForm setValue={setValue} empleado={empleado} register={register} errors={errors} />
                        </TabPanel>

                        <TabPanel value={"2"}>
                            <DataCurriculumCandidato />
                        </TabPanel>

                        <TabPanel value={"3"}>
                            <ListadosDataForm />
                        </TabPanel>

                    </TabContext>
                </Paper>
            </Container>
        </Grid>
    )
}
