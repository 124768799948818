import React from 'react'
import { Grid } from '@mui/material'
import { H3 } from 'components/texts'
import { ASSETS } from 'constants/Assets'
import { ImagePicker, Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Formulario con los datos del alumno
 */
export default function DataFormAlumno({ alumno, register, errors }) {

  return (
        <Grid container px={5}>
            <Grid container alignItems={"center"}>
                <Grid item xs={4} md={2}>
                    <ImagePicker 
                        mimeTypes    = {['image/jpeg', 'image/png']} 
                        register     = {register}
                        className    = "w-100"
                        name         = {"foto_logo"} 
                        defaultImage = {ASSETS.images + (alumno?.foto_logo ?? 'private/alumno/perfiles/0/default.png')} />
                </Grid>

                <Grid item xs={8} md={10} container spacing={2} px={2}>
                    <Grid item xs={6}>
                        <Input label="Nombre" register={register} errors={errors} className="w-100" name={"nombre"} defaultValue={alumno?.nombre ?? ""} uppercase/>
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Primer apellido" register={register} errors={errors} className="w-100" name={"apellido1"} defaultValue={alumno?.apellido1 ?? ""} uppercase/>
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Segundo apellido" register={register} errors={errors} className="w-100" name={"apellido2"} defaultValue={alumno?.apellido2 ?? ""} uppercase/>
                    </Grid>

                    <Grid item xs={6}>
                        <ItemsSelect name={"id_sexo"} keyValue={"sexo"} register={register} errors={errors} defaultValue={alumno?.id_sexo ?? ""}/>
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Numero de documento" register={register} errors={errors} className="w-100" name={"numero_documento"} defaultValue={alumno?.numero_documento ?? ""} uppercase/>
                    </Grid>

                    <Grid item xs={6}>
                        <ItemsSelect name={"id_tipo_documento"} keyValue={"tipo_documento"} register={register} errors={errors} defaultValue={alumno?.id_tipo_documento ?? ""}/>
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Email" register={register} errors={errors} className="w-100" name={"email"} defaultValue={alumno?.email ?? ""} uppercase/>
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Email secundario" register={register} errors={errors} className="w-100" name={"email2"} defaultValue={alumno?.email2 ?? ""} uppercase/>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <H3 className={"m-0"}>Datos de contacto</H3>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Teléfono principal" register={register} errors={errors} className="w-100" name={"telefono1"} defaultValue={alumno?.telefono1 ?? ""} uppercase/>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Teléfono secundario" register={register} errors={errors} className="w-100" name={"telefono2"} defaultValue={alumno?.telefono2 ?? ""} uppercase/>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Móvil principal" register={register} errors={errors} className="w-100" name={"tlfno_movil1"} defaultValue={alumno?.tlfno_movil1 ?? ""} />
                </Grid>
                
                <Grid item xs={6}>
                    <Input label="Móvil secundario" register={register} errors={errors} className="w-100" name={"tlfno_movil2"} defaultValue={alumno?.tlfno_movil2 ?? ""}/>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <H3 className={"m-0"}>Datos domicilio actual</H3>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Dirección" register={register} errors={errors} className="w-100" name={"nombre_via"} defaultValue={alumno?.nombre_via ?? ""} uppercase/>
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"id_tipo_via"} keyValue={"tipo_via"} className="w-100" register={register} errors={errors} defaultValue={alumno?.id_tipo_via ?? ""}/>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Código postal" register={register} errors={errors} className="w-100" name={"cod_postal"} defaultValue={alumno?.cod_postal ?? ""} uppercase/> 
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"id_provincia"} keyValue={"provincia"} className="w-100" register={register} errors={errors} defaultValue={alumno?.id_provincia ?? ""}/>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Municipio" register={register} errors={errors} className="w-100" name={"municipio"} defaultValue={alumno?.municipio ?? ""} uppercase/>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <H3 className={"m-0"}>Datos de nacimiento</H3>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Pais de nacimiento" register={register} errors={errors} className="w-100" name={"pais_nacimiento"} defaultValue={alumno?.pais_nacimiento ?? ""} uppercase/>
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect label={"Provincia de nacimiento"} name={"id_provincia_nacimiento"} keyValue={"provincia"} register={register} errors={errors} defaultValue={alumno?.id_provincia_nacimiento ?? ""} />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Municipio de nacimiento" register={register} errors={errors} className="w-100" name={"municipio_nacimiento"} defaultValue={alumno?.municipio_nacimiento ?? ""} uppercase/>
                </Grid>

            </Grid>

      </Grid>
    )
}
