import { Breadcrumbs } from '@mui/material'
import React, { Component } from 'react'
import Link from '../Link'
import { COLOR } from "../../../theme/Colors";
import { Box } from '@mui/material';

/**
 * Es una linea
 */
export default class BreadcrumbsList extends Component {

  constructor(props) {
    super(props)

    this.state = {
      breadcrumbsList: this.props.breadcrumbsList ?? []
    }
  }


  /**
   * ComponentDidUpdate
   * 
   * @param {Object} prevProps 
   */
  componentDidUpdate(prevProps) {
    if (this.props.breadcrumbsList != prevProps.breadcrumbsList)
      this.setState({ breadcrumbsList: this.props.breadcrumbsList })
  }


  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
      <Box display={"flex"} mx={this.props.mx ?? 8}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {this.state.breadcrumbsList.map(item => (
            <Link key={Math.random()} to={item.to} color={item.actualItem ? COLOR.standard.main : null} size={"small"} className={item.actualItem ? "text-decoration-none bold" : "text-decoration-none"}>{item.label ?? ""}</Link>
          ))}
        </Breadcrumbs>
      </Box>
    )
  }
}