import React, { Component } from "react";
import { ASSETS } from 'constants/Assets';
import { H3, Text } from 'components/texts';
import { Button } from 'components/forms/components';
import { Add, Delete, Edit } from '@mui/icons-material';
import { SearchEmpleadoModal } from 'components/modals';
import { Avatar, Box, Stack, TextField } from "@mui/material";

export default class EmpleadoPicker extends Component {

  constructor(props) {
    super(props)
    this.state = {
        empleado: this.props.empleado ?? null,
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.empleado != this.props.empleado)
        this.setState({empleado: this.props.empleado})
  }


  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    const { empleado } = this.state
    const name = this.props.name ?? "id_empleado"

    return (
        <>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <H3 className={"m-0"}>{this.props.label ?? ""}</H3>
                {this.props.canEdit ? (
                    <Stack direction={"row"} spacing={2}>
                        <Button size={"small"} color={"success"} startIcon={empleado ? <Edit/> : <Add />} onClick={() => {this.empleadoModal.modal.open()}}>{empleado ? (this.props.editLabel ?? "") : (this.props.addLabel ?? "")}</Button>
                        <Button 
                            size      = {"small"} 
                            color     = {"cancel"} 
                            startIcon = {<Delete htmlColor='white'/>} 
                            disabled  = {empleado ? false : true} 
                            onClick   = {() => {
                                this.setState({empleado: null}, () => { this.props.setValue(name, null) })
                            }}>{this.props.deleteLabel ?? ""}</Button>
                    </Stack>
                ) : null}
            </Box>

            {empleado != null ? (
                <Box display={"flex"} alignItems={"center"} mt={2}>
                    <Avatar
                        alt = {"Foto de: " + (empleado.nombre ?? "")}
                        src = {`${ASSETS.images + (empleado.foto_logo ?? 'private/empleado/perfiles/0/default.png')}`}
                        sx  = {{ width: "20%", height: "100%", borderRadius: 2}}/>

                    <Stack ml={4}>
                        <Text fontWeight={"bold"}>{(empleado.nombre ?? "") + " " + (empleado.apellido1 ?? "") + " " + (empleado.apellido2 ?? "")}</Text>
                        <Text>{empleado.email ?? ""}</Text>
                    </Stack>
                </Box>
            ) : null}

            <TextField type={"hidden"} value={empleado ? empleado.id : ""} variant={"standard"} {...this.props.register ? this.props.register(name) : {}} />

            <SearchEmpleadoModal 
                ref                = {(ref) => { this.empleadoModal = ref}} 
                onSelectedCallback = {(empleado) => {
                    this.setState({empleado}, () => { this.props.setValue(name, empleado.id, {shouldDirty: true}) })
                }} />
        </>
    );
  }
}
