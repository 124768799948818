import React from 'react'
import { Checkbox, Input } from 'components/forms/components'
import { Grid } from '@mui/material'
import Statics from 'objects/common/Statics'
import { ItemsSelect } from 'components/forms/components/Select/custom'


/**
 * Formulario con las actividades de la academia
 */
export default function DataFormActividadesAcademia({ academia, register, errors, isMounted = false }) {

    return (
        <Grid container mt={2} px={4} spacing={2}>
            <Grid item xs={4}>
                <Input label="Nombre abreviado" register={register} errors={errors} className="w-100" name={"nombre"} defaultValue={academia?.nombre ?? ""} uppercase />
            </Grid>
            <Grid item xs={6}>

            </Grid>
            <Grid item xs={4}>
                {isMounted ? <ItemsSelect name={"id_tipo_actividad"} keyValue={"tipo_actividad"} register={register} errors={errors} defaultValue={academia?.tipo_actividad_id ?? ""} /> : null}
            </Grid>
            
            <Grid item xs={4}>
                {isMounted ? <ItemsSelect name={"id_actividad"} keyValue={"actividad"} register={register} errors={errors} defaultValue={academia?.actividad_id ?? ""} searcheable/> : null}
            </Grid>

            <Grid item xs={4}>
                {isMounted ? <ItemsSelect name={"id_modalidad_cursos"} keyValue={"modalidad_cursos"} register={register} errors={errors} defaultValue={academia?.modalidad_cursos_id ?? ""} /> : null}
            </Grid>

            <Grid item xs={3}>
                <Input label="Precio" register={register} errors={errors} className="w-100" name={"precio"} defaultValue={academia?.precio ? Statics.changeDotAndComma(academia.precio) : ""} uppercase />
            </Grid>

            <Grid item xs={3}>
                <Input label="Matrícula" register={register} errors={errors} className="w-100" name={"matricula"} defaultValue={academia?.matricula ? Statics.changeDotAndComma(academia.matricula) : ""} uppercase />
            </Grid>

            <Grid item xs={3}>
                <Input label="Precio Junio" register={register} errors={errors} className="w-100" name={"precio_junio"} defaultValue={academia?.precio_junio ? Statics.changeDotAndComma(academia.precio_junio) : ""} uppercase />
            </Grid>

            <Grid item xs={3}>
                <Checkbox name={"intensivo"} label={"Curso intensivo"} register={register} defaultChecked={academia?.intensivo ? true : false} />
            </Grid>
        </Grid>
    )
}
