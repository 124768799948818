import { COLOR } from 'theme/Colors'
import { TabContext } from '@mui/lab'
import { Text, H3 } from 'components/texts'
import { useNavigate, useParams } from 'react-router'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import React, { useState, useEffect, useContext } from 'react'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { BreadcrumbsList, MoveButtons } from 'components/data_display'
import { Box, Container, Grid, Paper, Stack } from '@mui/material'
import { GroupInfo, AlumnosInfo } from './parts'
import { SearchGrupoColegioModal } from 'components/modals'

export default function SingleSeguimientoTrimestral() {
    const [tipoGrupo, setTipoGrupo] = useState("academia")
    const [open,      setOpen]      = useState(false)
    const [grupo,     setGrupo]     = useState(null)

    useEffect(() => {
        if(open) setOpen(false)
    }, [open])

    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <BreadcrumbsList breadcrumbsList={[
            { to: "/",     label: "Inicio" },
            { to: "./../", label: "Seguimiento de curso" },
            { to: "./",    label: "Seguimiento trimestal", actualItem: true },
            ]} />

            <Paper elevation={2} sx={{mx: 8, my: 2, py: 3, px: 5, mt: 2}}>
                <GroupInfo tipoGrupo={tipoGrupo} setTipoGrupo={setTipoGrupo} grupo={grupo} setOpen={setOpen}/>
            </Paper>

            <Paper elevation={2} sx={{mx: 8, my: 2, py: 3, px: 5, mt: 2}}>
                <AlumnosInfo grupo={grupo} tipoGrupo={tipoGrupo}/>
            </Paper>
            
            {tipoGrupo == 'academia' ? null : <SearchGrupoColegioModal open={open} onSelectedCallback={setGrupo}/>}
        </Container>
    )
}