import { Grid, Stack } from "@mui/material";
import { H3, Text } from "components/texts";
import Skeleton from './skeleton'
import { Line } from "components/data_display";

/**
 * Pagina con los datos de inscripción de la clase particular
 */
export default function ClaseParticularData({ claseParticular }) {
    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container px={5} pb={5}>
            {claseParticular ?
                <>
                    <Grid container>
                        <Grid item xs={6} py={3} pr={2}>
                            <Stack>
                                <H3 fontWeight={"bold"}>Alumno</H3>
                                <Text>
                                    {claseParticular?.alumno?.nombre && claseParticular?.alumno?.apellido1 && claseParticular.alumno?.apellido2
                                        ? `${claseParticular.alumno.nombre} ${claseParticular.alumno.apellido1} ${claseParticular.alumno.apellido2}`
                                        : "Sin rellenar"}
                                </Text>
                            </Stack>
                        </Grid>

                        <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9" }} py={3} pl={3}>
                            <Grid item>
                                <Text fontWeight={"bold"}>Curso escolar:</Text>
                                <Text> {claseParticular?.curso_escolar?.valor ?? "Sin rellenar"}</Text>
                            </Grid>

                            <Grid item my={1}>
                                <Text fontWeight={"bold"}>Forma de recogida:</Text>
                                <Text> {claseParticular?.forma_recogida?.nombre ?? "Sin rellenar"}</Text>
                            </Grid>

                            <Grid item>
                                <Text fontWeight={"bold"}>Forma de conocimiento:</Text>
                                <Text> {claseParticular?.forma_conocimiento?.nombre ?? "Sin rellenar"}</Text>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Line />
                    </Grid>

                    <Grid container mt={2} spacing={2}>
                        <Grid item xs={4} md={4}>
                            <Grid item>
                                <Text fontWeight={"bold"}>Empresa:</Text>
                                <Text> {claseParticular?.empresa?.valor ?? "Sin rellenar"}</Text>
                            </Grid>
                        </Grid>

                        <Grid item xs={4} md={4}>
                            <Grid item>
                                <Text fontWeight={"bold"}>Actividad:</Text>
                                <Text> { (claseParticular?.registro_actividad?.actividad?.valor + " - " + claseParticular?.registro_actividad?.modalidad_cursos?.nombre) ?? "Sin rellenar"}</Text>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Grid item>
                                <Text fontWeight={"bold"}>Lugar de la clase:</Text>
                                <Text> {claseParticular?.lugar_clase?.valor ? (claseParticular?.lugar_clase?.valor).toUpperCase() : "Sin rellenar"}</Text>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container mt={4}>
                        <Line />
                    </Grid>

                    <Grid container mt={2} spacing={2}>
                        <Grid item xs={7}>
                            <Stack>
                                <Text fontWeight={"bold"}>Observaciones:</Text>
                                <Text> {claseParticular?.observaciones ? (claseParticular?.observaciones).toUpperCase() : "Sin rellenar"}</Text>
                            </Stack>
                        </Grid>

                        <Grid item xs={5}>
                            <Grid item>
                                <Text fontWeight={"bold"}>Encuesta telefónica:</Text>
                                <Text> {claseParticular?.encuestado ? "Sí" : "No"}</Text>
                            </Grid>

                            <Grid item my={1}>
                                <Text fontWeight={"bold"}>Alumno del Colectivo:</Text>
                                <Text> {claseParticular?.alumno_colectivo ? "Sí" : "No"}</Text>
                            </Grid>

                            <Grid item>
                                <Text fontWeight={"bold"}>Avisado del comienzo de las clases:</Text>
                                <Text> {claseParticular?.encuestado ? "Sí" : "No"}</Text>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container mt={4}>
                        <Line />
                    </Grid>

                    <Grid container mt={2} spacing={2}>
                        <Grid item xs={6} md={4}>
                            <Text fontWeight={"bold"}>Fecha de inscripción:</Text>
                            <Text> {claseParticular?.fecha_alta ?? "Sin rellenar"}</Text>
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <Text fontWeight={"bold"}>Fecha de inicio:</Text>
                            <Text> {claseParticular?.fecha_inicio ?? "Sin rellenar"}</Text>
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <Text fontWeight={"bold"}>Fecha de baja:</Text>
                            <Text> {claseParticular?.fecha_baja ?? "Sin rellenar"}</Text>
                        </Grid>
                    </Grid>

                    <Grid container mt={4}>
                        <Line />
                    </Grid>

                    <Grid container mt={2} spacing={2}>
                        <Grid item xs={6} py={3} pr={2}>
                            <Stack>
                                <H3 fontWeight={"bold"}>Profesor de la clase particular</H3>
                                <Text>
                                    {claseParticular?.profesor?.nombre && claseParticular?.profesor?.apellido1 && claseParticular.profesor?.apellido2
                                        ? `${claseParticular.profesor.nombre} ${claseParticular.profesor.apellido1} ${claseParticular.profesor.apellido2}`
                                        : "Sin rellenar"}
                                </Text>
                            </Stack>
                        </Grid>

                        <Grid item xs={6} py={3} pr={2}>
                            <Grid item>
                                <Text fontWeight={"bold"}>Sexo:</Text>
                                <Text> {claseParticular?.profesor?.sexo?.valor ?? "Sin rellenar"} </Text>
                            </Grid>

                            <Grid item>
                                <Text fontWeight={"bold"}>Origen del profesor:</Text>
                                <Text> {claseParticular?.origen_profesor?.valor ?? "Sin rellenar"} </Text>
                            </Grid>
                        </Grid>
                    </Grid>
                </>

                : <Skeleton />}
        </Grid>
    )
}