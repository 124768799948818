//import axios from "axios"
import store from 'store/store'
import axios from 'objects/common/axiosconfig'

export default class Request {

    /**
     * Constructor
     */
    constructor() {
        this.headers = {}

        const { userState } = store.getState()
        if (userState.token)
            this.headers.Authorization = 'Bearer ' + userState.token
    }


    /**
     * Petición GET
     *
     * @param {String}   url
     * @param {Object}   [params = {}]
     * @param {Function} [callback=null]
     * @param {Function} [callbackError=null]
     * @param {Function} [callbackFinally=null]
     */
    get(url, params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        axios.get(url, { params, headers: this.headers })
            .then(function (response) {
                if (response.data.error) {
                    callbackError(response.data.error)
                }
                else {
                    callback(response.data)
                }
            })
            .catch(function (error) {
                const customError = error.response.status !== 500 ? error.response.data.error ?? error.response.data.errors : "Error del servidor."
                callbackError(customError)
            })
            .then(function () {
                callbackFinally()
            });
    }


    /**
     * Petición GET para obtener PDFS
     *
     * @param {String}   url
     * @param {Object}   [params = {}]
     * @param {Function} [callback=null]
     * @param {Function} [callbackError=null]
     * @param {Function} [callbackFinally=null]
     */
    file(url, params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        axios.get(url, { params, headers: this.headers, responseType: 'blob' })
            .then(function (response) {
                callback(response.data)
            })
            .catch(function (error) {
                var customError = error.response.status !== 500 ? error.response.data.error : "Error del servidor."
                callbackError(customError)
            })
            .finally(function () {
                callbackFinally()
            });
    }


    /**
     * Petición POST
     *
     * @param {String}   url
     * @param {Object}   [params = {}]
     * @param {Function} [callback=null]
     * @param {Function} [callbackError=null]
     * @param {Function} [callbackFinally=null]
     */
    post(url, params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        axios.post(url, params, { headers: this.headers })
            .then(function (response) {
                if (response.data.error)
                    callbackError(response.data.error)
                else {
                    callback(response.data)
                }
            })
            .catch(function (error) {
                const customError = error.response.status !== 500 ? error.response.data.error ?? error.response.data.errors : "Error del servidor."
                callbackError(customError)
            })
            .then(function () {
                callbackFinally()
            });
    }


    /**
     * Petición PUT
     *
     * @param {String}   url
     * @param {Object}   [params = {}]
     * @param {Function} [callback=null]
     * @param {Function} [callbackError=null]
     * @param {Function} [callbackFinally=null]
     */
    put(url, params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        params.append("_method", "put")

        axios.post(url, params, { headers: this.headers })
            .then(function (response) {
                if (response.data.error)
                    callbackError(response.data.error)
                else {
                    callback(response.data)
                }
            })
            .catch(function (error) {
                const customError = error.response.status !== 500 ? error.response.data.error ?? error.response.data.errors : "Error del servidor."
                callbackError(customError)
            })
            .then(function () {
                callbackFinally()
            });
    }



    /**
     * Petición DELTE
     *
     * @param {String}   url
     * @param {Object}   [params = {}]
     * @param {Function} [callback=null]
     * @param {Function} [callbackError=null]
     * @param {Function} [callbackFinally=null]
     */
    delete(url, params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        params.append("_method", "delete")

        axios.post(url, params, { headers: this.headers })
            .then(function (response) {
                if (response.data.error)
                    callbackError(response.data.error)
                else {
                    callback(response.data)
                }
            })
            .catch(function (error) {
                const customError = error.response.status !== 500 ? error.response.data.error ?? error.response.data.errors : "Error del servidor."
                callbackError(customError)
            })
            .then(function () {
                callbackFinally()
            });
    }
}
