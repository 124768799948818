import React, { useState, useEffect, useContext } from 'react'
import { GeneralData, IdiomasData, ProgramacionesData } from './parts'
import { Container, Paper, Stack, Box, Tab, Tabs } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { BreadcrumbsList, MoveButtons, Line } from 'components/data_display'
import { Text } from 'components/texts/index.js'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { COLOR } from 'theme/Colors.js'
import Requests from './Requests/Requests.js'
import { TabContext } from '@mui/lab'
import { enqueueSnackbar } from 'notistack'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'


export default function SingleGruposAcademia() {

    //HOOKS
    const navigationParams = useParams()
    const navigate = useNavigate()

    const { items } = useContext(EmpleadoListContext)
    //STATE
    const [id_grupo, setIdGrupo] = useState(navigationParams.id_grupo)
    const [grupo, setGrupo] = useState(null)
    const [tab, setTab] = useState("1")

    const updateId = (id) => {
        navigate(`../academias/grupos/${id}`);
        setIdGrupo(id);
    }

    useEffect(() => {
        const grupo = items?.find(item => item?.id == id_grupo)
        if (items.length == 0 || !grupo) {
            new Requests().getGrupo(id_grupo, setGrupo, () => enqueueSnackbar(<Text>No se ha podido obtener el grupo.</Text>, { variant: "error" }))
        } else {
            setGrupo(grupo)
        }
    }, [id_grupo])

    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>

            <Stack direction="row" justifyContent={"space-between"} marginX={8}>
                <BreadcrumbsList mx={0} breadcrumbsList={[
                    { to: "/", label: "Inicio" },
                    { to: "./../", label: "Grupos" },
                    { to: "./", label: "Grupo Academias #" + (id_grupo ?? "0000"), actualItem: true },
                ]} />

                <MoveButtons
                    actualItemId={id_grupo}
                    beforeFetchCallback={() => setGrupo(null)}
                    onUpdateActualItem={updateId} />
            </Stack>

            <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>

                <Line className={"my-5"} />

                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                        <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                            <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS GENERALES</Text>} value={"1"} />
                            <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>IDIOMAS</Text>} value={"2"} />
                            <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>PROGRAMACIONES</Text>} value={"3"} />
                        </Tabs>
                    </Box>

                    <TabPanel value={"1"}>
                        <GeneralData grupo={grupo} />
                    </TabPanel>

                    <TabPanel value={"2"}>
                        <IdiomasData grupo={grupo} />
                    </TabPanel>

                    <TabPanel value={"3"}>
                        <ProgramacionesData grupo={grupo} />
                    </TabPanel>
                </TabContext>

            </Paper>
        </Container>
    )
}
