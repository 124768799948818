import { Grid } from '@mui/material';
import { Text } from 'components/texts'
import React, { useEffect } from 'react'
import { LibroTemplate } from './parts';
import { useForm } from 'react-hook-form'
import Statics from 'objects/common/Statics';
import { RequestLibro } from 'objects/requests'
import { Select } from 'components/forms/components'
import { SearchButtons } from 'components/forms/display/components';

const itemsOrder  = [{ value: '{"nombre": "ASC"}', label: "Libro" }, { value: '{"id_editorial": "ASC"}', label: "Editorial" }]

let prepareParamsGlobal = null

//Formulario de busqueda de libros
export default function SearchFormLibros({ onLoadingCallback, searchCallback }) {
    //Form
	const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm()

    //State
    const [resetFormState, setResetFormState] = React.useState(false);

    useEffect(() => {
        if(resetFormState == true) {
            resetForm(reset)
            setResetFormState(false)
        }
    })

    //-------------------------------------------------------
    //---------------------- FUNCTIONS ----------------------
    //-------------------------------------------------------
      
    /**
     * Prepara los parametros que se van a usar
     * 
     * @param {Int} page
     */
    const prepareParams = (page) => {
        const params      = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()));
        params.activo     = params.activo ? 1 : 0
        params.relations  = 'pedidos_libro,devoluciones_libro,niveles_libro,editorial'
        params.atributtes = 'numVentasCurso'
        params.page       = page

        return params
    }

    prepareParamsGlobal = prepareParams

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    return (
        <Grid container p={2}>
            <Grid container>
                <Grid item xs={4} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Ordenar resultados por:</Text><br />
                    <Select width={"60%"} name={"order"} items={itemsOrder} defaultValue={itemsOrder[0].value} register={register} errors={errors}/>
                </Grid>
            </Grid>
            
            <LibroTemplate reset={resetFormState} register={register} errors={errors}/>
            
            <SearchButtons searchText={"Buscar libros"} onResetClick={() => {setResetFormState(true)}} onSubmitClick={handleSubmit(() => {search(1, onLoadingCallback, searchCallback)})}/>
        </Grid>
    )
}



/**
 * Hace la busqueda de alumnos
 * 
 * @param {Int}      page
 * @param {Function} onLoadingCallback
 * @param {Function} searchCallback
 * @param {Function} prepareParams
 */
export const search = (page = 1, onLoadingCallback, searchCallback) => {
    //Callback para controlar cuando esta cargando
    if(onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal(page)
    new RequestLibro().search(params, (res) => {
        if(searchCallback)
            searchCallback(res.items.data, res.items.total, page, params)
    }, () => {
        searchCallback([], 0, page, params)
    })
}


/**
 * Resetea todo el formulario
 * 
 * @param {Function} reset
 */ 
const resetForm = (reset) => {
    reset({ nombre: "" })
}