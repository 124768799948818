import React from "react"
import { Grid } from "@mui/material"
import { Text } from 'components/texts'
import { Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'


export default function InscripcionesColectivosAcademia({ register, errors, reset }) {

    return (
        <>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>
            <Grid container spacing={2} mb={2}>

                <Grid item xs={4}>
                    <Input label="academia" width={"100%"} name={"academia"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect name={"id_actividades"} keyValue={"actividad"} register={register} errors={errors} wantToReset={reset} searcheable/>
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect label={"Año"} name={"id_curso_escolar"} keyValue={"curso_escolar"} register={register} errors={errors} wantToReset={reset}/>
                </Grid>

            </Grid>
        </>
    )
}