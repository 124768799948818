import { RequestRegistroActividad } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Devuelve la actividad
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getActividad(id, callbackOk = () => {}, callbackError = () => {}) {
        new RequestRegistroActividad().get(id, {relations: 'tipo_actividad,entity,modalidad_cursos,tipo_centro,actividad'}, (res) => {
            res.actividad ? callbackOk(res.actividad) : callbackError(res)
        })
    }
}
