import React from 'react'
import { LoginForm } from 'components/forms/display'

export default function Login() {
  //RETURN
  return (
    <div>
      <LoginForm />
    </div>
  )
}
