import React, { Component } from 'react'
import { MenuItem } from '../../../../../../../components/navigation'
import {
    SportsBasketball as ActividadesIcon,
    Article as InscripcionesIcon,
    School as AulasIcon,
    Groups as GruposIcon,
} from '@mui/icons-material'

export default class AcademiaMenu extends Component {

    /**
     * ComponentDidMount
     * 
     * Inicializamos los componentes necesarios del tema
     */
    componentDidMount() {
        window.KTMenu?.createInstances()
        window.KTToggle?.createInstances()
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <div className="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">

                <div className="menu-item pt-5">
                    <div className="menu-content">
                        <span className="menu-heading fw-bold text-uppercase fs-7">Menú academia</span>
                    </div>
                </div>

                <MenuItem title="Actividades" to="./academias/actividades" icon={<ActividadesIcon />} />
                <MenuItem title="Inscripciones" to="./academias/inscripciones" icon={<InscripcionesIcon />} />
                <MenuItem title="Grupos" to="./academias/grupos" icon={<GruposIcon />} />
                <MenuItem title="Aulas" to="./academias/aulas" icon={<AulasIcon />} />
            </div>
        )
    }
}
