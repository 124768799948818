import { H3 } from 'components/texts'
import { columns } from './columns.js'
import { Box, Paper } from '@mui/material'
import Container from '@mui/material/Container'
import React, { useContext, useEffect, useState } from 'react'
import { ListTableHeader } from 'pages/logged/admin/components'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material'
import { Accordion, BreadcrumbsList, Datagrid, Link } from 'components/data_display'
import SearchFormIncidencia, { search } from 'components/forms/display/incidencia/SearchForm'

/**
 * Listado de las incidencias
 */
export default function ListadoIncidencias() {
  const [data,         setData]         = useState({data: [], rowCount: 0})
  const [loadingTable, setLoadingTable] = useState(false)
  const [params,       setParams]       = useState(null)

  const { update, resetContextState } = useContext(EmpleadoListContext)

  useEffect(() => {
    setLoadingTable(false)
  }, [data])

  useEffect(resetContextState, [])

  /**
   * Callback para actualizar la tabla una vez hecha la petición
   * 
   * @param {Array} data 
   * @param {Int}   rowCount 
   */
  const updateTable = (data, rowCount, page, params) => {
    setData({data, rowCount})
    update(rowCount, data, params, page)
    setParams(params)
  }
  
  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { to: "/",          label: "Inicio" },
        { to: "../alumnos", label: "Alumnos" },
        { to: "./",         label: "Incidencias", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar incidencias</H3></Box>}>
        <SearchFormIncidencia
          searchCallback    = {updateTable}
          onLoadingCallback = {setLoadingTable} />
      </Accordion>

      <Paper elevation={2} sx={{mx: 8, my: 2, p: 5}}>
        <ListTableHeader rowCount={data.rowCount} params={params} keyValue={"incidencias"}/>

        <Datagrid
          rows         = {data.data}
          columns      = {columns}
          loading      = {loadingTable}
          rowCount     = {data.rowCount}
          onPageChange = {(page) => { search(page, () => setLoadingTable(true), updateTable) }}
          serverSide />
      </Paper>
    </Container>
  )
}
