import React, { Component } from 'react'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Box } from '@mui/material'

/**
 * Esta es la plantilla para cuando se busque por los datos del domicilio del alumno
 */
export default class Accordion extends Component {
    
    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)
    }

    /**
     * Render
     *
     * @returns {Component}
     */
    render() {
        return (
            <Box my={2} mx={8}>
                <MuiAccordion>
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className='border-bottom align-items-center' expandIcon={<ExpandMoreIcon />}>
                        {this.props.header ?? null}
                    </AccordionSummary>
                    <AccordionDetails>
                        {this.props.children}
                    </AccordionDetails>
                </MuiAccordion>
            </Box>
        )
    }
}
