import { RequestHorario } from 'objects/requests'

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {
    /** 
     * Hace la petición para crear un horario
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createHorario(inscripcion_id, getValues, callbackOk = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const formData = new FormData()
        const valuesDias = getValues(['l', 'm', 'x', 'j', 'v', 's'])

        valuesDias.forEach((valor, indice) => {
            if (valor)
                formData.append("dias[]", indice + 1)
        })
        formData.append("curso_escolar_id", getValues("curso_escolar_id"))
        formData.append("aula_id", getValues("aula_id"))
        formData.append("entity_id", inscripcion_id)
        formData.append("entity_type", "academia")
        formData.append("color", getValues("color").toUpperCase())
        formData.append("hora_inicio", getValues("hora_inicio"))
        formData.append("hora_fin", getValues("hora_fin"))
        formData.append("lugar_clase_id", 1)

        new RequestHorario().create(formData, callbackOk, callbackError, callbackFinally)
    }

    /** 
     * Hace la petición para eliminar una definicion del horario
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    deleteDefinicionHorario(id, callbackOk, callbackError) {
        new RequestHorario().deleteDefinicionHorario(id, {}, callbackOk, callbackError)
    }
}