import { Grid, Stack } from "@mui/material";
import Skeleton from '@mui/material/Skeleton'
import { Line } from "components/data_display";

export default function DataFormInscripcionClasesParticularesSkeleton() {
    return (
        <Grid container>

            <Grid container px={5}>
                <Grid item xs={6} py={3} pr={2}>
                    <Skeleton variant="rounded" width={"100%"} height={100} className={""} />
                </Grid>

                <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9" }} py={3} pl={3}>
                    <Stack spacing={2}>
                        <Skeleton variant="rounded" width={"100%"} height={25} className={"my-2"} />
                        <Skeleton variant="rounded" width={"100%"} height={25} className={"my-2"} />
                        <Skeleton variant="rounded" width={"100%"} height={25} className={"my-2"} />
                    </Stack>
                </Grid>
            </Grid>

            <Grid container>
                <Line />
            </Grid>

            <Grid container px={5} mt={2} spacing={2}>
                <Grid item xs={4} md={4}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>

                <Grid item xs={4} md={4}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>
                <Grid item xs={4} md={4}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>
            </Grid>

            <Grid container px={5} mt={2} spacing={2}>
                <Grid item xs={7}>
                    <Skeleton variant="rounded" width={"100%"} height={100} />
                </Grid>

                <Grid item xs={2}>
                    <Skeleton variant="rounded" width={"100%"} height={25} className="mt-2" />
                    <Skeleton variant="rounded" width={"100%"} height={25} className="my-2" />
                    <Skeleton variant="rounded" width={"100%"} height={25} className="mb-2" />
                </Grid>
            </Grid>

            <Grid container px={5} mt={2} spacing={2}>
                <Grid item xs={6} md={4}>
                    <Skeleton variant="rounded" width={"100%"} height={25} className="my-2" />
                </Grid>

                <Grid item xs={6} md={4}>
                    <Skeleton variant="rounded" width={"100%"} height={25} className="my-2" />
                </Grid>

                <Grid item xs={6} md={4}>
                    <Skeleton variant="rounded" width={"100%"} height={25} className="my-2" />
                </Grid>
            </Grid>

            <Grid container mt={4}>
                <Line />
            </Grid>

            <Grid container px={5} mt={2} spacing={2}>
                <Grid item xs={6} py={3} pr={2}>
                    <Skeleton variant="rounded" width={"100%"} height={100} className="my-2" />
                </Grid>

                <Grid item xs={6} py={3} pr={2}>
                    <Skeleton variant="rounded" width={"100%"} height={25} className="my-2" />
                    <Skeleton variant="rounded" width={"100%"} height={25} className="my-2" />
                </Grid>
            </Grid>

        </Grid>
    )
}