import { RequestColegio } from 'objects/requests'

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para editar el responsable
     * 
     * @param {Int} id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editColegio(id, getValues, callbackOk = () => {}, callbackError = () => {}) {
        const formData = new FormData()

        formData.append("nombre",           getValues("nombre").toUpperCase())
        formData.append("clave",            getValues("clave").toUpperCase())
        formData.append("centro_pa",        getValues("centro_pa"))
        formData.append("activo",           getValues("activo") ? 1 : 0)
        formData.append("director",         getValues("director").toUpperCase())
        formData.append("persona_contacto", getValues("persona_contacto").toUpperCase())
        formData.append("telefono1",        getValues("telefono1"))
        formData.append("telefono2",        getValues("telefono2"))
        formData.append("movil",            getValues("movil"))
        formData.append("email",            getValues("email").toUpperCase())
        formData.append("email2",           getValues("email2").toUpperCase())
        formData.append("ampa",             getValues("ampa").toUpperCase())
        formData.append("otros_datos",      getValues("otros_datos").toUpperCase())
        formData.append("direccion",        getValues("direccion").toUpperCase())
        formData.append("cod_postal",       getValues("cod_postal"))
        formData.append("municipio",        getValues("municipio").toUpperCase())
        formData.append("id_provincia",     getValues("id_provincia"))
        formData.append("id_empleado",      getValues("id_empleado") ?? "")

        new RequestColegio().edit(id, formData, callbackOk, callbackError)
    }


    /**
     * Devuelve el colegio
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getColegio(id, callbackOk = () => {}, callbackError = () => {}) {
        new RequestColegio().get(id, {relations: 'coordinador'}, (res) => {
            res.colegio ? callbackOk(res.colegio) : callbackError(res)
        })
    }
}
