import { responses } from 'constants/Responses'
import { AlumnoData, TutoriaData } from './parts'
import { RequestTutoria } from 'objects/requests'
import React, { useState, useEffect } from 'react'
import { Container, Paper, Stack } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { BreadcrumbsList, Line, MoveButtons} from 'components/data_display'

export default function SingleTutoria() {
  const navigationParams = useParams()
  const navigate = useNavigate()

  const [id_tutoria, setIdTutoria] = useState(navigationParams.id_tutoria)
  const [tutoria,    setTutoria]   = useState(null)

  /**
   * Hace la petición para obtener el usuario
   */
  const getTutoria = () => {
    const params = { relations: 'alumno' }

    new RequestTutoria().get(id_tutoria, params, (res) => {
      if (res[responses.tutorias.singleName])
        setTutoria(res[responses.tutorias.singleName])
    })
  }

  const updateId = (id) => {
    navigate(`../alumnos/tutorias/${id}`);
    setIdTutoria(id);
  }

  useEffect(getTutoria, [id_tutoria])

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/",     label: "Inicio" },
            { to: "./../", label: "Tutorías" },
            { to: "./",    label: "Tutoría #" + (id_tutoria ?? "0000"), actualItem: true },
          ]} />

          <MoveButtons 
            actualItemId        = {id_tutoria}
            beforeFetchCallback = {() => setTutoria(null)} 
            onUpdateActualItem  = {updateId} />
        </Stack>
        
        <Paper elevation={2} sx={{mx: 8, my: 2, p: 5}}>
          
          <TutoriaData tutoria={tutoria} />

          <Line className={"my-10"}/>

          <AlumnoData tutoria={tutoria} />
          
        </Paper>
      </Container>
  )
}
