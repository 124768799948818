import { WarningNotSavedModal } from 'components/modals'
import { ChangesContext } from 'contexts/ChangesContext'
import React, { useContext, useRef } from 'react'
import { NavLink as NavLinkReactRouter, useNavigate } from 'react-router-dom'

/**
 * Custom NavLink
 */
export default function NavLink({ to, children }) {

  const { hasChanges, updateChanges } = useContext(ChangesContext) || { hasChanges: false }
  const navigate = useNavigate()

  const modalRef = useRef()

  const handleClick = (e) => {
    e.preventDefault()
    hasChanges ? modalRef.current.modal.open() : navigate(to)
  }

  return (
    <React.Fragment>
      <NavLinkReactRouter
        onClick   = {handleClick}
        className = {({ isActive }) => isActive ? 'active menu-link' : 'menu-link'}
        to={to}>
        
        {children}
      </NavLinkReactRouter>

      <WarningNotSavedModal 
        ref        = {modalRef} 
        onGoBack   = {() => {modalRef.current.modal.close()}}
        onContinue = {() => {
          modalRef.current.modal.close()
          updateChanges(false)
          navigate(to)
        }}/>
    </React.Fragment>
  )
}