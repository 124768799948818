import { Navigate } from 'react-router-dom'
import { publicRoutes } from '../routes'
import { useSelector } from 'react-redux'

const EmployeeRouter = ({ children }) => {
    const user  = useSelector((state) => state.userState.user)
    const roles = useSelector((state) => state.userState.roles)
    const token = useSelector((state) => state.userState.token)

    if(!user || roles.length == 0 || !token)
        return <Navigate replace to={publicRoutes.HOME} />
    
    if(!roles.includes('admin') && !roles.includes('profesor') && !roles.includes('personal-oficina'))
        return <Navigate replace to={publicRoutes.HOME} />
    
    return children
}

export default EmployeeRouter