import { RequestAlumno, RequestDomiciliacionBancaria } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para editar el usuario
     * 
     * @param {Int} id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editAlumno(id, getValues, callbackOk = () => {}, callbackError = () => {}) {
        var formData = new FormData()
        formData.append("nombre",                  getValues("nombre").toUpperCase())
        formData.append("apellido1",               getValues("apellido1").toUpperCase())
        formData.append("apellido2",               getValues("apellido2").toUpperCase())
        formData.append("email",                   getValues("email"))
        formData.append("numero_documento",        getValues("numero_documento").toUpperCase())
        formData.append("id_tipo_documento",       getValues("id_tipo_documento"))
        formData.append("nombre_via",              getValues("nombre_via").toUpperCase())
        formData.append("id_tipo_via",             getValues("id_tipo_via"))
        formData.append("id_sexo",                 getValues("id_sexo"))
        formData.append("cod_postal",              getValues("cod_postal"))
        formData.append("pais_nacimiento",         getValues("pais_nacimiento").toUpperCase())
        formData.append("id_provincia",            getValues("id_provincia"))
        formData.append("id_provincia_nacimiento", getValues("id_provincia_nacimiento"))
        formData.append("municipio",               getValues("municipio").toUpperCase())
        formData.append("municipio_nacimiento",    getValues("municipio_nacimiento").toUpperCase())
        formData.append("telefono1",               getValues("telefono1"))
        formData.append("telefono2",               getValues("telefono2"))

        if(getValues("foto_logo")[0])
            formData.append("foto_logo", getValues("foto_logo")[0])

        new RequestAlumno().edit(id, formData, callbackOk, callbackError)
    }


    /**
     * Hace la peticion para editar los datos bancarios del usuario
     * 
     * @param {Int} id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editDatosBancarios(id, getValues, callbackOk = () => {}, callbackError = () => {}) {
        var formData = new FormData()
        formData.append("codigo_pais",        getValues("codigo_pais"))
        formData.append("numero_control",     getValues("numero_control"))
        formData.append("cuenta1",            getValues("cuenta1"))
        formData.append("iban",               getValues("iban").toUpperCase())
        formData.append("nombre_deudor",      getValues("nombre_deudor").toUpperCase())
        formData.append("referencia_mandato", getValues("referencia_mandato").toUpperCase())

        new RequestDomiciliacionBancaria().edit(id, formData, callbackOk, callbackError)
    }


    /**
     * Devuelve el alumno
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    get(id, callbackOk = () => {}, callbackError = () => {}) {
        let params = { relations: 'sexo,tipo_documento,provincia,tipo_via,provincia_nacimiento,domiciliacion_bancaria' }
        
        new RequestAlumno().get(id, params, (res) => {
            res.alumno ? callbackOk(res.alumno) : callbackError(res)
        })
    }
}
