import React from 'react'
import { useDispatch } from "react-redux"
import StartIcon from '@mui/icons-material/Start'
import { Text } from '../../../../../components/texts'
import { COLOR } from '../../../../../theme/Colors'
import { deleteUser } from '../../../../../store/actions/userActions'
import { persistStore } from 'redux-persist'
import store from '../../../../../store/store.js'

/**
 * LogoutButton
 */
export default function LogoutButton() {
	const dispatch = useDispatch()

  const persistor = persistStore(store)
        

  /**
   * Hace el logout del usuario
   */
  const logOut = () => {
    persistor.purge()

    dispatch(deleteUser())
  }


  /**
   * Render
   * 
   * @returns {Component}
   */
  return (
    <div className="px-10 d-flex clickable" onClick={() => {logOut()}}>
        <StartIcon />

        <div className="mx-5">
          <Text color={COLOR.standard.main}>Cerrar sesión</Text>
        </div>
    </div>
  )
}
