import { Grid } from '@mui/material'
import React from 'react'
import { Text } from 'components/texts'
import { Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Esta es la plantilla para cuando se busque por los datos basicos del grupo en una inscripcion
 */
export default function InscripcionGruposAcademiaTemplate({ register, errors, reset }) {

    return (
        <>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>
            <Grid container spacing={2} mb={2}>

                <Grid item xs={6}>
                    <Input label="academia" width={"100%"} name={"academia"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect label={"Año"} name={"id_curso_escolar"} keyValue={"curso_escolar"} register={register} errors={errors} wantToReset={reset}/>
                </Grid>
            </Grid>
        </>
    )
}





