import Statics from 'objects/common/Statics'
import { RequestGrupoEmpleado, RequestGrupoColegio, RequestSeguimientoTrimestralGrupoColegio } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para crear el colegio
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getGrupo(id, callbackOk = () => {}, callbackError = () => {}) {
        const params = {relations: 'seguimiento_trimestral,profesor_actual,libro,colegio,actividad,profesores,horario'}
        new RequestGrupoColegio().get(id, params, callbackOk, callbackError)
    }


    /**
     * Devuelve la vista preliminar
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getPreliminar(id, callbackOk = () => {}, callbackError = () => {}) {
        new RequestGrupoColegio().preliminar(id, callbackOk, callbackError)
    }

    
    /**
     * Hace la petición para crear el grupo
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editGrupo(id, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData
        formData.append("clave", getValues("clave").toUpperCase())
        formData.append("id_curso_escolar", getValues("id_curso_escolar"))
        formData.append("id_colegio", getValues("id_colegio"))
        formData.append("id_registro_actividad", getValues("id_registro_actividad"))
        formData.append("id_descripcion_horario", getValues("id_descripcion_horario"))
        formData.append("id_nivel_grupo",         getValues("id_nivel_grupo") ?? "")
        formData.append("curso_escolar_string",   getValues("curso_escolar") ? getValues("curso_escolar").toUpperCase() : "")
        formData.append("num_grupo",              getValues("num_grupo") ?? "")

        const fecha_inicio = getValues("fecha_inicio")
        const fecha_fin = getValues("fecha_fin")
        formData.append("fecha_inicio", Statics.datePickerTimeFormat(fecha_inicio) ?? "")
        formData.append("fecha_fin", Statics.datePickerTimeFormat(fecha_fin) ?? "")

        formData.append("min_alumnos", getValues("min_alumnos") ?? "")
        formData.append("max_alumnos", getValues("max_alumnos") ?? "")
        formData.append("observaciones", getValues("observaciones") ? getValues("observaciones").toUpperCase() : "")
        //IDIOMAS
        formData.append("id_libro", getValues("id_libro") ?? "")
        formData.append("usar_libro_asociado", getValues("usar_libro_asociado") ? 1 : 0)
        formData.append("usar_progress_sheet", getValues("usar_progress_sheet") ? 1 : 0)

        new RequestGrupoColegio().edit(id, formData, callbackOk, callbackError)
    }

    
    editSeguimientoTrimestralGrupo(id_seguimiento_trimestral, getValues, callbackOk = () => {}, callbackError = () => {}) {
        const formData = new FormData

        /* -- T1 -- */
        const fecha_entrega_t1 = getValues("fecha_entrega_t1")
        formData.append("fecha_entrega_t1", Statics.datePickerTimeFormat(fecha_entrega_t1) ?? "")
        formData.append("objetivos_alcanzados_t1", getValues("objetivos_alcanzados_t1") ? getValues("objetivos_alcanzados_t1").toUpperCase() : "")
        formData.append("evolucion_alumnos_t1", getValues("evolucion_alumnos_t1") ? getValues("evolucion_alumnos_t1").toUpperCase() : "")
        formData.append("conclusiones_observaciones_t1", getValues("conclusiones_observaciones_t1") ? getValues("conclusiones_observaciones_t1").toUpperCase() : "")

        /* -- T2 -- */
        const fecha_entrega_t2 = getValues("fecha_entrega_t2")
        formData.append("fecha_entrega_t2", Statics.datePickerTimeFormat(fecha_entrega_t2) ?? "")
        formData.append("objetivos_alcanzados_t2", getValues("objetivos_alcanzados_t2") ? getValues("objetivos_alcanzados_t2").toUpperCase() : "")
        formData.append("evolucion_alumnos_t2", getValues("evolucion_alumnos_t2") ? getValues("evolucion_alumnos_t2").toUpperCase() : "")
        formData.append("conclusiones_observaciones_t2", getValues("conclusiones_observaciones_t2") ? getValues("conclusiones_observaciones_t2").toUpperCase() : "")

        /* -- T3 -- */
        const fecha_entrega_t3 = getValues("fecha_entrega_t3")
        formData.append("fecha_entrega_t3", Statics.datePickerTimeFormat(fecha_entrega_t3) ?? "")
        formData.append("objetivos_alcanzados_t3", getValues("objetivos_alcanzados_t3") ? getValues("objetivos_alcanzados_t3").toUpperCase() : "")
        formData.append("evolucion_alumnos_t3", getValues("evolucion_alumnos_t3") ? getValues("evolucion_alumnos_t3").toUpperCase() : "")
        formData.append("conclusiones_observaciones_t3", getValues("conclusiones_observaciones_t3") ? getValues("conclusiones_observaciones_t3").toUpperCase() : "")

        new RequestSeguimientoTrimestralGrupoColegio().put(id_seguimiento_trimestral, formData, callbackOk, callbackError)
    }


    editEmpleadoGrupo(grupo, getValues, callbackOk = () => {}, callbackError = () => {}) {
        if(grupo.profesor_actual && grupo.profesor_actual.empleado_id == getValues("id_empleado")) {
            callbackOk()
        } else {
            const formData = new FormData()
            formData.append("empleado_id", getValues("id_empleado") ?? "")
            formData.append("grupo_type",  "colegio")
            formData.append("grupo_id",    grupo.id)

            new RequestGrupoEmpleado().put("", formData, callbackOk, callbackError)
        }
    }
}
