import { Grid, Stack } from "@mui/material"
import { H3, Text } from "components/texts"
import SkeletonGroupAcademiaData from "./SkeletonGroupAcademiaData"

/**
 * Formulario con los datos de grupos-inscripciones academia
 */
export default function DataFormGrupoAcademias({ inscripcion }) {
    return (
        <Grid container p={3}>
            {!inscripcion ?
                <SkeletonGroupAcademiaData />
                : (
                    <>
                        <Grid container item xs={12} md={9} px={4} mt={2} >
                            <Grid item xs={12}>
                                <H3>Información del Grupo seleccionado</H3>
                            </Grid>

                            <Grid item xs={6} md={4} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Grupo</Text>
                                    <Text>{inscripcion?.grupo_academia?.clave_grupo ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={6} md={4} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Alumnos</Text>
                                    <Text>{inscripcion?.grupo_academia?.grupo_inscripciones.length ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={6} md={4} mt={2} >
                                <Stack>
                                    <Text fontWeight={"bold"}>Horario</Text>
                                    <Text>{inscripcion?.preferencia_horario?.valor ?? ""}</Text>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={4} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Nivel</Text>
                                    <Text>{inscripcion.nivel ?? ""}</Text>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={4} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Profesor</Text>
                                    <Text>
                                        {inscripcion?.profesor_actual?.empleado ?
                                            (inscripcion.profesor_actual.empleado.nombre ?? "") + " " + (inscripcion.profesor_actual.empleado.apellido1 ?? "") + " " + (inscripcion.profesor_actual.empleado.apellido2 ?? "")
                                            : ""}
                                    </Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={6} md={4} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Libro</Text>
                                    <Text>{inscripcion?.grupo_academia?.libro?.editorial?.nombre ?? ""}</Text>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid container mt={3} spacing={2} justifyContent={'space-between'} px={4}>
                            <Grid item xs={6} md={4} >
                                <Stack>
                                    <Text fontWeight={"bold"}>Comentarios del profesor:</Text>
                                    <Text>{inscripcion?.comentario_profesor_ap ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={6}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Comentarios generales:</Text>
                                    <Text>{inscripcion?.comentarios_generales ?? ""}</Text>
                                </Stack>
                            </Grid>
                        </Grid>
                    </>
                )
            }
        </Grid>
    )
}