import Timer from './parts/Timer'
import { COLOR } from "theme/Colors"
import { Paper } from '@mui/material'
import { Text, H3 } from 'components/texts'
import { useEffect, useState } from "react"
import Statics from 'objects/common/Statics.js'
import { RequestFichaje } from 'objects/requests'
import ModalIncidencia from './parts/ModalIncidencia'
import { LoadingButton } from 'components/forms/components'
import { enqueueSnackbar, SnackbarProvider } from 'notistack'

/**
 * Componente de fichaje
 * 
 * @returns Component
 */
export default function Fichaje({ containerClassName = ''}) {

    const [coords, setCoords] = useState({latitude: null, longitude: null});
    const [timer, setTimer] = useState({ wantToStart: false, wantToFinish: false, wantToUpdate: false, time: 0, fichaje: null })

    const startFichaje = () => {
        const formData = new FormData()
        formData.append('latitud',  coords.latitude)
        formData.append('longitud', coords.longitude)

        new RequestFichaje().start(formData, (res) => {
            const timeDifference = Statics.calculateTimeDifference(res.hora_inicio, Statics.getCurrentTime())
            setTimer({ fichaje: res, wantToStart: true, wantToFinish: false, wantToUpdate: false, time: timeDifference < 0 ? 0 : timeDifference })
            enqueueSnackbar(<Text>{"Jornada iniciada correctamente."}</Text>, {variant: "success"})
        }, (res) => {
            enqueueSnackbar(<Text>{res}</Text>, {variant: "error"})
        })
    }

    const currentFichaje = () => {
        new RequestFichaje().current((res) => {
            const timeDifference = Statics.calculateTimeDifference(res.hora_inicio, Statics.getCurrentTime())
            setTimer({ fichaje: res, wantToStart: false, wantToFinish: false, wantToUpdate: true, time: timeDifference < 0 ? 0 : timeDifference })
        })
    }

    const endFichaje = () => {
        new RequestFichaje().end(timer.fichaje.id, (res) => {
            setTimer({ wantToStart: false, wantToFinish: true, wantToUpdate: false, time: timer.time, fichaje: timer.fichaje })
            enqueueSnackbar(<Text>{"Jornada finalizada correctamente."}</Text>, {variant: "success"})
        })
    }

    useEffect(() => {
        currentFichaje();
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                currentFichaje();
            }
        };

        // Agregar el oyente del evento visibilitychange
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Limpiar el oyente cuando el componente se desmonte
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    

    useEffect(() => {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log(position.coords)
                    const { latitude, longitude } = position.coords
                    setCoords({latitude, longitude })
                },
                (error) => {
                    console.error("Error getting location:", error);
                    enqueueSnackbar(<Text>{"Es necesario aceptar la localización para poder hacer el fichaje, acepte la geolocalización en el navegador y recargue la página."}</Text>, {variant: "error"})
                }
            );
        } else {
            enqueueSnackbar(<Text>{"Este navegador no soporta la geolocalización."}</Text>, {variant: "error"})
        }
    }, []);

    const missingCoords = (coords.latitude == null || coords.longitude == null) ? true : false
    return (
        <Paper elevation={0} sx={{p: 5, borderRadius: 2, border: "1px solid var(--greyLight)"}} className={`d-flex justify-content-center flex-column ${containerClassName}`}>
            <div className="d-flex flex-row justify-content-between"> 
                <H3>FICHAJE</H3>

                <ModalIncidencia />
            </div>

            <Text className="text-center bold mb-5 mt-5" style={{color: COLOR.standard.main}} variant="titleMedium">{Statics.capitalizeFirstLetter(Statics.getCurrentDate())}</Text>

            <Timer timer={timer} setTimer={setTimer}/>

            {missingCoords ? <Text className="mt-5">No se han podido obtener las coordenadas, permita el acceso a las coordenadas en el navegador y recargue la página.</Text> : null }

            {!timer.fichaje ? 
                <LoadingButton disabled={missingCoords} className="mt-5" onClick={startFichaje} contentStyle={{ height: 70 }} colorVariant={`success`}>
                    EMPEZAR JORNADA
                </LoadingButton>
                :
                <LoadingButton className="mt-5" onClick={endFichaje} contentStyle={{ height: 70 }} colorVariant={`error`}>
                    FINALIZAR JORNADA
                </LoadingButton>
            }

            <SnackbarProvider autoHideDuration={7000}/>
        </Paper>
    );
}