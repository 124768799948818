import React, { Component } from "react"
import { ASSETS } from 'constants/Assets'
import { H3, Text } from 'components/texts'
import { Button } from 'components/forms/components'
import { SearchAlumnoModal } from 'components/modals'
import { Add, Delete, Edit } from '@mui/icons-material'
import { Avatar, Box, Stack, TextField } from "@mui/material"

export default class AlumnoPicker extends Component {

  constructor(props) {
    super(props)
    this.state = {
        alumno: this.props.alumno ?? null,
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.alumno != this.props.alumno)
        this.setState({alumno: this.props.alumno})
  }


  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    const { alumno } = this.state
    const name = this.props.name ?? "id_alumno"

    return (
        <>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <H3 className={"m-0"}>{this.props.label ?? ""}</H3>
                {this.props.canEdit ? (
                    <Stack direction={"row"} spacing={2}>
                        <Button size={"small"} color={"success"} startIcon={alumno ? <Edit/> : <Add />} onClick={() => {this.alumnoModal.modal.open()}}>{alumno ? (this.props.editLabel ?? "") : (this.props.addLabel ?? "")}</Button>
                        <Button 
                            size      = {"small"} 
                            color     = {"cancel"} 
                            startIcon = {<Delete htmlColor='white'/>} 
                            disabled  = {alumno ? false : true} 
                            onClick   = {() => {
                                this.setState({alumno: null}, () => { this.props.setValue(name, "") })
                            }}>{this.props.deleteLabel ?? ""}</Button>
                    </Stack>
                ) : null}
            </Box>

            <TextField 
                type        = {"hidden"} 
                value       = {alumno ? alumno.id : ""} 
                variant     = {"standard"} 
                error		= {this.props.errors && this.props.errors[name] ? true : false}
				helperText	= {this.props.errors && this.props.errors[name]?.message}
                {...this.props.register ? this.props.register(name) : {}} />

            {alumno != null ? (
                <Box display={"flex"} alignItems={"center"} mt={2}>
                    <Avatar
                        alt = {"Foto de: " + (alumno.nombre ?? "")}
                        src = {`${ASSETS.images + (alumno.foto_logo ?? 'private/alumno/perfiles/0/default.png')}`}
                        sx  = {{ width: "20%", height: "100%", borderRadius: 2}}/>

                    <Stack mx={2}>
                        <Text fontWeight={"bold"}>{(alumno.nombre ?? "") + " " + (alumno.apellido1 ?? "") + " " + (alumno.apellido2 ?? "")}</Text>
                        <Text>{alumno.email ?? ""}</Text>
                    </Stack>
                </Box>
            ) : null}

            <SearchAlumnoModal 
                ref                = {(ref) => { this.alumnoModal = ref}} 
                relations          = {this.props.relations}
                onSelectedCallback = {(alumno) => {
                    this.alumnoModal.modal.close()
                    this.setState({alumno}, () => {
                        if(this.props.clearErrors)
                            this.props.clearErrors(name)
                        
                        this.props.setValue(name, alumno.id, {shouldDirty: true}) 

                        if(this.props.onAlumnoSelected)
                            this.props.onAlumnoSelected(alumno)
                    })
                }} />
        </>
    )
  }
}
