import { useEffect, useRef, useState } from 'react'
import Requests from './Requests/Requests.js'
import { Box, Container, Paper, Tab, Tabs } from '@mui/material'
import { BreadcrumbsList, Line } from 'components/data_display'
import { CreateHeader } from 'pages/logged/admin/components'
import { DataFormInscripcionClasesParticulares, DataFormPagoClasesParticulares, DataFormLibroClasesParticulares, DataFormAnotacionesClasesParticulares, DataFormHorariosClasesParticulares } from 'components/forms/display'
import { useForm } from 'react-hook-form'
import { validationSchema } from 'components/forms/display/clases-particulares/DataForm/validation.js'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { TabContext } from '@mui/lab'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { COLOR } from 'theme/Colors.js'
import { Text } from 'components/texts/index.js'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'

/**
 * Pagina para crear una nueva clase particular
 */
export default function CreateClasesParticulares() {

    //HOOKS
    const { register, handleSubmit, formState: { errors }, getValues, setValue, control, clearErrors } = useForm({ resolver: yupResolver(validationSchema) })
    const navigate = useNavigate()

    //STATE
    const [isMounted, setIsMounted] = useState(false)
    const [tab, setTab] = useState("1")
    const [libroSolicitado, setLibroSolicitado] = useState(getValues("libro_solicitado"))
    const [libroAsociadoSolicitado, setLibroAsociadoSolicitado] = useState(getValues("libro_asociado_solicitado"))


    //REQUESTS
    const requests = new Requests()

    //REFS
    const header = useRef()

    useEffect(() => {
        setIsMounted(true)
    }, []);

    //-------------------------------------------------------
    //----------------------- REQUESTS ----------------------
    //-------------------------------------------------------

    /**
     * Hace la petición para crear un colegio
     * 
     * @param {Function} callback
     */
    const createClaseParticular = (pagos) => {
        header.current.button.setLoading(true)

        requests.createClaseParticular(getValues, (res) => {
            if (res.item)
                enqueueSnackbar(<Text>Se ha guardado la inscripción correctamente.</Text>, { variant: "success" })

            const promisePagos = new Promise((resolve, reject) => {
                requests.createPagos(res.item.id, pagos, resolve, reject);
            })

            const promiseLibro = new Promise((resolve, reject) => {
                requests.crudLibro(res.item, getValues, resolve, reject)
            })

            const promiseLibroAsociado = new Promise((resolve, reject) => {
                requests.crudLibroAsociado(res.item, getValues, resolve, reject)
            })

            Promise.allSettled([promisePagos, promiseLibro, promiseLibroAsociado]).then((values) => {
                for (const fail of values.filter(item => item.status == "rejected"))
                    enqueueSnackbar(<Text>{fail.reason}</Text>, { variant: "error" })

                header.current.button.setLoading(false)
            })

        }, (res) => {
            enqueueSnackbar(<Text>{"No se ha podido crear la clase particular. " + res}</Text>, { variant: "error" })
            header.current.button.setLoading(false)
        })
    }

    const handleSubmitCustom = async (e) => {
        let handleCallbacks = handleSubmit(() => createClaseParticular(getValues("pagos")), (errors) => {
            enqueueSnackbar(<Text>{"No se ha podido editar la clase particular. Revisa los campos requeridos."}</Text>, { variant: "error" })
        })
        handleCallbacks(e)
    }

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                <BreadcrumbsList breadcrumbsList={[
                    { to: "/", label: "Inicio" },
                    { to: "./../", label: "Clases particulares" },
                    { to: "./", label: "Crear clase particular", actualItem: true },
                ]} />

                <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
                    <CreateHeader
                        ref={header}
                        title={"Crear clase particular"}
                        handleSubmit={handleSubmitCustom} />

                    <Line className={"my-5"} />

                    <TabContext value={tab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                            <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                                <Tab label={<Text fontWeight={tab == "1" ? "bold" : null} color={tab == "1" ? COLOR.standard.main : null}>DATOS DE LA INSCRIPCIÓN</Text>} value={"1"} />
                                <Tab label={<Text fontWeight={tab == "2" ? "bold" : null} color={tab == "2" ? COLOR.standard.main : null}>DATOS DE LOS PAGOS</Text>} value={"2"} />
                                <Tab label={<Text fontWeight={tab == "3" ? "bold" : null} color={tab == "3" ? COLOR.standard.main : null}>LIBROS</Text>} value={"3"} />
                                <Tab label={<Text fontWeight={tab == "4" ? "bold" : null} color={tab == "4" ? COLOR.standard.main : null}>ANOTACIONES</Text>} value={"4"} />
                            </Tabs>
                        </Box>

                        <TabPanel value={"1"}>
                            <DataFormInscripcionClasesParticulares
                                setValue={setValue}
                                getValues={getValues}
                                register={register}
                                errors={errors}
                                clearErrors={clearErrors}
                                isMounted={isMounted}
                                control={control} />
                        </TabPanel>

                        <TabPanel value={"2"}>
                            <DataFormPagoClasesParticulares
                                setValue={setValue}
                                register={register}
                                getValues={getValues}
                                errors={errors}
                                clearErrors={clearErrors}
                                isMounted={isMounted}
                                control={control} />
                        </TabPanel>

                        <TabPanel value={"3"}>
                            <DataFormLibroClasesParticulares
                                setValue={setValue}
                                register={register}
                                getValues={getValues}
                                errors={errors}
                                clearErrors={clearErrors}
                                isMounted={isMounted}
                                setLibroSolicitado={setLibroSolicitado}
                                setLibroAsociadoSolicitado={setLibroAsociadoSolicitado}
                                control={control} />
                        </TabPanel>

                        <TabPanel value={"4"}>
                            <DataFormAnotacionesClasesParticulares
                                setValue={setValue}
                                register={register}
                                getValues={getValues}
                                errors={errors}
                                clearErrors={clearErrors}
                                isMounted={isMounted}
                                control={control} />
                        </TabPanel>
                    </TabContext>
                </Paper>

                <SnackbarProvider autoHideDuration={3000} />
                
            </form>
        </Container>
    )
}