import { TabContext } from '@mui/lab'
import { COLOR } from 'theme/Colors.js'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { Text } from 'components/texts/index.js'
import { useNavigate, useParams } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangesContext } from 'contexts/ChangesContext'
import { EditHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import React, { useContext, useState, useEffect, useRef } from 'react'
import { Box, Container, Paper, Tab, Tabs, Stack } from '@mui/material'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { BreadcrumbsList, MoveButtons, Line } from 'components/data_display'
import { validationSchema } from 'components/forms/display/colegio/grupos/DataForm/validation.js'
import SkeletonDataForm from 'components/forms/display/colegio/grupos/DataForm/GeneralDataForm/skeleton'
import SkeletonIdiomaForm from 'components/forms/display/colegio/grupos/DataForm/IdiomaDataForm/skeleton'
import SkeletonProgramacionesForm from 'components/forms/display/colegio/grupos/DataForm/ProgramacionesDataForm/skeleton'
import SkeletonSeguimientoTrimestralForm from 'components/forms/display/colegio/grupos/DataForm/SeguimientoTrimestralDataForm/skeleton'
import { DataFormGeneralGrupoColegio, DataFormIdiomaGrupoColegio, DataFormSeguimientoTrimestralGrupoColegio, DataFormProgramacionesGrupoColegio } from 'components/forms/display/index.js'

export default function Wrapper() {
  const navigationParams = useParams()

  const { items, updateItem } = useContext(EmpleadoListContext)

  const [grupo,    setGrupo]   = useState(null)
  const [id_grupo, setIdGrupo] = useState(navigationParams.id_grupo)
  const [tab,      setTab]     = useState("1")

  const updateAlert = (message, variant = "error") => {
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }

  const getGrupo = (getFromServer = false) => {
    const item = items.find(item => item?.id == id_grupo)
    if(items.length == 0 || !item || getFromServer) {
      new Requests().getGrupo(id_grupo, ({ grupo }) => {
        setGrupo(grupo)
        updateItem(grupo)
      }, () => updateAlert("No se ha podido recuperar el grupo."))
    } else {
      setGrupo(item)
    }
  }
  
  useEffect(getGrupo, [id_grupo])

  return (
    <React.Fragment>
      <EditGrupo key={grupo?.id ?? 0} grupo={grupo} id_grupo={id_grupo} setIdGrupo={setIdGrupo} setGrupo={setGrupo} tab={tab} setTab={setTab} updateAlert={updateAlert} onSaveFinish={() => getGrupo(true)}/>

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}

function EditGrupo({ grupo, id_grupo, setIdGrupo, setGrupo, updateAlert, tab, setTab, onSaveFinish }) {
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, control, setValue, reset } = useForm({ resolver: yupResolver(validationSchema) })
  
  const { updateChanges } = useContext(ChangesContext)
  
  const headerRef = useRef()

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])

  const promises = () => {
    const promiseSeguimientoTrimestral = new Promise((resolve, reject) => {
      new Requests().editSeguimientoTrimestralGrupo(grupo.seguimiento_trimestral?.id ?? null, getValues, resolve, reject)
    })

    const promiseEmpleado = new Promise((resolve, reject) => {
      new Requests().editEmpleadoGrupo(grupo, getValues, resolve, reject)
    })

    return [promiseSeguimientoTrimestral, promiseEmpleado]
  }

  const editGrupo = () => {
    headerRef.current.button.setLoading(true)
    new Requests().editGrupo(grupo.id, getValues, () => {
      updateAlert("Se ha editado el grupo correctamente.", "success")

      Promise.allSettled(promises())
          .then(async (values) => {
            const errors = values.filter(item => item.status == "rejected")
            for (const fail of errors)
              updateAlert(fail.reason)

              headerRef.current.button.setLoading(false)
              reset({}, {keepValues: true})
              updateChanges(false)
              onSaveFinish()
          })
    }, (res) => {
      headerRef.current.button.setLoading(false)
      updateAlert(res)
    })
  }

  const updateId = (id) => {
    navigate(`../colegios/grupos/${id}/editar`)
    setIdGrupo(id)
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/",           label: "Inicio" },
            { to: "./../../../", label: "Colegios" },
            { to: "./../../",    label: "Grupos" },
            { to: "./../",       label: "Grupo #" + (id_grupo ?? "0000")},
            { to: "./",          label: "Editar", actualItem: true },
          ]} />

          <MoveButtons 
            actualItemId        = {id_grupo}
            beforeFetchCallback = {() => setGrupo(null)} 
            onUpdateActualItem  = {updateId} />
        </Stack>

        <Paper elevation={2} sx={{mx: 8, my: 2}}>
          <EditHeader 
            ref          = {headerRef} 
            title        = {"Editar grupo colegio"}
            item         = {grupo} 
            handleSubmit = {handleSubmit(editGrupo)} />

          <Line className={"mb-5"} />

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
              <Tabs value={tab} onChange={(_, tab) => setTab(tab)} TabIndicatorProps={{style: {backgroundColor: COLOR.standard.main}}}>
                <Tab label={<Text fontWeight={tab == "1" ? "bold" : null} color={tab == "1" ? COLOR.standard.main : null}>DATOS GENERALES</Text>}        value={"1"}/>
                <Tab label={<Text fontWeight={tab == "2" ? "bold" : null} color={tab == "2" ? COLOR.standard.main : null}>IDIOMAS</Text>}                value={"2"}/>
                <Tab label={<Text fontWeight={tab == "3" ? "bold" : null} color={tab == "3" ? COLOR.standard.main : null}>SEGUIMIENTO TRIMESTRAL</Text>} value={"3"}/>
                <Tab label={<Text fontWeight={tab == "4" ? "bold" : null} color={tab == "4" ? COLOR.standard.main : null}>PROGRAMACIONES</Text>}         value={"4"}/>
              </Tabs>
            </Box>

            <TabPanel value={"1"}>
              {grupo ? 
                <DataFormGeneralGrupoColegio 
                  control   = {control} 
                  errors    = {errors}
                  register  = {register} 
                  setValue  = {setValue} 
                  grupo     = {grupo}
                  isMounted /> : <SkeletonDataForm />}
            </TabPanel>
            <TabPanel value={"2"}>
              {grupo ? 
                <DataFormIdiomaGrupoColegio 
                  register  = {register}
                  errors    = {errors} 
                  grupo     = {grupo} 
                  setValue  = {setValue}
                  isMounted /> : <SkeletonIdiomaForm />}
            </TabPanel>
            <TabPanel value={"3"}>
              {grupo ?
                <DataFormSeguimientoTrimestralGrupoColegio
                  setValue = {setValue}
                  control  = {control} 
                  register = {register}
                  errors   = {errors} 
                  grupo    = {grupo}/> : <SkeletonSeguimientoTrimestralForm />}
            </TabPanel>
            <TabPanel value={"4"}>
              {grupo ? <DataFormProgramacionesGrupoColegio grupo={grupo} /> : <SkeletonProgramacionesForm />}
            </TabPanel>
          </TabContext>

        </Paper>
      </form>
    </Container>
  )
}