import { RequestVentaLibro } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para generar los pedidos
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    generarPedidos(getValues, callbackOk = () => {}, callbackError = () => {}) {
        const formData = new FormData()
        formData.append("curso_escolar_id", getValues("id_curso_escolar"))
        formData.append("libro_id",         getValues("id_libro"))

        new RequestVentaLibro().generarPedidos(formData, callbackOk, callbackError)
    }
}
