import { H3 } from 'components/texts'
import React, { useState, useEffect } from 'react'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { Grid } from '@mui/material'
import { Checkbox, Input, DatePicker } from 'components/forms/components'

export default function LiquidacionPagosColegioForm({ register, errors, pago, setValue }) {

    const [total, setTotal] = useState(pago?.importe_total ?? "")

    useEffect(() => {
        if(pago && pago.detalles) {
            let total = 0
            for(const detalle of pago.detalles)
                total += detalle.num_alumnos * detalle.precio_actividad

            setTotal(total ?? "")
        }
    }, [JSON.stringify(pago?.detalles)])

    return (
        <Grid container spacing={2} p={4}>
            <Grid item xs={12}>
                <H3>Liquidación</H3>
            </Grid>
            
            <Grid item xs={6}>
                <DatePicker register={register} errors={errors} className="w-100" name={"fecha_pago"} label={"Fecha pago"} defaultValue={pago?.fecha_pago ?? ""} setValue={setValue}/> 
            </Grid>

            <Grid item xs={6}>
                <ItemsSelect name={"forma_pago_id"} keyValue={"forma_pago"} register={register} errors={errors} defaultValue={pago?.forma_pago_id ?? ""}/>
            </Grid>

            <Grid item xs={6}>
                <Input label={"Importe total"} name={"importe_total"} className="w-100" register={register} errors={errors} useValue value={total} disabled/>
            </Grid>

            <Grid item xs={6}>
                <Checkbox label={"Pagado"} name={"pagado"} register={register} defaultChecked={pago?.pagado ? true : false} />
            </Grid>
        </Grid>
    )
}