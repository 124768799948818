import { 
    RequestAlumno, 
    RequestResponsable, 
    RequestDatosColegio, 
    RequestTutoria, 
    RequestIncidencia,
    RequestColegio,
    RequestRegistroActividad,
    RequestInscripcionColegio,
    RequestGrupoColegio,
    RequestLibro,
    RequestFichaje,
    RequestIncidenciaFichaje,
    RequestEmpleado
} from 'objects/requests'

export const keyValues = {
    "alumnos": {
        request: RequestAlumno,
        fileName: "alumnos",
        addText: 'Añadir nuevo alumno',
        title: "Listado de alumnos",
    },
    "tutores": {
        request: RequestResponsable,
        fileName: "tutores",
        addText: 'Añadir nuevo tutor',
        title: "Listado de tutores",
    },
    "datos-colegio": {
        request: RequestDatosColegio,
        fileName: "datos_colegio_alumnos",
        addText: 'Añadir nuevo',
        title: "Listado de datos en colegios",
    },
    "tutorias": {
        request: RequestTutoria,
        fileName: "tutorias",
        addText: 'Añadir nueva tutoría',
        title: "Listado de tutorías",
    },
    "incidencias": {
        request: RequestIncidencia,
        fileName: "incidencias",
        addText: 'Añadir nueva incidencia',
        title: "Listado de incidencias",
    },
    "colegios": {
        request: RequestColegio,
        fileName: "colegios",
        addText: 'Añadir nuevo colegio',
        title: "Listado de colegios",
    },
    "registro_actividad": {
        request: RequestRegistroActividad,
        fileName: "actividades",
        addText: 'Añadir nueva actividad',
        title: "Listado de actividades",
    },
    "inscripcion_colegio": {
        request: RequestInscripcionColegio,
        fileName: "inscripciones",
        addText: 'Añadir nueva inscripción',
        title: "Listado de inscripciones en colegios",
    },
    "grupos_colegio": {
        request: RequestGrupoColegio,
        fileName: "grupos",
        addText: 'Añadir nuevo grupo',
        title: "Listado de grupos",
    },
    "libros": {
        request: RequestLibro,
        fileName: "libros",
        addText: 'Añadir nuevo libro',
        title: "Listado de libros",
    },
    "fichajes": {
        request: RequestFichaje,
        fileName: "fichajes",
        addText: 'Añadir nuevo fichaje',
        title: "Listado de fichajes",
    },
    "fichajes_incidencias": {
        request: RequestIncidenciaFichaje,
        fileName: "incidencias_fichaje",
        addText: '',
        title: "Listado de incidencias",
    },
    "empleados": {
        request: RequestEmpleado,
        fileName: "empleados",
        addText: 'Añadir empleado',
        title: "Listado de empleados",
    }
}
