import { Link } from 'components/data_display'
import { Button } from 'components/forms/components'

export const columns = [
    {
        field: "actions",
        type: 'actions',
        headerName: "Acciones",
        minWidth: 200,
        getActions: (params) => [<Link to={"" + params.row.id}><Button size={"small"}>Ver</Button></Link>, <Link to={"" + params.row.id + "/editar"}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>]
    },

    { field: "id",           headerName: "ID",          maxWidth: 65 },
    //TODO: MOSTRAR EL PROFESOR CUANDO ESTE ASOCIADO AL GRUPO
    //{ field: "profesor",     headerName: "Profesor",    flex: 1, valueGetter: (params) => { return params.row.profesor?.nombre } },
    { field: "colegio",      headerName: "Colegio",     flex: 1, valueGetter: (params) => { return params.row.colegio?.nombre }},
    { field: "clave",        headerName: "Clave Grupo", flex: 1 },
    { field: "cursoEscolar", headerName: "Año",         flex: 1, valueGetter: (params) => { return params.row.curso_escolar?.valor }},
    { field: "actividad",    headerName: "Actividad",   flex: 1, valueGetter: (params) => { return params.row.actividad?.nombre }},
]
