import React from 'react'
import Skeleton from './skeleton'
import { H3, Text } from 'components/texts'
import Statics from 'objects/common/Statics'
import { Stack, Grid } from '@mui/material'
import { Link } from 'components/data_display'
import { auxFieldName } from 'constants/Responses'
import { Button } from 'components/forms/components'
import { Edit as EditIcon } from '@mui/icons-material'

export default function TutoriaAlumnoData({ tutoria }) {

	return (
		<Grid container spacing={5}>
			{!tutoria ? 
					<Skeleton /> 
				: 
					(
					<React.Fragment>
						<Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
							<H3>Datos de la tutoría:</H3>

							<Link to="editar">
								<Button variant={"outlined"} startIcon={<EditIcon />}>
									Editar
								</Button>
							</Link>
						</Grid>
						
						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Fecha:</Text>
								<Text>{tutoria.fecha ? Statics.formatDate(tutoria.fecha) : ""}</Text>
							</Stack>
						</Grid>

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Solucionada:</Text>
								<Text>{tutoria.solucionada ? "Sí" : "No"}</Text>
							</Stack>
						</Grid>

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Solución:</Text>
								<Text>{tutoria.solucion ?? ""}</Text>
							</Stack>
						</Grid>

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Curso escolar:</Text>
								<Text>{tutoria.curso_escolar[auxFieldName.cursoEscolar] ?? ""}</Text>
							</Stack>
						</Grid>

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Causa:</Text>
								<Text>{tutoria.causa ? tutoria.causa[auxFieldName.causa] : ""}</Text>
							</Stack>
						</Grid>

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Origen:</Text>
								<Text>{tutoria.origen ? tutoria.origen[auxFieldName.origen] : ""}</Text>
							</Stack>
						</Grid>


						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Motivo:</Text>
								<Text>{tutoria.motivo ?? ""}</Text>
							</Stack>
						</Grid>

						

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Seguimiento adicional:</Text>
								<Text>{tutoria.seguimiento_adicional ?? ""}</Text>
							</Stack>
						</Grid>

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Otros comentarios:</Text>
								<Text>{tutoria.otros_comentarios ?? ""}</Text>
							</Stack>
						</Grid>
					</React.Fragment>
				)
			}
		</Grid>
  )
}
