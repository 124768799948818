import React, { useRef } from 'react'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import Statics from 'objects/common/Statics'
import { validationSchema } from "./validation.js"
import { yupResolver } from '@hookform/resolvers/yup'
import { RequestHorarioColegio } from 'objects/requests'
import { Button, Input } from 'components/forms/components'
import { ItemsSelect, RegistroActividadColegioSelect } from 'components/forms/components/Select/custom'

let prepareParamsGlobal = null

//Formulario de busqueda de alumnos
export default function SearchFormHorarioColegio({ isMounted, onLoadingCallback, searchCallback, resetHorarios = () => {} }) {

    //Form
	const { register, handleSubmit, formState: { errors }, getValues } = useForm({ resolver: yupResolver(validationSchema) })

    //State
    const registroActividadColegioSelect = useRef()

    //-------------------------------------------------------
    //---------------------- FUNCTIONS ----------------------
    //-------------------------------------------------------

    /**
     * Callback para cuando cambien el colegio
     * 
     * @param {Object} target
     */
    const colegioChanged = ({ target }) => {
        if(getValues('id_curso_escolar') && registroActividadColegioSelect) {
            registroActividadColegioSelect.current.select.reset()
            registroActividadColegioSelect.current.getActividadesColegio(target.value, getValues('id_curso_escolar'))
            resetHorarios()
        }
    }


    /**
     * Callback para cuando cambien el curso escolar
     * 
     * @param {Object} target
     */
    const cursoEscolarChanged = ({ target }) => {
        if(getValues('id_colegio') && registroActividadColegioSelect) {
            registroActividadColegioSelect.current.select.reset()
            registroActividadColegioSelect.current.getActividadesColegio(getValues('id_colegio'), target.value)
            resetHorarios()
        }
    }


    /**
     * Prepara los parametros que se van a usar
     */
    const prepareParams = () => {
        const params     = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()))
        params.relations = 'definiciones,actividad'

        return params
    }

    prepareParamsGlobal = prepareParams

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    return (
        <Grid container spacing={2}>
            <Grid item xs={3} md={3}>
                {isMounted ? <ItemsSelect
                                name         = {"colegio_id"}
                                keyValue     = {"colegio"}
                                register     = {register}
                                errors       = {errors}
                                onChange     = {colegioChanged}
                                searcheable /> : null}
            </Grid>

            <Grid item xs={3} md={2}>
                {isMounted ?  <ItemsSelect name={"curso_escolar_id"} keyValue={"curso_escolar"} register={register} errors={errors} onChange={cursoEscolarChanged}/> : null}
            </Grid>

            <Grid item xs={3} md={3}>
                {isMounted ? <RegistroActividadColegioSelect ref={registroActividadColegioSelect} register={register} errors={errors} name="registro_actividad_id"/> : false}
            </Grid>

            <Grid item xs={1} md={2}>
                <Input label="Clave" register={register} errors={errors} name={"clave"} uppercase />
            </Grid>

            <Grid item xs={2} md={2} display={"flex"} justifyContent={"end"}>
                <Button onClick={handleSubmit(() => {search(onLoadingCallback, searchCallback)})}>
                    Ver horario
                </Button>
            </Grid>
        </Grid>
    )
}



/**
 * Hace la busqueda de alumnos
 * 
 * @param {Function} onLoadingCallback
 * @param {Function} searchCallback
 * @param {Function} prepareParams
 */
export const search = (onLoadingCallback, searchCallback) => {
    //Callback para controlar cuando esta cargando
    if(onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal()

    new RequestHorarioColegio().search(params, (res) => {
        searchCallback(res.items.data[0])
    }, () => {
        searchCallback(null)
    })
}