import React, { useEffect, useRef, useState, useContext } from 'react'
import Requests from './Requests/Requests.js'
import { Box, Container, Paper, Tab, Tabs, Stack } from '@mui/material'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { useForm } from 'react-hook-form'
import { validationSchema } from 'components/forms/display/academia/grupos/DataForm/GeneralDataForm/validation.js'
import { yupResolver } from '@hookform/resolvers/yup'
import { TabContext } from '@mui/lab'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { COLOR } from 'theme/Colors.js'
import { Text } from 'components/texts/index.js'
import { useNavigate, useParams } from 'react-router-dom'
import { EditHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { GeneralGrupoAcademiaDataForm, IdiomaGrupoAcademiaDataForm, ProgramacionesAcademiaDataForm, HorariosAcademiasDataForm } from 'components/forms/display'
import SkeletonDataFormGeneralGruposAcademia from 'components/forms/display/academia/grupos/DataForm/GeneralDataForm/skeleton'
import SkeletonDataFormIdiomaGrupoAcademia from 'components/forms/display/academia/grupos/DataForm/IdiomaDataForm/skeleton'
import SkeletonDataFormProgramacionesGrupoColegio from 'components/forms/display/academia/grupos/DataForm/ProgramacionesDataForm/skeleton'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import Statics from 'objects/common/Statics.js'


export default function Wrapper() {

    const navigationParams = useParams()
    const [grupo, setGrupo] = useState(null)
    const [id_grupo, setIdGrupo] = useState(navigationParams.id_grupo)
    const [tab, setTab] = useState("1")

    const { items, updateItem } = useContext(EmpleadoListContext)

    const getGrupo = (getFromServer = false) => {
        const grupo = items?.find(item => item?.id == id_grupo)
        if (items.length == 0 || !grupo || getFromServer) {
            new Requests().getGrupo(id_grupo, (grupo) => {
                setGrupo(grupo)
                updateItem(grupo)
            }, () => enqueueSnackbar(<Text>No se ha podido obtener el grupo.</Text>, { variant: "error" }))
        } else {
            setGrupo(grupo)
        }
    }

    useEffect(getGrupo, [id_grupo])

    return (
        <React.Fragment>
            <EditGrupoAcademia key={grupo?.id ?? 0} tab={tab} setTab={setTab} grupo={grupo} id_grupo={id_grupo} setIdGrupo={setIdGrupo} setGrupo={setGrupo} onSaveFinish={() => getGrupo(true)} />
            <SnackbarProvider autoHideDuration={3000} />
        </React.Fragment>
    )
}

function EditGrupoAcademia({ grupo, id_grupo, setIdGrupo, setGrupo, onSaveFinish, tab, setTab, isMounted }) {
    const navigate = useNavigate()

    const { register, handleSubmit, formState: { errors }, getValues, setValue, control } = useForm({ resolver: yupResolver(validationSchema) })
    const headerRef = useRef()


    const updateHeaderAndAlert = ({ message, variant = "error" }, loading = false) => {
        headerRef.current.button.setLoading(loading)
        enqueueSnackbar(<Text>{message}</Text>, { variant })
    }

    /**
     * Hace la petición para editar los datos del Grupo
     */

    const editGrupo = () => {
        headerRef.current.button.setLoading(true)
        new Requests().editGrupo(id_grupo, getValues, (res) => {
            updateHeaderAndAlert({ message: "Se ha editado el grupo correctamente.", variant: "success" })
            onSaveFinish()
        }, (res) => {
            const message = Statics.errorArrayResponseToString(res)
            updateHeaderAndAlert({ message })
        })
    }

    const updateId = (id) => {
        navigate(`../academias/grupos/${id}/editar`);
        setIdGrupo(id);
    }

    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">

                <Stack direction="row" justifyContent={"space-between"} alignItems="center" marginX={8}>
                    <BreadcrumbsList mx={0} breadcrumbsList={[
                        { to: "/", label: "Inicio" },
                        { to: "./../../", label: "Grupos" },
                        { to: "./", label: "Editar grupo Academia", actualItem: true },
                    ]} />

                    <MoveButtons
                        actualItemId={id_grupo}
                        beforeFetchCallback={() => setGrupo(null)}
                        onUpdateActualItem={updateId} />
                </Stack>
                <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>

                    <EditHeader
                        ref={headerRef}
                        title={"Editar grupo"}
                        item={grupo}
                        handleSubmit={handleSubmit(editGrupo)} />
                    <Line />

                    <TabContext value={tab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2} mt={2}>
                            <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                                <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS GENERALES</Text>} value={"1"} />
                                <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>IDIOMAS</Text>} value={"2"} />
                                <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>PROGRAMACIONES</Text>} value={"3"} />
                                <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>HORARIOS</Text>} value={"4"} />
                            </Tabs>
                        </Box>

                        <TabPanel value={"1"}>
                            {grupo ? <GeneralGrupoAcademiaDataForm
                                grupo={grupo}
                                setValue={setValue}
                                getValues={getValues}
                                register={register}
                                errors={errors}
                                control={control}
                            /> : <SkeletonDataFormGeneralGruposAcademia />}
                        </TabPanel>

                        <TabPanel value={"2"}>
                            {grupo ? <IdiomaGrupoAcademiaDataForm
                                setValue={setValue}
                                getValues={getValues}
                                register={register}
                                errors={errors}
                                grupo={grupo}
                                control={control}
                            /> : <SkeletonDataFormIdiomaGrupoAcademia />}
                        </TabPanel>

                        <TabPanel value={"3"}>
                            {grupo ? <ProgramacionesAcademiaDataForm
                                setValue={setValue}
                                grupo={grupo}
                                getValues={getValues}
                                register={register}
                                errors={errors}
                                control={control} /> : <SkeletonDataFormProgramacionesGrupoColegio />}
                        </TabPanel>

                        <TabPanel value={"4"}>
                            <HorariosAcademiasDataForm
                                isMounted={isMounted}
                                control={control}
                                grupo={grupo}
                            />
                        </TabPanel>
                    </TabContext>

                </Paper>

                <SnackbarProvider autoHideDuration={3000} />
            </form>
        </Container>
    )
}