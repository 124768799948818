import React, { Component } from 'react'
import { Text } from 'components/texts'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { Container, Paper } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreateHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { BreadcrumbsList, Line } from 'components/data_display'
import { DataFormLibroGenerarEnvio } from 'components/forms/display'
import validationSchema from 'components/forms/display/libro/generar-envios/DataForm/validation.js'

const Wrapper = () => {
  const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm({ resolver: yupResolver(validationSchema) })

  return <GenerarEnvioLibros register={register} handleSubmit={handleSubmit} errors={errors} getValues={getValues} setValue={setValue}/>
}
export default Wrapper

/**
 * Página para crear un colegio
 */
class GenerarEnvioLibros extends Component {

  constructor(props) {
    super(props)

    this.state = {
      tab: "1",
      isMounted: false,
    }

    this.requests = new Requests
  }

  componentDidMount() {
    this.setState({isMounted: true})
  }

  //-------------------------------------------------------
  //-------------------- CLASS FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Controlla el envio del formulario
   */
  handleSubmit = (e) => {
    const handleCallbacks = this.props.handleSubmit(this.generarPedidos)
    handleCallbacks(e)
  }

  //-------------------------------------------------------
  //----------------------- REQUESTS ----------------------
  //-------------------------------------------------------

  /**
   * Hace la petición para crear un colegio
   * 
   * @param {Function} callback
   */
  generarPedidos = () => {
    this.header.button.setLoading(true)
    
    this.requests.generarPedidos(this.props.getValues, (res) => {
      
    }, (res) => {
      this.header.button.setLoading(false)
      enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
    })
  }

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
      <Container maxWidth={false} sx={{ mt: 5 }}>
        <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
          <BreadcrumbsList breadcrumbsList={[
            { to: "/",     label: "Inicio" },
            { to: "./../", label: "Libros" },
            { to: "./",    label: "Generar envíos", actualItem: true },
          ]} />

          <Paper elevation={2} sx={{mx: 8, my: 2, pb: 5}}>
            <CreateHeader 
              ref          = {(ref) => this.header = ref} 
              title        = {"Generar envío de libros"}
              btnText      = {"Generar pedidos"}
              handleSubmit = {this.handleSubmit} />

            <Line className={"my-5"} />

            <DataFormLibroGenerarEnvio 
              isMounted = {this.state.isMounted}
              register  = {this.props.register}
              errors    = {this.props.errors}
              setValue  = {this.props.setValue} />
          </Paper>

          <SnackbarProvider autoHideDuration={5000}/>
        </form>
      </Container>
    )
  }
}
