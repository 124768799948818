import { Grid } from '@mui/material'
import { H3 } from 'components/texts'
import { WarningNotSavedModal } from 'components/modals'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'


/**
 * Formulario para las importaciones de las actividades
 */
export default function ImportForm({ register, errors, handleSubmit, setValue, getValues, importActividades, onCursoChange, importActividadesCursoAnterior, checkCanChange }) {
    const modalRef = useRef()
    const cursoSelectRef = useRef()

    const [goToPreviousCursoEscolar, setGoToPreviousCursoEscolar] = useState(false)
    
    useEffect(() => {
        if(goToPreviousCursoEscolar) setGoToPreviousCursoEscolar(false)
    }, [goToPreviousCursoEscolar])

    const onTipoCondicionActividadChange = (_, item) => {
        setValue("tipo_condicion", item)
    }

    const onCursoSelectChange = (e) => {
        const canChange = checkCanChange()
        canChange ? onCursoChange(e) : modalRef.current.modal.open()
    }

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <ItemsSelect 
                        ref                = {cursoSelectRef}
                        name               = {"id_curso_escolar"} 
                        keyValue           = {"curso_escolar"} 
                        register           = {register} 
                        errors             = {errors} 
                        onChange           = {onCursoSelectChange}
                        goToPreviousOption = {goToPreviousCursoEscolar}
                        setValue           = {setValue}
                        initialOption      = {0} />
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect
                        name      = {"id_tipo_condicion"}
                        keyValue  = {"tipos_condicion_actividad"}
                        register  = {register} 
                        errors    = {errors}
                        onChange  = {onTipoCondicionActividadChange}  />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Condiciones" register={register} errors={errors} className="w-100" name={"condicion"} defaultValue={0}/>
                </Grid>
            </Grid>

            <Grid item xs={12} mt={4} mb={1}>
                <H3>Opciones de importación: </H3>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Button className="w-100" onClick={handleSubmit}>Pasar las condiciones a las actividades</Button>
                </Grid>

                <Grid item xs={4}>
                    <Button className="w-100" color={"success"} onClick={importActividades}>Importar nuevas actividades</Button>
                </Grid>

                <Grid item xs={4}>
                    <Button className="w-100" onClick={importActividadesCursoAnterior}>Importar actividades del curso anterior</Button>
                </Grid>
            </Grid>

            <WarningNotSavedModal 
                ref={modalRef} 
                onGoBack={() => { 
                    setGoToPreviousCursoEscolar(true)
                    modalRef.current.modal.close()
                }} 
                onContinue={() => {
                    onCursoChange({target: {value: getValues('id_curso_escolar')}})
                    modalRef.current.modal.close()
                }}/>
        </React.Fragment>
    )
}
