import { Grid } from '@mui/material';
import React, { Component } from 'react'
import { Line } from 'components/data_display';
import { Button } from 'components/forms/components';

/**
 * Listado de los datos del colegio de un alumno
 */
export default class LoadMoreButton extends Component {

  constructor() {
    super()
  }

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
        <Grid container px={5}>
            <Line className="my-2" />
            <Grid item display={"flex"} justifyContent={"center"}>
                <Button className="my-5" variant={"outlined"} onClick={() => {this.props.onClick()}}>
                    Ver más
                </Button>
            </Grid>
        </Grid>
    )
  }
}
