import React, { useState, useEffect } from 'react'
import { DataPersonalEmpleado, CurriculumEmpleado } from "./parts"
import { Box, Container, Paper, Tab, Tabs, Stack, Grid } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { BreadcrumbsList, MoveButtons } from 'components/data_display'
import { COLOR } from 'theme/Colors.js'
import { Button } from 'components/forms/components';
import { Text } from 'components/texts/index.js'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { Edit as EditIcon } from '@mui/icons-material'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { RequestEmpleado } from 'objects/requests'
import { TabContext } from '@mui/lab'

/**
 * Formulario formulario del empleado
 * 
 */
export default function SingleFichaEmpleado() {

    //HOOKS
    const [tab, setTab] = useState("1")
    const [empleado, setEmpleado] = useState()
    const navigate = useNavigate()
    const navigationParams = useParams()


    const [id_empleado, setIdEmpleado] = useState(navigationParams?.id_empleado)

    const getEmpleado = () => {
        const params = { relations: 'sexo,tipo_documento,provincia,tipo_via,provincia_nacimiento,responsables,domiciliacion_bancaria' }

        new RequestEmpleado().get(id_empleado, params, (res) => {
            if (res.alumno)
                setEmpleado(res.alumno)
        })
    }

    const updateId = (id) => {
        navigate(`../ficha-empleados/${id}`);
        setIdEmpleado(id);
    }

    useEffect(getEmpleado, [id_empleado])

    return (
        <Grid container p={3}>
            <Container maxWidth={false} sx={{ mt: 5 }}>
                <Stack direction="row" justifyContent={"space-between"} marginX={8}>
                    <BreadcrumbsList mx={0} breadcrumbsList={[
                        { to: "/", label: "Inicio" },
                        { to: "./../", label: "Empleados" },
                        { to: "./", label: "Ficha Empleado #" + (id_empleado ?? "0000"), actualItem: true },
                    ]} />

                    <MoveButtons
                        actualItemId={id_empleado}
                        beforeFetchCallback={() => setIdEmpleado(null)}
                        onUpdateActualItem={updateId} />
                </Stack>

                <Paper elevation={2} sx={{ m: 2, mx: 8, my: 2, pb: 5 }}>
                    <Grid container display={"flex"} justifyContent={"end"} alignItems={"start"}>
                        <Grid item xs={2} sx={{ marginTop: 3 }}>
                            <Button variant={"outlined"} startIcon={<EditIcon />} onClick={() => { navigate('./editar') }}>
                                Editar
                            </Button>
                        </Grid>
                    </Grid>

                    <TabContext value={tab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                            <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                                <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS PERSONALES</Text>} value={"1"} />
                                <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>CURRICULUM VITAE</Text>} value={"2"} />
                            </Tabs>
                        </Box>

                        <TabPanel value={"1"}>
                            <DataPersonalEmpleado empleado={empleado} />
                        </TabPanel>

                        <TabPanel value={"2"}>
                           <CurriculumEmpleado/>
                        </TabPanel>

                    </TabContext>
                </Paper>
            </Container>

        </Grid>
    )
}