import { v4 as uuid } from "uuid";
import { COLOR } from 'theme/Colors'
import React, { Component } from "react";
import { H3, Text } from 'components/texts';
import { RequestGrupoAcademia } from "objects/requests";
import { Visibility as EyeIcon } from "@mui/icons-material";
import { Button } from 'components/forms/components';
import { Grid, ListItemButton, ListItemText, Stack, List, Box, TextField } from "@mui/material";

export default class GrupoAcademiaPicker extends Component {

    constructor(props) {
        super(props)

        this.state = {
            grupos: [],
            selected: null,
            defaultId: this.props.defaultValue ?? null
        }
    }
    componentDidMount() {
        if (this.props.defaultValue)
            this.props.setValue(this.props.name ?? "id", this.props.defaultValue)

        if (this.props.cursoEscolar && this.props.actividad) {
            this.getGrupos(this.props.cursoEscolar, this.props.actividad)
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.cursoEscolar != prevProps.cursoEscolar) ||
            (this.props.actividad != prevProps.actividad)) {
            this.getGrupos(this.props.cursoEscolar, this.props.actividad)
        }
    }

    /**
     * Devuelve los grupos a partir del horario
     * 
     * @param {Int} id 
     */
    getGrupos = (cursoEscolar, actividad) => {
        const params = { cursoEscolar, actividad }
        params.appends = ['curso_escolar', 'registro_actividad', 'nivel_grupo', 'horarios']
        new RequestGrupoAcademia().search(params, (res) => {
            this.setState({ grupos: res.data, selected: this.state.defaultId ? res.data.find(item => item.id == this.state.defaultId) : null })
        }, (res) => {
            this.setState({ grupos: [], selected: null })
        })
    }


    /**
     * Resetea el valor
     */
    reset = () => {
        this.setState({ selected: null }, () => { this.props.setValue(this.props.name ?? "id", "") })
    }

    /**
 * Genera pdf con los alumnos
 */
    composicion = () => {
        console.log('Generar Pdf')
    }


    /**
     * Actualiza el item seleccionado
     * 
     * @param {Object} selected 
     */
    onSelect = (selected) => {
        this.setState({ selected }, () => {
            this.props.setValue(this.props.name ?? "id", selected?.id ?? "", { shouldDirty: true })

            if (this.props.onGrupoChange)
                this.props.onGrupoChange(selected)
        })
    }


    /**
     * Render
     *
     * @returns {Component}
     */
    render() {
        const { selected } = this.state

        return (
            <Grid container>
                <Grid item xs={12} sm={3} display={"flex"} flexDirection={"column"}>
                    <Box sx={{ backgroundColor: COLOR.grey.light, minHeight: 150, maxHeight: 200, overflowY: "auto", borderRadius: 3, flexGrow: 1 }} px={2}>
                        <List dense>
                            {this.state.grupos.map(item => {
                                return (
                                    <ListItemButton
                                        key={uuid()}
                                        sx={{ backgroundColor: COLOR.white.main, borderRadius: 2, my: 1 }}
                                        selected={selected?.id === item.id}
                                        onClick={(e) => { this.onSelect(item) }}
                                        disabled={item.max_alumnos && item.max_alumnos <= 3}>
                                        <ListItemText primary={item.clave_grupo} />
                                    </ListItemButton>
                                )
                            })}
                        </List>
                    </Box>

                    <Button variant={"outlined"} sx={{ mt: 1, alignSelf: "end" }} onClick={this.reset}>
                        Limpiar
                    </Button>

                    <TextField sx={{ width: 0 }} type={"hidden"} value={selected?.id ?? ""} variant={"standard"}
                        {...this.props.register ? this.props.register(this.props.name ?? "id") : {}} />
                </Grid>
                <Grid container item xs={12} md={9} px={4} mt={2}>
                    <Grid item xs={12}>
                        <H3>Información del academia seleccionada</H3>
                    </Grid>
                    <Grid item xs={6} md={4} mt={2}>
                        <Stack>
                            <Text fontWeight={"bold"}>Grupo</Text>
                            <Text>{selected?.clave_grupo ?? ""}</Text>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} md={4} mt={2}>
                        <Stack>
                            <Text fontWeight={"bold"}>Alumnos</Text>
                            <Text>{selected ? selected?.grupo_inscripciones?.length : ""}</Text>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} md={4} mt={2} >
                        <Stack>
                            <Text fontWeight={"bold"}>Horario</Text>
                            <Text>{selected?.horarios[0]?.horario_formatted ?? ""}</Text>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} md={4} mt={2}>
                        <Stack>
                            <Text fontWeight={"bold"}>Nivel</Text>
                            <Text>{selected?.nivel_grupo?.valor ?? ''}</Text>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} md={4} mt={2}>
                        <Stack>
                            <Text fontWeight={"bold"}>Profesor</Text>
                            <Text>
                                {/* {selected?.profesor_actual?.empleado ?
                                    (selected.profesor_actual.empleado.nombre ?? "") + " " + (selected.profesor_actual.empleado.apellido1 ?? "") + " " + (selected.profesor_actual.empleado.apellido2 ?? "")
                                    : ""} */}
                                {/* {selected?.profesor ?? ''} */}
                            </Text>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} md={4} mt={2}>
                        <Stack>
                            <Text fontWeight={"bold"}>Libro</Text>
                            <Text>{selected?.libro?.nombre ?? ""}</Text>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} mt={2}>
                        <Button color={"cancel"} startIcon={<EyeIcon />} onClick={this.composicion}>
                            Composición del Grupo
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
