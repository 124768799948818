import React from 'react'
import { H3, Text } from 'components/texts'
import { Grid, Stack } from '@mui/material'
import Statics from 'objects/common/Statics'

/**
 * Formulario con los datos generales del grupo
 */
export default function SeguimientoTrimestralData({ grupo }) {

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container p={5}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <H3>1º Trimestre</H3>
                </Grid>
                
                <Grid item xs={12}>
                    <Stack>
                        <Text fontWeight={"bold"}>Fecha de entrega</Text>
                        <Text>{grupo?.seguimiento_trimestral?.fecha_entrega_t1 ? Statics.formatDate(grupo?.seguimiento_trimestral?.fecha_entrega_t1) : "Sin especificar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Objetivos alcanzados</Text>
                        <Text>{grupo?.seguimiento_trimestral?.objetivos_alcanzados_t1 ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Evolución de los alumnos</Text>
                        <Text>{grupo?.seguimiento_trimestral?.evolucion_alumnos_t1 ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Conclusiones y observaciones</Text>
                        <Text>{grupo?.seguimiento_trimestral?.conclusiones_observaciones_t1 ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={5}>
                <Grid item xs={12}>
                    <H3>2º Trimestre</H3>
                </Grid>
                
                <Grid item xs={12}>
                    <Stack>
                        <Text fontWeight={"bold"}>Fecha de entrega</Text>
                        <Text>{grupo?.seguimiento_trimestral?.fecha_entrega_t2 ? Statics.formatDate(grupo?.seguimiento_trimestral?.fecha_entrega_t2) : "Sin especificar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Objetivos alcanzados</Text>
                        <Text>{grupo?.seguimiento_trimestral?.objetivos_alcanzados_t2 ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Evolución de los alumnos</Text>
                        <Text>{grupo?.seguimiento_trimestral?.evolucion_alumnos_t2 ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Conclusiones y observaciones</Text>
                        <Text>{grupo?.seguimiento_trimestral?.conclusiones_observaciones_t2 ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={5}>
                <Grid item xs={12}>
                    <H3>3º Trimestre</H3>
                </Grid>
                
                <Grid item xs={12}>
                    <Stack>
                        <Text fontWeight={"bold"}>Fecha de entrega</Text>
                        <Text>{grupo?.seguimiento_trimestral?.fecha_entrega_t3 ? Statics.formatDate(grupo?.seguimiento_trimestral?.fecha_entrega_t3) : "Sin especificar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Objetivos alcanzados</Text>
                        <Text>{grupo?.seguimiento_trimestral?.objetivos_alcanzados_t3 ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Evolución de los alumnos</Text>
                        <Text>{grupo?.seguimiento_trimestral?.evolucion_alumnos_t3 ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Conclusiones y observaciones</Text>
                        <Text>{grupo?.seguimiento_trimestral?.conclusiones_observaciones_t3 ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    )
}
