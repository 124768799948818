import { Grid, Skeleton, Stack } from "@mui/material"
import { logDOM } from "@testing-library/react"
import { Line } from "components/data_display"
import { DatePicker, Input, Checkbox } from "components/forms/components"
import { ItemsSelect } from "components/forms/components/Select/custom"
import { AlumnoPicker } from 'components/forms/components/custom'
import { EmpleadoPicker } from 'components/forms/components/custom'
import { useState } from "react"

/**
 * Formulario con los datos de las clases particulares
 */
export default function DataFormInscripcionClasesParticulares({ claseParticular, setLugarClase = null, register, errors, isMounted = false, setValue, clearErrors }) {
    //STATE
    const [alumno, setAlumno] = useState(claseParticular?.alumno ?? null)

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container>

            <Grid container px={5}>
                <Grid item xs={6} py={3} pr={2}>
                    <AlumnoPicker
                        label={"Alumno"}
                        editLabel={"Cambiar alumno"}
                        addLabel={"Añadir alumno"}
                        deleteLabel={"Borrar alumno"}
                        relations={"claseParticular"}
                        register={register}
                        errors={errors}
                        alumno={alumno}
                        onAlumnoSelected={setAlumno}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        //Si está editando ya no se puede cambiar el alumno
                        canEdit={claseParticular ? false : true} />
                </Grid>

                <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9" }} py={3} pl={3}>
                    <Stack spacing={2}>
                        {isMounted ?
                            <ItemsSelect name={"id_curso_escolar"} keyValue={"curso_escolar"} register={register} errors={errors} defaultValue={claseParticular?.curso_escolar_id ?? ""} />
                            : <Skeleton variant="rounded" width={"100%"} height={40} />}

                        {isMounted ?
                            <ItemsSelect name={"id_forma_recogida"} keyValue={"forma_recogida"} register={register} errors={errors} defaultValue={claseParticular?.forma_recogida_id ?? ""} />
                            : <Skeleton variant="rounded" width={"100%"} height={40} />}

                        {isMounted ?
                            <ItemsSelect name={"id_forma_conocimiento"} keyValue={"forma_conocimiento"} register={register} errors={errors} defaultValue={claseParticular?.forma_conocimiento_id ?? ""} />
                            :
                            <Skeleton variant="rounded" width={"100%"} height={40} />}
                    </Stack>
                </Grid>
            </Grid>

            <Grid container>
                <Line />
            </Grid>

            <Grid container px={5} mt={2} spacing={2}>
                <Grid item xs={4} md={4}>
                    {isMounted ?
                        <ItemsSelect name={"id_empresa"} keyValue={"empresa"} register={register} errors={errors} defaultValue={claseParticular?.empresa_id ?? ""} />
                        : <Skeleton variant="rounded" width={"100%"} height={40} />}
                </Grid>

                <Grid item xs={4} md={4}>
                    {/*TODO: REVISAR ITEM */}
                    {isMounted ?
                        <ItemsSelect
                            name={"registro_actividad_id"}
                            keyValue={"registro_actividad_academia"}
                            register={register}
                            errors={errors}
                            params={{ relations: "modalidad_cursos,actividad" }}
                            defaultValue={claseParticular?.registro_actividad_id ?? ""}
                            renderItem={(item) => {
                                console.log('renderItem', item)
                                return { value: item.id, label: item.actividad?.valor + " - " + item.modalidad_cursos?.nombre }
                            }} />
                        : <Skeleton variant="rounded" width={"100%"} height={40} />}
                </Grid>

                <Grid item xs={4} md={4}>
                    {isMounted ? <ItemsSelect name={"lugar_clase_id"} keyValue={"lugar_clase"} register={register} errors={errors} defaultValue={claseParticular?.lugar_clase_id ?? ""} onChange={
                        (_, item) => {
                            typeof setLugarClase === 'function' && setLugarClase(item.id)
                        }
                    } /> : null}
                </Grid>
            </Grid>

            <Grid container px={5} mt={2} spacing={2}>
                <Grid item xs={7}>
                    <Input placeholder={"Observaciones"} register={register} errors={errors} className="w-100" name={"observaciones"} defaultValue={claseParticular?.observaciones ?? null} multiline minRows={6} maxRows={10} uppercase />
                </Grid>

                <Grid item xs={2}>
                    <Stack>
                        <Checkbox name={"encuesta_telefonica"} label={"Encuesta telefónica"} register={register} defaultChecked={claseParticular?.encuestado ? true : false} />
                        <Checkbox name={"alumno_colectivo"} label={"Alumno de Colectivo"} register={register} defaultChecked={claseParticular?.alumno_colectivo ? true : false} />
                        <Checkbox name={"avisado_comienzo_clases"} label={"Avisado del comienzo de las clases"} register={register} />
                    </Stack>
                </Grid>
            </Grid>

            <Grid container px={5} mt={2} spacing={2}>
                <Grid item xs={6} md={4}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_inscripcion"} label={"Fecha de inscripción"} defaultValue={claseParticular?.fecha_alta ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={6} md={4}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_inicio"} label={"Fecha de inicio"} defaultValue={claseParticular?.fecha_inicio ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={6} md={4}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_baja"} label={"Fecha baja"} defaultValue={claseParticular?.fecha_baja ?? ""} setValue={setValue} clearable />
                </Grid>
            </Grid>

            <Grid container mt={4}>
                <Line />
            </Grid>

            <Grid container px={5} mt={2} spacing={2}>
                <Grid item xs={6} py={3} pr={2}>
                    <EmpleadoPicker
                        label={"Profesor de la clase particular"}
                        editLabel={"Cambiar profesor"}
                        addLabel={"Añadir profesor"}
                        name={"profesor_id"}
                        deleteLabel={"Borrar profesor"}
                        register={register}
                        empleado={claseParticular?.profesor ?? null}
                        setValue={setValue}
                        //Si tiene más de un profesor ese grupo, ya no se podrá cambiar desde aqui
                        canEdit={claseParticular && claseParticular.profesor && claseParticular.profesor.length > 1 ? false : true} />
                </Grid>

                <Grid item xs={6} py={3} pr={2}>
                    <Stack spacing={1}>
                        {isMounted ?
                            <ItemsSelect name={"sexo_id"} keyValue={"sexo"} register={register} errors={errors} defaultValue={claseParticular?.profesor?.id_sexo ?? ""} />
                            : <Skeleton variant="rounded" width={"100%"} height={40} />}

                        {isMounted ?
                            <ItemsSelect name={"origen_profesor_id"} keyValue={"origen_empleado"} register={register} errors={errors} defaultValue={claseParticular?.origen_profesor_id ?? ""} />
                            : <Skeleton variant="rounded" width={"100%"} height={40} />}
                    </Stack>

                </Grid>
            </Grid>

        </Grid>
    )
}