import { Link } from 'components/data_display'
import { Button } from 'components/forms/components'



export const columns = [
    {
        field: "actions",
        type: 'actions',
        headerName: "Acciones",
        minWidth: 200,
        getActions: (params) => [<Link to={"" + params.row.id}><Button size={"small"}>Ver</Button></Link>, <Link to={"" + params.row.id + "/editar"}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>]
    },

    { field: "id", headerName: "ID", maxWidth: 150 },
    { field: "num_documento", headerName: "Nº Documento", flex: 1 ,maxWidth: 200},
    { field: "apellido1", headerName: "Apellido 1", valueGetter: (params) => { return params.row.tipo_actividad?.valor }, flex: 1 ,maxWidth: 200},
    { field: "apellido2", headerName: "Apellido 2", valueGetter: (params) => { return params.row.actividad?.valor }, flex: 1 ,maxWidth: 200},
    { field: "nombre", headerName: "Nombre", valueGetter: (params) => { return params.row.modalidad_cursos?.nombre }, flex: 1 ,maxWidth: 200},
    { field: "d_cand", headerName: "D. Cant", maxWidth: 150 },
]
