import React from 'react'
import { Grid, Skeleton } from '@mui/material'
import { Line } from 'components/data_display'

export default function DataFormControlAlmacen() {

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container px={3}>

            <Grid container mb={2} mt={4}>
                <Skeleton variant="rounded" width={"100%"} height={50}/>
            </Grid>

            <Line className={"mt-5"}/>

            <Grid container my={3} spacing={2}>
                <Grid item xs={12} xl={6}>
                    <Skeleton variant="rounded" width={"100%"} height={100}/>
                </Grid>

                <Grid item xs={12} xl={6}>
                    <Skeleton variant="rounded" width={"100%"} height={100}/>
                </Grid>
            </Grid>

            <Line className={"mt-5"}/>

            <Grid container my={3}>
                <Skeleton variant="rounded" width={"100%"} height={50}/>
            </Grid>
        </Grid>
    )
}
