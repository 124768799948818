import { ActividadesAcademiaData } from './parts'
import React, { useState, useEffect, useContext } from 'react'
import { Container, Paper, Stack } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { BreadcrumbsList, MoveButtons } from 'components/data_display'
import { enqueueSnackbar } from 'notistack';
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { Text } from 'components/texts'
import Requests from '../single/Request/Request'

export default function SingleActividades() {
  const navigationParams = useParams()
  const navigate = useNavigate()

  const { items } = useContext(EmpleadoListContext)

  const [id_academia, setIdacademia] = useState(navigationParams.id_academia)
  const [academia, setAcademia] = useState(null)

  const updateId = (id) => {
    navigate(`../academias/actividades/${id}`);
    setIdacademia(id);
  }

  useEffect(() => {
    const academia = items.find(item => item?.id == id_academia)
    if (items.length == 0 || !academia) {
      new Requests().getActividad(id_academia, setAcademia, () => enqueueSnackbar(<Text>No se ha podido recuperar las actividades de la academia.</Text>, { variant: "error" }))
    } else {
      setAcademia(academia)
    }
  }, [id_academia])

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

      <Stack direction="row" justifyContent={"space-between"} marginX={8}>
        <BreadcrumbsList mx={0} breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "Academias" },
          { to: "./", label: "Actividad #" + (id_academia ?? "0000"), actualItem: true },
        ]} />

        <MoveButtons
          actualItemId={id_academia}
          beforeFetchCallback={() => setAcademia(null)}
          onUpdateActualItem={updateId} />
      </Stack>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <ActividadesAcademiaData academia={academia} />
      </Paper>
    </Container>
  )
}
