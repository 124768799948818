import Statics from 'objects/common/Statics'
import { RequestGrupoAcademia } from "objects/requests"

export default class Requests {

    /**
     * Hace la petición para crear el grupo-academia
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createGrupo(getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData
        formData.append("clave_grupo", getValues("clave").toUpperCase())
        formData.append("curso_escolar_id", getValues("curso_escolar_id"))
        formData.append("registro_actividad_id", getValues("registro_actividad_id"))
        formData.append("nivel_grupo_id", getValues("nivel_grupo_id") ?? "")
        formData.append("num_grupo", getValues("num_grupo") ?? "")
        formData.append("fecha_inicio", Statics.datePickerTimeFormat(getValues("fecha_inicio")) ?? "")
        formData.append("fecha_fin", Statics.datePickerTimeFormat(getValues("fecha_fin")) ?? "")
        formData.append("min_alumnos", getValues("min_alumnos") ?? "")
        formData.append("max_alumnos",            getValues("max_alumnos") ?? "")
        // formData.append("id_empleado",            getValues("id_empleado") ?? "")
        formData.append("observaciones_grupo", getValues("observaciones_grupo").toUpperCase() ?? "")

        // //IDIOMAS
        formData.append("libro_id", getValues("id_libro") ?? "")
        formData.append("libro", getValues("libro") ?? "")
        formData.append("usar_libro_asociado", getValues("usar_libro_asociado") ? 1 : 0)
        formData.append("usar_progress_sheet", getValues("usar_progress_sheet") ? 1 : 0)

        //PROGRAMACIONES
        formData.append("programacion_1t_enviada", "0")
        formData.append("programacion_2t_enviada", "0")
        formData.append("programacion_3t_enviada", "0")
        formData.append("circular_libro_enviada", "0")

        new RequestGrupoAcademia().create(formData, callbackOk, callbackError)
    }
}
