import { Text } from 'components/texts'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { useParams, useNavigate } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Paper, Stack } from '@mui/material'
import { ChangesContext } from 'contexts/ChangesContext'
import { EditHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'

//FORM
import { DataFormResponsable } from 'components/forms/display'
import Skeleton from 'components/forms/display/responsable/DataForm/skeleton.jsx'
import { validationSchema } from 'components/forms/display/responsable/DataForm/validation.js'

const Wrapper = () => {
	const navigationParams = useParams()
	
	const { items, updateItem } = useContext(EmpleadoListContext)
	
	const [responsable,    setResponsable]   = useState(null)
	const [id_responsable, setIdResponsable] = useState(navigationParams.id_responsable)

	const updateAlert = (message, variant = "error") => {
		enqueueSnackbar(<Text>{message}</Text>, { variant })
	}

	const getResponsable = (getFromServer = false) => {
		const item = items.find(item => item?.id == id_responsable)
		if(items.length == 0 || !item || getFromServer) {
			new Requests().getResponsable(id_responsable, (responsable) => {
			setResponsable(responsable)
			updateItem(responsable)
		  }, () => updateAlert("No se ha podido recuperar el responsable."))
		} else {
			setResponsable(item)
		}
	  }

	useEffect(getResponsable, [id_responsable])

	return (
		<React.Fragment>
			<EditResponsable key={responsable?.id ?? 0} id_responsable={id_responsable} responsable={responsable} setIdResponsable={setIdResponsable} setResponsable={setResponsable} updateAlert={updateAlert} onSaveFinish={() => getResponsable(true)}/>

      		<SnackbarProvider autoHideDuration={3000} />
		</React.Fragment>
	)
}
export default Wrapper

/**
 * Pagina para editar un responsable
 */
function EditResponsable({ responsable, id_responsable, setIdResponsable, setResponsable, updateAlert, onSaveFinish }) {
	const navigate = useNavigate()

	const { updateChanges } = useContext(ChangesContext)

	const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) })

	useEffect(() => {
		if (Object.keys(dirtyFields).length > 0)
		  updateChanges(true)
	  }, [Object.keys(dirtyFields).length])

	//Refs
	const headerRef = useRef()

	const updateHeaderAndAlert = ({ message, severity = "error"}, loading = false) => {
		headerRef.current.button.setLoading(loading)
		updateAlert(message, severity)
	}

	/**
	 * Hace la petición para editar los datos basicos del responsable
	 */
	const editResponsable = () => {
		headerRef.current.button.setLoading(true)

		new Requests().editResponsable(id_responsable, getValues, () => {
			updateHeaderAndAlert({message: "Se ha editado el tutor correctamente.", severity: "success"})
			reset({}, {keepValues: true})
			updateChanges(false)
			onSaveFinish()
		}, (res) => {
			updateHeaderAndAlert({message: res})
		})
	}

	const updateId = (id) => {
		navigate(`../alumnos/tutores/${id}/editar`);
		setIdResponsable(id);
	}

	return (
		<Container maxWidth={false} sx={{ mt: 5 }}>
			<form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">

				<Stack direction="row" justifyContent={"space-between"} marginX={8}>
					<BreadcrumbsList mx={0} breadcrumbsList={[
						{ to: "/",        label: "Inicio" },
						{ to: "./../../", label: "Tutores" },
						{ to: "./../",    label: "Tutor #" + (id_responsable ?? "0000") },
						{ to: "./",       label: "Editar", actualItem: true },
					]} />

					<MoveButtons 
						actualItemId        = {id_responsable}
						beforeFetchCallback = {() => setResponsable(null)} 
						onUpdateActualItem  = {updateId} />
				</Stack>

				<Paper elevation={2} sx={{mx: 8, my: 2, pb: 5}}>
					<EditHeader 
						ref          = {headerRef} 
						title        = {"Editar tutor"}
						item         = {responsable} 
						handleSubmit = {handleSubmit(editResponsable)} />

					<Line className={"mb-5"} />

					{responsable ? <DataFormResponsable responsable={responsable} register={register} errors={errors} /> : <Skeleton />}
				</Paper>
			</form>
		</Container>
	)
}