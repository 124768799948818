import React, { Component } from 'react'
import { Outlet } from 'react-router-dom'
import { H1, Text } from '../../../../components/texts'

export default class NotLoggedRoot extends Component {

  /**
   * ComponentDidMount
   */
  componentDidMount() {
    document.body.classList.add('background-login')
    document.body.classList.add('app-blank')
  }


  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    return (
      <div className="d-flex flex-column flex-root h-100" id="kt_app_root">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 bg-white">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="w-lg-500px p-10">
                <div className="mb-11">
                  <img alt="Logo" src={process.env.PUBLIC_URL + "/images/icons/logo.svg"} className="h-60px h-lg-125px mb-10" />

                  <H1>Academia CL</H1>
                  <Text>Bienvenido a Academia CL Granada.</Text>
                </div>

                {/* OUTLET */}
                <Outlet />
                {/* OUTLET */}
              </div>
            </div>
          </div>

          <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 background-login">
            <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">

              <h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">Fast, Efficient and Productive</h1>
              <div className="d-none d-lg-block text-white fs-base text-center">In this kind of post,
                the blogger introduces a person they’ve interviewed
                <br />and provides some background information about the intervieweeand their
                <br />work following this is a transcript of the interview.</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
