import { v4 as uuid } from 'uuid';
import React, { Component } from 'react'
import { H3, Text } from 'components/texts';
import { Line } from 'components/data_display';
import { Button } from 'components/forms/components'
import { Alert, CircularProgress, Grid, Box, Stack } from '@mui/material'
import SearchFormAlumno, { search } from 'components/forms/display/alumno/SearchForm'

/**
 * Componente que nos muestra un listado con el listado de los alumnos para poder elegir uno
 */
export default class AlumnosListSelector extends Component {

    constructor(props) {
        super(props)

        this.state = {
            alumnos: [],
            searched: false,
            actualPage: 1,
            totalResults: 0,
            loading: false,
        }
    }


    /**
     * Callback para cuando se completa la búsqueda
     * 
     * @param {Array} alumnos
     */
    searchCallback = (alumnos, totalResults) => {
        this.setState({ alumnos, searched: true, actualPage: this.state.actualPage + 1, totalResults, loading: false }, () => {
            this.scrollable.scrollTo(0, 0)
        })
    }


    /**
     * Callback para cuando se elige un alumno de la lista
     * 
     * @param {Object} alumno
     */
    onSelectedCallback = (alumno) => {
        if (this.props.onSelectedCallback)
            this.props.onSelectedCallback(alumno)
    }


    /**
     * Se encarga de controlar el scroll
     * 
     * @param {Event} e 
     */
    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 5;
        if (bottom && this.state.totalResults != this.state.alumnos.length && !this.state.loading) {
            this.setState({ loading: true }, () => {
                search(this.state.actualPage, () => { }, (alumnos) => {
                    this.setState({ alumnos: this.state.alumnos.concat(alumnos), loading: false, actualPage: this.state.actualPage + 1 })
                })
            })

        } else if (bottom && this.state.totalResults == this.state.alumnos.length) {
            this.setState({ actualPage: 1 })
        }
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Grid container pb={10} pt={5} px={8}>
                <SearchFormAlumno
                    searchCallback    = {this.searchCallback}
                    relations         = {this.props.relations ?? null}
                    onLoadingCallback = {() => { this.setState({ loading: true }) }} />

                <Grid container p={2} display={"flex"} flexDirection={"column"}>
                    <H3>Resultado de la búsqueda: </H3>
                    <div ref={(ref) => this.scrollable = ref} className={"mh-300 overflow-y"} onScroll={this.handleScroll}>
                        {this.state.alumnos.length == 0 ? <Alert severity="info"><Text>{!this.state.searched ? "Inserta los datos del alumno para buscarlo." : "No se ha encontrado ningun alumno."}</Text></Alert> : null}

                        {this.state.alumnos.map(item => <AlumnoItem key={uuid()} item={item} onSelectedCallback={this.onSelectedCallback} />)}

                        {this.state.loading ?
                            <Box my={2} display={"flex"} justifyContent={"center"}>
                                <CircularProgress color="standard" className='' />
                            </Box> : null
                        }
                    </div>
                </Grid>
            </Grid>
        )
    }
}


class AlumnoItem extends Component {
    render() {
        return (
            <Grid item xs={12}>
                <Box display={"flex"} justifyContent={"space-between"} my={2}>
                    <Stack>
                        <Text fontWeight={"bold"}>{(this.props.item.nombre ?? "") + " " + (this.props.item.apellido1 ?? "") + " " + (this.props.item.apellido2 ?? "")}</Text>
                        <Text>{(this.props.item.tipo_documento?.nombre ?? "") + " - " + this.props.item.numero_documento ?? ""}</Text>
                        <Text>{this.props.item.email ?? ""}</Text>
                    </Stack>

                    <Button
                        sx      = {{ mx: 2, alignSelf: "center" }}
                        variant = {"outlined"}
                        onClick = {() => { this.props.onSelectedCallback(this.props.item) }}>

                        Seleccionar alumno
                    </Button>
                </Box>

                <Line />
            </Grid>
                
        )
    }
}