import React, { Component } from 'react'
import { v4 as uuid } from 'uuid';
import { Select } from '../..';
import { RequestRegistroActividad } from 'objects/requests';

/**
 * RegistroActividAdacademiaSelect. Un select que se encarga de la descarga de los cursos escolares disponibles en el servidor
 */
export default class RegistroActividadAcademiaSelect extends Component {

    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)

        this.state = {
            actividades: [],
            actividadesToShow: [{ value: "", label: this.props.initialLabel ?? "Elige un academia y un curso escolar para cargar las actividades." }]
        }
    }


    /**
     * ComponentDidUpdate
     */
    componentDidUpdate() {
        if(this.props.wantToReset) {
            this.setState({actividades: []})
            this.select.reset()
        }
    }

    //-------------------------------------------------------
    //-------------------- CLASS FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Callback para cuando se cambia de opcion en el selector
     * 
     * @param {Object} event
     */
    onChange = (event) => {
        if (this.props.onChange) {
            const itemSelected = this.state.actividades.find(item => item.id == event.target.value)
            this.props.onChange(event, itemSelected)
        }
    }

        
    /**
     * Hace la peticion para obtener las actividades de un academia en un curso escolar
     * 
     * @param {Int} id_academia
     * @param {Int} id_curso_escolar
     */
    getActividadesAcademia = (id_academia, id_curso_escolar = null) => {
        if(id_academia) {
            const params = { entity_id: id_academia, entity_type: 'academia', limit: 100 }
            if(id_curso_escolar)
                params.id_curso_escolar = id_curso_escolar

            new RequestRegistroActividad().search(params, (res) => {
                const actividadesToShow = res.actividades.data.map(item => ({ value: item.id, label: item.nombre }))
                this.setState({ actividades: res.actividades.data, actividadesToShow })
            }, (res) => {
                this.setState({ actividadesToShow: [{ value: "", label: "Esta academia no tiene ninguna actividad creada." }], actividades: []})
            })
        }
    }

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     * 
     * @returns {Component}
     */

    render() {
        const labelId = "registro_actividad_academia_select-id-" + uuid()
        return (
            <Select
                ref          = {(ref) => this.select = ref}
                width        = {this.props.width ?? "100%"}
                items        = {this.state.actividadesToShow}
                className    = {this.props.className}
                defaultValue = {this.props.defaultValue}
                onDefaultValueLoaded = {this.props.onDefaultValueLoaded}
                onChange     = {this.onChange}
                register     = {this.props.register}
                name         = {this.props.name ?? "id_registro_actividad"}
                errors       = {this.props.errors}
                labelId      = {labelId}
                label        = {this.props.label ?? "Actividad academia"} />
        )
    }
}
