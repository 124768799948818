import React, { Component } from "react";
import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { COLOR } from "theme/Colors";

export default class CustomCheckbox extends Component {

  constructor(props) {
    super(props)
    this.state = {
      checked: this.props.defaultChecked ?? false
    }
  }


  setChecked = (checked) => {
    this.setState({checked}, () => {
      if(this.props.setValue)
        this.props.setValue(this.props.name, checked)
    })
  }
  

  onClick = () => {
    this.setState({checked: !this.state.checked}, () => {
      if(this.props.onClick)
        this.props.onClick(this.state.checked)
    })
  }

  
  getChecked = () => {
    return this.state.checked
  }


  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
      <>
        <FormControlLabel
          className = {this.props.className}
          label     = {this.props.label ?? ""}
          sx        = {{mr: this.props.label ? null : 0, ml: this.props.label ? null : 0}}
          control   = {
              <Checkbox
                inputRef       = {(ref) => this.checkbox = ref}
                name           = {this.props.name}
                checked        = {this.state.checked}
                sx             = {{color: COLOR.standard.main}}
                color          = {this.props.color ?? "standard"}
                disabled       = {this.props.disabled}
                onClick        = {this.onClick}
                {... this.props.register ? this.props.register(this.props.name) : {}} />
          } />

        <FormHelperText error={this.props.errors && this.props.errors[this.props.name] ? true : false}>
          {this.props.errors && this.props.errors[this.props.name]?.message}
        </FormHelperText>
      </>
    );
  }
}
