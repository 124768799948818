import { Link } from 'components/data_display'
import { Button } from 'components/forms/components'

export const columns = [
    {
        field: "actions",
        type: 'actions',
        headerName: "Acciones",
        minWidth: 200,
        getActions: (params) => [<Link to={"" + params.row.id}><Button size={"small"}>Ver</Button></Link>, <Link to={"" + params.row.id + "/editar"}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>]
    },

    { field: "id", headerName: "ID", maxWidth: 65 },
    { field: "nombre", headerName: "Nombre", flex: 1 },
    { field: "clave", headerName: "Clave", flex: 1  },
    { field: "municipio", headerName: "Municipio", flex: 1  },
    { field: "provincia", headerName: "Provincia", minWidth: 100, valueGetter: (params) => { return params.row.provincia?.nombre } , flex: 1 },
]
