import { TabContext } from '@mui/lab'
import { Text } from 'components/texts'
import { COLOR } from 'theme/Colors.js'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { useNavigate, useParams } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangesContext } from 'contexts/ChangesContext'
import { EditHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import React, { useState, useRef, useContext, useEffect } from 'react'
import { Box, Container, Paper, Stack, Tab, Tabs } from '@mui/material'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import validationSchema from 'components/forms/display/colegio/colegio/DataForm/validation.js'
import { DataFormLibro, DataFormLibroControlAlmacen, DataFormLibroNiveles, DataFormLibroProgramaciones } from 'components/forms/display'
import { DataFormLibroSkeleton, DataFormLibroNivelesSkeleton, DataFormControlAlmacenSkeleton, DataFormLibroProgramacionesSkeleton } from 'components/forms/display/libro/libro/skeletons'

export default function Wrapper() {
  const navigationParams = useParams()

  const { items, updateItem } = useContext(EmpleadoListContext)

  const [libro,    setLibro]   = useState(null)
  const [tab,      setTab]     = useState("1")
  const [id_libro, setIdLibro] = useState(navigationParams.id_libro)

  const updateAlert = (message, variant = "error") => {
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }


  const getLibro = (getFromServer = false) => {
    const item = items.find(item => item?.id == id_libro)
    if(items.length == 0 || !item || getFromServer) {
      new Requests().getLibro(id_libro, (libro) => {
        setLibro(libro)
        updateItem(libro)
      }, () => updateAlert("No se ha podido recuperar el libro."))
    } else {
      setLibro(item)
    }
  }
  
  useEffect(getLibro, [id_libro])

  return (
    <React.Fragment>
      <EditLibros libro={libro} id_libro={id_libro} setIdLibro={setIdLibro} setLibro={setLibro} tab={tab} setTab={setTab} updateAlert={updateAlert} onSaveFinish={() => getLibro(true)}/>

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}

function EditLibros({ libro, id_libro, setIdLibro, setLibro, updateAlert, tab, setTab, onSaveFinish }) {
  const navigate = useNavigate()
  
  const { updateChanges } = useContext(ChangesContext)

  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, setValue, reset } = useForm({ resolver: yupResolver(validationSchema), defaultValues: { niveles: [] }})

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])

  //Refs
  const headerRef = useRef()

  const updateHeaderAndAlert = ({ message, severity = "error"}, loading = false) => {
    headerRef.current.button.setLoading(loading)
    updateAlert(message, severity)
  }

  const promises = () => {
    const request = new Requests()

    const promiseCreateLibrosPedidos = new Promise((resolve, reject) => {
      request.createLibrosPedidos(id_libro, getValues, resolve, reject)
    })

    const promiseEditLibrosPedidos = new Promise((resolve, reject) => {
      request.editLibrosPedidos(getValues, resolve, reject)
    })

    const promiseDeleteLibrosPedidos = new Promise((resolve, reject) => {
      request.deleteLibrosPedidos(getValues, resolve, reject)
    })

    const promiseCreateLibrosDevueltos = new Promise((resolve, reject) => {
      request.createLibrosDevueltos(id_libro, getValues, resolve, reject)
    })

    const promiseEditLibrosDevueltos = new Promise((resolve, reject) => {
      request.editLibrosDevueltos(getValues, resolve, reject)
    })

    const promiseDeleteLibrosDevueltos = new Promise((resolve, reject) => {
      request.deleteLibrosDevueltos(getValues, resolve, reject)
    })

    const promiseEditLibroNiveles = new Promise((resolve, reject) => {
      request.updateNiveles(id_libro, getValues, resolve, reject)
    })

    return [promiseCreateLibrosPedidos, promiseEditLibrosPedidos, promiseDeleteLibrosPedidos, promiseEditLibroNiveles, promiseEditLibrosDevueltos, promiseDeleteLibrosDevueltos]
  }
  
  const editLibro = () => {
    headerRef.current.button.setLoading(true)
    
    new Requests().editLibro(id_libro, getValues, (res) => {
      updateAlert("Se ha editado el libro correctamente.", "success")

      Promise.allSettled(promises())
          .then(async (values) => {
            const errors = values.filter(item => item.status == "rejected")
            for (const fail of errors)
              updateAlert(fail.reason)

            // if(errors.length == 0)
            //   updateAlert("Se ha actualizado el libro correctamente.", "success")

            headerRef.current.button.setLoading(false)
            reset({}, {keepValues: true})
            updateChanges(false)
            onSaveFinish()
          })
    }, (res) => {
      updateHeaderAndAlert(res)
    })
  }
  
  const updateIdLibro = (id) => {
    navigate(`../libros/${id}/editar`)
    setIdLibro(id)
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/",        label: "Inicio" },
            { to: "./../../", label: "Libros" },
            { to: "./../",    label: "Libro #" + (id_libro ?? "0000") },
            { to: "./",       label: "Editar", actualItem: true },
          ]} />

          <MoveButtons 
            actualItemId        = {id_libro}
            beforeFetchCallback = {() => setLibro(null)} 
            onUpdateActualItem  = {updateIdLibro} />
        </Stack>

        <Paper elevation={2} sx={{mx: 8, my: 2, pb: 5}}>
          <EditHeader 
            ref           = {headerRef} 
            title         = {"Editar libro"}
            item          = {libro} 
            handleSubmit  = {handleSubmit(editLibro)} />

          <Line />

          {libro ? <DataFormLibro register={register} errors={errors} libro={libro} /> : <DataFormLibroSkeleton />}

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2} mt={5}>
              <Tabs value={tab} onChange={(_, tab) => setTab(tab)} TabIndicatorProps={{style: {backgroundColor: COLOR.standard.main}}}>
                <Tab label={<Text fontWeight={tab == "1" ? "bold" : null} color={tab == "1" ? COLOR.standard.main : null}>CONTROL DEL AMACÉN</Text>} value={"1"}/>
                <Tab label={<Text fontWeight={tab == "2" ? "bold" : null} color={tab == "2" ? COLOR.standard.main : null}>NIVELES</Text>}            value={"2"}/>
                <Tab label={<Text fontWeight={tab == "3" ? "bold" : null} color={tab == "3" ? COLOR.standard.main : null}>PROGRAMACIONES</Text>}     value={"3"}/>
              </Tabs>
            </Box>

            <TabPanel value={"1"}>
              {libro ? 
                <DataFormLibroControlAlmacen
                  setValue  = {setValue}
                  libro     = {libro}
                  errors    = {errors}
                  register  = {register} 
                  isMounted/> : <DataFormControlAlmacenSkeleton />}
            </TabPanel>

            <TabPanel value={"2"}>
              {libro ? 
                <DataFormLibroNiveles
                  setValue  = {setValue}
                  register  = {register}
                  libro     = {libro}
                  getValues = {getValues} /> : <DataFormLibroNivelesSkeleton />}
            </TabPanel>

            <TabPanel value={"3"}>
              {libro ?
                <DataFormLibroProgramaciones
                  libro     = {libro}
                  register  = {register}
                  getValues = {getValues} /> : <DataFormLibroProgramacionesSkeleton />}
            </TabPanel>
          </TabContext>
        </Paper>
      </form>
    </Container>
  )
}