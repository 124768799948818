import { Component, useEffect, useRef, useState } from 'react'
import Requests from './Requests/Requests.js'
import { Box, Container, Paper, Tab, Tabs } from '@mui/material'
import { BreadcrumbsList, Line, SnackbarAlert } from 'components/data_display'
import { CreateHeader } from 'pages/logged/admin/components'
import { useForm } from 'react-hook-form'
import { validationSchema } from 'components/forms/display/academia/grupos/DataForm/GeneralDataForm/validation.js'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { TabContext } from '@mui/lab'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { COLOR } from 'theme/Colors.js'
import { Text } from 'components/texts/index.js'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { GeneralGrupoAcademiaDataForm, IdiomaGrupoAcademiaDataForm, ProgramacionesAcademiaDataForm } from 'components/forms/display/index.js'
import Statics from 'objects/common/Statics.js'

/**
 * Creación de una grupo a academia
 */
export default function CreateInscripcionesAcademia() {

  //HOOKS
  const { register, handleSubmit, formState: { errors }, getValues, setValue, control, clearErrors } = useForm({ resolver: yupResolver(validationSchema) })
  const navigate = useNavigate()

  //STATE
  const [isMounted, setIsMounted] = useState(false)
  const [tab, setTab] = useState("1")

  //REQUESTS
  const requests = new Requests()

  //-------------------------------------------------------
  //----------------------- REQUESTS ----------------------
  //-------------------------------------------------------

  const headerRef = useRef()
  const updateHeaderAndAlert = ({ message, variant = "error" }, loading = false) => {
    headerRef.current.button.setLoading(loading)
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }

  const createGrupoAcademia = () => {
    headerRef.current.button.setLoading(true)

    new Requests().createGrupo(getValues, (res) => {
      updateHeaderAndAlert({ message: "Se ha creado el Academia correctamente.", variant: "success" })
    }, (err) => {
      const message = Statics.errorArrayResponseToString(err)
      updateHeaderAndAlert({ message })
    })
  }

  useEffect(() => {
    setIsMounted(true)
  }, []);

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <BreadcrumbsList breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "grupos" },
          { to: "./", label: "Crear grupo Academia", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <CreateHeader
            ref={headerRef}
            title={"Crear grupo para la academia"}
            handleSubmit={handleSubmit(createGrupoAcademia, (errors) => {
              console.error(errors);
            })} />

          <Line className={"my-5"} />

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
              <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS GENERALES</Text>} value={"1"} />
                <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>IDIOMAS</Text>} value={"2"} />
                <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>PROGRAMACIONES</Text>} value={"3"} />

              </Tabs>
            </Box>

            <TabPanel value={"1"}>
              <GeneralGrupoAcademiaDataForm
                setValue={setValue}
                getValues={getValues}
                register={register}
                errors={errors}
                clearErrors={clearErrors}
                isMounted={isMounted}
                control={control}
              />
            </TabPanel>

            <TabPanel value={"2"}>
              <IdiomaGrupoAcademiaDataForm
                setValue={setValue}
                getValues={getValues}
                register={register}
                errors={errors}
                clearErrors={clearErrors}
                isMounted={isMounted}
                control={control}
              />

            </TabPanel>

            <TabPanel value={"3"}>
              <ProgramacionesAcademiaDataForm
                setValue={setValue}
                getValues={getValues}
                register={register}
                errors={errors}
                clearErrors={clearErrors}
                isMounted={isMounted}
                control={control} />
            </TabPanel>

      
          </TabContext>

        </Paper>

        <SnackbarProvider autoHideDuration={3000} />
      </form>
    </Container>
  )
}