
export const ACTIVIDADES_VALUE = "actividades"
export const PROFESORES_VALUE  = "profesor"

export const itemsSearch = [
    { value: ACTIVIDADES_VALUE, label: "Grupos de los colegios por Actividades"}, 
    { value: PROFESORES_VALUE,  label: "Grupos de los colegios por Profesores" }
]

export const itemsOrder  = {
    actividades: [
        { value: '{"id_actividad": "ASC"}', label: "Actividades" }, 
        { value: '{"clave_grupo": "ASC"}',  label: "Clave de Grupo" }, 
        { value: '{"id": "ASC"}',           label: "Id. Grupo" }, 
    ],
    profesor: [
        { value: '{"apellido1": "ASC", "apellido2": "ASC", "nombre": "ASC"}', label: "Profesores" }
    ]
}