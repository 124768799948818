import React from 'react'
import { Edit as EditIcon } from '@mui/icons-material'
import { Grid, Stack, Container } from '@mui/material'
import { Checkbox, Button } from 'components/forms/components';
import { H3, Text, H2 } from 'components/texts'
import { useNavigate } from 'react-router-dom'
import Skeleton from './skeletonActividadesAcademia'

export default function ActividadesAcademiaData({ academia }) {
  const navigate = useNavigate()

  return (

    <Container maxWidth={false} >
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">

        <Grid container p={5}>
          {!academia ?
            <Skeleton />
            : (
              <>
                <Grid item xs={10}>
                  <Stack>
                    <H2 className="profile-id-text">{"#" + academia?.id}</H2>
                    <H3>{academia?.nombre ?? ""}</H3>
                  </Stack>
                </Grid>

                <Grid item xs={2} display={"flex"} justifyContent={"end"} alignItems={"start"}>
                  <Button variant={"outlined"} startIcon={<EditIcon />} onClick={() => { navigate('./editar') }}>
                    Editar actividad
                  </Button>
                </Grid>

                <Grid item xs={12} mt={3}>
                  <H3>Datos de las actividades de la academia: </H3>
                </Grid>

                <Grid container mt={2} spacing={2} columns={12}>
                  <Grid item xs={4} display={"flex"} flexDirection={"column"}>
                    <Text fontWeight={"bold"}>Tipo de actividad</Text>
                    <Text>{academia?.tipo_actividad?.valor ?? "Sin rellenar"}</Text>
                  </Grid>

                  <Grid item xs={4} display={"flex"} flexDirection={"column"}>
                    <Text fontWeight={"bold"}>Actividad</Text>
                    <Text>{academia?.nombre ?? "Sin rellenar"}</Text>
                  </Grid>

                  <Grid item xs={4} display={"flex"} flexDirection={"column"}>
                    <Text fontWeight={"bold"}>Modalidad del curso</Text>
                    <Text>{academia?.modalidad_cursos?.nombre ?? "Sin rellenar"}</Text>
                  </Grid>

                  <Grid item xs={3} display={"flex"} flexDirection={"column"}>
                    <Text fontWeight={"bold"}>Precio</Text>
                    <Text>{academia?.precio ?? "Sin rellenar"}</Text>
                  </Grid>

                  <Grid item xs={3} display={"flex"} flexDirection={"column"}>
                    <Text fontWeight={"bold"}>Matrícula</Text>
                    <Text>{academia?.matricula ?? "Sin rellenar"}</Text>
                  </Grid>

                  <Grid item xs={3} display={"flex"} flexDirection={"column"}>
                    <Text fontWeight={"bold"}>Precio Junio</Text>
                    <Text>{academia?.precio_junio ?? "Sin rellenar"}</Text>
                  </Grid>

                  <Grid item xs={3} display={"flex"} flexDirection={"column"} >
                    <Checkbox name={"intensivo"} disabled label={"Curso intensivo"} className="mt-2" defaultChecked={academia?.intensivo ? true : false} />
                  </Grid>
                </Grid>
              </>
            )
          }
        </Grid>
      </form>
    </Container>
  )
}

