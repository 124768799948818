import Statics from 'objects/common/Statics'
import { RequestIncidencia } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para editar la incidencia
     * 
     * @param {Int} id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editIncidencia(id, getValues, callbackOk = () => {}, callbackError = () => {}) {
        var formData = new FormData()
        
        let fecha = getValues("fecha")
        formData.append("incidencia",            getValues("incidencia").toUpperCase())
        formData.append("fecha",                 Statics.datePickerTimeFormat(fecha))
        formData.append("solucionada",           getValues("solucionada") ? 1 : 0)
        formData.append("id_curso_escolar",      getValues("id_curso_escolar"))
        formData.append("id_tipo_incidencia",    getValues("id_tipo_incidencia"))

        new RequestIncidencia().edit(id, formData, callbackOk, callbackError)
    }


    /**
     * Devuelve la incidencia
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getIncidencia(id, callbackOk = () => {}, callbackError = () => {}) {
        const params = { relations: "alumno" }
        new RequestIncidencia().get(id, params, (res) => {
            res.incidencia ? callbackOk(res.incidencia) : callbackError(res)
        })
    }
}
