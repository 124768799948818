import React from 'react'
import { Grid } from '@mui/material'
import { ItemsSelect } from 'components/forms/components/Select/custom'

const destinatario = "empresas"

export default function EmpresasTemplate({ register, errors, reset }) {
    return (
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={4}>

            <Grid item xs={3}>
                <ItemsSelect name={`${destinatario}.id_curso_escolar`} keyValue={"curso_escolar"} register={register} errors={errors} wantToReset={reset} />
            </Grid>

            <Grid item xs={3}>
                <ItemsSelect name={`${destinatario}.id_tipo_actividad`} keyValue={"tipo_actividad"} register={register} errors={errors} />
            </Grid>

            <Grid item xs={3}>
                <ItemsSelect name={`${destinatario}.id_actividades`} keyValue={"actividad"} register={register} errors={errors} wantToReset={reset} searcheable />
            </Grid>

            <Grid item xs={3}>
                <ItemsSelect name={`${destinatario}.id_modalidad_cursos`} keyValue={"modalidad_cursos"} register={register} errors={errors} wantToReset={reset} searcheable />
            </Grid>
        </Grid>
    )
}
