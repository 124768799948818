import RequestMaster from '../RequestMaster'

export default class RequestAlumno extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/alumno'
    }


    /**
     * Hace el logueo del usuario
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    search(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.get(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Crea un usuario
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    create(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Hace el edit del usuario
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    edit(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "" + id
        this.put(path, params, callback, callbackError, callbackFinally)
    }


    historico(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = `${id}/historico`
        this.get(path, params, callback, callbackError, callbackFinally)
    }
}
