import React, { Component } from 'react'
import { Box, TextField } from '@mui/material'
import { TwitterPicker } from 'react-color'
import { Text } from '../../../texts'

/**
 * Custom Input
 */
export default class ColorInput extends Component {

	constructor(props) {
		super(props)

		this.state = {
			color: this.props.defaultValue ?? "#4B799E",
		}
	}

	//-------------------------------------------------------
	//------------------- CLASS FUNCTIONS -------------------
	//-------------------------------------------------------

	handleClick = () => {
		this.setState({ displayColorPicker: !this.state.displayColorPicker })
	}


	handleClose = () => {
		this.setState({ displayColorPicker: false })
	}


	handleChange = (color) => {
		this.setState({ color: color.hex.toUpperCase() }, () => {
			//Es importante hacer focus despues de cambiar de color para que el input lo sobreescriba
			this.color_input.focus()
		})
	}

	//-------------------------------------------------------
	//------------------- RENDER FUNCTIONS ------------------
	//-------------------------------------------------------

	/**
	 * Render
	 * 
	 * @returns {Component}
	 */
	render() {
		return (
			<Box sx={{width: "100%"}}>
				<div style={{ borderRadius: 2, display: 'inline-block', cursor: 'pointer', width: this.props.width ?? "80%", display: "flex"}} onClick={ this.handleClick }>
					<TextField
						inputRef   = {(ref) => this.color_input = ref}
						type	   = {"text"}
						value 	   = {this.state.color}
						size	   = {"small"}
						className  = {"w-100"}
						InputProps = {{
							startAdornment: <Box position={"start"} sx={{width: "40%", height: "100%", backgroundColor: this.state.color, borderRadius: 1}} mr={2} />,
							className: "p-0"
						}}
						{...this.props.register(this.props.name ?? "color")} />
				</div>

				<Box sx={{position: 'absolute', zIndex: 2}} display={this.state.displayColorPicker ? null : "none"}>
					<Box sx={{position: 'fixed', top: 0, right: 0, left: 0, bottom: 0}} onClick={ this.handleClose }/>

					<TwitterPicker color={ this.state.color } onChange={ this.handleChange } />
				</Box>


			</Box>
		)
	}
}