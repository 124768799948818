import Skeleton from './skeleton'
import { H3 } from 'components/texts'
import React, { Component } from 'react'
import { Alert, Grid } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { Button } from 'components/forms/components'
import { SnackbarAlert } from 'components/data_display'
import { AlumnoItem } from 'components/data_display/users'
import { DeleteRelacionModal, SearchAlumnoModal, ChooseTipoRelacionModal } from 'components/modals'

/**
 * Informacion de los alumnos del responsable
 */
export default class AlumnoData extends Component {

  constructor(props) {
    super(props)

    this.state = {
      alumnos: this.props.alumnos ?? null
    }
  }


  /**
   * ComponentDidUpdate
   * 
   * @param {Object} prevProps 
   */
  componentDidUpdate(prevProps) {
    if (this.props.alumnos != prevProps.alumnos)
      this.setState({ alumnos: this.props.alumnos })
  }

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    let { alumnos } = this.state
    return (
      <Grid container>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <H3 className={"m-0"}>Alumnos a su cargo:</H3>
        </Grid>

        <Grid item xs={6} display={"flex"} justifyContent={"end"} alignItems={"center"}>
          {alumnos ? (
            <Button variant={"outlined"} startIcon={<AddIcon />} onClick={() => { this.addAlumnoModal.modal.open() }}>
              Asociar alumno
            </Button>) : null}
        </Grid>

        {!alumnos ? <Skeleton /> : (
          <Grid container mt={2} spacing={2}>
            {alumnos.map(item => (<AlumnoItem key={Math.random()} alumno={item} openDeleteRelacionModal={(alumno) => { this.deleteRelacionModal.open(this.props.responsable, alumno) }} />))}

            {alumnos.length == 0 ? <Alert severity="info" className='mt-10 w-100'><H3 className="standard">Este tutor no tiene ningún alumno a su cargo.</H3></Alert> : null}
          </Grid>
        )}

        {/** MODALES */}
        <SearchAlumnoModal ref={(ref) => this.addAlumnoModal = ref} onSelectedCallback={(alumno) => { this.chooseTipoRelacionModal.open(alumno, this.props.responsable) }} />
        <DeleteRelacionModal ref={(ref) => this.deleteRelacionModal = ref} onDeleteCallback={this.props.reload} />
        <ChooseTipoRelacionModal
          ref={(ref) => this.chooseTipoRelacionModal = ref}
          callbackOk={() => {
            this.snackBarAlertRef.updateState({ message: "Se ha asociado el tutor correctamente.", severity: "success", open: true })
            this.addAlumnoModal.modal.close()
            this.props.reload()
          }}
          callbackError={(res) => {
            this.snackBarAlertRef.updateState({ message: res, severity: "error", open: true })
          }} />

        <SnackbarAlert ref={(ref) => this.snackBarAlertRef = ref} />
      </Grid>
    )
  }
}
