import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests'
import { H3, Text } from 'components/texts'
import React, { useEffect, useState } from 'react'
import { Box, Container, Paper } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { DataFormHorariosColegio } from 'components/forms/display'
import { Accordion, BreadcrumbsList, Horario } from 'components/data_display'
import { validationSchema } from 'components/forms/display/colegio/horarios/DataForm/validation.js'


export default function HorarioColegio() {
  const { register, handleSubmit, formState: { errors }, getValues } = useForm({ resolver: yupResolver(validationSchema) })

  const [horarios,  setHorarios]  = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => setIsMounted(true), [])

  const onActividadSelected = (registro_actividad_id, colegio_id, curso_escolar_id) => {
    const params = { registro_actividad_id, colegio_id, curso_escolar_id, relations: 'actividad' }

    new Requests().getHorario(params, setHorarios)
  }

  const createHorario = () => {
    setIsLoading(true)

    new Requests().createHorario(getValues, (res) => {
      enqueueSnackbar(<Text>Se ha creado el horario correctamente.</Text>, {variant: "success"})
      onActividadSelected(getValues("id_registro_actividad"), getValues("id_colegio"), getValues("id_curso_escolar"))
    }, (res) => {
      enqueueSnackbar(<Text>{res}</Text>, {variant: "error"})
    }, () => {
      setIsLoading(false)
    })
  }

  const deleteClick = (id_definicion_horario) => {
    new Requests().deleteHorario(id_definicion_horario, () => {
      onActividadSelected(getValues("id_registro_actividad"), getValues("id_colegio"), getValues("id_curso_escolar"))
    }, () => {
      enqueueSnackbar(<Text>No se ha podido borrar el horario.</Text>, {variant: "error"})
    })
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { to: "/",     label: "Inicio" },
        { to: "./../", label: "Colegios" },
        { to: "./",    label: "Horarios", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><H3 className="my-0 mx-5">Generar horario</H3></Box>}>
        <DataFormHorariosColegio 
          isMounted           = {isMounted} 
          onActividadSelected = {onActividadSelected} 
          register            = {register}
          handleSubmit        = {handleSubmit(createHorario)}
          loading             = {isLoading}
          errors              = {errors}
          resetHorarios       = {() => setHorarios([])} />
      </Accordion>

      <Paper elevation={2} sx={{mx: 8, my: 2, p: 5}}>
        <Horario horarios={horarios ?? []} onDeleteClick={deleteClick} />
      </Paper>
      
      <SnackbarProvider autoHideDuration={3000} />
    </Container>
  )
}