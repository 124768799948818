import { Link } from 'components/data_display'
import { Button } from 'components/forms/components'

export const columns = [
    {
        field: "actions",
        type: 'actions',
        headerName: "Acciones",
        minWidth: 200,
        getActions: (params) => [<Link to={"" + params.row.id}><Button size={"small"}>Ver</Button></Link>, <Link to={"" + params.row.id + "/editar"}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>]
    },

    { field: "id", headerName: "ID", maxWidth: 65 },
    { field: "nombre", headerName: "Nombre", flex: 1 },
    { field: "tipo_actividad", headerName: "Tipo Act.", valueGetter: (params) => { return params.row.tipo_actividad?.valor }, flex: 1 },
    { field: "descripcion", headerName: "Descripción", valueGetter: (params) => { return params.row.actividad?.valor }, flex: 1 },
    { field: "modalidad", headerName: "Modalidad Curso", valueGetter: (params) => { return params.row.modalidad_cursos?.nombre }, flex: 1 },
    { field: "precio", headerName: "Precio", maxWidth: 65 },
]
