import React, { Component } from 'react'
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';

/**
 * SKELETON
 */
export default class SkeletonAlumno extends Component {


  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
        <>
          <Grid item xs={2}>
            <Skeleton variant="rounded" width={"80%"} height={"100%"}/>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="text" sx={{ fontSize: '2.5em' }} width={"20%"}/>
            <Skeleton variant="text" sx={{ fontSize: '2em' }}   width={"30%"}/>
            <Skeleton variant="text" sx={{ fontSize: '1em' }}   width={"30%"}/>
            <Skeleton variant="text" sx={{ fontSize: '1em' }}   width={"30%"}/>
          </Grid>
        </>
    )
  }
}
