import { Grid, Skeleton } from "@mui/material";
import { Line } from "components/data_display";

export default function SkeletonClasesParticularesAnotaciones() {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Skeleton variant="rounded" width={"100%"} height={100} />
            </Grid>

            <Grid item xs={12} pt={5}>
                <Line />
            </Grid>

            <Grid item xs={12} pt={5}>
                <Skeleton variant="rounded" width={"100%"} height={100} />
            </Grid>
        </Grid>
    )
}