import React from 'react'
import { ASSETS } from 'constants/Assets'
import { H3, Text } from 'components/texts'
import { Avatar, Box, Grid } from '@mui/material'

export default function AlumnoFullItem({ alumno, containerClassName, imageXS = 2, contentXS = 8 }) {

	return (
		<Grid container spacing={2} className={containerClassName}>
            <Grid item xs={imageXS}>
                <Avatar
                    alt = {"Foto de: " + (alumno?.nombre ?? "")}
                    src = {`${ASSETS.images + (alumno?.foto_logo ? alumno?.foto_logo : 'private/alumno/perfiles/0/default.png')}`}
                    sx  = {{ width: "80%", height: "100%", borderRadius: 2}}/>
            </Grid>

            <Grid item xs={contentXS}>
                <Box mt={2}>
                    <H3>{alumno?.nombre + " " + (alumno?.apellido1 ?? "") + " " + (alumno?.apellido2 ?? "")}</H3>

                    <Box display={"flex"}>
                        <Text fontWeight={"bold"}>Email:</Text>
                        <Text className="mx-2">{alumno?.email ?? ""}</Text>
                    </Box>

                    <Box display={"flex"}>
                        <Text fontWeight={"bold"}>Documento de identidad:</Text>
                        <Text className="mx-2">{(alumno?.numero_documento ?? "") + " - " + (alumno?.tipo_documento?.nombre ?? "")}</Text>
                    </Box>

                    <Box display={"flex"}>
                        <Text fontWeight={"bold"}>Ubicación:</Text>
                        <Text className="mx-2">{(alumno?.tipo_via?.valor ?? "Tipo vía ?") + " - " + (alumno?.nombre_via ?? "")}</Text>
                    </Box>

                    <Box display={"flex"}>
                        <Text fontWeight={"bold"}>Género:</Text>
                        <Text className="mx-2">{alumno?.sexo?.valor}</Text>
                    </Box>
                </Box>
            </Grid>
        </Grid>
  )
}
