import React, { Component } from 'react'
import { AlumnoMenu, ColegioMenu, LibroMenu, AcademiaMenu, SeguimientoCursoMenu, EmpleadoMenu, ComunicacionesMenu } from './menus'

export default class Main extends Component {

	/**
	 * Render
	 * 
	 * @returns {Component}
	 */
	render() {
		return (
			<div className="app-sidebar-menu overflow-hidden flex-column-fluid">
				<div id="kt_app_sidebar_menu_wrapper" className="app-sidebar-wrapper hover-scroll-overlay-y my-5" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer" data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px" data-kt-scroll-save-state="true" style={{ height: 915 }}>
					{!this.props.sidebar || this.props.sidebar == "alumnos" ? <AlumnoMenu /> : null}

					{!this.props.sidebar || this.props.sidebar == "colegios" ? <ColegioMenu /> : null}

					{!this.props.sidebar || this.props.sidebar == "libros" ? <LibroMenu /> : null}

					{!this.props.sidebar || this.props.sidebar == "academias" ? <AcademiaMenu /> : null}

					{!this.props.sidebar || this.props.sidebar == "seguimiento-curso" ? <SeguimientoCursoMenu /> : null}

					{!this.props.sidebar || this.props.sidebar == "empleados" ? <EmpleadoMenu /> : null}

					{!this.props.sidebar || this.props.sidebar == "comunicaciones" ? <ComunicacionesMenu /> : null}
				</div>
			</div>
		)
	}
}
