

import React, { useState, useRef } from 'react'

const TableColumn = ({defaultValue, onEdit, type = "number", disabled = false}) => {
    const input             = useRef()
    const [value, setValue] = useState(defaultValue ?? "")

    return (
        <td>
            <input 
                ref      = {input} 
                type     = {type} 
                value    = {value} 
                onChange = {(e) => {
                    setValue(e.target.value)

                    if(type === "date")
                        onEdit(e.target.value != "" ? e.target.value : null)
                }} 
                onBlur    = {() => onEdit(value != "" ? value : null)} 
                onKeyDown = {(event) => {
                    if(event.code === 'Enter')
                        input.current.blur()
                    
                    if(["e", "E", "+", "-"].includes(event.key))
                        event.preventDefault()
                }}
                disabled={disabled}/>
        </td>
    )
}

export default TableColumn