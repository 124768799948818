import { useTabContext } from '@mui/lab';

export function TabPanel(props) {
    const {
      children,
      className,
      style,
      value: id,
      containerProps,
      ...other
    } = props
  
    const context = useTabContext()
  
    if (context === null) {
      throw new TypeError("No TabContext provided")
    }
    const tabId = context.value
  
    return (
      <div
        className={className}
        style={{
          width: "100%",
          margin: 0,
          padding: 0,
          display: id === tabId ? "flex" : "none",
          ...style,
        }}
        {...other}
      >
        {children}
      </div>
    )
  }
  