import React from 'react'
import { Grid } from "@mui/material"
import { Datagrid } from 'components/data_display'
import Statics from 'objects/common/Statics'

export default function ClasesParticularesTemplate({ historico = [] }) {
    return (
        <Grid container p={3}>
            <Grid item xs={12}>
                <Datagrid
                    rows={historico}
                    columns={columns} />
            </Grid>
        </Grid>
    )
}

//params.row.profesor
const columns = [
    { field: "curso", headerName: "Año", maxWidth: 125, flex: 1, valueGetter: (params) => { return params?.row?.curso_escolar?.valor ?? 'No definido' } },
    { field: "materia", headerName: "materia", flex: 1, maxWidth: 180, valueGetter: (params) => { return params?.row?.actividad?.nombre ?? 'No definido' } },
    { field: "fecha_alta", headerName: "F. Alta", flex: 1, maxWidth: 180, valueGetter: (params) => { return Statics.formatDate(params?.row?.fecha_alta) ?? 'No definido' } },
    { field: "fecha_baja", headerName: "F. Baja", flex: 1, maxWidth: 180, valueGetter: (params) => { return params?.row?.fecha_baja ?? 'No definido' } },
    {
        field: "profesor", headerName: "Profesor", flex: 1, maxWidth: 250, valueGetter: (params) => {
            return params?.row?.profesor?.nombre + ' ' +
                params?.row?.profesor?.apellido1 + ' ' + params?.row?.profesor?.apellido2 ?? 'No definido'
        }
    },
    { field: "libro", headerName: "Libro", flex: 1, maxWidth: 250, valueGetter: (params) => { return params?.row?.libro?.editorial?.nombre ?? 'No definido' } },
]