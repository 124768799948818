import React, { useContext, useRef, useState } from 'react'
import { ChangesContext } from 'contexts/ChangesContext'
import { WarningNotSavedModal } from 'components/modals'
import { Stack, ButtonGroup, Button } from '@mui/material'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { KeyboardArrowLeft, KeyboardDoubleArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowRight } from '@mui/icons-material'

const PAGE_SIZE = 25

export default function MoveButtons({ 
    actualItemId, 
    context = EmpleadoListContext,
    beforeFetchCallback = () => {},
    onUpdateActualItem = () => {},
}) {
  const { updateChanges, hasChanges }                   = useContext(ChangesContext)
  const { rowCount, items, page, setPage, fetchByPage } = useContext(context)

  const [continueCallback, setCallback] = useState(() => {})
  
  const modalRef = useRef()

  const fetchNew = (newPage, first = true) => {
    beforeFetchCallback()

    fetchByPage(newPage, (items) => {
        updateItem(items[first ? 0 : items.length - 1].id)
    })
  }
  
  const updateItem = (id) => {
    onUpdateActualItem(id)
      
    const index = items.findIndex((item) => item?.id === id)
    if (index !== -1) {
      const page = Math.ceil(index / PAGE_SIZE)
      setPage(page && page !== 0 ? page : 1)
    }
  }

  const previousItem = () => {
    const index = items.findIndex(item => item?.id == actualItemId)
    const item = items[index - 1]
    !item ? fetchNew(page - 1, false) : updateItem(item.id)
  }

  const nextItem = () => {
    const index = items.findIndex(item => item?.id == actualItemId)
    const item = items[index + 1]
    !item ? fetchNew(page + 1) : updateItem(item.id)
  }

  const goToLast = () => {
    const lastItem = items[rowCount - 1]
    !lastItem ? fetchNew(Math.ceil(rowCount / PAGE_SIZE), false) : updateItem(lastItem.id)
  }

  const goToFirst = () => {
    updateItem(items[0].id)
  }

  const checkHasChanges = (callback) => {
    if(hasChanges) {
      setCallback(() => callback)
      modalRef.current.modal.open()
    } else {
      callback()
    }
  }

  const index = items.findIndex(item => item?.id == actualItemId)
  return (
    <React.Fragment>
        {items.length > 0 ?
            <Stack direction="row" justifyContent={"space-between"}>
                <ButtonGroup variant="contained" aria-label="text button group">
                    <Button disabled={(index == 0)} onClick={() => checkHasChanges(goToFirst)}>
                        <KeyboardDoubleArrowLeft />
                    </Button>
                    
                    <Button disabled={(index == 0)} onClick={() => checkHasChanges(previousItem)}>
                        <KeyboardArrowLeft />
                    </Button>

                    <Button disabled={(index + 1 == rowCount)} onClick={() => checkHasChanges(nextItem)}>
                        <KeyboardArrowRight />
                    </Button>

                    <Button disabled={(index + 1 == rowCount)} onClick={() => checkHasChanges(goToLast)}>
                        <KeyboardDoubleArrowRight />
                    </Button>
                </ButtonGroup>
            </Stack>
        : null}

        <WarningNotSavedModal 
            ref        = {modalRef} 
            onGoBack   = {() => {modalRef.current.modal.close()}}
            onContinue = {() => {
              modalRef.current.modal.close()
              updateChanges(false)
              continueCallback()
            }}/>
    </React.Fragment>
    
  )
}
