import React, { useState, useEffect } from 'react'
import { H3 } from 'components/texts'
import { Grid } from '@mui/material'
import TableMesesPagados from './parts/TableMesesPagados'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { Input, DatePicker, Checkbox } from 'components/forms/components'
import { Button } from 'components/forms/components/index.js'

import { Groups as GroupsIcon, InsertDriveFile as FileIcon, CardMembership as CardMember } from '@mui/icons-material'

/**
 * Formulario con los datos generales de la inscripcion a una academia
 */
export default function PagoInscripcionAcademiaDataForm({ inscripcion, register, errors, setValue, handleSubmit }) {
    const [formaDePago, setFormaDePago] = useState()
    const [tipoMatricula, setTipoMatricula] = useState(inscripcion?.tipo_matricula_id)

    return (
        <Grid container py={3}>
            <Grid container p={3} spacing={2} display={"flex"} alignItems={"center"}>
                <Grid item xs={4} >
                    <ItemsSelect
                        name={"forma_pago_id"}
                        keyValue={"forma_pago"}
                        register={register}
                        errors={errors}
                        onChange={(_, formaDePago) => setFormaDePago(formaDePago)}
                        className={"w-100"}
                        defaultValue={inscripcion?.forma_pago_id ?? ""} />
                </Grid>

                <Grid item xs={4} >

                    <ItemsSelect
                        name={"tipo_matricula_id"}
                        keyValue={"tipo_matricula"}
                        register={register}
                        onChange={(_, tipoMatricula) => setTipoMatricula(tipoMatricula)}
                        errors={errors}
                        className={"w-100"}
                        defaultValue={inscripcion?.tipo_matricula_id ?? ""} />
                </Grid>

                <Grid item xs={4} >
                    <Input register={register} errors={errors} name={"matricula"} className="w-100" defaultValue={tipoMatricula?.tipo_matricula_id !== 1 ? '0' : inscripcion?.matricula} type={"number"} />
                </Grid>

                <Grid item xs={4} >
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_pago_matricula"}
                        label={"Fecha de pago"} defaultValue={inscripcion?.fecha_pago_matricula} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={6} >
                    <ItemsSelect
                        name={"tipo_pago_id"}
                        keyValue={"tipo_pago"}
                        register={register}
                        errors={errors}
                        defaultValue={inscripcion?.tipo_pago_id ?? ""} />
                </Grid>

                <Grid item xs={6} >
                    <Input
                        label="Cantidad"
                        register={register}
                        errors={errors}
                        className="w-100"
                        name={"cantidad_pago"}
                        defaultValue={inscripcion?.cantidad_pago ?? ''}
                        type={"number"}
                        uppercase
                        useValue />
                </Grid>

                <Grid item xs={6} md={2}>
                    <Button startIcon={<FileIcon />}>
                        Recibo
                    </Button>
                </Grid>

                <Grid item xs={4}>
                    <Button startIcon={<CardMember />} variant={"outlined"} onClick={handleSubmit}>
                        Certificado
                    </Button>
                </Grid>

            </Grid>
            <Grid container item xs={12} xl={5} style={{ borderLeft: "1px solid #D9D9D9" }} p={3}>

                <Grid item xs={12}>
                    <H3>Contrato</H3>
                </Grid>
                <Grid item xs={6}>
                    <Checkbox name={"emitido"} label={"Emitido"} register={register} />
                </Grid>

                <Grid item xs={6}>
                    <Checkbox name={"recibido"} label={"Recibido"} register={register} />
                </Grid>

            </Grid>
            <Grid container mx={3} mt={5} pb={2} sx={{ overflowX: "auto" }} className={"darker-scrollbar"}>
                <TableMesesPagados inscripcion={inscripcion} register={register} setValue={setValue} />
            </Grid>

            <Grid container px={3} mt={5}>
                <Input placeholder={"Observaciones de los pagos..."} register={register} errors={errors} className="w-100" name={"observaciones"}
                    defaultValue={inscripcion?.observaciones ?? ''} multiline minRows={6} maxRows={10} uppercase />
            </Grid>
        </Grid >
    )
}
