import * as yup from 'yup';

const validationSchema = yup.object().shape({
    hora_inicio: yup.string().required('La hora de inicio es obligatoria'),
    hora_fin: yup.string()
        .required('La hora de fin es obligatoria')
        .test('hora_fin-posterior', 'La hora de fin debe ser posterior a la hora de inicio', function (value) {
            const { hora_inicio } = this.parent;
            if (!hora_inicio || !value) return true;
            return hora_inicio < value;
        }),
});

export default validationSchema;