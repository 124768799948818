import { H3, Text } from "components/texts"
import { enqueueSnackbar } from 'notistack'
import { RequestAuth } from 'objects/requests'
import { Grid, Box } from "@mui/material"
import React, { useEffect, useState } from 'react'
import { LoadingScreen } from 'components/data_display'
import { Checkbox } from 'components/forms/components'

export default function PermisosList ({ setValue, userPermissions, loading, name = 'permissions' }) {
    const [permissions,         setPermissions]         = useState([])
    const [selectedPermissions, setSelectedPermissions] = useState([])

    useEffect(() => {
        new RequestAuth().allPermissions(setPermissions, (res) => {
            enqueueSnackbar(<Text>No se han podido recuperar los permisos: {res}.</Text>, { variant: "error" })
        })
    }, [])

    useEffect(() => {
        const defaultPermissions = userPermissions.map(item => item.id)
        setValue(name, defaultPermissions)
        setSelectedPermissions(defaultPermissions)
    }, [userPermissions])

    const orderPermissions = (permissions) => {
        const groupedData = {}

        permissions.forEach(item => {
            const nameParts = item.name.split(': ') // Dividimos el nombre en dos partes
            
            // Si el nombre se puede dividir y la primera parte no está en groupedData, la agregamos como clave
            if (nameParts.length === 2 && !groupedData[nameParts[0]])
                groupedData[nameParts[0]] = []
            
            // Agregamos el elemento al grupo correspondiente
            if (nameParts.length === 2) {
                groupedData[nameParts[0]].push({
                    id: item.id,
                    name: nameParts[1],
                    guard_name: item.guard_name,
                    created_at: item.created_at,
                    updated_at: item.updated_at
                })
            }
        })

        return groupedData
    }

    const orderedPermissions = orderPermissions(permissions)

    const onCheckboxClick = (checked, permission) => {
        const permissionId = permission.id;

        if (checked && !selectedPermissions.includes(permissionId)) {
            selectedPermissions.push(permissionId);
        } else if (!checked && selectedPermissions.includes(permissionId)) {
          const indexToRemove = selectedPermissions.indexOf(permissionId);
          if (indexToRemove !== -1) {
            selectedPermissions.splice(indexToRemove, 1);
          }
        }

        setValue(name, selectedPermissions)
        setSelectedPermissions(selectedPermissions)
    }

    return (
        <Box sx={{
            p: 5,
            pt: 2,
            width: "100%",
            maxHeight: 500,
            overflowY: "auto"
        }}>
            {loading ? 
                <LoadingScreen />
                :
                Object.keys(orderedPermissions).map(key => {
                    return (
                        <Grid key={key} container my={2}>
                            <Grid item xs={12}>
                                <H3>{formatName(key)}</H3>
                            </Grid>
    
                            {orderedPermissions[key].map(permission => (
                                <Grid key={permission.id} item xs={4}>
                                    <Checkbox 
                                        label          = {formatName(permission.name)} 
                                        defaultChecked = {selectedPermissions.includes(permission.id)}
                                        onClick        = {(checked) => onCheckboxClick(checked, permission)} />
                                </Grid>
                            ))}
                        </Grid>
                    )
                })
            }
        </Box>
    )
}

function formatName(name) {
    const formattedName = name.replace(/-/g, ' ').replace(/^\w/, c => c.toUpperCase())
    return formattedName
}