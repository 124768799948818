import { Text } from 'components/texts'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests'
import { useNavigate, useParams } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Paper, Stack } from '@mui/material'
import { ChangesContext } from 'contexts/ChangesContext'
import { EditHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { AlumnoFullItem } from 'components/data_display/users'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'

//FORM
import { DataFormDatosColegio } from 'components/forms/display'
import Skeleton from 'components/forms/display/datos-colegio/DataForm/skeleton.jsx'
import { validationSchema } from 'components/forms/display/datos-colegio/DataForm/validation.js'

const Wrapper = () => {
  const navigationParams = useParams()

  const { items, updateItem } = useContext(EmpleadoListContext)

  const [datosColegio,     setDatosColegio]   = useState(null)
  const [id_datos_colegio, setIdDatosColegio] = useState(navigationParams.id_datos_colegio)

  const updateAlert = (message, variant = "error") => {
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }

  const getDatosColegio = (getFromServer = false) => {
    const item = items.find(item => item?.id == id_datos_colegio)
    if(items.length == 0 || !item || getFromServer) {
      new Requests().getDatosColegio(id_datos_colegio, (datos_colegio) => {
        setDatosColegio(datos_colegio)
        updateItem(datos_colegio)
      }, () => updateAlert("No se ha podido recuperar los datos del colegio."))
    } else {
      setDatosColegio(item)
    }
  }
    
  useEffect(getDatosColegio, [id_datos_colegio])

  return (
    <React.Fragment>
      <EditDatosColegio key={datosColegio?.id ?? 0} datosColegio={datosColegio} id_datos_colegio={id_datos_colegio} setIdDatosColegio={setIdDatosColegio} setDatosColegio={setDatosColegio} updateAlert={updateAlert} onSaveFinish={() => getDatosColegio(true)}/>

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}
export default Wrapper

function EditDatosColegio({ datosColegio, id_datos_colegio, setIdDatosColegio, setDatosColegio, updateAlert, onSaveFinish }) {
  const navigate = useNavigate()
 
  const { updateChanges } = useContext(ChangesContext)

  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) })

  const headerRef = useRef()

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])


  const updateHeaderAndAlert = ({ message, severity = "error"}, loading = false) => {
    headerRef.current.button.setLoading(loading)
    updateAlert(message, severity)
  }

  /**
   * Hace la petición para editar los datos basicos del responsable
   */
  const editDatosColegio = () => {
    headerRef.current.button.setLoading(true)

    new Requests().editDatosColegio(id_datos_colegio, getValues, (res) => {
      updateHeaderAndAlert({message: "Se han editado los datos correctamente.", severity: "success"})
      reset({}, {keepValues: true})
      updateChanges(false)
      onSaveFinish()
    }, (res) => {
      updateHeaderAndAlert({message: res})
    })
  }

  const updateId = (id) => {
    navigate(`../alumnos/datos-colegio/${id}/editar`)
    setIdDatosColegio(id)
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/",           label: "Inicio" },
            { to: "./../../../", label: "Alumnos" },
            { to: "./../../",    label: "Datos colegio" },
            { to: "./../",       label: "Datos colegio #" + (id_datos_colegio ?? "0000") },
            { to: "./",          label: "Editar", actualItem: true  },
          ]} />

          <MoveButtons 
            actualItemId        = {id_datos_colegio}
            beforeFetchCallback = {() => setDatosColegio(null)} 
            onUpdateActualItem  = {updateId} />
        </Stack>

        <Paper elevation={2} sx={{mx: 8, my: 2, pb: 5}}>
          <EditHeader 
            ref          = {headerRef} 
            title        = {"Editar datos colegio"}
            item         = {datosColegio} 
            handleSubmit = {handleSubmit(editDatosColegio)} />

          <Line />

          {datosColegio ? <DataFormDatosColegio 
                            datosColegio = {datosColegio} 
                            register     = {register} 
                            errors       = {errors} 
                            isMounted
                            hideButton /> : <Skeleton />}
        </Paper>

        <Paper elevation={2} sx={{mx: 8, my: 2, pb: 2}}>
          <EditHeader title="Alumno de los datos" />
            
          <Line />

          {datosColegio?.alumno ? <AlumnoFullItem alumno={datosColegio.alumno} containerClassName="m-5"/> : null}
        </Paper>
      </form>
    </Container>
  )
}