import { Grid } from '@mui/material';
import React, { Component } from 'react'
import { H3, Text } from 'components/texts';

/**
 * Header para los listados de tutorias, incidencias, datos-colegio
 */
export default class Header extends Component {

  constructor(props) {
    super(props)
  }

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
      <Grid container px={5} py={2}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
            <H3 className={"m-0"}>{this.props.title ?? ""}</H3>
            <Text className={"mx-10"}>{"#" + (this.props.id ?? "0000")}</Text>
        </Grid>
      </Grid>
    )
  }
}
