import React from 'react'
import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import { Line } from 'components/data_display'
import { Checkbox, Input } from 'components/forms/components'
import { EmpleadoPicker } from 'components/forms/components/custom'
import { ItemsSelect, YesNoSelect } from 'components/forms/components/Select/custom'

/**
 * Formulario con los datos del colegio
 */
export default function DataFormColegio({ colegio, register, errors, setValue }) {
  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  return (
        <Grid container px={5}>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={4} md={6}>
                    <Input label="Nombre del colegio" register={register} errors={errors} className="w-100" name={"nombre"} defaultValue={colegio?.nombre ?? ""} uppercase />
                </Grid>

                <Grid item xs={4} md={4}>
                    <Input label="Clave del colegio" register={register} errors={errors} className="w-100" name={"clave"} defaultValue={colegio?.clave ?? ""} uppercase />
                </Grid>

                <Grid item xs={4} md={2}>
                    <YesNoSelect label={"Centro de puertas abiertas"} name={"centro_pa"} register={register} errors={errors} defaultValue={colegio?.centro_pa ?? 0} minWidth={"50%"}/>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={4} md={6} />
                <Grid item xs={4} md={4} />

                <Grid item xs={4} md={2}>
                    <Checkbox name={"activo"} label={"Colegio activo"} register={register} defaultChecked={colegio?.activo ? true : false}/>
                </Grid>
            </Grid>

            <Grid container mt={2}>
                <Grid item xs={12}>
                    <Text fontWeight={"bold"}>Datos de contacto:</Text>
                </Grid>
            </Grid>

            <Grid container spacing={2} py={2}>
                <Grid item xs={6}>
                    <Input label="Director" register={register} errors={errors} className="w-100" name={"director"} defaultValue={colegio?.director ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Persona de contacto" register={register} errors={errors} className="w-100" name={"persona_contacto"} defaultValue={colegio?.persona_contacto ?? ""} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Teléfono 1" register={register} errors={errors} className="w-100" name={"telefono1"} defaultValue={colegio?.telefono1 ?? ""} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Teléfono 2" register={register} errors={errors} className="w-100" name={"telefono2"} defaultValue={colegio?.telefono2 ?? ""} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Móvil" register={register} errors={errors} className="w-100" name={"movil"} defaultValue={colegio?.movil ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Email" register={register} errors={errors} className="w-100" name={"email"} defaultValue={colegio?.email ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Email 2" register={register} errors={errors} className="w-100" name={"email2"} defaultValue={colegio?.email2 ?? ""} uppercase />
                </Grid>
                
                <Grid item xs={6}>
                    <Input label="AMPA" register={register} errors={errors} className="w-100" name={"ampa"} defaultValue={colegio?.ampa ?? ""} multiline minRows={6} maxRows={10} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Otros datos" register={register} errors={errors} className="w-100" name={"otros_datos"} defaultValue={colegio?.otros_datos ?? ""} multiline minRows={6} maxRows={10} uppercase />
                </Grid>
            </Grid>

            <Grid container mt={4}>
                <Line />
            </Grid>

            <Grid container spacing={2} py={2}>
                <Grid item xs={6} p={4} style={{borderRight: "1px solid #D9D9D9"}} >
                    <EmpleadoPicker
                        label        = {"Coordinador del colegio"}
                        editLabel    = {"Cambiar"}
                        addLabel     = {"Añadir"}
                        deleteLabel  = {"Borrar"}
                        register     = {register}
                        empleado     = {colegio?.coordinador ?? null}
                        setValue     = {setValue}
                        canEdit />
                </Grid>

                <Grid container spacing={2} item xs={6} p={3} display={"flex"} alignItems={"center"}>
                    <Grid item xs={12}>
                        <Text fontWeight={"bold"}>Dirección del colegio:</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <Input label="Dirección:" register={register} errors={errors} className="w-100" name={"direccion"} defaultValue={colegio?.direccion ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={4}>
                        <Input label="Código postal" register={register} errors={errors} className="w-100" name={"cod_postal"} defaultValue={colegio?.cod_postal ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={4}>
                        <Input label="Municipio" register={register} errors={errors} className="w-100" name={"municipio"} defaultValue={colegio?.direccion ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect name={"id_provincia"} keyValue={"provincia"} register={register} errors={errors} defaultValue={colegio?.id_provincia ?? ""} searcheable/>
                    </Grid>
                </Grid>
            </Grid>
      </Grid>
    )
}
