import { Grid } from '@mui/material';
import React, { Component } from 'react'
import { Input } from 'components/forms/components';
import { GrupoColegioPicker } from 'components/forms/components/custom';

/**
 * Formulario con los datos generales de la inscripcion a un colegio
 */
export default class GrupoInscripcionColegioDataForm extends Component {
    
    constructor(props) {
        super(props)
    }

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    render() {
        return (
            <Grid container p={3}>
                <GrupoColegioPicker id_horario={this.props.id_horario} register={this.props.register} setValue={this.props.setValue} onGrupoChange={this.props.onGrupoChange} defaultValue={this.props.inscripcion?.grupo?.id ?? null}/>

                <Grid container mt={3} spacing={2}>
                    <Grid item xs={6}>
                        <Input placeholder={"Comentarios del profesor"} register={this.props.register} errors={this.props.errors} className="w-100" name={"comentarios_profesor"} defaultValue={this.props.inscripcion?.comentarios_profesor ?? null} multiline minRows={6} maxRows={10} uppercase/>
                    </Grid>

                    <Grid item xs={6}>
                        <Input placeholder={"Observaciones"} register={this.props.register} errors={this.props.errors} className="w-100" name={"observaciones"} defaultValue={this.props.inscripcion?.observaciones ?? null} multiline minRows={6} maxRows={10} uppercase/>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
