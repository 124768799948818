import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom'
import { AdminRoot } from 'pages/components/roots'
import { employeeRoutes } from '../routes.js'

//OTROS
import {
  AdminDashboard, ListadoEmpleados, CreatePagosPPAA, ListadoCandidatos,
  SingleFichaCandidato, EditFichaCandidato, CreateCandidato, CreateEmpleado, SingleFichaEmpleado, EditFichaEmpleado
} from 'pages/logged/admin'

//Fichajes
import { ListadoFichajes, ListadoIncidenciaFichaje } from 'pages/logged/admin'

//COMUNICACIONES
import { ComunicacionesAlumnos } from 'pages/logged/admin'

//ACADEMIA
import { CreateInscripcionesAcademia } from 'pages/logged/admin'
//ALUMNO
import { CreateAlumno, EditAlumno, ListadoAlumnos, SingleAlumnos } from 'pages/logged/admin'

import { SinglePagosAlumnos, EditPagosAlumnos, CreatePagosAlumnos } from 'pages/logged/admin'

import { SingleHistorico } from 'pages/logged/admin'

import { SingleConsultas } from 'pages/logged/admin'

//RESPONSABLE
import { CreateResponsable, EditResponsable, ListadoResponsables, SingleResponsables } from 'pages/logged/admin'
//TUTORIAS
import { EditTutoria, ListadoTutoriasAlumno, CreateTutoria, SingleTutoria } from 'pages/logged/admin'
//INCIDENCIAS
import { CreateIncidencia, ListadoIncidenciasAlumno, EditIncidencia, SingleIncidenciaAlumno } from 'pages/logged/admin'
//DATOS ALUMNO COLEGIO
import { CreateDatosColegio, ListadoDatosColegioAlumno, EditDatosColegio, SingleDatosColegioAlumno } from 'pages/logged/admin'
//COLEGIOS
import { ListadoColegios, CreateColegio, EditColegio, SingleColegio, SingleHorarioColegio } from 'pages/logged/admin'
//GRUPO COLEGIOS
import { CreateGrupoColegio, EditGrupoColegio, ListadoGruposColegio, SingleGrupoColegio } from 'pages/logged/admin'
//ACTIVIDADES
import { CreateActividadesColegio, EditActividadColegio, ListadoActividadesColegio, SingleRegistroActividadColegio } from 'pages/logged/admin'
//INSCRIPCIONES COLEGIO
import { EditInscripcionColegio, CreateInscripcionColegio, ListadoInscripcionesColegio, SingleInscripcionColegio, SinglePagosColegios } from 'pages/logged/admin'
//LIBROS
import { ListadoLibros, CreateLibros, EditLibros, GenerarEnvios, SingleLibros } from 'pages/logged/admin'

//CLASES PARTICULARES
import { ListadoClasesParticulares, CreateClasesParticulares, EditClasesParticulares, SingleClasesParticulares } from 'pages/logged/admin'

//ACTIVIDADES ACADEMIAS
import { ListadoActividadesAcademias, SingleActividades, CreateActividadesAcademia, EditActividadesAcademia } from 'pages/logged/admin'

//INSCRIPCIONES ACADEMIAS
import { ListadoInscripcionesAcademias, SingleInscripcion, EditInscripcionesAcademia } from 'pages/logged/admin'

//AULAS ACADEMIAS
import { ListadoAulasAcademias, CreateAulasAcademia, EditAulasAcademia, SingleAula } from 'pages/logged/admin'
//CONTEXTS
import { EmpleadoListProvider } from 'contexts/employee/ListContext'
//GRUPOS ACADEMIA
import { ListadoGruposAcademia, CreateGrupoAcademia, EditGrupoAcademia, SingleGruposAcademia } from 'pages/logged/admin'
//SEGUIMIENTO CURSO
import { SingleReportTrimestral } from 'pages/logged/admin'

/**
 * Clase para definir y renderizar las rutas de los admins
 */
export default class EmployeeRoutes extends Component {

  /**
   * ComponentDidMount
   */
  componentDidMount() {
    document.body.setAttribute('data-kt-app-layout', 'dark-sidebar')
    document.body.setAttribute('data-kt-app-toolbar-enabled', 'true')
    document.body.setAttribute('data-kt-app-toolbar-enabled', 'true')
    document.body.classList.add('app-default')
    document.body.classList.add('background-app')
    document.body.classList.remove('background-login')
  }

  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    return (
      <Routes>
        <Route element={<AdminRoot />}>
          <Route index element={<AdminDashboard />} />
        </Route>

        {/* ALUMNOS */}
        <Route element={<AdminRoot sidebar={"alumnos"} />}>
          <Route path={employeeRoutes.LISTADO_ALUMNOS} element={<EmpleadoListProvider tipoContexto="alumnos"><ListadoAlumnos /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ALUMNO_SINGLE} element={<EmpleadoListProvider tipoContexto="alumnos"><SingleAlumnos /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ALUMNO_EDIT} element={<EmpleadoListProvider tipoContexto="alumnos"><EditAlumno /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ALUMNO_CREATE} element={<CreateAlumno />} />

          {/* ALUMNOS PAGOS */}
          <Route path={employeeRoutes.ALUMNO_PAGOS} element={<EmpleadoListProvider tipoContexto={"alumnos"}><SinglePagosAlumnos /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ALUMNO_PAGOS_EDITAR} element={<EmpleadoListProvider tipoContexto={""}><EditPagosAlumnos /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ALUMNO_PAGOS_CREATE} element={<EmpleadoListProvider tipoContexto={"alumnos"}><CreatePagosAlumnos /></EmpleadoListProvider>} />

          {/* HISTORICO */}
          <Route path={employeeRoutes.ALUMNOS_HISTORICO_SINGLE} element={<EmpleadoListProvider tipoContexto={"alumnos"}><SingleHistorico /></EmpleadoListProvider>} />

          {/* TUTORIAS */}
          <Route path={employeeRoutes.ALUMNOS_TUTORIAS} element={<EmpleadoListProvider tipoContexto="tutorias"><ListadoTutoriasAlumno /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.TUTORIA_SINGLE} element={<EmpleadoListProvider tipoContexto="tutorias"><SingleTutoria /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.TUTORIA_EDIT} element={<EmpleadoListProvider tipoContexto="tutorias"><EditTutoria /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.TUTORIA_CREATE} element={<CreateTutoria />} />
          {/* INCIDENCIAS */}
          <Route path={employeeRoutes.ALUMNOS_INCIDENCIAS} element={<EmpleadoListProvider tipoContexto="incidencias"><ListadoIncidenciasAlumno /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ALUMNO_INCIDENCIA_SINGLE} element={<EmpleadoListProvider tipoContexto="incidencias"><SingleIncidenciaAlumno /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ALUMNO_INCIDENCIAS_EDITAR} element={<EmpleadoListProvider tipoContexto="incidencias"><EditIncidencia /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ALUMNOS_INCIDENCIAS_CREATE} element={<CreateIncidencia />} />
          {/* RESPONSABLES */}
          <Route path={employeeRoutes.RESPONSABLE_EDITAR} element={<EmpleadoListProvider tipoContexto="responsables"><EditResponsable /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.RESPONSABLE_SINGLE} element={<EmpleadoListProvider tipoContexto="responsables"><SingleResponsables /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.LISTADO_RESPONSABLES} element={<EmpleadoListProvider tipoContexto="responsables"><ListadoResponsables /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.RESPONSABLE_CREATE} element={<CreateResponsable />} />
          {/* DATOS COLEGIO */}
          <Route path={employeeRoutes.DATOS_COLEGIO} element={<EmpleadoListProvider tipoContexto="datos-colegio"><ListadoDatosColegioAlumno /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ALUMNO_DATOS_COLEGIO} element={<EmpleadoListProvider tipoContexto="datos-colegio"><SingleDatosColegioAlumno /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ALUMNO_DATOS_COLEGIO_EDITAR} element={<EmpleadoListProvider tipoContexto="datos-colegio"><EditDatosColegio /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ALUMNO_DATOS_COLEGIO_CREATE} element={<CreateDatosColegio />} />
        </Route>

        {/* LIBROS */}
        <Route element={<AdminRoot sidebar={"libros"} />}>
          <Route path={employeeRoutes.LISTADO_LIBROS} element={<EmpleadoListProvider tipoContexto="libros"><ListadoLibros /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.LIBRO_EDIT} element={<EmpleadoListProvider tipoContexto="libros"><EditLibros /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.LIBRO_SINGLE} element={<EmpleadoListProvider tipoContexto="libros"><SingleLibros /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.LIBRO_CREATE} element={<CreateLibros />} />
          <Route path={employeeRoutes.LIBRO_GENERAR_ENVIO} element={<GenerarEnvios />} />
          <Route path={employeeRoutes.LIBRO_LISTADOS} element={<EmpleadoListProvider tipoContexto={null}><SingleConsultas /></EmpleadoListProvider>} />

        </Route>

        {/* EMPLEADOS */}
        <Route element={<AdminRoot sidebar={"empleados"} />}>
          <Route path={employeeRoutes.LISTADO_EMPLEADOS} element={<EmpleadoListProvider tipoContexto="empleados"><ListadoEmpleados /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.SINGLE_EMPLEADO} element={<EmpleadoListProvider tipoContexto="empleados"><SingleFichaEmpleado /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.EDIT_EMPLEADO} element={<EmpleadoListProvider tipoContexto="empleados"><EditFichaEmpleado /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.CREATE_EMPLEADO} element={<EmpleadoListProvider tipoContexto=""><CreateEmpleado /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.EMPLEADOS_FICHAJE_HISTORIAL} element={<ListadoFichajes />} />
          <Route path={employeeRoutes.EMPLEADOS_FICHAJE_INCIDENCIAS} element={<ListadoIncidenciaFichaje />} />
          <Route path={employeeRoutes.EMPLEADOS_FICHA_CANDIDATOS_LIST} element={<EmpleadoListProvider tipoContexto=""><ListadoCandidatos /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.EMPLEADOS_SINGLE_FICHA_CANDIDATO} element={<EmpleadoListProvider tipoContexto=""><SingleFichaCandidato /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.EMPLEADOS_EDIT_FICHA_CANDIDATO} element={<EmpleadoListProvider tipoContexto=""><EditFichaCandidato /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.EMPLEADOS_CREATE_FICHA_CANDIDATO} element={<EmpleadoListProvider tipoContexto=""><CreateCandidato /></EmpleadoListProvider>} />

        </Route>

        {/* COLEGIOS */}
        <Route element={<AdminRoot sidebar={"colegios"} />}>
          <Route path={employeeRoutes.LISTADO_COLEGIOS} element={<EmpleadoListProvider tipoContexto="colegios"><ListadoColegios /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.COLEGIO_SINGLE} element={<EmpleadoListProvider tipoContexto="colegios"><SingleColegio /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.COLEGIO_EDIT} element={<EmpleadoListProvider tipoContexto="colegios"><EditColegio /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.COLEGIO_CREATE} element={<CreateColegio />} />
          {/* GRUPOS */}
          <Route path={employeeRoutes.COLEGIO_GRUPOS_CREAR} element={<CreateGrupoColegio />} />
          <Route path={employeeRoutes.COLEGIO_GRUPOS} element={<EmpleadoListProvider tipoContexto="grupos-colegio"><ListadoGruposColegio /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.COLEGIO_GRUPOS_EDITAR} element={<EmpleadoListProvider tipoContexto="grupos-colegio"><EditGrupoColegio /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.COLEGIO_GRUPOS_SINGLE} element={<EmpleadoListProvider tipoContexto="grupos-colegio"><SingleGrupoColegio /></EmpleadoListProvider>} />

          {/* ACTIVIDADES */}
          <Route path={employeeRoutes.COLEGIO_LISTADO_ACTIVIDADES} element={<EmpleadoListProvider tipoContexto="actividades-colegio"><ListadoActividadesColegio /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.EDIT_ACTIVIDADES_COLEGIO} element={<EmpleadoListProvider tipoContexto="actividades-colegio"><EditActividadColegio /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.SINGLE_ACTIVIDADES_COLEGIO} element={<EmpleadoListProvider tipoContexto="actividades-colegio"><SingleRegistroActividadColegio /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.CREATE_ACTIVIDADES_COLEGIO} element={<CreateActividadesColegio />} />

          {/* INSCRIPCIONES */}
          <Route path={employeeRoutes.COLEGIO_INSCRIPCIONES_CREATE} element={<CreateInscripcionColegio />} />
          <Route path={employeeRoutes.COLEGIO_LISTADO_INSCRIPCIONES} element={<EmpleadoListProvider tipoContexto="inscripciones-colegio"><ListadoInscripcionesColegio /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.COLEGIO_INSCRIPCIONES_SINGLE} element={<EmpleadoListProvider tipoContexto="inscripciones-colegio"><SingleInscripcionColegio /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.COLEGIO_INSCRIPCIONES_EDITAR} element={<EmpleadoListProvider tipoContexto="inscripciones-colegio"><EditInscripcionColegio /></EmpleadoListProvider>} />

          {/** HORARIOS */}
          <Route path={employeeRoutes.COLEGIO_HORARIOS} element={<SingleHorarioColegio />} />

          {/** PAGOS */}
          <Route path={employeeRoutes.COLEGIO_PAGOS_SINGLE} element={<SinglePagosColegios />} />

        </Route>

        {/* ACADEMIAS */}
        <Route element={<AdminRoot sidebar={"academias"} />}>
          <Route path={employeeRoutes.LISTADO_ACADEMIAS} element={null} />

          {/* ACTIVIDADES */}
          <Route path={employeeRoutes.ACADEMIA_LISTADO_ACTIVIDADES} element={<EmpleadoListProvider tipoContexto="actividadesAcademias"><ListadoActividadesAcademias /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ACADEMIA_CREATE} element={<EmpleadoListProvider tipoContexto="actividadesAcademias"><CreateActividadesAcademia /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ACADEMIA_ACTIVIDADES_DATA} element={<EmpleadoListProvider tipoContexto="actividadesAcademias"><SingleActividades /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ACADEMIA_EDIT} element={<EmpleadoListProvider tipoContexto="actividadesAcademias"><EditActividadesAcademia /></EmpleadoListProvider>} />

          {/* INSCRIPCIONES */}
          <Route path={employeeRoutes.ACADEMIA_LISTADO_INSCRIPCIONES} element={<EmpleadoListProvider tipoContexto="inscripcionesAcademias"><ListadoInscripcionesAcademias /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ACADEMIA_CREATE_INSCRIPCIONES} element={<EmpleadoListProvider tipoContexto="inscripcionesAcademias"><CreateInscripcionesAcademia /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ACADEMIA_SINGLE_INSCRIPCIONES} element={<EmpleadoListProvider tipoContexto="inscripcionesAcademias"><SingleInscripcion /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ACADEMIA_EDIT_INSCRIPCIONES} element={<EmpleadoListProvider tipoContexto="inscripcionesAcademias"><EditInscripcionesAcademia /></EmpleadoListProvider>} />

          {/* AULAS */}
          <Route path={employeeRoutes.ACADEMIA_LISTADO_AULAS} element={<EmpleadoListProvider tipoContexto="aulas"><ListadoAulasAcademias /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ACADEMIA_AULA_EDIT} element={<EmpleadoListProvider tipoContexto="aulas"><EditAulasAcademia /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ACADEMIA_AULA_SINGLE} element={<EmpleadoListProvider tipoContexto="aulas"><SingleAula /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ACADEMIA_AULA_CREATE} element={<CreateAulasAcademia />} />

          {/* GRUPOS */}
          <Route path={employeeRoutes.ACADEMIA_LISTADO_GRUPOS} element={<EmpleadoListProvider tipoContexto={'grupo-academia'}><ListadoGruposAcademia /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ACADEMIA_CREATE_GRUPOS} element={<CreateGrupoAcademia />} />
          <Route path={employeeRoutes.ACADEMIA_SINGLE_GRUPOS} element={<EmpleadoListProvider tipoContexto={'grupo-academia'}><SingleGruposAcademia /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.ACADEMIA_EDIT_GRUPOS} element={<EmpleadoListProvider tipoContexto={'grupo-academia'}><EditGrupoAcademia /></EmpleadoListProvider>} />

        </Route>

        {/** CLASES PARTICULARES */}
        <Route element={<AdminRoot />}>
          <Route path={employeeRoutes.CLASES_PARTICULARES} element={<EmpleadoListProvider tipoContexto="clases-particulares"><ListadoClasesParticulares /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.CLASES_PARTICULARES_EDIT} element={<EmpleadoListProvider tipoContexto="clases-particulares"><EditClasesParticulares /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.CLASES_PARTICULARES_SINGLE} element={<EmpleadoListProvider tipoContexto="clases-particulares"><SingleClasesParticulares /></EmpleadoListProvider>} />
          <Route path={employeeRoutes.CLASES_PARTICULARES_CREATE} element={<CreateClasesParticulares />} />
        </Route>

        <Route element={<AdminRoot sidebar={"seguimiento-curso"} />}>
          <Route path={employeeRoutes.SEGUIMIENTO_CURSO} element={null} />
          <Route path={employeeRoutes.SEGUIMIENTO_CURSO_REPORT_TRIMESTRAL} element={<SingleReportTrimestral />} />
          <Route path={employeeRoutes.SEGUIMIENTO_CURSO_PAGOS_PPAA} element={<CreatePagosPPAA />} />
        </Route>


        {/** COMUNICACIONES */}

        <Route element={<AdminRoot sidebar={"comunicaciones"} />}>
          <Route path={employeeRoutes.COMUNICACIONES} element={<EmpleadoListProvider tipoContexto="colegios"><ComunicacionesAlumnos /></EmpleadoListProvider>} />
        </Route>

        {/* NOT FOUND */}
        <Route path="/*" element={<h1>404 NOT FOUND</h1>} />
      </Routes>
    )
  }
}
