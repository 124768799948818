import React, { useState, useEffect } from 'react'
import { RequestResponsable } from 'objects/requests'
import { useNavigate, useParams } from 'react-router'
import { AlumnoData, ResponsableData } from './parts'
import { Container, Paper, Stack } from '@mui/material'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'

export default function SingleResponsable() {
  const navigationParams = useParams()
  const navigate = useNavigate()

  const [id_responsable, setIdResponsable] = useState(navigationParams.id_responsable)
  const [responsable,    setResponsable]   = useState(null)
  
  /**
   * Hace la petición para obtener el usuario
   */
  const getResponsable = () => {
    const params = { relations: 'alumnos,tipo_documento' }

    new RequestResponsable().get(id_responsable, params, (res) => {
      if (res.responsable)
        setResponsable(res.responsable)
    })
  }

  useEffect(getResponsable, [id_responsable])

  /**
   * Actualiza la id del responsable actual
   * 
   * @param {Int} id 
   */
  const updateId = (id) => {
    navigate(`../alumnos/tutores/${id}`);
    setIdResponsable(id);
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

		<Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
			{ to: "/",     label: "Inicio" },
			{ to: "./../", label: "Tutores" },
			{ to: "./",    label: "Tutor #" + (id_responsable ?? "0000"), actualItem: true },
          ]} />

          <MoveButtons 
            actualItemId        = {id_responsable}
            beforeFetchCallback = {() => setResponsable(null)} 
            onUpdateActualItem  = {updateId} />
        </Stack>

		<Paper elevation={2} sx={{mx: 8, my: 2, p: 5}}>
			<ResponsableData responsable={responsable} navigate={navigate} />

			<Line className={"my-10"}/>

			<AlumnoData alumnos={responsable && responsable.alumnos ? responsable.alumnos : null} responsable={responsable} reload={getResponsable} />
		</Paper>
    </Container>
  )
}