import { RequestClasesParticulares } from "objects/requests";

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {
    /**
     * Hace la petición para obtener la clase particular
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getClaseParticular(id, callbackOk = () => { }, callbackError = () => { }) {
        const params = { relations: 'horarios,venta_libro,venta_libro_asociado,empresa,empleado,registro_actividad.actividad.modalidad_cursos,alumno,lugar_clase,profesor.sexo,forma_pago,forma_conocimiento,forma_recogida,libro,origen_profesor,sexo,pagos,curso_escolar' }
        new RequestClasesParticulares().get(id, params, (res) => { callbackOk(res.item ?? null) }, callbackError)
    }

    /**
     * Hace la petición para obtener la vista preliminar de una clase particular
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getPreliminar(id, callbackError = () => { }) {
        const path = "preliminar/" + id
        new RequestClasesParticulares().pdf(path, {}, callbackError)
    }
}