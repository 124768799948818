import React, { Component } from 'react'

/**
 * Es una linea
 */
export default class Line extends Component {

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
        <div className={"custom-line " + (this.props.className ?? "")}></div>
    )
  }
}
