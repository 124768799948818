import moment from 'moment'
import styles from './styles.js'
import { SnackbarProvider } from 'notistack'
import { Line } from 'components/data_display'
import { Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { LibrosPedidos, LibrosDevueltos, Almacen } from './parts'
import { ItemsSelect } from 'components/forms/components/Select/custom'

const cursoActual = moment().subtract(1, 'year').format('YYYY') + "-" + moment().format('YYYY')

/**
 * Formulario con los datos de un libro
 */
export default function DataFormLibroControlAlmacen({ libro, register, isMounted = false, setValue }) {

  const [cursoEscolar,    setCursoEscolar]    = useState(null)
  const [librosDevueltos, setLibrosDevueltos] = useState(libro?.devoluciones_libro ?? [])
  const [librosPedidos,   setLibrosPedidos]   = useState(libro?.pedidos_libro      ?? [])

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  useEffect(() => {
    setValue("librosDevueltos", librosDevueltos)
  }, [])

  useEffect(() => {
    setValue("librosPedidos", librosPedidos)
  }, [])

  const updateLibrosPedidos = (librosPedidos) => {
    setLibrosPedidos(librosPedidos)
    setValue("librosPedidos", librosPedidos, {shouldDirty: true})
  }

  const updateLibrosDevueltos = (librosDevueltos) => {
    setLibrosDevueltos(librosDevueltos)
    setValue("librosDevueltos", librosDevueltos, {shouldDirty: true})
  }


  /**
   * Render
   *
   * @returns {Component}
   */
  return (
      <Grid container px={3}>

          <Grid container mb={2} mt={4}>
              {isMounted ? 
                <ItemsSelect 
                  name                 = {"id_curso_escolar"}
                  keyValue             = {"curso_escolar"}
                  className            = {"w-50"}
                  initialOptionByLabel = {cursoActual}
                  onChange             = {({target}) => setCursoEscolar(target.value)}
                  setValue             = {setValue}
                  register             = {register} /> : null}
          </Grid>

          <Line className={"mt-5"}/>

          <Grid container my={3}>
            <Grid item xs={12} xl={6}>
              <LibrosPedidos cursoEscolar={cursoEscolar} librosPedidos={librosPedidos} onUpdate={updateLibrosPedidos}/>
            </Grid>

            <Grid item xs={12} xl={6} sx={styles.librosDevueltosGrid}>
              <LibrosDevueltos cursoEscolar={cursoEscolar} librosDevueltos={librosDevueltos} onUpdate={updateLibrosDevueltos}/>
            </Grid>
          </Grid>

          <Line />

          <Grid container my={3}>
            <Almacen cursoEscolar={cursoEscolar} librosDevueltos={librosDevueltos} librosPedidos={librosPedidos} libro={libro}/>
          </Grid>

          <TextField type={"hidden"} variant={"standard"} {...register ? register("librosPedidos") : {}} />
          <TextField type={"hidden"} variant={"standard"} {...register ? register("librosDevueltos") : {}} />

          <SnackbarProvider autoHideDuration={5000} />
    </Grid>
  )
}