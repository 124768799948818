import moment from "moment"
import { days } from 'components/data_display/Horario/constants'

export default class Statics {
    
    /**
     * Limpia un objeto de valores vacios o nulos
     * 
     * @param {Object} object
     * 
     * @return {Object}
     */
    static cleanObject = (object) => {
        for (let propName in object) {
            if (object[propName] === null || object[propName] === '' || object[propName] === undefined)
              delete object[propName]
        }
        
        return object
    }


    /**
     * Tranforma las propiedades de un objeto a uppercase en caso de ser un string
     * 
     * @param {Object} object
     * 
     * @return {Object}
     */
    static objectPropsToUppercase = (object) => {
        for (let propName in object) {
            if (object[propName] != null && object[propName] != undefined  && (typeof object[propName] === 'string' || object[propName] instanceof String))
                object[propName] = object[propName].toUpperCase()
        }
        
        return object
    }

    /**
     * Abre un fichero en una nueva pestaña
     * 
     * @param {File}   file
     * @param {String} type
     */
    static openFileInExplorer = (file, type) => {
        const pdfBlob = new Blob([file], { type })
        const blobURL = window.URL.createObjectURL(pdfBlob)
        
        const link = document.createElement('a')
        link.href = blobURL
        link.setAttribute('target', "_blank ")
        link.click()
        link.remove()
    
        URL.revokeObjectURL(blobURL)
    }

    /**
     * Descarga un fichero en el navegador
     * 
     * @param {File}   file
     * @param {String} type
     * @param {String} fileName
     */
    static downloadFileInExplorer = (file, type, fileName) => {
        const blob = new Blob([file], { type })
        const blobURL = window.URL.createObjectURL(blob)
      
        const link = document.createElement('a')
        link.href = blobURL
        link.setAttribute('download', `${fileName}.xlsx`)
        link.click()
        link.remove()
      
        URL.revokeObjectURL(blobURL)
    }

    /**
     * Suma todos los valores de un campo de un array de objetos
     * 
     * @param {Array}  array 
     * @param {String} property 
     * @returns 
     */
    static sumParamsOfObjectArray = (array, property) => {
        let sum = 0

        array.forEach(element => { 
            if(element && element[property])
                sum += parseInt(element[property]) 
        })
      
        return sum
    }


    /**
     * Copia un objeto eliminando la referencia
     */
    static copyObject = (obj) => {
        return JSON.parse(JSON.stringify(obj))
    }


    /**
     * Borra los espacios en blanco de un string
     * 
     * @param {String} string
     * 
     * @return {String}
     */
    static removeWhiteSpaces = (string) => {
        return string.replace(/\s+/g, '')
    }


    /**
     * Hace una espera del tiempo que le pases en milisegundos
     * 
     * @param {Int} time 
     * 
     * @returns {Promise}
     */
    static delay(time) {
        return new Promise(resolve => setTimeout(resolve, time))
    }


    /**
     * Transforma el objeto de fechga que te devuelve el DatePicker a YYYY-MM-DD
     * 
     * @param {Object} date
     * 
     * @return {String}
     */
    static datePickerTimeFormat(date) {
        if(!date)
            return ""

        if(this.checkDateFormat(date))
            return date

        return this.formatDate(date, 'DD/MM/YYYY', 'YYYY-MM-DD')
    }


    /**
     * Devuelve true si algún elemento de un objeto no es null
     * 
     * @param Object obj 
     * @param Array  ignore 
     * @returns 
     */
    static checkIfOneElementIsNotEmpty(obj, ignore) {
        for (let key in obj)
          if (obj[key] && !ignore.includes(key))
            return true

        return false
    }


    /**
     * Validador de numeros para las validaciones YUP
     * 
     * @returns {String}
     */
    static validatorValidNumberWithDecimals(oldValue, value){
        if (value.includes('.')) {
            return null
        }

        return +value.replace(/,/, '.')
    }


    /**
     * Cambia las comas por puntos o los puntos por comas dependiendo de un booleano
     * 
     * @param {String} text
     * @param {Boolean} dotToComma
     * 
     * @returns {String}
     */
    static changeDotAndComma(text, dotToComma = true) {
        text = "" + text //Por si es un numero
        return dotToComma ? text.replace(/\./g, ',') : text.replace(/\,/g, '.')
    }


    /**
     * Calcula si el color del texto debe ser negro o blanco dependiendo del fondo
     * 
     * @param {String} bgColor
     * 
     * @returns {String}
     */
    static detectTextColorByBackgroundColor(bgColor) {
        const r = parseInt(bgColor.substring(1,3),16)
        const g = parseInt(bgColor.substring(3,5),16)
        const b = parseInt(bgColor.substring(5,7),16)
        const brightness = ((r*299)+(g*587)+(b*114))/1000

        return (brightness > 125) ? 'black' : 'white'
    }

    
    /**
     * Separa el array de definiciones que viene de la base de datos en horas con sus respectivos días
     * 
     * @param {Array} definiciones 
     * 
     * @returns {Array}
     */
    static separateDaysWithHours(definiciones) {
        const horarios = definiciones.reduce((items, item) => {
            const horario = items[item.hora_inicio + "-" + item.hora_fin] || []
            horario.push(item)
            items[item.hora_inicio + "-" + item.hora_fin] = horario

            return items
        }, {})

        return horarios
    }


    /**
     * Comprueba si un texto contiene otro texto
     * 
     * @param {String} text 
     * @param {String} searchText 
     * @returns 
     */
    static containsText = (text, searchText) => {
        return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    }


    /**
     * Comprueba si el formato de una fecha es valido
     * 
     * @param {Date}   date 
     * @param {String} format 
     */
    static checkDateFormat(date, format = 'YYYY-MM-DD') {
        return moment(date, format, true).isValid()
    }


    /**
     * Se encarga de formatear una fecha a otro formato utilizando moment
     * 
     * @param {String} date 
     * @param {String} initialFormat 
     * @param {String} endFormat 
     */
    static formatDate(date, initialFormat = 'YYYY-MM-DD', endFormat = 'DD/MM/YYYY') {
        if(!date) 
            return ""
        
        date = moment(date, initialFormat)
        return date.format(endFormat)
    }


    /**
     * Transforma el array de horarios que llega de la base de datos y lo transforma para que el label sea legible
     * 
     * @param {Array} horarios 
     */
    static getHorariosFormattedArray(horarios) {
        return horarios.map((item, index) => ( Statics.getHorarioFormatted(item) ))
    }


    /**
     * Formatea un horario de la base de datos a un formatio legible
     * 
     * @param {Object} horario 
     * 
     * @returns {Object}
     */
    static getHorarioFormatted(horario) {
        if(horario) {
            const params       = { value: horario.id, label: '[' + horario.clave + '] - ' }
            const formatter    = new Intl.ListFormat('es', { style: 'long', type: 'unit' })
            const horariosDays = Statics.separateDaysWithHours(horario.definiciones)
            let dayHourString  = ""
    
            for(let key of Object.keys(horariosDays)) {
                const localDays = horariosDays[key].map(horarioItem => (days.find(itemDay => itemDay.value == horarioItem.dia).short))
                
                const cad = (formatter.format(localDays)) + ": " + key
                dayHourString += cad + " | "
            }
    
            params.label += dayHourString.replace(/\|\s*$/, "")
            return params
        }
        
        return null
    }

    /**
     * Compara los valores de los campos del formulario y devuelve true si hay algún cambio, de lo contrario devuelve false
     */
    static hasFormChanged = (previousValues, currentValues) => {
        return JSON.stringify(previousValues) !== JSON.stringify(currentValues)
    }

    
    static errorArrayResponseToString(errors) {
        let errorMessage = '';
      
        for (let key in errors) {
          if (errors.hasOwnProperty(key)) {
            const errorMessages = errors[key];
      
            if (Array.isArray(errorMessages)) {
              errorMessage += errorMessages.join('. ') + '. ';
            }
          }
        }
      
        return errorMessage.trim();
    }


    // FUNCIONES PARA EL FICHAJE
    static getCurrentDate = (date) => {
        const format = date ?? new Date()
        return format.toLocaleDateString('es-ES', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' });
    }
    
    static getCurrentTime = () => {
        const fecha = new Date();
        const horas = String(fecha.getHours()).padStart(2, '0');
        const minutos = String(fecha.getMinutes()).padStart(2, '0');
        const segundos = String(fecha.getSeconds()).padStart(2, '0');
        const horaActual = `${horas}:${minutos}:${segundos}`;
        return horaActual;
    }

    static calculateTimeDifference = (time1, time2) => {
        const startTime = new Date(`1970-01-01T${time1}Z`);
        const endTime = new Date(`1970-01-01T${time2}Z`);
    
        // Calcula la diferencia en milisegundos
        const milisecondsDifference = endTime - startTime;
    
        // Convierte la diferencia a segundos
        const secondsDifference = Math.floor(milisecondsDifference / 1000);
    
        return secondsDifference;
    };
    
    static capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    static openNewTab = (url) => {
        const newWindow = window.open(url, '_blank');
        if (newWindow) {
          newWindow.focus(); // Enfoca la nueva pestaña si se pudo abrir
        } else {
          alert('El navegador bloqueó la apertura de una nueva pestaña. Verifica tu configuración de bloqueo de ventanas emergentes.');
        }
      };

}