import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Agenda, Fichaje } from './parts'
import { useSelector } from 'react-redux'

export default function Dashboard(){
  const roles = useSelector((state) => state.userState.roles)

  return (
    <Grid container p={4} spacing={2}>
      {roles.includes('admin') ? <AdminDashboard /> : <FichajeDashboard />}
    </Grid>
  )
}


function AdminDashboard() {
  return (
    <React.Fragment>
      <Grid item md={6} xs={12}>
        <Fichaje />
      </Grid>

      <Grid item md={6} xs={12}>
        <Agenda />
      </Grid>
    </React.Fragment>
  )
    
}


function FichajeDashboard() {
  return (
    <Grid item xs={12} className="d-flex justify-content-center">
      <Fichaje containerClassName="w-50"/>
    </Grid>
  )
}