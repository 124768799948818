import React, { useState } from 'react'
import { Text } from 'components/texts'
import { Grid } from '@mui/material'
import { Input } from 'components/forms/components'
import { GroupButtons } from 'components/data_display'



/**
 * Esta es la plantilla para cuando se filtren los candidatos por actividades
 */

const labels = [
    { label: "Nativo", isSelected: false },
    { label: "Vehiculo", isSelected: false },
    { label: "Idiomas", isSelected: false },
    { label: "Inglés", isSelected: false },
    { label: "Francés", isSelected: false },
    { label: "Alemán", isSelected: false },
    { label: "Italiano", isSelected: false },
    { label: "Arabe", isSelected: false },
    { label: "Otras Act. Dep", isSelected: false },
];
export default function CandidatosIdiomasTemplate({onChange}) {

    //******* functions *******//

    const handleButtonChange = (selectedButtons) => {
        console.log("Botones seleccionados:", selectedButtons);
        if (onChange) {
            onChange(selectedButtons);
        }
    };


    return (
        <>
            <Grid container>
                <Grid container mt={2} mb={1}>
                    <Text fontWeight={'bold'}>Según datos de:</Text><br />
                </Grid>
            </Grid>

          <GroupButtons labels={labels} onChange={handleButtonChange} searchable={true}/>
           
        </>
    )
}

