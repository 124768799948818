import {
    InscripcionAlumnosAcademiaTemplate,
    InscripcionActividadesAcademiaTemplate,
    InscripcionGruposAcademiaTemplate,
    InscripcionesColectivosAcademiaTemplate,
    InscripcionPendientesAcademiaTemplate
} from "./parts"

export const itemsSearch = [
    { value: 0, columnKey: "alumnos", label: "Inscripciones de la Academia por Alumnos", template: InscripcionAlumnosAcademiaTemplate },
    { value: 1, columnKey: "grupos", label: "Inscripciones de la Academia por Grupos", template: InscripcionGruposAcademiaTemplate },
    { value: 2, columnKey: "actividades", label: "Inscripciones de la Academia por Actividades", template: InscripcionActividadesAcademiaTemplate },
    { value: 3, columnKey: "colectivos", label: "Inscripciones de la Academia por colectivos", template:     InscripcionesColectivosAcademiaTemplate},
    { value: 4, columnKey: "pendientes", label: "Inscripciones pendientes de la Academia", template:     InscripcionPendientesAcademiaTemplate},
]

export const itemsOrder = [
    { value: '{"alumno.nombre": "ASC"}', label: "Alumnos" },
]