import React, { Component } from 'react'
import { COLOR } from 'theme/Colors'
import { Button } from 'components/forms/components'
import { Text } from 'components/texts'
import Modal from 'components/modals/Modal.jsx'
import { Edit as EditIcon, Search as SearchIcon } from '@mui/icons-material'
import { FastCreateFormResponsable } from 'components/forms/display'
import { SnackbarAlert } from 'components/data_display'
import ChooseList from './parts/ChooseList'
import { Grid } from '@mui/material'

const CREATE_BUTTON = 1
const ASOCIATE_BUTTON = 2

/**
 * Modal con el formulario de añadir responsable
 */
export default class AddResponsableModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            buttonSelected: CREATE_BUTTON
        }
    }


    /**
     * Cambia el botón que está elegido actualmente
     * 
     * @param {Int} buttonSelected 
     */
    buttonClicked = (buttonSelected) => {
        this.setState({ buttonSelected })
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <>
                <Modal ref={(ref) => this.modal = ref} title={"Añadir un tutor"}>
                    <Grid container pb={5} pt={2} px={5}>
                        <Grid item xs={12} display={"flex"} justifyContent={"center"} >
                            <Text fontWeight={"bold"}>Puedes crear un nuevo tutor o escoger uno ya existente.</Text>
                        </Grid>

                        <Grid container my={2}>
                            <Grid item xs={6} display={"flex"} justifyContent={"end"} alignSelf={"end"} pr={1}>
                                <Button
                                    startIcon={<EditIcon style={{ color: this.state.buttonSelected == CREATE_BUTTON ? COLOR.white.main : COLOR.standard.main }} />}
                                    variant={this.state.buttonSelected == CREATE_BUTTON ? "contained" : "outlined"}
                                    onClick={() => this.buttonClicked(CREATE_BUTTON)}>
                                    Crear nuevo
                                </Button>
                            </Grid>

                            <Grid item xs={6} pl={1}>
                                <Button
                                    startIcon={<SearchIcon style={{ color: this.state.buttonSelected == ASOCIATE_BUTTON ? COLOR.white.main : COLOR.standard.main }} />}
                                    variant={this.state.buttonSelected == ASOCIATE_BUTTON ? "contained" : "outlined"}
                                    onClick={() => this.buttonClicked(ASOCIATE_BUTTON)} >
                                    Escoger de la lista
                                </Button>
                            </Grid>
                        </Grid>

                        {this.state.buttonSelected == CREATE_BUTTON ?
                            <FastCreateFormResponsable id_alumno={this.props.alumno ? this.props.alumno.id : null}
                                callbackOk={() => {
                                    this.snackBarAlertRef.updateState({ message: "Se ha creado el tutor correctamente.", severity: "success", open: true })
                                    this.modal.close()
                                    this.props.onCreateCallback()
                                }}
                                callbackError={(res) => {
                                    this.snackBarAlertRef.updateState({ message: res, severity: "error", open: true })
                                }} />
                            :
                            <ChooseList alumno={this.props.alumno}
                                callbackOk={() => {
                                    this.snackBarAlertRef.updateState({ message: "Se ha asociado el tutor correctamente.", severity: "success", open: true })
                                    this.modal.close()
                                    this.props.onCreateCallback()
                                }}
                                callbackError={(res) => {
                                    this.snackBarAlertRef.updateState({ message: res, severity: "error", open: true })
                                }} />
                        }
                    </Grid>
                </Modal>
                <SnackbarAlert ref={(ref) => this.snackBarAlertRef = ref} />
            </>
        )
    }
}
