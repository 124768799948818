import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const CustomFixedSizeList = ({ itemCount, itemsPerColumn, itemWidth, renderItem }) => {
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleScroll = (event) => {
        const { scrollLeft } = event.target;
        setScrollLeft(scrollLeft);
    };

    const containerWidth = itemWidth * Math.ceil(itemCount / itemsPerColumn);
    const visibleColumns = Math.ceil(window.innerWidth / itemWidth);
    const startColumn = Math.floor(scrollLeft / itemWidth);
    const endColumn = Math.min(startColumn + visibleColumns, Math.ceil(itemCount / itemsPerColumn));

    const columns = [];
    for (let columnIndex = startColumn; columnIndex < endColumn; columnIndex++) {
        const startItem = columnIndex * itemsPerColumn;
        const endItem = Math.min(startItem + itemsPerColumn, itemCount);

        const items = [];
        for (let index = startItem; index < endItem; index++) {
            items.push(renderItem(index));
        }

        columns.push(
            <div key={columnIndex} style={{ width: `${itemWidth}px` }}>
                {items}
            </div>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                width: `${containerWidth}px`,
                height: '300px',
                overflowX: 'scroll',
                overflowY: 'hidden',
                border: '1px solid #D9D9D9',
                borderRadius: '5px',
                scrollbarWidth: 'auto',
                '&::-webkit-scrollbar': {
                    width: '20px',
                    height: '10px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#D9D9D9',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#D9D9D9',
                },
            }}
            onScroll={handleScroll}>
            {columns}
        </Box>
    );
};

CustomFixedSizeList.propTypes = {
    itemCount: PropTypes.number.isRequired,
    itemsPerColumn: PropTypes.number.isRequired,
    itemWidth: PropTypes.number.isRequired,
    renderItem: PropTypes.func.isRequired,
};

export default CustomFixedSizeList;
