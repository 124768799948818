export const REGEX = {
    excludeCaracteresEspeciales: {
        message: "No puede contener caracteres especiales.",
        validator: "^[A-Za-z0-9ñÑ ()]+$",
    },
    telefono: {
        message: "El formato del teléfono es incorrecto.",
        validator: "^[6-9][0-9]{8}$",
    },
    codigo_postal: {
        message: "El formato del código postal es incorrecto.",
        validator: "^[0-9]{5}$",
    }
}