import React from 'react'
import { Grid } from "@mui/material"
import { Datagrid } from 'components/data_display'
import Statics from 'objects/common/Statics'


// TODO AÑADIR ISLOADING
const columns = [
    { field: "anio", headerName: "Año", maxWidth: 100, flex: 1, valueGetter: (params) => { { return params?.row?.curso_escolar?.valor ?? 'No definido' } } },
    { field: "Grupo", headerName: "Grupo", maxWidth: 120, flex: 1, valueGetter: (params) => { { return params?.row?.grupo?.clave ?? 'No definido' } } },
    { field: "fecha_baja", headerName: "F. Baja", flex: 1, maxWidth: 100, valueGetter: (params) => { { return Statics.formatDate(params?.row?.fecha_baja) ?? 'No definido' } } },
    { field: "libro", headerName: "Libro", maxWidth: 145, valueGetter: (params) => { { return params?.row?.libro?.valor ?? 'No definido' } } },
    {
        field: "profesor", headerName: "Profesor", flex: 1, maxWidth: 145, valueGetter: (params) => {
            return params?.row?.profesor_actual?.empleado?.nombre + ' ' +
                params?.row?.profesor_actual?.empleado?.apellido1 + ' ' + params?.row?.profesor_actual?.empleado?.apellido2 ?? 'No definido'
        }
    },
    { field: "nota", headerName: "Nota", maxWidth: 100, valueGetter: (params) => { { return params?.row?.curso_actual?.nota_final ?? 'No definido' } } },
    { field: "colegio", headerName: "Colegio", maxWidth: 160, flex: 1, valueGetter: (params) => { { return params?.row?.colegio?.nombre ?? 'No definido' } } },
    { field: "libroColegio", headerName: "Libro en Colegio", flex: 1, maxWidth: 200, valueGetter: (params) => { { return params?.row?.libro ?? 'No definido' } } },
    { field: "notaColegio", headerName: "Nota en Colegio", flex: 1, maxWidth: 120, valueGetter: (params) => { { return params?.row?.alumno?.curso_actual?.nota_final ?? 'No definido' } } },
    {
        field: "comentarioProfesorAP", headerName: "Comentario Profesor A/P", flex: 1,
        maxWidth: 250, valueGetter: (params) => { { return params?.row?.comentarios_profesor ?? 'No definido' } }
    },
]

export default function InscripcionesAcademiaTemplate({ historico = [] }) {
    return (
        <Grid container p={3}>
            <Grid item xs={12}>
                <Datagrid
                    rows={historico}
                    columns={columns} />
            </Grid>
        </Grid>
    )
}
