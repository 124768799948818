import { Text } from 'components/texts'
import React, { useEffect } from 'react'
import Statics from 'objects/common/Statics'
import { RequestGrupoAcademia } from 'objects/requests'
import { Select } from 'components/forms/components'
import { SearchButtons } from 'components/forms/display/components'
import { ProfesorTemplate, ActividadTemplate } from './parts'

//VALIDACION FORMULARIO
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import validationSchema from "./validation"
import { yupResolver } from '@hookform/resolvers/yup'
import { v4 as uuid } from 'uuid';


//SEARCH 
import { PROFESORES_VALUE, ACTIVIDADES_VALUE, itemsSearch, itemsOrder } from './constants.js'


let prepareParamsGlobal = null


//Formulario de busqueda de aulas
export default function SearchFormGrupos({ onChangeSearchIndex, onLoadingCallback, searchCallback }) {

    //Form
    const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) })

    //State
    const [resetFormState, setResetFormState] = React.useState(false)
    //Form

    //State
    const [searchIndex, setSearchIndex] = React.useState(ACTIVIDADES_VALUE);


    //-------------------------------------------------------
    //---------------------- FUNCTIONS ----------------------
    //-------------------------------------------------------
    /**
  * Controla cuando se hace un cambio en el select de busqueda
  * 
  * @param {Object} event
  */
    const handleChange = (event) => {
        let index = event.target.value

        setSearchIndex(index)
        setResetFormState(true)

        if (onChangeSearchIndex)
            onChangeSearchIndex(index)
    };


    useEffect(() => {
        if (resetFormState === true) {
            resetForm(reset)
            setResetFormState(false)
        }
    }, [resetFormState, reset])

    /**
     * Prepara los parametros que se van a usar
     * 
     * @param {Int} page
     */
    const prepareParams = (page) => {
        const params = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()))
        params.appends = ['curso_escolar', 'registro_actividad', 'nivel_grupo', 'horarios']
        params.page = page

        return params
    }

    prepareParamsGlobal = prepareParams



    return (
        <Grid container p={2}>
            <Grid container>
                <Grid item xs={8} >
                    <Text fontWeight={'bold'} marginBottom={6}>Buscar por:</Text><br />
                    <Select width={"60%"} items={itemsSearch} defaultValue={ACTIVIDADES_VALUE} onChange={handleChange} />
                </Grid>

                <Grid item xs={4} >
                    <Text fontWeight={'bold'} marginBottom={6}>Ordenar resultados por:</Text><br />
                    <Select key={uuid()} width={"60%"} name={"order"} items={itemsOrder[searchIndex]} defaultValue={itemsOrder[searchIndex][0].value} register={register} errors={errors} />
                </Grid>
            </Grid>

            <Grid container className={searchIndex === PROFESORES_VALUE ? "" : "d-none"}>
                <ProfesorTemplate reset={resetFormState} register={register} errors={errors} />
            </Grid>

            <Grid container className={searchIndex === ACTIVIDADES_VALUE ? "" : "d-none"}>
                <ActividadTemplate reset={resetFormState} register={register} errors={errors} />
            </Grid>

            <SearchButtons searchText={"Buscar grupos"} onResetClick={() => setResetFormState(true)} onSubmitClick={handleSubmit(() => search(1, onLoadingCallback, searchCallback))} />

        </Grid>
    )
}



/**
 * Hace la busqueda de aulas
 * 
 * @param {Int}      page
 * @param {Function} onLoadingCallback
 * @param {Function} searchCallback
 * @param {Function} prepareParams
 */
export const search = (page = 1, onLoadingCallback, searchCallback) => {
    //Callback para controlar cuando esta cargando
    if (onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal(page)

    new RequestGrupoAcademia().search(params, (res) => {

        if (searchCallback)
            searchCallback(res.data, res.total, page, params)
    }, () => {
        searchCallback([], 0, page, params)
    })
}


/**
 * Resetea todo el formulario
 * 
 * @param {Function} reset
 */
const resetForm = (reset) => {
    reset({
        apellido1: "",
        apellido2: "",
        nombre: "",
        id_curso_escolar: "",
        clave: "",
        id_modalidad_cursos: "",
        id_registro_actividad: "",
        id_tipo_actividad: ""
    })
}