import React, { Component } from 'react'
import { MenuItem, MenuItemGroup } from 'components/navigation'
import {
    CastForEducation   as TutoriaIcon,
    Warning            as IncidenciaIcon,
    FamilyRestroom     as ResponsableIcon,
    School             as DatosColegioIcon,
    AttachMoneyRounded as MoneyIcon,
    HistoryEdu         as HistoryIcon,
} from '@mui/icons-material'

export default class AlumnoMenu extends Component {

    /**
     * ComponentDidMount
     * 
     * Inicializamos los componentes necesarios del tema
     */
    componentDidMount() {
        window.KTMenu?.createInstances()
        window.KTToggle?.createInstances()
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <div className="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
                {/* <MenuItemGroup title="Principal">
                </MenuItemGroup> */}
                <div className="menu-item pt-5">
                    <div className="menu-content">
                        <span className="menu-heading fw-bold text-uppercase fs-7">Menú alumnos</span>
                    </div>
                </div>

                <MenuItem title="Tutores" to="./alumnos/tutores" icon={<ResponsableIcon />} />
                <MenuItem title="Datos del colegio" to="./alumnos/datos-colegio" icon={<DatosColegioIcon />} />
                <MenuItem title="Histórico de los alumnos" to="./alumnos/historico" icon={<HistoryIcon />} />
                <MenuItem title="Tutorías" to="./alumnos/tutorias" icon={<TutoriaIcon />} />
                <MenuItem title="Incidencias" to="./alumnos/incidencias" icon={<IncidenciaIcon />} />
                <MenuItem title="Alumnos Pagos" to="./alumnos/pagos-alumnos" icon={<MoneyIcon />} />
            </div>
        )
    }
}
