import RequestMaster from '../RequestMaster'

export default class RequestVentaLibro extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/venta-libro'
    }

    
    /**
     * Crea una venta de un libro
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    create(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Hace el edit de una venta de un libro
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
    */
    edit(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "" + id
        this.put(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Hace el delete de una venta de un libro
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
    */
    erase(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "" + id
        this.delete(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Hace la peticion para generar el envío de los libros
     * 
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    generarPedidos(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        this.post("generarPedidos", params, callback, callbackError, callbackFinally)
    }
}
