import { Component } from 'react'
import { RequestSeguimientoTrimestralGrupoColegio } from 'objects/requests'

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests extends Component {

    /**
     * Hace la petición para crear un evento
     * 
     * @param {Array}    values 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createSeguimientoTrimestral(inscripcion_id, getValues, callbackOk = () => {}, callbackError = () => {}) {
        const formData = new FormData()
        formData.append("inscripcion_id",       inscripcion_id)
        formData.append("comportamiento",       getValues('comportamiento').toUpperCase())
        formData.append("expresion_oral",       getValues('expresion_oral').toUpperCase())
        formData.append("compresion",           getValues('compresion').toUpperCase())
        formData.append("expresion_escrita",    getValues('expresion_escrita').toUpperCase())
        formData.append("nota_media",           getValues('nota_media').toUpperCase())
        formData.append("evolucion_simulacros", getValues('evolucion_simulacros').toUpperCase())
        formData.append("materia_repasar",      getValues('materia_repasar').toUpperCase())
        formData.append("comentarios",          getValues('comentarios').toUpperCase())

        new RequestSeguimientoTrimestralGrupoColegio().create(formData, callbackOk, callbackError)
    }
}
