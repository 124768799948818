import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { SnackbarProvider } from 'notistack'
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Paper, Grid } from '@mui/material'
import { BreadcrumbsList, Line } from 'components/data_display'
import { Header, DetallePagoGrid, DetalleActividadGrid } from './parts'
import { SearchFormPagosColegio, LiquidacionFormPagosColegio } from 'components/forms/display'
import { validationSchema as SearchSchema } from 'components/forms/display/colegio/pagos/SearchForm/validation'

export default function SinglePagosColegio() {
    const searchForm = useForm({ resolver: yupResolver(SearchSchema) })
    const saveForm   = useForm()

    const [pago,          setPago]          = useState(null)
    const [detallePagoId, setDetallePagoId] = useState(null)

    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                <BreadcrumbsList breadcrumbsList={[
                    { to: "/",     label: "Inicio" },
                    { to: "./../", label: "Colegios" },
                    { to: "./",    label: "Pagos de los colegios", actualItem: true },
                ]} />

                <Paper elevation={2} sx={{mx: 8, my: 2, pb: 2}}>
                    <Header handleSubmit={searchForm.handleSubmit} getValues={searchForm.getValues} getValues2={saveForm.getValues} onPagoFetched={setPago}/>

                    <SearchFormPagosColegio register={searchForm.register} errors={searchForm.errors} />

                    <Line />

                    <Grid container spacing={2} px={4} my={2}>
                        <Grid item xs={7}>
                            <DetallePagoGrid pago={pago} setPago={setPago} handleSubmit={saveForm.handleSubmit} getValues={saveForm.getValues} onRowClick={({id}) => setDetallePagoId(id)}/>
                        </Grid>

                        <Grid item xs={5} mt={1}>
                            <DetalleActividadGrid detallePagoId={detallePagoId} pago={pago}/>
                        </Grid>
                    </Grid>

                    <Line className="mt-10"/>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <LiquidacionFormPagosColegio key={pago?.id ?? 0} register={saveForm.register} errors={saveForm.errors} control={saveForm.control} pago={pago} setValue={saveForm.setValue}/>
                        </Grid>
                    </Grid>
                </Paper>
            </form>

            <SnackbarProvider autoHideDuration={3000}/>
        </Container>
    )
}