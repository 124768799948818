import { v4 as uuid } from 'uuid'
import React, { Component } from 'react'
import { H3, Text } from 'components/texts'
import { Line } from 'components/data_display'
import { Button } from 'components/forms/components'
import { Alert, CircularProgress, Grid, Box, Stack } from '@mui/material'
import SearchFormGrupoColegio, { search } from 'components/forms/display/colegio/grupos/SearchForm'

/**
 * Componente que nos muestra un listado con el listado de los alumnos para poder elegir uno
 */
export default class GruposColegioListSelector extends Component {

    constructor(props) {
        super(props)

        this.state = {
            items: [],
            searched: false,
            actualPage: 1,
            totalResults: 0,
            loading: false,
        }
    }


    /**
     * Callback para cuando se completa la búsqueda
     * 
     * @param {Array} items
     */
    searchCallback = (items, totalResults) => {
        this.setState({ items, searched: true, actualPage: this.state.actualPage + 1, totalResults, loading: false }, () => {
            this.scrollable.scrollTo(0, 0)
        })
    }


    /**
     * Callback para cuando se elige un item de la lista
     * 
     * @param {Object} item
     */
    onSelectedCallback = (item) => {
        if (this.props.onSelectedCallback)
            this.props.onSelectedCallback(item)
    }


    /**
     * Se encarga de controlar el scroll
     * 
     * @param {Event} e 
     */
    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 5
        if (bottom && this.state.totalResults != this.state.items.length && !this.state.loading) {
            this.setState({ loading: true }, () => {
                search(this.state.actualPage, () => { }, (items) => {
                    this.setState({ items: this.state.items.concat(items), loading: false, actualPage: this.state.actualPage + 1 })
                })
            })

        } else if (bottom && this.state.totalResults == this.state.items.length) {
            this.setState({ actualPage: 1 })
        }
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Grid container pb={10} pt={5} px={8}>
                <SearchFormGrupoColegio
                    searchCallback    = {this.searchCallback}
                    relations         = {this.props.relations ?? null}
                    onLoadingCallback = {() => { this.setState({ loading: true }) }}
                    showIdField />

                <Grid container p={2} display={"flex"} flexDirection={"column"}>
                    <H3>Resultado de la búsqueda: </H3>
                    <div ref={(ref) => this.scrollable = ref} className={"mh-300 overflow-y"} onScroll={this.handleScroll}>
                        {this.state.items.length == 0 ? <Alert severity="info"><Text>{!this.state.searched ? "Inserta los datos del grupo para buscarlo." : "No se ha encontrado ningún grupo."}</Text></Alert> : null}

                        {this.state.items.map(item => <GrupoItem key={uuid()} item={item} onSelectedCallback={this.onSelectedCallback} />)}

                        {this.state.loading ?
                            <Box my={2} display={"flex"} justifyContent={"center"}>
                                <CircularProgress color="standard" className='' />
                            </Box> : null
                        }
                    </div>
                </Grid>
            </Grid>
        )
    }
}

function GrupoItem ({ item, onSelectedCallback }){
    return (
        <Grid item xs={12}>
            <Box display={"flex"} justifyContent={"space-between"} my={2}>
                <Stack>
                    <Text fontWeight={"bold"}>{item.id + " - " + item.clave}</Text>
                    <Text>{"Actividad: " + (item.actividad?.nombre ?? "")}</Text>
                    <Text>{"Colegio: " + (item.colegio?.nombre ?? "")}</Text>
                </Stack>

                <Button
                    sx      = {{ mx: 2, alignSelf: "center" }}
                    variant = {"outlined"}
                    onClick = {() => { onSelectedCallback(item) }}>

                    Seleccionar grupo
                </Button>
            </Box>

            <Line />
        </Grid>
            
    )
}