import React from 'react'
import { H3 } from 'components/texts';
import { Grid, Stack } from '@mui/material';
import { Line } from 'components/data_display';
import { Input, Button } from 'components/forms/components';
import SkeletonProgramacionesData from './skeletonProgramacionesData'

/**
 * Formulario con los datos de las programaciones de un grupo
 */
export default function ProgramacionesData({ grupo }) {
    return (
        <Grid container px={5} pb={5}>
            {!grupo ?
                <SkeletonProgramacionesData />
                : (
                    <>
                        <Grid container spacing={2} my={2}>
                            <Grid item xs={8}>
                                <Stack>
                                    <H3>1º Trimestre</H3>
                                    <Input className="w-100" defaultValue={grupo?.programacion_1t_enviada ?? ''} disabled />
                                </Stack>
                            </Grid>

                            <Grid item xs={4} display={"flex"} alignItems={"end"}>
                                <Stack direction={"row"} spacing={2} className={"w-100"}>
                                    <Button className={"w-100 py-3"}>
                                        Enviar programación
                                    </Button>
                                    <Button className={"w-100 py-3"} color={"cancel"}>
                                        Ver programación
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} mt={2}>
                            <Line />
                        </Grid>

                        <Grid container spacing={2} my={2}>
                            <Grid item xs={8}>
                                <Stack>
                                    <H3>2º Trimestre</H3>
                                    <Input className="w-100" defaultValue={grupo?.programacion_2t_enviada ?? ''} disabled />
                                </Stack>
                            </Grid>

                            <Grid item xs={4} display={"flex"} alignItems={"end"}>
                                <Stack direction={"row"} spacing={2} className={"w-100"}>
                                    <Button className={"w-100 py-3"}>
                                        Enviar programación
                                    </Button>
                                    <Button className={"w-100 py-3"} color={"cancel"}>
                                        Ver programación
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} mt={2}>
                            <Line />
                        </Grid>

                        <Grid container spacing={2} my={2}>
                            <Grid item xs={8}>
                                <Stack>
                                    <H3>3º Trimestre</H3>
                                    <Input className="w-100" defaultValue={grupo?.programacion_3t_enviada ?? ''} disabled />
                                </Stack>
                            </Grid>

                            <Grid item xs={4} display={"flex"} alignItems={"end"}>
                                <Stack direction={"row"} spacing={2} className={"w-100"}>
                                    <Button className={"w-100 py-3"}>
                                        Enviar programación
                                    </Button>
                                    <Button className={"w-100 py-3"} color={"cancel"}>
                                        Ver programación
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </>
                )}
        </Grid>
    )
}
