import { Text } from 'components/texts'
import { useForm } from 'react-hook-form'
import { enqueueSnackbar } from 'notistack'
import Modal from 'components/modals/Modal.jsx'
import { Button } from 'components/forms/components'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useRef, useState } from "react"
import { DataFormIncidenciaFichaje } from 'components/forms/display'
import validationSchema from 'components/forms/display/empleado/fichaje/incidencia/DataForm/validation'

export default function IncidenciaModal({ }) {
    const [open, setOpen] = useState(false)

    const modalRef = useRef(null)

	const { register, handleSubmit, formState: { errors }, setValue } = useForm({ resolver: yupResolver(validationSchema) })

    useEffect(() => {
        if(open) {
            modalRef.current.open()
            setOpen(false)
        }
    }, [open])

    const onSuccess = (_) => {
        enqueueSnackbar(<Text>Se ha creado la incidencia correctamente.</Text>, {variant: "success"})
        modalRef.current.close()
    }

    return (
        <React.Fragment>
            <Button onClick={() => setOpen(true)}>
                Crear incidencia
            </Button>

            <Modal ref={modalRef} title={"Enviar incidencia de fichaje"} style={{minWidth: "50%"}}>
                <DataFormIncidenciaFichaje 
                    register          = {register} 
                    errors            = {errors} 
                    setValue          = {setValue} 
                    handleSubmit      = {handleSubmit}
                    onSuccessCallback = {onSuccess}
                    onErrorCallback   = {() => enqueueSnackbar(<Text>Ha habido un error al crear la incidencia.</Text>, {variant: "error"})} />
            </Modal>
        </React.Fragment>
    )
}