import React from 'react'
import { Grid } from '@mui/material'
import { ItemsSelect, MesSelect } from 'components/forms/components/Select/custom'
import { Input } from "components/forms/components";


export default function ExaOficialesTemplate({ register, errors, reset }) {

    return (
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={4}>

            <Grid item xs={3} border={'1px solid red'}>
                <ItemsSelect name={"curso_escolar"} keyValue={"curso_escolar"} register={register} errors={errors} wantToReset={reset} />
            </Grid>

            <Grid item xs={3} border={'1px solid black'}>
                <ItemsSelect name={"provincia"} keyValue={"provincia"} register={register} errors={errors} />
            </Grid>

            <Grid item xs={3} border={'1px solid red'}>
                <MesSelect register={register} errors={errors} />
            </Grid>

            <Grid item xs={3} border={'1px solid black'}>
                formato
            </Grid>
            <Grid container item mt={2}>
                <Grid item xs={6} border={'1px solid red'}>
                    <Input
                        type="text"
                        label={'Municipio'}
                        width={"100%"}
                        errors={errors}
                        name={"municipio"}
                        register={register} />
                </Grid>

                <Grid item xs={3}>
                    <ItemsSelect name={"id_examenes"} keyValue={"examenes"} register={register} errors={errors} wantToReset={reset} />
                </Grid>

                <Grid item xs={3} >
                    itemselect convocatoria
                </Grid>
            </Grid>
        </Grid>
    )
}
