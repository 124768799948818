import { COLOR } from 'theme/Colors'
import { keyValues } from './constants'
import { Box, Stack } from '@mui/material'
import { H2, Text } from 'components/texts'
import AlertModal from './parts/AlertModal'
import { Link } from 'components/data_display'
import React, { useRef, useState } from 'react'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { Button, LoadingButton } from 'components/forms/components'
import { Add as AddIcon, FileDownload as FileDownloadIcon } from '@mui/icons-material'

export default function ListTableHeader({ rowCount, keyValue, params, title, addText, hideAddButton = false, extraButtons = null}){

    const modalRef = useRef()

    const [loading, setLoading] = useState(false)

    const exportExcel = () => {
        setLoading(true)

        const { request, fileName } = keyValues[keyValue]
        new request().excel(params, fileName, () => {}, (err = "Error al exportar el excel") => {
            enqueueSnackbar(<Text>{err}</Text>, {variant: "error"})
        }, () => {
            setLoading(false)
        })
    }

    return (
        <Box display={"flex"} justifyContent={"space-between"} mb={5}>
            <H2>{title ?? keyValues[keyValue]?.title ?? "Listado"}</H2>

            <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} spacing={"2"}>
                {extraButtons}

                <LoadingButton className="me-5" startIcon={<FileDownloadIcon style={{ color: COLOR.white.main }} />} disabled={rowCount == 0 || loading} loading={loading} onClick={rowCount >= 1000 ? () => {modalRef.current.modal.open()} : exportExcel}>
                    Exportar en excel
                </LoadingButton>

                {hideAddButton ? null :
                    <Link to={"./crear"}>
                        <Button color={'success'} startIcon={<AddIcon style={{ color: COLOR.white.main }} />}>
                        {addText ?? keyValues[keyValue]?.addText ?? "Añadir"}
                        </Button>
                    </Link>
                }
            </Stack>

            <AlertModal ref={modalRef} rowCount={rowCount} onGoBack={() => { modalRef.current.modal.close() }} onContinue={() => {
                modalRef.current.modal.close()
                exportExcel()
            }} />

            <SnackbarProvider autoHideDuration={3000}/>
        </Box>

    )
}
