import React, { useEffect } from 'react'
import { Grid } from "@mui/material";
import { Text } from "components/texts";
import { useForm } from "react-hook-form";
import { itemsSearch } from './searchAndOrderOptions'
import { Select } from 'components/forms/components'
import { SearchButtons } from 'components/forms/display/components';
import Statics from 'objects/common/Statics';
import validationSchema from "./validation"
import { yupResolver } from '@hookform/resolvers/yup';
import { RequestClasesParticulares } from 'objects/requests'

let prepareParamsGlobal = null

export default function SearchFormClasesParticulares({ searchCallback, onChangeSearchIndex, onLoadingCallback }) {
    //Form
    const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) })

    //State
    const [resetFormState,  setResetFormState]  = React.useState(false);
    const [searchIndex,     setSearchIndex]     = React.useState(0)
    const [itemsOrder,      setItemsOrder]      = React.useState(itemsSearch[searchIndex].itemsOrder)

    useEffect(() => {
        if (resetFormState == true) {
            resetForm(reset)
            setResetFormState(false)
        }
    })

    //-------------------------------------------------------
    //---------------------- FUNCTIONS ----------------------
    //-------------------------------------------------------

    /**
     * Controlla cuando se hace un cambio en el select de busqueda
     * 
     * @param {Object} event
     */
    const handleChange = (event) => {
        const index = event.target.value

        setSearchIndex(index)
        setResetFormState(true)
        setItemsOrder(itemsSearch[index].itemsOrder)

        if (onChangeSearchIndex)
            onChangeSearchIndex(index, itemsSearch[index].columnKey)
    }

    /**
     * Prepara los parámetros que se van a usar
     * 
     * @param {Int} page
     */
    const prepareParams = (page) => {
        const params = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()))
        params.relations = 'venta_libro,venta_libro_asociado,horarios,empresa,empleado,registro_actividad.actividad,alumno,lugar_clase,profesor,forma_pago,forma_conocimiento,forma_recogida,libro,origen_profesor,sexo,pagos,curso_escolar'
        params.page = page

        return params
    }

    prepareParamsGlobal = prepareParams

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    const ActualTemplate = itemsSearch[searchIndex].template

    return (
        <Grid container p={2}>
            <Grid container>
                <Grid item xs={8} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Buscar por:</Text><br />
                    <Select width={"60%"} items={itemsSearch} defaultValue={itemsSearch[0].value} onChange={handleChange} />
                </Grid>

                <Grid item xs={4} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Ordenar resultados por:</Text><br />
                    <Select key={itemsOrder[0].value} width={"60%"} name={"order"} items={itemsOrder} defaultValue={itemsOrder[0].value} register={register} errors={errors} />
                </Grid>
            </Grid>

            <ActualTemplate reset={resetFormState} register={register} errors={errors} />

            <SearchButtons searchText={"Buscar clases particulares"} onResetClick={() => { setResetFormState(true) }} onSubmitClick={handleSubmit(() => { search(1, onLoadingCallback, searchCallback) })} />
        </Grid>
    )
}

/**
 * Hace la búsqueda de clases particulares
 * 
 * @param {Int}      page
 * @param {Function} onLoadingCallback
 * @param {Function} searchCallback
 * @param {Function} prepareParams
 */
export const search = (page = 1, onLoadingCallback, searchCallback) => {
    //Callback para controlar cuando esta cargando
    if (onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal(page)
    new RequestClasesParticulares().search(params, (res) => {
        if (searchCallback)
            searchCallback(res.items.data, res.items.total, page, params)
    }, () => {
        searchCallback([], 0, page, params)
    })
}

/**
 * Reset de todo el formulario
 * 
 * @param {Function} reset
 */
const resetForm = (reset) => {
    reset({
        nombre: "",
        apellido1: "",
        apellido2: "",
    })
}