import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import React, { Component } from 'react'
import { v4 as uuid } from 'uuid';
import { COLOR } from 'theme/Colors';

/**
 * SelectCustom. Select de Mui personalizado
 * 
 * @params {Array} items = [{value: "x", label: "X"}]
 */
export default class SelectCustom extends Component {

    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)

        this.state = {
            value: this.props.defaultValue ?? (this.props.multiple ? [] : ""),
            previousValue: this.props.defaultValue ?? (this.props.multiple ? [] : ""),
            defaultValueLoaded: false
        }

    }


    componentDidUpdate(prevProps, prevState) {
        if(prevProps.items != this.props.items && this.props.items.length > 0 && this.props.items[0].value != 0 && this.props.defaultValue && !this.state.defaultValueLoaded)
            this.setState({defaultValueLoaded: true}, () => {
                if(this.props.onDefaultValueLoaded)
                    this.props.onDefaultValueLoaded({target: {value: this.props.defaultValue}})
            })

        if(this.props.wantToReset && !prevProps.wantToReset)
            this.reset()
    }

    //-------------------------------------------------------
    //-------------------- CLASS FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Callback para cuando se cambia de opcion en el selector
     * 
     * @param {Object} event
     */
    onChange = (event) => {
        this.setState({ previousValue: this.state.value, value: event.target.value }, () => {
            if (this.props.onChange)
                this.props.onChange(event)
        })
    }

    /**
     * Pone la opción anterior que tenia el select
     */
    goToPreviousOption = () => {
        this.setState({value: this.state.previousValue})
    }

    /**
     * Devuelve un objeto de la lista a partir del value
     * 
     * @param {Int} value
     */
    getItemByValue = (value) => {
        return this.props.items.find(item => item.value == value)
    }


    /**
     * Resetea el valor del select
     */
    reset = () => {
        this.setState({ previousValue: (this.props.multiple ? [] : ""), value: (this.props.multiple ? [] : "") })
    }


    /**
     * Devuelve el valor elegido actualmente
     * 
     * @return {Int}
     */
    getValue = () => {
        return this.state.value
    }


    /**
     * Actualiza el valor del estado
     * 
     * @param {Int} value 
     */
    setValue = (value, callback = () => {}) => {
        this.setState({previousValue: this.state.value , value}, callback)
    }

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     * 
     * @returns {Component}
     */

    render() {
        const labelId = this.props.labelId ?? (this.props.label ? this.props.label + "-id" : uuid() + "-id")
        return (
            <FormControl variant={this.props.variant} sx={{ m: 1, width: this.props.width ?? "100%", margin: 0 }} size={this.props.size ?? "small"} className={this.props.className}>
                <InputLabel id={labelId}>{this.props.label ?? ""}</InputLabel>
                <Select
                    ref         = {(ref) => this.select = ref}
                    labelId     = {labelId}
                    label       = {this.props.label ?? ""}
                    className   = 'custom-select'
                    disabled    = {this.props.disabled}
                    multiple    = {this.props.multiple ?? false}
                    value       = {this.state.value ?? ""}
                    onChange    = {this.onChange}
                    name        = {this.props.name}
                    inputProps  = {{ ... this.props.register ? this.props.register(this.props.name) : {} }}
                    error       = {this.props.errors && this.props.errors[this.props.name] ? true : false}>

                    {this.props.items?.map(item => <MenuItem key={uuid()} value={item.value}>{item.label ?? ""}</MenuItem>)}
                </Select>
                {this.props.errors && this.props.errors[this.props.name]?.message ? <FormHelperText style={{ color: COLOR.error.main }}>{this.props.errors[this.props.name]?.message}</FormHelperText> : null}
            </FormControl>
        )
    }
}
