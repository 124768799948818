import { useForm } from 'react-hook-form'
import Statics from 'objects/common/Statics'
import Requests from './Requests/Requests.js'
import { useNavigate } from "react-router-dom"
import { Text } from 'components/texts/index.js'
import { Container, Paper } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangesContext } from 'contexts/ChangesContext'
import { CreateHeader } from 'pages/logged/admin/components'
import React, { useRef, useContext, useEffect } from 'react'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { BreadcrumbsList, Line } from 'components/data_display'
import { validationSchema } from 'components/forms/display/alumno/DataForm/validation.js'
import { DataFormAlumno, DataDomiciliacionBancariaFormAlumno } from 'components/forms/display'

export default function CreateAlumnos() {
  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues } = useForm({ resolver: yupResolver(validationSchema) })
  const navigate = useNavigate()

  const headerRef = useRef()

  const { updateChanges } = useContext(ChangesContext)

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])

  const createAlumno = () => {
    headerRef.current.button.setLoading(true)

    new Requests().createAlumno(getValues, (res) => {
      createDatosBancarios(res.alumno.id)
    }, (res) => {
      headerRef.current.button.setLoading(false)
      enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
    })
  }

  const createDatosBancarios = (id) => {
    new Requests().createDatosBancarios(id, getValues, async () => {
      headerRef.current.button.setLoading(false)
      enqueueSnackbar(<Text>Se ha creado el alumno. Redirigiendo a la pagina de edición...</Text>, { variant: "success" })
      updateChanges(false)

      await Statics.delay(1500).then(() => {navigate('./../' + id + "/editar")})
    }, (res) => {
      headerRef.current.button.setLoading(false)
      enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
    })
  }
  
  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <BreadcrumbsList breadcrumbsList={[
          { to: "/",     label: "Inicio" },
          { to: "./../", label: "Alumnos" },
          { to: "./",    label: "Crear alumno", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{mx: 8, my: 2, pb: 5}}>
          <CreateHeader 
            ref          = {headerRef} 
            title        = {"Crear alumno"}
            handleSubmit = {handleSubmit(createAlumno)} />

          <Line className={"my-5"} />

          <DataFormAlumno register={register} errors={errors} />
        </Paper>

        <Paper elevation={2} sx={{mx: 8, my: 2, p: 5}}>
          <DataDomiciliacionBancariaFormAlumno register={register} errors={errors} />
        </Paper>

        <SnackbarProvider autoHideDuration={3000}/>
      </form>
    </Container>
  )
}