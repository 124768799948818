import React, { Component } from 'react'
import { TextField } from '@mui/material'

const notAvailableNumericChars = ["e", "E", "+", "-", '.']

/**
 * Custom Input
 */
export default class Input extends Component {

	constructor(props) {
		super(props)
		this.state = {
			value: this.props.defaultValue ?? "",
		}
	}

	//-------------------------------------------------------
	//------------------- CLASS FUNCTIONS -------------------
	//-------------------------------------------------------

	/**
	 * Actualiza el estado del value
	 * 
	 * @param {Object} evt 
	 */
	onInput = (evt) => {
		if(this.props.type && this.props.type == "number" && notAvailableNumericChars.includes(evt.nativeEvent.data)) {
			evt.preventDefault()
			return
		}

		if (this.props.onInput)
			this.props.onInput(evt)

		if(this.props.didInputChange)
			this.props.didInputChange(true)

		if(this.props.useValue)
			this.setState({value: evt.target.value})
	}


	/**
	 * OnKeyDown
	 * 
	 * @param {Object} evt 
	 */
	onKeyDown = (evt) => {
		if(this.props.type && this.props.type == "number" && notAvailableNumericChars.includes(evt.key)) {
			evt.preventDefault()
			return
		}
	}


	/**
	 * Actualiza el value
	 * 
	 * @param {String} value 
	 */
	setValue = (value) => {
		this.setState({value})
	}


	/**
	 * Actualiza el value
	 * 
	 * @return {String}  
	 */
	getValue = () => {
		return this.state.value
	}

	//-------------------------------------------------------
	//------------------- RENDER FUNCTIONS ------------------
	//-------------------------------------------------------

	/**
	 * Render
	 * 
	 * @returns {Component}
	 */
	render() {
		const inputProps = { 
			style: { textTransform: this.props.uppercase ? "uppercase" : "none" },
			...this.props.inputProps
		}

		const style = {
			maxWidth: this.props.maxWidth ?? null, 
			width: this.props.width ?? null,
			...this.props.style
		}

		const value = this.props.useValue ? {value: this.props.value ?? this.state.value} : {defaultValue: this.props.defaultValue}
		return (
			<TextField
				inputRef		= {(ref) => this.input = ref}
				label		 	= {this.props.label ?? ""}
				variant		 	= {this.props.variant ?? "outlined"}
				onInput		 	= {(evt) => { this.onInput(evt) }}
				onKeyDown		= {this.onKeyDown}
				size		 	= {this.props.size ?? "small"}
				type		 	= {this.props.type ?? ""}
				style		 	= {style}
				className	 	= {this.props.className ?? ""}
				inputProps	 	= {inputProps}
				error		 	= {this.props.errors && this.props.errors[this.props.name] ? true : false}
				helperText	 	= {this.props.errors && this.props.errors[this.props.name]?.message}
				multiline	 	= {this.props.multiline ?? false}
				minRows		 	= {this.props.minRows}
				maxRows		 	= {this.props.maxRows}
				placeholder		= {this.props.placeholder}
				InputLabelProps = {this.props.InputLabelProps}
				disabled		= {this.props.disabled}
				{...value}
				{... this.props.register ? this.props.register(this.props.name) : {}} />
		)
	}
}