import React, { useState } from "react";
import { Grid, Box, useMediaQuery, useTheme, Tab, Tabs, FormControlLabel, Checkbox } from "@mui/material";
import { Input, DatePicker } from 'components/forms/components';
import { COLOR } from 'theme/Colors.js'
import { useForm } from 'react-hook-form';
import { H3, Text } from 'components/texts/index.js'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { TabContext } from '@mui/lab'
import ActividadesCurriculum from "./parts/ActividadesCurriculum";
import { DataCurriculumTable } from 'components/data_display';



/**
 * Formulario con los datos del currículum del candidato
 * 
 */
export default function DataCurriculumCandidato({ onChange }) {
    const labels = [
        { label: "Vehículo", isSelected: false },
        { label: "Nativo", isSelected: false },
        { label: "Habla Español", isSelected: false },
    ];

    const actividades = [
        { label: "Idiomas", isSelected: false },
        { label: "Deporte", isSelected: false },
        { label: "Talleres", isSelected: false },
        { label: "Médico", isSelected: false },
        { label: "Socorrismo", isSelected: false },
        { label: "Primeros Auxilios", isSelected: false },

    ];

    const otros_idiomas = [
        { label: "Inglés", isSelected: false },
        { label: "Francés", isSelected: false },
        { label: "Alemán", isSelected: false },
        { label: "Italiano", isSelected: false },
        { label: "Árabe", isSelected: false },
    ];

    const otras_actividades = [
        { label: "Futbol", isSelected: false },
        { label: "Baloncesto", isSelected: false },
        { label: "Judo/Karate", isSelected: false },
        { label: "Voleybol", isSelected: false },
        { label: "Otras Act. Deportivas", isSelected: false },
        { label: "Baile", isSelected: false },
        { label: "Aerobic", isSelected: false },
        { label: "G. Rítimica", isSelected: false },
        { label: "Informática", isSelected: false },
        { label: "Teatro", isSelected: false },
        { label: "Manualidades", isSelected: false },
        { label: "Animación", isSelected: false },
        { label: "Refuerzo", isSelected: false },
        { label: "Otros Talleres", isSelected: false },
    ];

    const { handleSubmit, register, setValue, watch, formState: { errors } } = useForm();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));
    const isXl = useMediaQuery(theme.breakpoints.only('xl'));

    const buttonSize = isXs ? "small" : isMd ? "medium" : isXl ? "large" : "medium";
    const [tab, setTab] = useState("1")

    const idiomas_ppl = [
        { value: 0, label: "ALEMÁN" },
        { value: 1, label: "ARABE" },
        { value: 2, label: "CHINO" },
        { value: 3, label: "FRANCÉS" },
        { value: 4, label: "ITALIANO" },
    ]

    const handleButtonChange = (selectedButtons) => {
        if (onChange) {
            onChange(selectedButtons);
        }
    };

    return (
        <Grid container p={3} direction={'row'}>

            <Grid item mb={2} p={2} xs={12}>

                <Grid container spacing={2} alignItems="center">

                    <Grid item xs={10} >
                        <H3>Datos Entrega</H3>
                    </Grid>

                    <Grid item xs={2} >
                        <FormControlLabel control={<Checkbox />} label="Entrega Currículum" />
                    </Grid>

                    <Grid item xs={4} >
                        <Input className="w-80" label="Nº Curriculum" name="num_curriculum" disabled />
                    </Grid>

                    <Grid item xs={4} >
                        <DatePicker
                            name="fecha"
                            label="Fecha"
                            defaultValue={undefined}
                            clearable={true}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                        />
                    </Grid>

                    <Grid item xs={4} >
                        <Input className="w-100" label="Archivo" name="archivo" />
                    </Grid>

                </Grid>
            </Grid>

            <Grid item mt={2} xs={12}>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2} >
                        <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                            <Tab label={<Text fontWeight={tab === "1" ? "normal" : null} color={tab === "1" ? COLOR.standard.main : null}>Datos Actividades</Text>} value={"1"} />
                            <Tab label={<Text fontWeight={tab === "2" ? "normal" : null} color={tab === "2" ? COLOR.standard.main : null}>Datos Currículum y Archivo</Text>} value={"2"} />
                        </Tabs>
                    </Box>

                    <TabPanel value={"1"}>
                        <ActividadesCurriculum />
                    </TabPanel>

                    <TabPanel value={"2"}>
                        {/* TABLA PARA AÑADIR TITULACIÓN, DEBE MOSTRAS Y EDITAR  */}
                        <DataCurriculumTable />
                    </TabPanel>

                </TabContext>

            </Grid>
        </Grid>
    )
}
