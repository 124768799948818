import { ConsultaAlumnos } from './parts'
import React, { useEffect, useState } from 'react'
import { Container, Paper, Stack } from '@mui/material'
import { useForm } from 'react-hook-form';
import { BreadcrumbsList } from 'components/data_display'


export default function SingleConsultas() {
  
  const { register,  getValues, handleSubmit } = useForm({});

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

      <Stack direction="row" justifyContent={"space-between"} marginX={8}>
        <BreadcrumbsList mx={0} breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "Libros" },
          { to: "./", label: "Listados #" + ('id' ?? "0000"), actualItem: true },
        ]} />
      </Stack>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <ConsultaAlumnos
          getValues={getValues}
          register={register}
          handleSubmit={handleSubmit}
          />
      </Paper>

    </Container>
  )
}
