import React, { useRef } from 'react'
import { BreadcrumbsList, Line } from 'components/data_display'
import { Container, Paper } from '@mui/material'
import { PagosPPAA } from 'components/forms/display'
import { CreateHeader } from 'pages/logged/admin/components'
// import { Text } from 'components/texts'
// import Requests from './Requests/Requests.js'


export default function CreatePagosPPAA() {
    const headerRef = useRef()

    //-------------------------------------------------------
    //----------------------- REQUESTS ----------------------
    //-------------------------------------------------------

    // ¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡          [IMPORTANTE]         !!!!!!!!!!!!!!            
    // Tal como está hecha la aplicación actual este proceso se tiene que hacer antes
    // de actualizar los pagos de los alumnos, para que se apliquen las subvenciones. 

    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <BreadcrumbsList breadcrumbsList={[
                { to: "/", label: "Inicio" },
                { to: "./../", label: "Seguimiento de curso" },
                { to: "./", label: "Pagos PP.AA", actualItem: true },
            ]} />
            <Paper elevation={2} sx={{ mx: 8, my: 2, py: 3, px: 5, mt: 2 }}>

                <CreateHeader
                    ref={headerRef}
                    title={"Pagos PP.AA"}
                    btnText={" Realizar "}
                // handleSubmit={handleSubmit(createGrupoAcademia, (errors) => {
                //     console.error(errors);
                // })}
                />
                <Line className={"my-5"} />

                <PagosPPAA />
            </Paper>

        </Container>
    )
}