import React, { useState } from 'react'
import { Grid, Stack } from '@mui/material'
import { ItemsSelect, MesSelect } from 'components/forms/components/Select/custom'
import { H3 } from 'components/texts';
import { Input } from 'components/forms/components'

export default function PagosPPAA({ register, errors }) {

    return (
        <Grid container mt={2} px={4} spacing={2}>
            <Grid item xs={4} >
                <ItemsSelect
                    label={"Curso Escolar"}
                    name={"curso_escolar_id"}
                    register={register}
                    errors={errors}
                    keyValue={"curso_escolar"}
                    defaultValue={""}
                />
            </Grid>

            <Grid item xs={4} >
                <MesSelect register={register} errors={errors} />
            </Grid>

            <Grid item xs={4} >
                <ItemsSelect
                    name={"id_colegio"}
                    keyValue={"colegio"}
                    register={register}
                    errors={errors}
                    searcheable />
            </Grid>

            <Grid item xs={12} mb={1}>
                <Stack>
                    <H3>Subvenciones Actualizadas</H3>
                </Stack>
            </Grid>

            <Grid item xs={6} >
                <Stack>
                    <Input label={"Nº Subvenciones"} className="w-100" defaultValue={"..."} disabled />
                </Stack>
            </Grid>

            <Grid item xs={6} >
                <Stack>
                    <Input label={"Cantidad"} className="w-100" defaultValue={"..."} disabled />
                </Stack>
            </Grid>

        </Grid>
    )
}