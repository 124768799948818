import * as Yup from 'yup'
import Statics from 'objects/common/Statics'

export const validationSchema = Yup.object().shape({
    nombre:              Yup.string().required('El nombre es obligatorio'),
    id_tipo_actividad:   Yup.string().required('El tipo de actividad es obligatorio'),
    id_actividad:        Yup.string().required('La actividad es obligatoria'),
    id_modalidad_cursos: Yup.string().required('La modalidad es obligatoria'),
    id_academia:         Yup.string().required('La academia es obligatoria'),
    precio:              Yup.number().required('El precio es obligatorio').transform(Statics.validatorValidNumberWithDecimals).typeError("El precio tiene que ser un numero"),
    precio_junio:        Yup.number().required('El precio para Junio es obligatorio').transform(Statics.validatorValidNumberWithDecimals).typeError("El precio de junio tiene que ser un numero"),
    matricula:           Yup.number().required('La matrícula obligatoria').transform(Statics.validatorValidNumberWithDecimals).typeError("La matricula tiene que ser un numero"),
})