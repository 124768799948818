import * as Yup from 'yup'

export const validation = {
    alumno_id: Yup.string().required('Debe indicar el alumno'),
    curso_escolar_id: Yup.string().required('El curso escolar es obligatorio'),
    forma_recogida_id: Yup.string().required('La forma de recogida es obligatoria'),
    forma_conocimiento_id: Yup.string().required('La forma de recogida es obligatoria'),
    preferencia_horario_id: Yup.string().required('La preferencia de pago es obligatoria'),
    grupo_academia_id: Yup.string().typeError('El grupo de la academia debe ser tipo texto'),
    motivo_baja_id: Yup.string().when('fecha_baja', {
        is: (value) => !!value,
        then: Yup.string().required('Debes indicar el motivo de la baja.'),
    }),
    fecha_inscripcion: Yup.string().required('Fecha de inscripción es requerida'),
    fecha_incorporacion: Yup.string().required('Fecha de incorporación es requerida'),
    // .when('fecha_inscripcion', (fecha_inscripcion, schema) => {
    //     return fecha_inscripcion ? schema.min(fecha_inscripcion, 'La fecha de incorporación no puede ser anterior a la fecha de inscripción') : schema;
    // }),
    fecha_baja: Yup.string().required('Fecha de baja es requerida')
    // .when('fecha_incorporacion', (fecha_incorporacion, schema) => {
    //     return fecha_incorporacion ? schema.max(fecha_incorporacion, 'La fecha de baja no puede ser anterior a la fecha de incorporación') : schema;
    // }),
}

export const validationSchema = Yup.object().shape(validation)