import React, { Component } from "react";
import { DataGrid, esES, GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton, GridToolbarDensitySelector } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import { COLOR } from 'theme/Colors'

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      {/* <GridToolbarExport /> */}
    </GridToolbarContainer>
  );
}

export default class DataGridCustom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: this.props.rows ?? [],
      columns: this.props.columns ?? [{ field: "NO_COLUMNS", headerName: "Sin columnas", flex: 1, }],
      rowCount: this.props.rowCount ?? 0,
      page: 1,
      pageSize: this.props.pageSize ?? 25,
      rowsPerPageOptions: this.props.rowsPerPageOptions ?? [25]
    }
  }


  /**
   * ComponentDidUpdate
   * 
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.rows != this.props.rows)
      this.setState({ rows: this.props.rows })

    if (prevProps.columns != this.props.columns)
      this.setState({ columns: this.props.columns })

    if (prevProps.rowCount != this.props.rowCount)
      this.setState({ rowCount: this.props.rowCount })
  }

  //-------------------------------------------------------
  //-------------------- CLASS FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * OnPageChange
   * 
   * @param {Int} page
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 }, () => {
      if (this.props.onPageChange)
        this.props.onPageChange(this.state.page)
    })
  }

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    let serverSide = this.props.serverSide ? { paginationMode: "server", rowCount: this.state.rowCount, onPageChange: this.onPageChange } : {}
    return (
      <>
        {/* Para colorear el header de la tabla, se usa un Box */}
        <Box
          sx={{
            height: this.state.rows?.length > 0 ? (this.props.heightWithContent ?? 800) : (this.props.heightWithoutContent ?? 200),
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: COLOR.cancel.main,
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              color: '#FFF',
              fontWeight: 900,
            },
            '& .MuiDataGrid-menuIconButton': {
              color: '#FFF',
              fontWeight: 900,
            },
            '& .MuiDataGrid-sortIcon': {
              color: '#FFF',
              fontWeight: 900,
            },
          }}>

          <DataGrid
            rows                 = {this.state.rows}
            columns              = {this.state.columns}
            loading              = {this.props.loading}
            localeText           = {esES.components.MuiDataGrid.defaultProps.localeText}
            pageSize             = {this.state.pageSize}
            rowsPerPageOptions   = {this.state.rowsPerPageOptions}
            rowHeight            = {this.props.rowHeight ?? 60}
            getRowClassName      = {(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd' }
            experimentalFeatures = {this.props.experimentalFeatures}
            onCellEditStop       = {this.props.onCellEditStop}
            onRowEditStop        = {this.props.onRowEditStop}
            editMode             = {this.props.editMode ?? "cell"}
            onCellEditCommit     = {this.props.onCellEditCommit}
            onRowClick           = {this.props.onRowClick}
            onRowDoubleClick     = {this.props.onRowDoubleClick}
            components={{
              Toolbar: CustomToolbar,
            }}
            {...serverSide} />
        </Box>
      </>
    );
  }
}
