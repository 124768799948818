import { Grid } from '@mui/material'
import React from 'react'
import { Text } from 'components/texts'
import { Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Esta es la plantilla para cuando se busque por los datos basicos de una actividad del colegio
 */
export default function ActividadTemplate({ register, errors, reset }) {

    return (
        <>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Input label="Nombre" width={"100%"} name={"nombre"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect name={"id_actividad"} keyValue={"tipo_actividad"} register={register} errors={errors} wantToReset={reset}/>
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect name={"id_actividades"} keyValue={"actividad"} register={register} errors={errors} wantToReset={reset}/>
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect name={"id_modalidad_cursos"} keyValue={"modalidad_cursos"} register={register} errors={errors} wantToReset={reset}/>
                </Grid>

                <Grid item xs={4}>
                    <Input label="academia" width={"100%"} name={"nombre_academia"} register={register} errors={errors} uppercase />
                </Grid>
            </Grid>
        </>
    )
}
