import { Avatar } from '@mui/material'
import React, { Component } from 'react'
import '../style.css'

export default class FileImage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            image: this.props.image ?? null,
            renderImage: this.props.image && typeof this.props.image !== 'object'  ? this.props.image : null,
        }
    }


    /**
     * ComponentDidMount
     */
    componentDidMount() {
        if (this.state.image && this.state.image instanceof Blob)
            this.loadImage64()
    }


    /**
     * ComponentDidUpdate
     */
    componentDidUpdate(prevProps) {
        if (prevProps.image != this.props.image)
            this.setState({ image: this.props.image }, () => {
                if (this.state.image && this.state.image instanceof Blob)
                    this.loadImage64()
            })
    }

    /* ================================================================================================
    ============================================= CLASS FUNCTIONS =====================================
    ================================================================================================ */

    /**
     * Transforma el fichero a imagen en base64
     */
    loadImage64 = () => {
        var reader = new FileReader()
        reader.onload = () => { this.setState({ renderImage: reader.result }) }
        reader.readAsDataURL(this.state.image)
    }

    /* ================================================================================================
    ============================================ RENDER FUNCTIONS =====================================
    ================================================================================================ */

    /**
     * Render
     */
    render() {
        return (
            <Avatar src={this.state.renderImage} sx={[{ width: "100%", height: "100%", borderRadius: 1}]} imgProps={{sx: {objectFit: "contain"}}}/>
        )
    }
}
