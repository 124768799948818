import React from 'react'
import { Stack, Grid } from '@mui/material'
import { Text } from 'components/texts'
import  SkeletonActividades  from './skeletonActividades'

export default function ActividadesData({ candidato }) {
    return (
        <Grid container alignItems="center" direction={'row'} mt={4} ml={2}>
            {!candidato ?
                <SkeletonActividades />
                : (
                    <>

                        <Grid container item xs={10} >
                            <Grid item xs={4} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Actividades Principales</Text>
                                    <Text>{candidato?.actividades_principal ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid container >
                                <Grid item xs={4} mt={2}>
                                    <Stack>
                                        <Text fontWeight={"bold"}>Actividades</Text>
                                        <Text>{candidato?.actividades ?? ""}</Text>
                                    </Stack>
                                </Grid>

                                <Grid item xs={4} mt={2}>
                                    <Stack>
                                        <Text fontWeight={"bold"}>Idioma Principal</Text>
                                        <Text>{candidato?.idioma_principal ?? ""}</Text>
                                    </Stack>
                                </Grid>

                                <Grid item xs={4} mt={2}>
                                    <Stack>
                                        <Text fontWeight={"bold"}>otros Idiomas</Text>
                                        <Text>{candidato?.otras_idiomas ?? ""}</Text>
                                    </Stack>
                                </Grid>

                            </Grid>

                            <Grid container mt={4} >

                                <Grid item xs={2} mt={2}>
                                    <Stack>
                                        <Text fontWeight={"bold"}>Otras Actividades</Text>
                                        <Text>{candidato?.otras_actividades ?? ""}</Text>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )
            }
        </Grid>
    )
}
