import React from 'react'
import { Grid } from '@mui/material'
import { H3 } from 'components/texts'
import { ASSETS } from 'constants/Assets'
import { ImagePicker, Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Formulario con los datos del responsable
 */
export default function DataFormResponsable({ responsable, register, errors }) {

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container px={5}>
            <Grid container alignItems={"center"}>
                <Grid item xs={4} md={2}>
                    <ImagePicker 
                        mimeTypes    = {['image/jpeg', 'image/png']} 
                        register     = {register} 
                        name         = {"foto_logo"}
                        style        = {{ width: "100%", maxHeight: "100%" }}
                        defaultImage = {ASSETS.images + (responsable?.foto_logo ?? 'private/responsable/perfiles/0/default.png')} />
                </Grid>

                <Grid item xs={8} md={10} container spacing={2} px={2}>
                    <Grid item xs={6}>
                        <Input label="Nombre" register={register} errors={errors} name={"nombre"} className="w-100" defaultValue={responsable?.nombre ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Segundo apellido" register={register} errors={errors} name={"apellido2"} className="w-100" defaultValue={responsable?.apellido2 ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Email" register={register} errors={errors} name={"email"} className="w-100" defaultValue={responsable?.email ?? ""} />
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Primer apellido" register={register} errors={errors} name={"apellido1"} className="w-100" defaultValue={responsable?.apellido1 ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Numero de documento" register={register} errors={errors} name={"numero_documento"} className="w-100" defaultValue={responsable?.numero_documento ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={6}>
                        <ItemsSelect name={"id_tipo_documento"} keyValue={"tipo_documento"} register={register} errors={errors} defaultValue={responsable?.id_tipo_documento ?? ""}/>
                    </Grid>
                </Grid>
            </Grid>


            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <H3 className={"m-0"}>Otros datos</H3>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Profesion" register={register} errors={errors} className="w-100" name={"profesion"} defaultValue={responsable?.profesion ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Teléfono móvil" register={register} errors={errors} className="w-100" name={"telefono_movil"} defaultValue={responsable?.telefono_movil ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Teléfono trabajo" register={register} errors={errors} className="w-100" name={"telefono_trabajo"} defaultValue={responsable?.telefono_trabajo ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Otro teléfono" register={register} errors={errors} className="w-100" name={"telefono_otros"} defaultValue={responsable?.telefono_otros ?? ""} uppercase />
                </Grid>
            </Grid>
        </Grid>
    )
}
