import React from 'react'
import { Text } from 'components/texts'
import '../style.css'
import DeleteIcon from '@mui/icons-material/Delete'
import Statics from 'objects/common/Statics'

export default function TemplateAcademiaClasesParticulares({ day, event, calculateSteps, onDeleteClick = () => { }, isSingle }) {
    const txtColor = Statics.detectTextColorByBackgroundColor(event.color)

    return (
        <td key={day.value} rowSpan={calculateSteps(event.hora_inicio, event.hora_fin)} style={{ backgroundColor: event.color, position: "relative", cursor: "pointer" }} onClick={() => onDeleteClick(event.id)}>
            { isSingle ? null : <DeleteIcon style={{ color: txtColor, position: 'absolute', top: 16, right: 16 }} /> }

            <Text color={txtColor}>{event.lugar_clase ?? ""}</Text>
        </td>
    )
}