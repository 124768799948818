import RequestMaster from '../RequestMaster'

export default class RequestAlumno extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/domiciliacion_bancaria_iban'
    }


    /**
     * Edita la domiciliacion bancaria de un alumno
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    edit(id_alumno, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "" + id_alumno
        this.put(path, params, callback, callbackError, callbackFinally)
    }
}
