import React from 'react'
import { Grid, Box, FormControlLabel, Checkbox, Stack } from "@mui/material";
import { Line, GroupButtons } from "components/data_display";
import { Input, Select } from 'components/forms/components';
import { useForm } from 'react-hook-form';
import { Text } from 'components/texts/index.js'


export default function ActividadesCurriculum({ onChange }) {
    const { handleSubmit, register, setValue, watch, formState: { errors } } = useForm();

    const idiomas_ppl = [
        { value: 0, label: "ALEMÁN" },
        { value: 1, label: "ARABE" },
        { value: 2, label: "CHINO" },
        { value: 3, label: "FRANCÉS" },
        { value: 4, label: "ITALIANO" },
    ]

    const labels = [
        { label: "Vehículo", isSelected: false },
        { label: "Nativo", isSelected: false },
        { label: "Habla Español", isSelected: false },
    ];

    const actividades = [
        { label: "Idiomas", isSelected: false },
        { label: "Deporte", isSelected: false },
        { label: "Talleres", isSelected: false },
        { label: "Médico", isSelected: false },
        { label: "Socorrismo", isSelected: false },
        { label: "Primeros Auxilios", isSelected: false },

    ];

    const otros_idiomas = [
        { label: "Inglés", isSelected: false },
        { label: "Francés", isSelected: false },
        { label: "Alemán", isSelected: false },
        { label: "Italiano", isSelected: false },
        { label: "Árabe", isSelected: false },
    ];

    const otras_actividades = [
        { label: "Futbol", isSelected: false },
        { label: "Baloncesto", isSelected: false },
        { label: "Judo/Karate", isSelected: false },
        { label: "Voleybol", isSelected: false },
        { label: "Otras Act. Deportivas", isSelected: false },
        { label: "Baile", isSelected: false },
        { label: "Aerobic", isSelected: false },
        { label: "G. Rítimica", isSelected: false },
        { label: "Informática", isSelected: false },
        { label: "Teatro", isSelected: false },
        { label: "Manualidades", isSelected: false },
        { label: "Animación", isSelected: false },
        { label: "Refuerzo", isSelected: false },
        { label: "Otros Talleres", isSelected: false },
    ];

    const handleButtonChange = (selectedButtons) => {
        if (onChange) {
            onChange(selectedButtons);
        }
    };

    return (

        <Grid container alignItems="center" direction={'row'} mt={4} ml={2}>

            <Grid container item xs={10} >
                <GroupButtons labels={labels} onChange={handleButtonChange} />
            </Grid>

            <Grid container >

                <Grid item xs={12} mt={2}>
                    <Text>Actividades</Text>
                    <Line></Line>
                    <Box mt={2}>
                        <GroupButtons labels={actividades} onChange={handleButtonChange} />
                    </Box>
                </Grid>

                <Grid item xs={4} mt={2}>
                    <Text>Idioma Principal</Text>
                    <Line></Line>
                    <Box mt={2}>
                        <Select width={"60%"} items={idiomas_ppl} defaultValue={null} />
                    </Box>
                    <Box mt={2.5} >
                        <FormControlLabel control={<Checkbox />} label="Otros" />
                        <Input label="Otro" width={'43%'} name={"otro"} register={register} errors={errors} uppercase />
                    </Box>
                </Grid>

                <Grid item xs={8} mt={2}>
                    <Text>Otros Idiomas</Text>
                    <Line></Line>
                    <Box mt={2}>
                        <GroupButtons labels={otros_idiomas} onChange={handleButtonChange} />
                    </Box>

                </Grid>

            </Grid>

            <Grid container mt={4} >
                <Grid item xs={12}>
                    <Text>Otras Actividades</Text>
                    <Line></Line>
                    <Box mt={2.5}>
                        <GroupButtons labels={otras_actividades} onChange={handleButtonChange} />
                    </Box >
                    <Box mt={2.5} display="flex" alignItems="center">
                        <Text>Otros</Text>
                        <Input style={{ marginLeft: '20px' }} label="Otro" width={'14%'} name={"otro"} register={register} errors={errors} uppercase />
                    </Box>
                </Grid>
            </Grid>

        </Grid>
    )
}