import Statics from "objects/common/Statics";
import { RequestInscripcionAcademia, RequestVentaLibro, RequestPagosClasesParticulares } from "objects/requests";

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {
    /**
     * Hace la petición para obtener la clase particular
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getInscripcion(id, callbackOk = () => { }, callbackError = () => { }) {
        const params = {}

        params.appends = ['actividad', 'grupo_academia', 'forma_pago', 'forma_recogida',
            'forma_conocimiento', 'motivo_baja', 'operador', 'alumno', 'curso_escolar', 'preferencia_horario', 'tipo_matricula', 'tipo_pago']
        new RequestInscripcionAcademia().get(id, params, (res) => {
            callbackOk(res ?? null)
        }, callbackError)
    }

    /**
     * Hace la petición para actualizar una clase particular
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editInscripcion(inscripcion_id, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()

        //-- GENERAL --//

        formData.append('alumno_id', getValues('alumno_id'))
        formData.append('curso_escolar_id', getValues('curso_escolar_id'))
        formData.append('forma_pago_id', getValues('forma_pago_id'))

        formData.append('forma_recogida_id', getValues('forma_recogida_id'))
        formData.append('forma_conocimiento_id', getValues('forma_conocimiento_id'))
        formData.append('registro_actividad_id', getValues('registro_actividad_id'))
        formData.append('matricula', getValues('matricula'))
        formData.append('tipo_matricula_id',getValues('tipo_matricula_id'))
        formData.append('cantidad_pago', getValues('cantidad_pago'))
        formData.append('tipo_pago_id', getValues('tipo_pago_id'))
        formData.append('motivo_baja_id', getValues('motivo_baja_id'))

        formData.append('preferencia_horario_id', getValues('preferencia_horario_id'))
        formData.append('comentarios_horario', getValues('comentarios_horario'))
        formData.append('hermanos', getValues('hermanos') ? 1 : 0)
        // formData.append('test_nivel', getValues('test_nivel').toUpperCase())
        formData.append('empresa_colectivo', getValues('empresa_colectivo') ? 1 : 0)
        formData.append('observaciones', getValues('observaciones'))

        const fecha_inscripcion = getValues("fecha_inscripcion")
        formData.append("fecha_inscripcion", Statics.datePickerTimeFormat(fecha_inscripcion))

        const fecha_incorporacion = getValues("fecha_incorporacion")
        formData.append("fecha_incorporacion", Statics.datePickerTimeFormat(fecha_incorporacion))

        const fecha_baja = getValues("fecha_baja")
        formData.append("fecha_baja", Statics.datePickerTimeFormat(fecha_baja))

        const fecha_pago_matricula = getValues("fecha_pago_matricula")
        formData.append("fecha_pago_matricula", Statics.datePickerTimeFormat(fecha_pago_matricula))
        formData.append('alumno_colectivo', getValues('alumno_colectivo') ? 1 : 0)
        formData.append('comentario_profesor_ap', getValues('comentario_profesor_ap'))
        formData.append('comentarios_generales', getValues('comentarios_generales'))

        //-- LIBRO --//
        formData.append('libro_id', getValues("libro_id"))

        new RequestInscripcionAcademia().edit(inscripcion_id, formData, callbackOk, callbackError)
    }

    /**
     * Comprueba que peticion tiene que hacer
     */
    crudLibro(inscripcion, getValues, resolve, reject) {
        const libroSolicitado = getValues("libro_solicitado")
        if (inscripcion.venta_libro) {
            libroSolicitado ? this.editVentaLibro(inscripcion.venta_libro.id, getValues, resolve, reject) : this.deleteVentaLibro(inscripcion.venta_libro.id, resolve, reject)
        } else if (libroSolicitado) {
            this.createVentaLibro(inscripcion.id, getValues, resolve, reject)
        } else {
            resolve()
        }
    }

    /**
     * Comprueba que petición tiene que hacer (para los libros asociados)
     */
    crudLibroAsociado(inscripcion, getValues, resolve, reject) {
        const libroAsociadoSolicitado = getValues("libro_asociado_solicitado")

        if (inscripcion?.venta_libro_asociado) {
            libroAsociadoSolicitado ? this.editVentaLibroAsociado(inscripcion.venta_libro_asociado.id, getValues, resolve, reject) : this.deleteVentaLibro(inscripcion.venta_libro_asociado.id, resolve, reject)
        } else if (libroAsociadoSolicitado) {
            this.createVentaLibroAsociado(inscripcion.id, getValues, resolve, reject)
        } else {
            resolve()
        }
    }

    /**
     * Método para editar un registro de una venta de un libro
     * 
     * @param {Int}      inscripcion_id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createVentaLibro(inscripcion_id, getValues, callbackOk = () => { }, callbackError = () => { }) {
        let formData = this.ventaLibroFormdata(getValues, true)
        formData.append('inscripcion_id', inscripcion_id)

        new RequestVentaLibro().edit(inscripcion_id, formData, callbackOk, callbackError)
    }

    /**
     * Método para crear un registro de una venta de un libro asociado
     * 
     * @param {Int}      id_inscripcion 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createVentaLibroAsociado(inscripcion_id, getValues, callbackOk = () => { }, callbackError = () => { }) {
        let formData = this.ventaLibroFormdata(getValues, true)
        formData.append('inscripcion_id', inscripcion_id)

        new RequestVentaLibro().create(formData, callbackOk, callbackError)
    }

    /**
     * Método para editar un registro de una venta de un libro
     * 
     * @param {Int}      id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editVentaLibro(id, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = this.ventaLibroFormdata(getValues)
        new RequestVentaLibro().edit(id, formData, callbackOk, callbackError)
    }

    /**
     * Método para editar un registro de una venta de un libro asociado
     * 
     * @param {Int}      id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editVentaLibroAsociado(id, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = this.ventaLibroFormdata(getValues, true)
        new RequestVentaLibro().edit(id, formData, callbackOk, callbackError)
    }

    /**
     * Método para borrar un registro de la venta de un libro
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    deleteVentaLibro(id, callbackOk = () => { }, callbackError = () => { }) {
        new RequestVentaLibro().erase(id, new FormData(), callbackOk, callbackError)
    }

    /**
     * Crea y devuelve el FormData para las ventaLibro
     * 
     * @param {Function} getValues 
     */
    ventaLibroFormdata(getValues, asociado = false) {
        const sufix = asociado ? "_asociado" : ""

        let formData = new FormData
        formData.append('curso_escolar_id', getValues('year'))
        formData.append('libro_id', getValues(`libro${sufix}_id`) ?? "")
        formData.append('precio', getValues(`precio${sufix}`))
        formData.append('inscripcion_type', 'inscripcion-academia')
        if (asociado)
            formData.append('asociado', 1)

        const fecha_peticion = getValues(`fecha${sufix}_peticion`)
        formData.append("fecha_peticion", Statics.datePickerTimeFormat(fecha_peticion))

        const fecha_pago = getValues(`fecha_pago${sufix}`)
        formData.append("fecha_pago", Statics.datePickerTimeFormat(fecha_pago))

        const fecha_entrega = getValues(`fecha_entrega${sufix}`)
        formData.append("fecha_entrega", Statics.datePickerTimeFormat(fecha_entrega))

        return formData
    }

    /**
     * Método para crear los pagos de una clase particular
     * 
     * @param {Int}      id_inscripcion 
     * @param {Object}   pagos 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createPagos(inscripcion_id, pagos, callbackOk = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const formData = new FormData
        formData.append('inscripcion_type', 'inscripcion-academia')
        formData.append('inscripcion_id', inscripcion_id)
        for (const index in pagos) {
            formData.append(`pagos[${index}][mes]`, pagos[index].mes)
            formData.append(`pagos[${index}][fecha]`, pagos[index].fecha ?? "")
            formData.append(`pagos[${index}][cantidad]`, pagos[index].cantidad ?? "")
            formData.append(`pagos[${index}][pagado]`, pagos[index].pagado ?? "")
            formData.append(`pagos[${index}][otros_cargos]`, pagos[index].otros_cargos ?? "")
            formData.append(`pagos[${index}][total]`, pagos[index].total ?? "")

        }

        new RequestPagosClasesParticulares().createMultiple(formData, callbackOk, callbackError, callbackFinally)
    }
}