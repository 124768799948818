import React, { Component } from 'react'
import { useForm } from 'react-hook-form'
import Requests from './request/Request'
import { useNavigate } from "react-router-dom"
import Statics from 'objects/common/Statics.js'
import { Container, Paper } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreateHeader } from 'pages/logged/admin/components'
import { DataFormAula } from 'components/forms/display'
import { BreadcrumbsList, Line, SnackbarAlert } from 'components/data_display'
import { validationSchema } from 'components/forms/display/academia/aula/DataForm/validation'

const Wrapper = () => {
  const { register, handleSubmit, formState: { errors }, getValues } = useForm({ resolver: yupResolver(validationSchema) })
  const navigate = useNavigate()

  return <CreateAulasAcademia register={register} handleSubmit={handleSubmit} errors={errors} getValues={getValues} navigate={navigate} />
}
export default Wrapper

/**
 * Crear Aulas Academia
 */
class CreateAulasAcademia extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isMounted: false,
    }

    this.requests = new Requests()
  }


  /**
   * ComponentDidMount
   */
  componentDidMount() {
    this.setState({ isMounted: true })
  }
  //-------------------------------------------------------
  //-------------------- CLASS FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Controla el envio del formulario
   */
  handleSubmit = (e) => {
    let handleCallbacks = this.props.handleSubmit(this.createAula, (error) => { console.log(error) })
    handleCallbacks(e)
  }

  //-------------------------------------------------------
  //----------------------- REQUESTS ----------------------
  //-------------------------------------------------------

  /**
   * Hace la petición para editar los datos del aula
   */
  createAula = () => {
    this.header.button.setLoading(true)

    this.requests.createAula(this.props.getValues, async (res) => {
      this.snackbarAlert.updateState({ message: "Se ha creado el aula correctamente. Redirigiendo...", severity: "success", open: true })
      await Statics.delay(1500).then(() => { this.props.navigate('./../' + res.aula.id + "/editar") })
    }, (res) => {
      this.header.button.setLoading(false)
      this.snackbarAlert.updateState({ message: res, severity: "error", open: true })
    })
  }

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
      <Container maxWidth={false} sx={{ mt: 5 }}>
        <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
          <BreadcrumbsList breadcrumbsList={[
            { to: "/", label: "Inicio" },
            { to: "./", label: "Academias" },
            { to: "./../", label: "Aulas" },
            { to: "./../../", label: "Crear aula", actualItem: true },
          ]} />

          <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
            <CreateHeader
              ref={(ref) => this.header = ref}
              title={"Crear Aula"}
              btnText={"Guardar"}
              handleSubmit={this.handleSubmit} />

            <Line className={"my-5"} />
            <DataFormAula register={this.props.register} errors={this.props.errors} isMounted={this.state.isMounted} />
          </Paper>

          <SnackbarAlert ref={(ref) => this.snackbarAlert = ref} />
        </form>
      </Container>
    )
  }
}
