import axios from 'axios'
import { API_URL } from 'constants/Api'
import { deleteUser } from 'store/actions/userActions'
import store from 'store/store'

const instance = axios.create({
  baseURL: API_URL,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status == 401) {
        store.dispatch(deleteUser());
    }
    return Promise.reject(error);
  }
);

export default instance;