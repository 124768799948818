import { Link } from 'components/data_display'
import { Button } from 'components/forms/components'

export const columns = [
    {
        field: "actions",
        type: 'actions',
        headerName: "Acciones",
        minWidth: 350,
        getActions: (params) => [<Link to={"" + params.row?.id}><Button size={"small"}>Ver</Button></Link>, <Link to={"" + params.row?.id + "/editar"}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>]
    },

    //los datos que se esperan para rellenar la tabla son relevantes a aulas
    { field: "id",           headerName: "ID",          maxWidth: 350, valueGetter: (params) => { return params.row?.id } },
    { field: "denominacion", headerName: "denominacion",  maxWidth: 350, flex: 1, valueGetter: (params) => { return params.row?.denominacion }},
    { field: "capacidad",    headerName: "capacidad", maxWidth: 350,  flex: 1, valueGetter: (params) => { return params.row?.capacidad }},
]
