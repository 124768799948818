import React from 'react'
import Skeleton from './skeleton'
import { ASSETS } from 'constants/Assets'
import { H3, Text } from 'components/texts'
import { Avatar, Box, Grid } from '@mui/material'

export default function IncidenciaAlumnoData({ incidencia }) {

	const alumno = incidencia?.alumno ?? {}
	return (
		<Grid container>
			{!incidencia ? 
					<Skeleton /> 
				: 
					(
					<React.Fragment>
						<Grid item xs={12} mb={5}>
							<H3>Alumno de la tutoría:</H3>
						</Grid>

						<Grid item xs={2}>
							<Avatar
								alt = {"Foto de: " + (alumno?.nombre ?? "")}
								src = {`${ASSETS.images + (alumno.foto_logo ? alumno.foto_logo : 'private/alumno/perfiles/0/default.png')}`}
								sx  = {{ width: "80%", height: "100%", borderRadius: 2}}/>
						</Grid>

						<Grid item xs={8}>
							<Box mt={2}>
								<H3>{alumno.nombre + " " + (alumno.apellido1 ?? "") + " " + (alumno.apellido2 ?? "")}</H3>

								<Box display={"flex"}>
								<Text fontWeight={"bold"}>Documento de identidad:</Text>
								<Text className="mx-2">{alumno.numero_documento + " - " + alumno.tipo_documento?.nombre}</Text>
								</Box>

								<Box display={"flex"}>
								<Text fontWeight={"bold"}>Género:</Text>
								<Text className="mx-2">{alumno.sexo?.valor}</Text>
								</Box>
							</Box>
						</Grid>
					</React.Fragment>
				)
			}
		</Grid>
  )
}
