import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Paper, Stack } from '@mui/material'
import { EditHeader } from 'pages/logged/admin/components'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { BreadcrumbsList, Line, MoveButtons, SnackbarAlert } from 'components/data_display'
import { Text } from 'components/texts'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { EmpleadoListContext } from 'contexts/employee/ListContext'


//FORM
import { DataFormAula } from 'components/forms/display'
import SkeletonAula from 'components/forms/display/academia/aula/DataForm/skeleton.jsx'
import { validationSchema } from 'components/forms/display/academia/aula/DataForm/validation.js'

export default function Wrapper() {

  const navigationParams = useParams()

  const [aula, setAula] = useState(null)
  const [id_aula, setIdAula] = useState(navigationParams.id_aula)

  const { items, updateItem } = useContext(EmpleadoListContext)

  const getAula = (getFromServer = false) => {
    const aula = items.find(item => item.id == id_aula)
    if (items.length == 0 || !aula || getFromServer) {
      new Requests().getAula(id_aula, (aula) => {
        setAula(aula)
        updateItem(aula)
      }, () => enqueueSnackbar(<Text>No se ha podido obtener el Aula.</Text>, { variant: "error" }))
    } else {
      setAula(aula)
    }

  }

  useEffect(getAula, [id_aula])

  return (
    <React.Fragment>
      <EditAulasAcademia key={aula?.id ?? 0} aula={aula} id_aula={id_aula} setIdAula={setIdAula} setAula={setAula} />
      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}

function EditAulasAcademia({ aula, id_aula, setIdAula, setAula }) {
  const { updateItem } = useContext(EmpleadoListContext)

  const navigate = useNavigate()

  const { register, handleSubmit, formState: { errors }, getValues, control } = useForm({ resolver: yupResolver(validationSchema) })
  const headerRef = useRef()


  const updateHeaderAndAlert = ({ message, variant = "error" }, loading = false) => {
    headerRef.current.button.setLoading(loading)
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }


  /**
   * Hace la petición para editar los datos del aula
   */
  const editAula = () => {
    headerRef.current.button.setLoading(true)
    new Requests().editAula(id_aula, getValues, (res) => {
      updateItem(res.item)
      updateHeaderAndAlert({ message: "Se ha editado el aula correctamente.", variant: "success" })
    }, (res) => {
      updateHeaderAndAlert({ message: res })
    })
  }

  const updateId = (id) => {
    navigate(`../academias/aulas/${id}/editar`);
    setIdAula(id);
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">

        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/", label: "Inicio" },
            { to: "./", label: "Academias" },
            { to: "./../", label: "Aulas" },
            { to: "./../../", label: "Editar aula", actualItem: true },
          ]} />

          <MoveButtons
            actualItemId={id_aula}
            beforeFetchCallback={() => setAula(null)}
            onUpdateActualItem={updateId} />
        </Stack>

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <EditHeader
            ref={headerRef}
            title={"Editar Aula"}
            item={aula}
            handleSubmit={handleSubmit(editAula)} />

          <Line />

          {aula ? <DataFormAula
            aula={aula}
            register={register}
            errors={errors}
            control={control}
            isMounted={true}
            hideButton /> : <SkeletonAula />}
        </Paper>
      </form>
    </Container>
  )
}