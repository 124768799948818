export const COLOR = {

    standard: {
        //main: "#3846C2",
        main: "#4B799E",
        dark: "#29338A",
    },

    cancel: {
        main: "#404A56",
    },

    success: {
        main: "#38C288",
    },

    error: {
        main: "#F42449",
    },

    black: {
        main: "#232323",
    },

    grey: {
        main: "#7F8791",
        dark: "#404A56",
        light: "#F7F9FA",
    },

    white: {
        main: "#FFF",
    },

    yellow: {
        main: "#F8BC04"
    },

    infoBox: {
        main: "#FFFAF3",
    }
}