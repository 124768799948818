import { AlumnoData, DatosColegioData } from './parts'
import { RequestDatosColegio } from 'objects/requests'
import React, { useState, useEffect } from 'react'
import { Container, Paper, Stack } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { BreadcrumbsList, Line, MoveButtons} from 'components/data_display'

export default function SingleDatosColegioAlumno() {
  const navigationParams = useParams()
  const navigate = useNavigate()

  const [id_datos_colegio, setIdDatosColegio] = useState(navigationParams.id_datos_colegio)
  const [datosColegio,     setDatosColegio]   = useState(null)

  /**
   * Hace la petición para obtener el usuario
   */
  const getDatosColegio = () => {
    const params = { relations: 'alumno,curso_escolar,curso_colegio,colegio' }

    new RequestDatosColegio().get(id_datos_colegio, params, (res) => {
      if (res.item)
        setDatosColegio(res.item)
    })
  }

  const updateId = (id) => {
    navigate(`../alumnos/datos-colegio/${id}`);
    setIdDatosColegio(id);
  }

  useEffect(getDatosColegio, [id_datos_colegio])

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/",     label: "Inicio" },
            { to: "./../../", label: "Alumnos" },
            { to: "./../", label: "Datos en colegios" },
            { to: "./",    label: "Datos en colegios #" + (id_datos_colegio ?? "0000"), actualItem: true },
          ]} />

          <MoveButtons 
            actualItemId        = {id_datos_colegio}
            beforeFetchCallback = {() => setDatosColegio(null)} 
            onUpdateActualItem  = {updateId} />
        </Stack>
        
        <Paper elevation={2} sx={{mx: 8, my: 2, p: 5}}>
          
          <DatosColegioData datosColegio={datosColegio} />

          <Line className={"my-10"}/>

          <AlumnoData datosColegio={datosColegio} />
          
        </Paper>
      </Container>
  )
}
