import * as Yup from 'yup'

const Regex = "^[A-Za-z0-9]+$"
const validationSchema = Yup.object().shape({
    tipo_actividad:        Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    actividad:        Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    nombre:           Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    modalidad:           Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    matricula: Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
})

export default validationSchema