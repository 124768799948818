

import './style.css'
import { v4 as uuid } from 'uuid';
import Box from '@mui/material/Box';
import TableColumn from '../TableColumn'
import React, { Component } from 'react';
import { erase, add, edit } from '../CRUD'
import { enqueueSnackbar } from 'notistack';
import { H3, Text } from 'components/texts';
import Statics from 'objects/common/Statics';
import AddIcon from '@mui/icons-material/Add';
import { libroDevueltoObject } from './constants'
import { Button } from 'components/forms/components'
import RemoveIcon from '@mui/icons-material/Remove';

export default class LibrosDevueltos extends Component {

    constructor(props){
        super(props)

        this.state = {
            librosDevueltos: this.props.librosDevueltos ?? [],
            cursoEscolar: null,
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.cursoEscolar != this.props.cursoEscolar)
            this.setState({cursoEscolar: this.props.cursoEscolar})
        
        if(prevProps.librosDevueltos != this.props.librosDevueltos)
            this.setState({librosDevueltos: this.props.librosDevueltos})
    }

    
    updateLibrosDevueltos = (librosDevueltos) => {
        this.props.onUpdate([...librosDevueltos])
    }


    render() {
        const librosToRender = this.state.cursoEscolar ? this.state.librosDevueltos.filter(item => item.id_curso_escolar == this.state.cursoEscolar && item.deleted != true) ?? [] : []
        const sumDevueltas   = Statics.sumParamsOfObjectArray(librosToRender, "cantidad_devuelta")

        return (
            <Box px={2}>
                <H3>Libros devueltos: </H3>
                <table className='libros-devueltos-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th><Text fontWeight={"bold"}>Fecha</Text></th>
                            <th><Text fontWeight={"bold"}>Unidades devueltas</Text></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className='no-border'>
                        {librosToRender.map((item, index, array) => (
                            <tr key={uuid()}>
                                <td className='bg-white'>
                                    <Button style={{borderRadius: 200}} color={"error"} iconType onClick={() => {
                                        const librosDevueltos = erase(item, this.state.librosDevueltos)
                                        this.updateLibrosDevueltos(librosDevueltos)
                                    }}>
                                        <RemoveIcon/>
                                    </Button>
                                </td>
                                
                                <TableColumn defaultValue={item.fecha} type={"date"} onEdit={(value) => {
                                    const librosDevueltos = edit(item, value, "fecha", this.state.librosDevueltos)
                                    this.updateLibrosDevueltos(librosDevueltos)
                                }}/>

                                <TableColumn defaultValue={item.cantidad_devuelta} onEdit={(value) => {
                                    const librosDevueltos = edit(item, value, "cantidad_devuelta", this.state.librosDevueltos)
                                    this.updateLibrosDevueltos(librosDevueltos)
                                }}/>

                                {array.length === index + 1 && this.state.cursoEscolar ? (
                                    <td className='bg-white'>
                                        <Button iconType onClick={() => {
                                            const librosDevueltos = add(librosToRender, this.state.librosDevueltos, this.state.cursoEscolar, libroDevueltoObject)
                                            librosDevueltos ? this.updateLibrosDevueltos(librosDevueltos) : enqueueSnackbar(<Text>Rellena el pedido anterior para añadir más.</Text>, { variant: "error" })
                                        }}>
                                            <AddIcon/>
                                        </Button>
                                    </td>
                                ) : null}
                            </tr>
                        ))}

                        {librosToRender.length == 0 && this.state.cursoEscolar ? 
                            <tr>
                                <td className='bg-white' colSpan={3}></td>
                                <td className='bg-white'>
                                    <Button iconType onClick={() => {
                                        const librosDevueltos = add(librosToRender, this.state.librosDevueltos, this.state.cursoEscolar, libroDevueltoObject)
                                        librosDevueltos ? this.updateLibrosDevueltos(librosDevueltos) : enqueueSnackbar(<Text>Rellena el pedido anterior para añadir más.</Text>, { variant: "error" })
                                    }}>
                                        <AddIcon/>
                                    </Button>
                                </td>
                            </tr> : null}

                        <tr className="total-unidades">
                            <td colSpan={2} className='bg-white'><Text fontWeight="bold">Total devueltas</Text></td>
                            <td><Text>{sumDevueltas}</Text></td>
                        </tr>
                    </tbody>
                </table>

            </Box>
        ); 
    }
};