import React from 'react'
import { Grid, Stack } from '@mui/material'
import { H3, Text } from 'components/texts'
import Skeleton from './skeleton'

export default function ActividadData({ actividad }) {

	return (
		<Grid container spacing={5}>
			{!actividad ?
				<Skeleton />
				: (
					<React.Fragment>
						<Grid item xs={12} display={"flex"}>
							<H3>Datos del registro de actividad:</H3>
						</Grid>

                        <Grid item xs={6} md={4} xl={3}>
							<Stack>
								<Text fontWeight={"bold"}>Colegio:</Text>
								<Text>{(actividad.entity?.nombre ?? "") + " - " + (actividad.entity?.clave ?? "")}</Text>
							</Stack>
						</Grid>

						<Grid item xs={6} md={4} xl={3}>
							<Stack>
								<Text fontWeight={"bold"}>Actividad:</Text>
								<Text>{actividad.actividad?.valor ?? ""}</Text>
							</Stack>
						</Grid>

						<Grid item xs={6} md={4} xl={3}>
							<Stack>
								<Text fontWeight={"bold"}>Tipo de ctividad:</Text>
								<Text>{actividad.tipo_actividad?.valor ?? ""}</Text>
							</Stack>
						</Grid>

                        <Grid item xs={6} md={4} xl={3}>
							<Stack>
								<Text fontWeight={"bold"}>Modalidad cursos:</Text>
								<Text>{actividad.modalidad_cursos?.nombre ?? ""}</Text>
							</Stack>
						</Grid>

						<Grid item xs={6} md={4} xl={3}>
							<Stack>
								<Text fontWeight={"bold"}>Precio:</Text>
								<Text>{actividad.precio ?? ""}</Text>
							</Stack>
						</Grid>

						<Grid item xs={6} md={4} xl={3}>
							<Stack>
								<Text fontWeight={"bold"}>Matricula:</Text>
								<Text>{actividad.matricula ?? ""}</Text>
							</Stack>
						</Grid>

                        <Grid item xs={6} md={4} xl={3}>
							<Stack>
								<Text fontWeight={"bold"}>Precio junio:</Text>
								<Text>{actividad.precio_junio ?? ""}</Text>
							</Stack>
						</Grid>

					</React.Fragment>
				)
			}
		</Grid>
	)
}

