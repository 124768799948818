import React, { useState } from 'react'
import { Container, Paper, Stack, Grid } from '@mui/material'
import { Button } from 'components/forms/components';
import { BreadcrumbsList } from 'components/data_display'
import { Checkbox, Input } from 'components/forms/components'
import { GroupButtons } from 'components/data_display'
import {
    FormControl, Radio, FormLabel,
    FormControlLabel, RadioGroup, Tab, Tabs, Box
} from '@mui/material'
import { H3, Text } from 'components/texts'
import { useForm } from 'react-hook-form'
import { COLOR } from 'theme/Colors'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { TabContext } from '@mui/lab'
import { grupo_1, grupo_2, grupo_3, grupo_4, grupo_5, tabItems, placeholderText } from './constants';
import AttachFile from '@mui/icons-material/AttachEmail';


export default function ComunicacionesAlumnos() {
    const [tipoGrupo, setTipoGrupo] = useState('todos')
    const [tab, setTab] = useState("1")
    const [lastActiveTab, setLastActiveTab] = useState("1");
    const [selectedButtons, setSelectedButtons] = useState(['Academia']);

    const { handleSubmit, setValue, getValues, register, formState: { errors }, reset } = useForm()

    const sendComunicaciones = (values) => {
        console.log(values)
    }

    const handleButtonChange = (button) => {
        const index = selectedButtons.indexOf(button.label);

        if (index === -1) {
            selectedButtons.push(button.label);
        } else {
            selectedButtons.splice(index, 1);
        }

        setSelectedButtons([...selectedButtons]);

        if (button.isSelected === true) {
            setTab(button.id);
            setLastActiveTab(button.id);
        } else if (button.isSelected === false) {
            // Si el botón se deshabilita, encuentra el último botón habilitado
            const lastEnabledButton = selectedButtons.slice().reverse().find(label => {
                const tabItem = tabItems.find(item => item.title === label);
                return tabItem && tabItem.content !== null;
            });

            // Establece el tab en función del último botón habilitado
            if (lastEnabledButton) {
                const correspondingTab = tabItems.find(item => item.title === lastEnabledButton);
                setTab(correspondingTab.id);
                setLastActiveTab(correspondingTab.id);
            }
        }
    };

    const sortTabs = () => {
        const selected = tabItems.filter(obj => selectedButtons.includes(obj.title))
            .sort((a, b) => a.title.localeCompare(b.title));
        const notSelected = tabItems.filter(obj => !selectedButtons.includes(obj.title))
            .sort((a, b) => a.title.localeCompare(b.title));

        return [...selected, ...notSelected]
    }

    const tabsSorted = sortTabs()

    return (
        <>
            <Container maxWidth={false} sx={{ mt: 5 }}>

                <Stack direction="row" justifyContent={"space-between"} marginX={8}>
                    <BreadcrumbsList mx={0} breadcrumbsList={[
                        { to: "/", label: "Inicio" },
                        { to: "./../", label: "   Comunicaciones", actualItem: true },
                    ]} />
                </Stack>

                <Paper elevation={2} sx={{ mx: 6, my: 2, p: 5 }}>
                    <Grid container spacing={1.5}>
                        <Grid item xs={12}>
                            <H3 fontWeight='bold'>DESTINATARIOS</H3>
                        </Grid>
                        <Grid item xs={2.3}>
                            <GroupButtons labels={grupo_1} onChange={handleButtonChange} />
                        </Grid>

                        <Grid item xs={2.4}>
                            <GroupButtons labels={grupo_2} onChange={handleButtonChange} />
                        </Grid>

                        {/* <Grid item xs={2.4}>
                            <GroupButtons labels={grupo_3} onChange={handleButtonChange} />
                        </Grid>

                        <Grid item xs={2.4}>
                            <GroupButtons labels={grupo_4} onChange={handleButtonChange} />
                        </Grid>

                        <Grid item xs={2.4}>
                            <GroupButtons labels={grupo_5} onChange={handleButtonChange} />
                        </Grid> */}

                    </Grid>
                    <Grid container mt={4}>

                        <Grid item xs={2.5}>
                            <FormControl>
                                <FormLabel >
                                    <Text fontWeight='bold'>Tipo de Destinatario</Text>
                                </FormLabel>
                                <RadioGroup sx={{ m: 1 }} onChange={({ target }) => setTipoGrupo(target.value)} value={tipoGrupo} row aria-labelledby="tipo-grupo" name="tipo-grupo">
                                    <FormControlLabel value="todos" control={<Radio />} label="Todos" />
                                    <FormControlLabel sx={{ ml: 1.5 }} value="actuales" control={<Radio />} label="Actuales" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3.5} mt={4.5} >
                            <Checkbox name={"p_mandato"} label={"Pdte. Mandato"} />
                        </Grid>

                        <Grid item xs={6}>
                            <Text fontWeight='bold'>Destinatarios</Text>
                            <Stack mt={2.5}>
                                <Text>302 destinatarios ejemplo</Text>
                            </Stack>
                        </Grid>

                    </Grid>
                </Paper>

                <Paper elevation={2} sx={{ mx: 6, my: 2, p: 5 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TabContext value={tab}>
                                <Box sx={{ borderBottom: 0, borderColor: 'divider' }} mx={0}>
                                    <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }} variant="scrollable">
                                        {tabsSorted
                                            .map((item) => {
                                                const correspondingButton = selectedButtons.find(button => button === item.title);
                                                return (
                                                    <Tab
                                                        key={item.id}
                                                        label={<Text fontWeight={tab === item.id ? "bold" : null}
                                                            color={
                                                                tab === item.id ?
                                                                    (COLOR.standard.main)
                                                                    :
                                                                    (!(correspondingButton) ? '#F0F0F0' : 'grey')
                                                            }>
                                                            {item.title}
                                                        </Text>}
                                                        value={item.id}
                                                        disabled={!(correspondingButton)}
                                                    />
                                                );
                                            })}
                                    </Tabs>
                                    {tabItems.map((item) => {
                                        const Template = item.content
                                        return (<TabPanel key={item.id} value={item.id}>
                                            <Template register={register} errors={errors} getValues={getValues} setValue={setValue} />
                                        </TabPanel>)
                                    })}
                                </Box>
                            </TabContext>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper elevation={2} sx={{ mx: 6, my: 2, p: 5 }}>
                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} rowSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} mb={1}>
                            <H3>Mensaje</H3>
                        </Grid>
                        <Grid item xs={6}>
                            <Input label="Asunto" register={register} errors={errors} className="w-100" name={"asunto"} uppercase />
                        </Grid>

                        <Grid item xs={5}>
                            <Input label="Adjuntar archivos" register={register} errors={errors} className="w-100" name={"adjuntar_archivos"} uppercase />
                        </Grid>

                        <Grid item xs={1}>
                            <Button size="medium" sx={{ backgroundColor: COLOR.standard.main }}
                                variant={'contained'} endIcon={<AttachFile style={{ marginLeft: '-8px' }} />}>
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Input placeholder={placeholderText} defaultValue={placeholderText}
                                register={register} errors={errors}
                                className="w-100" name={"observaciones_grupo"} multiline minRows={6} maxRows={10} />
                        </Grid>

                    </Grid>
                    <Grid item container direction={'row-reverse'} mt={4}>
                        <Button color='standard' onClick={handleSubmit(sendComunicaciones)}>Enviar</Button>
                    </Grid>
                </Paper>
            </Container>
        </>
    )
}

