import Statics from "objects/common/Statics"
import { useGridApiContext } from "@mui/x-data-grid"
import { YesNoSelect, ItemsSelect } from "components/forms/components/Select/custom"

export function columns(renderActions) {
    return [
        {
            field: "actions",
            type: 'actions',
            headerName: "Acciones",
            maxWidth: 150,
            getActions: renderActions
        },
        { 
            field: "nombre",         
            headerName: "Actividad",       
            width: 130,
            align:'center',
            headerAlign: 'center',
            valueGetter: (params) => { return params.row.actividad?.nombre } 
        },
        { 
            field: "modalidad",      
            headerName: "Modalidad",       
            width: 200,    
            align:'center',
            headerAlign: 'center',   
            valueGetter: (params) => { return params.row.actividad?.modalidad_cursos?.nombre } 
        },
        { 
            field: "activa",
            headerName: "Activa",
            maxWidth: 100, 
            editable: true,
            align:'center',
            headerAlign: 'center',
            renderEditCell: renderSiNoSelect,
            valueSetter: ({row, value}) => row,
        },
        { 
            field: "precio",         
            headerName: "Precio",          
            maxWidth: 100, 
            editable: true,
            type: 'number',
            align:'center',
            headerAlign: 'center',
            valueGetter: (params) => { return params.row.precio != null ? Statics.changeDotAndComma(params.row.precio) : "" },
            valueSetter: ({row, value}) => row,
        },
        { 
            field: "matricula",    
            headerName: "Matricula", 
            maxWidth: 100, 
            editable: true,
            type: 'number',
            align:'center',
            headerAlign: 'center',
            valueGetter: (params) => { return params.row.matricula != null ? Statics.changeDotAndComma(params.row.matricula) : "" },
            valueSetter: ({row, value}) => row,
        },
        { 
            field: "id_tipo_condicion_actividad", 
            headerName: "Tipo Condición.", 
            width: 130, 
            align:'center',
            headerAlign: 'center',
            editable: true,
            renderEditCell: renderTipoCondicionSelectCell,
            valueGetter: (params) => { return params.row.tipo_condicion?.valor ?? ""},
            valueSetter: ({row, value}) => row,
        },
        { 
            field: "condiciones",      
            headerName: "Condición.",
            type: 'number',
            align:'center',
            headerAlign: 'center',
            width: 130,
            editable: true,
            valueGetter: (params) => { return params.row.condiciones != null ? Statics.changeDotAndComma(params.row.condiciones) : ""},
            valueSetter: ({row, value}) => row,
        },
    ]
}

function renderTipoCondicionSelectCell (params) {
    return <CustomEditTipoCondicionComponent {...params} />;
}

function CustomEditTipoCondicionComponent(props) {
    const { id, value, field, row } = props;
    const apiRef = useGridApiContext();
    
    const onChange = (_, item) => {
        apiRef.current.setEditCellValue({ id, field, value: item });
    }
  
    return <ItemsSelect
            name         = {"id_tipo_condicion"}
            keyValue     = {"tipos_condicion_actividad"}
            defaultValue = {row.tipo_condicion?.id ?? ""}
            onChange     = {onChange}  />
}


function renderSiNoSelect (params) {
    return <CustomEditSiNoComponent {...params} />;
}

function CustomEditSiNoComponent(props) {
    const { id, value, field, row } = props;
    const apiRef = useGridApiContext();
    
    const onChange = ({target}) => {
      apiRef.current.setEditCellValue({ id, field, value: target.value });
    }
  
    return <YesNoSelect label={"Activa"} name={"activa"} defaultValue={row.activa ?? 0} onChange={onChange} width={"100%"} minWidth={75}/>;
}