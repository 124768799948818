import React from 'react'
import { Grid } from '@mui/material'
import { Input, LoadingButton } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Formulario con los datos del alumno
 */
export default function DataFormDatosColegio({ datosColegio, register, errors, handleSubmit, hideButton = false, isMounted = false }) {


    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container mt={2} px={4} spacing={2}>
            <Grid item xs={4}>
                {isMounted ? <ItemsSelect name={"id_curso_escolar"} keyValue={"curso_escolar"} register={register} errors={errors} defaultValue={datosColegio?.id_curso_escolar ?? ""} /> : false}
            </Grid>

            <Grid item xs={4}>
                {isMounted ? <ItemsSelect name={"id_curso_colegio"} keyValue={"curso_colegio"} register={register} errors={errors} defaultValue={datosColegio?.id_curso_colegio ?? ""} /> : false}
            </Grid>

            <Grid item xs={4}>
                <Input label="Letra" register={register} errors={errors} className="w-100" name={"num_curso"} defaultValue={datosColegio?.num_curso ?? ""} uppercase />
            </Grid>

            <Grid item xs={8}>

                {isMounted ?
                    <ItemsSelect 
                        name         = {"id_colegio"}
                        keyValue     = {"colegio"}
                        register     = {register} 
                        errors       = {errors}
                        defaultValue = {datosColegio?.id_colegio ?? ""}
                        searcheable /> : null}
            </Grid>

            <Grid item xs={4}>
                <Input label="Nota final" register={register} errors={errors} className="w-100" name={"nota_final"} defaultValue={datosColegio?.nota_final ? datosColegio.nota_final.toString().replace('.', ',') : ""} />
            </Grid>

            {hideButton ? null : 
                (<Grid item xs={12} justifyContent="end" display={"flex"}>
                    <LoadingButton onClick={e => handleSubmit(e)} color={"success"}>
                        Guardar datos del alumno en el colegio
                    </LoadingButton>
                </Grid>)
            }
        </Grid>
    )
}
