import styles from './styles.js'
import { Grid } from '@mui/material'
import { H3 } from 'components/texts'
import { NIÑO, ADULTO } from './constants'
import { RequestNivel } from 'objects/requests'
import React, { useEffect, useState } from 'react'
import { Checkbox } from 'components/forms/components'

/**
 * Formulario con los datos de un libro
 */
export default function DataFormLibroNiveles({ libro }) {

  const [niveles, setNiveles] = useState([])

  useEffect(() => {
    new RequestNivel().all({}, (res) => { setNiveles(res.niveles) }, (res) => { setNiveles([]) })
  }, [])

  const adulto = niveles.filter(item => item.edad == ADULTO)
  const niño   = niveles.filter(item => item.edad == NIÑO)

  /**
   * Render
   *
   * @returns {Component}
   */
  return (
      <Grid container>
          <Grid container item xs={12} xl={6} p={3}>
            <Grid item xs={12}><H3>Niveles para adultos: </H3></Grid>

            {adulto.map(item => (
              <Grid key={item.id} item xs={4}>
                <Checkbox 
                  name           = {item.valor} 
                  label          = {item.valor} 
                  defaultChecked = {libro?.niveles_libro.find(nivel_libro => nivel_libro.id_nivel == item.id) ? true : false} 
                  disabled />
              </Grid>))}
          </Grid>

          <Grid container item xs={12} xl={6} p={3} sx={styles.nivelesNiñoGrid}>
            <Grid item xs={12}><H3>Niveles para niños: </H3></Grid>

            {niño.map(item => (
              <Grid key={item.id} item xs={4}>
                <Checkbox 
                  name           = {item.valor} 
                  label          = {item.valor} 
                  defaultChecked = {libro?.niveles_libro.find(nivel_libro => nivel_libro.id_nivel == item.id) ? true : false} 
                  disabled />
              </Grid>))}
          </Grid>
      </Grid>
  )
}