import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import React, { Component } from 'react'
import { Input, Checkbox } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Esta es la plantilla para cuando se busque por los datos basicos del alumno
 */
export default class IncidenciaTemplate extends Component {
    
    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)
    }

    /**
     * Render
     *
     * @returns {Component}
     */
    render() {
        const { register, errors } = this.props
        return (
            <Grid container>
                <Grid container mt={2} mb={1}>
                    <Text fontWeight={'bold'}>Según datos de:</Text><br />
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Input label="Nombre" width={"100%"} name={"nombre"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={4}>
                        <Input label="Primer apellido" width={"100%"} name={"apellido1"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={4}>
                        <Input label="Segundo apellido" width={"100%"} name={"apellido2"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect 
                            label        = {"Año"}
                            name         = {"id_curso_escolar"}
                            keyValue     = {"curso_escolar"}
                            register     = {register} 
                            errors       = {errors}
                            wantToReset  = {this.props.reset} />
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect 
                            name         = {"id_tipo_incidencia"}
                            keyValue     = {"tipo_incidencia"}
                            register     = {register} 
                            errors       = {errors}
                            wantToReset  = {this.props.reset} />
                    </Grid>

                    <Grid item xs={4}>
                        <Checkbox name={"solucionada"} label={"Solucionada"} register={register} />
                    </Grid>
                </Grid>

            </Grid>
        )
    }
}
