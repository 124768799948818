import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import React, { Component } from 'react'
import { Input } from 'components/forms/components'

/**
 * Esta es la plantilla para cuando se busque por los datos basicos del alumno
 */
export default class AulaTemplate extends Component {
    
    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)
    }


    /**
     * ComponentDidUpdate
     */
    componentDidUpdate() {
    
           
    }

    //-------------------------------------------------------
    //-------------------- CLASS FUNCTIONS ------------------
    //-------------------------------------------------------

 

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    render() {
        const { register, errors } = this.props
        return (
            <Grid container>
                <Grid container mt={2} mb={1}>
                    <Text fontWeight={'bold'}>Según datos de:</Text><br />
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Input label="Denominación" width={"100%"} name={"denominacion"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Capacidad" width={"100%"} name={"capacidad"} register={register} errors={errors} uppercase/>
                    </Grid>

                </Grid>
            </Grid>
        )
    }
}
