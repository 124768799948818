import React, { useEffect, useRef, useState, useContext } from 'react'
import Requests from './Requests/Requests.js'
import { Box, Container, Paper, Tab, Tabs, Stack } from '@mui/material'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { EditHeader } from 'pages/logged/admin/components'
import { DataFormInscripcionAcademias, DataFormGrupoAcademias, PagoInscripcionAcademiaDataForm, LibroInscripcionAcademiaDataForm } from 'components/forms/display'
import { useForm } from 'react-hook-form'
import { validationSchema } from 'components/forms/display/academia/inscripciones/DataForm/GeneralDataForm/validation.js'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { TabContext } from '@mui/lab'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { COLOR } from 'theme/Colors.js'
import { useParams } from 'react-router'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { Text } from 'components/texts'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import SkeletonDataFormGeneral from 'components/forms/display/academia/inscripciones/DataForm/GeneralDataForm/skeleton'
import SkeletonDataFormGeneralPagos from 'components/forms/display/academia/inscripciones/DataForm/PagoDataForm/skeleton'
import SkeletonGroupData from 'components/forms/display/academia/inscripciones/DataForm/GroupData/SkeletonGroupData'
import SkeletonDataFormLibros from 'components/forms/display/academia/inscripciones/DataForm/LibroDataForm/SkeletonDataFormLibros'
import Statics from 'objects/common/Statics.js'


export default function Wrapper() {

  const navigationParams = useParams()

  const [inscripcion, setInscripcion] = useState(null)
  const [id_inscripcion, setIdInscripcion] = useState(navigationParams?.id_inscripcion)

  const { items } = useContext(EmpleadoListContext)

  useEffect(() => {
    const inscripcion = items.find(item => item?.id == id_inscripcion)
    if (items.length == 0 || !inscripcion) {
      new Requests().getInscripcion(id_inscripcion, setInscripcion, () => enqueueSnackbar(<Text>No se ha podido obtener la inscripción.</Text>, { variant: "error" }))
    } else {
      setInscripcion(inscripcion)
    }
  }, [id_inscripcion])

  return (
    <React.Fragment>
      <EditInscripcionesAcademia key={inscripcion?.id ?? 0} inscripcion={inscripcion} id_inscripcion={id_inscripcion} setIdInscripcion={setIdInscripcion} setInscripcion={setInscripcion} />
      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}

/**
 * Edición de una inscripción a academia
 */
function EditInscripcionesAcademia({ inscripcion, id_inscripcion, setInscripcion, setIdInscripcion }) {

  //HOOKS
  const { register, handleSubmit, formState: { errors }, getValues, setValue, control, clearErrors } = useForm({ resolver: yupResolver(validationSchema) })
  const navigate = useNavigate()

  //STATE
  const [isMounted, setIsMounted] = useState(false)
  const [tab, setTab] = useState("1")

  const [actividad, setActividad] = useState(inscripcion?.registro_actividad_id ?? null)
  const [cursoEscolar, setCursoEscolar] = useState(inscripcion?.curso_escolar_id ?? null)
  const [grupo, setGrupo] = useState(null)

  //REQUESTS
  const requests = new Requests()

  //REFS
  const headerRef = useRef()

  useEffect(() => {
    setIsMounted(true)
  }, []);


  const updateHeaderAndAlert = ({ message, variant = "error" }, loading = false) => {
    headerRef.current.button.setLoading(loading)
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }

  //-------------------------------------------------------
  //----------------------- REQUESTS ----------------------
  //-------------------------------------------------------

  /**
   * Hace la petición para crear una inscripcion
   * 
   * @param {Function} callback
   */
  const editInscripcionAcademia = (pagos) => {
    headerRef.current.button.setLoading(true)

    requests.editInscripcion(id_inscripcion, getValues, (res) => {
      if (res)
        enqueueSnackbar(<Text>Se ha guardado la inscripción correctamente.</Text>, { variant: "success" })

      const promisePagos = new Promise((resolve, reject) => {
        requests.createPagos(res.id, pagos, resolve, reject);
      })

      const promiseLibro = new Promise((resolve, reject) => {
        requests.crudLibro(res, getValues, resolve, reject)
      })

      const promiseLibroAsociado = new Promise((resolve, reject) => {
        requests.crudLibroAsociado(res, getValues, resolve, reject)
      })

      Promise.allSettled([promisePagos, promiseLibro, promiseLibroAsociado]).then((values) => {
        for (const fail of values.filter(item => item?.status == "rejected")) {
          enqueueSnackbar(<Text>{fail.reason}</Text>, { variant: "error" })
        }
        headerRef.current.button.setLoading(false)
      })

    }, (err) => {
      const message = Statics.errorArrayResponseToString(err)
      updateHeaderAndAlert({ message })

      headerRef.current.button.setLoading(false)
    })
  }

  //-------------------------------------------------------
  //------------------- FUNCTIONS ------------------
  //-------------------------------------------------------

  const updateId = (id) => {
    navigate(`../academias/inscripciones/${id}/editar`);
    setIdInscripcion(id);
  }

  const handleSubmitCustom = async (e) => {
    let handleCallbacks = handleSubmit(() => editInscripcionAcademia(getValues("pagos")))
    handleCallbacks(e)
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <Stack direction="row" justifyContent={"space-between"} alignItems="center" marginX={8}>

          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/", label: "Inicio" },
            { to: "./../", label: "inscripciones" },
            { to: "./", label: "Editar inscripción academia", actualItem: true },
          ]} />

          <MoveButtons
            actualItemId={id_inscripcion}
            beforeFetchCallback={() => setInscripcion(null)}
            onUpdateActualItem={updateId} />
        </Stack>

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <EditHeader
            ref={headerRef}
            item={inscripcion}
            title={"Editar inscripción para la academia"}
            handleSubmit={handleSubmitCustom} />

          <Line className={"my-5"} />

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
              <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS DE LA INSCRIPCIÓN</Text>} value={"1"} />
                <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>DATOS DEL GRUPO</Text>} value={"2"} />
                <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>DATOS DE LOS PAGOS</Text>} value={"3"} />
                <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>LIBROS</Text>} value={"4"} />

              </Tabs>
            </Box>

            <TabPanel value={"1"}>
              {inscripcion ?
                <DataFormInscripcionAcademias
                  inscripcion={inscripcion}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  isMounted={isMounted}
                  control={control}
                  onChangeCursoEscolar={setCursoEscolar}
                  onChangeActividad={setActividad}
                /> : <SkeletonDataFormGeneral />}
            </TabPanel>

            <TabPanel value={"2"}>
              {inscripcion ?
                <DataFormGrupoAcademias
                  inscripcion={inscripcion}
                  onGrupoChange={setGrupo}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  isMounted={isMounted}
                  control={control}
                  actividad={actividad}
                  cursoEscolar={cursoEscolar}
                /> : <SkeletonGroupData />}
            </TabPanel>

            <TabPanel value={"3"}>
              {inscripcion ? <PagoInscripcionAcademiaDataForm
                inscripcion={inscripcion}
                register={register}
                setValue={setValue}
                errors={errors}
                isMounted={isMounted}
                control={control}
              /> : <SkeletonDataFormGeneralPagos />}
            </TabPanel>

            <TabPanel value={"4"}>
              {inscripcion ? <LibroInscripcionAcademiaDataForm
                inscripcion={inscripcion}
                register={register}
                setValue={setValue}
                grupo={grupo}
                errors={errors}
                isMounted={isMounted}
                control={control} /> : <SkeletonDataFormLibros />}
            </TabPanel>
          </TabContext>
        </Paper>

        <SnackbarProvider autoHideDuration={3000} />
      </form>
    </Container>
  )
}