import { H3 } from 'components/texts'
import { Grid, Stack } from '@mui/material'
import { Line } from 'components/data_display'
import React, { useRef, useState } from 'react'
import ProgressSheetTable from './parts/ProgressSheetTable'
import { Checkbox, Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Formulario con los datos de los idiomas del grupo
 */
export default function IdiomaGrupoColegioDataForm({ grupo, register, errors, isMounted = false, wantToReset, setValue }) {

    const [libroAsociado, setLibroAsociado] = useState(grupo?.libro?.libro_asociado ?? null)

    const libroAsociadoCheck = useRef()
    
    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container py={5}>
            <Grid container spacing={2} px={5}>
                <Grid item xs={6}>
                    {isMounted ? <ItemsSelect
                                    label        = {"Libro principal"}
                                    name         = {"id_libro"}
                                    keyValue     = {"libro"}
                                    register     = {register}
                                    errors       = {errors}
                                    wantToReset  = {wantToReset} 
                                    defaultValue = {grupo?.id_libro ?? ""}
                                    onChange     = {(event, libro) => {
                                        if(libro) setLibroAsociado(libro.libro_asociado ?? null)
                                        if(!libro || !libro.libro_asociado || !libroAsociadoCheck.current.getChecked()) libroAsociadoCheck.current.setChecked(false)
                                    }}
                                    searcheable /> : null}
                </Grid>
                <Grid item xs={6}>
                    <Input key={Math.random()} label={"Libro asociado"} defaultValue={libroAsociado?.nombre ?? ""} disabled={true} className={"w-100"} />
                </Grid>
            </Grid>

            <Grid container spacing={2} px={5}>
                <Grid item xs={6} />

                <Grid item xs={6}>
                    <Checkbox 
                        ref            = {libroAsociadoCheck} 
                        name           = {"usar_libro_asociado"} 
                        label          = {"Usa libro asociado"} 
                        setValue       = {setValue}
                        register       = {register} 
                        defaultChecked = {grupo?.usar_libro_asociado ? true : false}
                        disabled       = {libroAsociado ? false : true} />
                </Grid>
            </Grid>
            
            <Grid container my={3} px={5}>
                <Line />
            </Grid>

            <Grid container px={5}>
                <Grid item xs={12}>
                    <Stack>
                        <H3>Progress sheet</H3>
                        <Checkbox name={"usar_progress_sheet"} label={"Se usa Progress Sheet"} register={register} defaultChecked={grupo?.usar_progress_sheet ? true : false}/>
                    </Stack>
                </Grid>

                <ProgressSheetTable />
            </Grid>
        </Grid>
    )
}
