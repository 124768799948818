import React from 'react'
import { Grid } from "@mui/material"
import { Datagrid } from 'components/data_display'
import Statics from 'objects/common/Statics'

//params.row.profesor_actual.empleado
const columns = [
    { field: "anio", headerName: "Año", maxWidth: 125, flex: 1, valueGetter: (params) => { { return params?.row?.curso_escolar?.valor ?? 'No definido' } } },
    { field: "Grupo", headerName: "Grupo", flex: 1, maxWidth: 125, valueGetter: (params) => { { return params?.row?.grupo?.clave ?? 'No definido' } } },
    { field: "fecha_baja", headerName: "F. Baja", flex: 1, maxWidth: 125, valueGetter: (params) => { { return params?.row?.fecha_baja ?? 'No definido' } } },
    { field: "libro", headerName: "Libro", flex: 1, maxWidth: 125, valueGetter: (params) => { { return params?.row?.libro ?? 'No definido' } } },
    { field: "profesor", headerName: "Profesor", flex: 1, maxWidth: 125, valueGetter: (params) => { { return params?.row?.profesor_actual?.empleado?.nombre ?? 'No definido' } } },
    { field: "nota", headerName: "Nota", flex: 1, maxWidth: 100, valueGetter: (params) => { { return params?.row?.profesor_actual?.empleado?.nombre ?? 'No definido' } } },
    { field: "colegio", headerName: "Colegio", maxWidth: 160, flex: 1, valueGetter: (params) => { { return params?.row?.colegio?.nombre ?? 'No definido' } } },
    { field: "libroColegio", flex: 1, headerName: "Libro en Colegio", maxWidth: 150, valueGetter: (params) => { { return params?.row?.libroColegio ?? 'No definido' } } },
    { field: "notaColegio", flex: 1, headerName: "Nota en Colegio", maxWidth: 180, valueGetter: (params) => { { return params?.row?.nota_cole ?? 'No definido' } } },
    {
        field: "comentarioProfesorAP", flex: 1, headerName: "Comentario Profesor A/P",
        maxWidth: 180, valueGetter: (params) => { { return params?.row?.comentarios_profesor ?? 'No definido' } }
    },
]

export default function InscripcionesColegioTemplate({ historico = [] }) {

    return (
        <Grid container p={3}>
            <Grid item xs={12}>
                <Datagrid
                    heightWithContent={450}
                    rows={historico}
                    height={800}
                    onRowDoubleClick={({ row }) => Statics.openNewTab(`../../empleado/colegios/inscripciones/${row.id}`)}
                    columns={columns} />
            </Grid>
        </Grid>
    )
}