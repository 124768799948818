import React, { useEffect } from 'react'
import { Text } from '../../../../texts'
import { Select } from '../../../components'
import { AlumnoTemplate, DomicilioTemplate} from './parts'
import Statics from '../../../../../objects/common/Statics';
import { RequestAlumno } from '../../../../../objects/requests'
import Grid from '@mui/material/Grid';
//VALIDACION FORMULARIO
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import validationSchema from "./validation"
import { SearchButtons } from '../../components';


const ALUMNOS_VALUE = "alumnos"
const itemsSearch = [{ value: ALUMNOS_VALUE, label: "Alumnos"}, {value: "domicilio", label: "Domicilio" }]
const itemsOrder  = [{ value: '{"apellido1": "ASC", "apellido2": "ASC", "nombre": "ASC"}', label: "Apellidos, Nombre" }, { value: '{"id_tipo_documento": "ASC", "numero_documento": "ASC"}', label: "Documento (Tipo, Número)" }]

let prepareParamsGlobal = null

//Formulario de busqueda de alumnos
export default function SearchFormAlumno({ onChangeSearchIndex, onLoadingCallback, searchCallback, relations }) {

    //Form
	const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) })

    //State
    const [searchIndex,    setSearchIndex]    = React.useState(0);
    const [resetFormState, setResetFormState] = React.useState(false);

    useEffect(() => {
        if(resetFormState == true) {
            resetForm(reset)
            setResetFormState(false)
        }
    })

    //-------------------------------------------------------
    //---------------------- FUNCTIONS ----------------------
    //-------------------------------------------------------

    /**
     * Controlla cuando se hace un cambio en el select de busqueda
     * 
     * @param {Object} event
     */
    const handleChange = (event) => {
        let index = event.target.value == ALUMNOS_VALUE ? 0 : 1

        setSearchIndex(index)
        setResetFormState(true)

        if(onChangeSearchIndex)
            onChangeSearchIndex(index)
    };

      
    /**
     * Prepara los parametros que se van a usar
     * 
     * @param {Int} page
     */
    const prepareParams = (page) => {
        const params     = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()));
        params.relations = relations ?? 'sexo,provincia,tipo_documento,tipo_via'
        params.page      = page

        return params
    }

    prepareParamsGlobal = prepareParams

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    return (
        <Grid container p={2}>
            <Grid container>
                <Grid item xs={8} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Buscar por:</Text><br />
                    <Select width={"60%"} items={itemsSearch} defaultValue={ALUMNOS_VALUE} onChange={handleChange}/>
                </Grid>

                <Grid item xs={4} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Ordenar resultados por:</Text><br />
                    <Select width={"60%"} name={"order"} items={itemsOrder} defaultValue={itemsOrder[0].value} register={register} errors={errors}/>
                </Grid>
            </Grid>

            {/* INICIO TEMPLATE */}
            <Grid container className={searchIndex == 0 ? "" : "d-none"}>
                <AlumnoTemplate reset={resetFormState} register={register} errors={errors}/>
            </Grid>

            <Grid container className={searchIndex == 1 ? "" : "d-none"}>
                <DomicilioTemplate reset={resetFormState} register={register} errors={errors}/>
            </Grid>
            {/* FIN TEMPLATE */}

            <SearchButtons searchText={"Buscar alumnos"} onResetClick={() => {setResetFormState(true)}} onSubmitClick={handleSubmit(() => {search(1, onLoadingCallback, searchCallback)})}/>
        </Grid>
    )
}



/**
 * Hace la busqueda de alumnos
 * 
 * @param {Int}  page
 * @param {Function} onLoadingCallback
 * @param {Function} searchCallback
 */
export const search = (page = 1, onLoadingCallback, searchCallback) => {
    //Callback para controlar cuando esta cargando
    if(onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal(page)
    new RequestAlumno().search(params, (res) => {
        if(searchCallback)
            searchCallback(res.alumnos.data, res.alumnos.total, page, params)
    }, () => {
        searchCallback([], 0, page, params)
    })
}


/**
 * Resetea todo el formulario
 * 
 * @param {Function} reset
 */ 
const resetForm = (reset) => {
    reset({
        nombre: "",
        apellido1: "",
        apellido2: "",
        email: "",
        numero_documento: "",
        nombre_via: "",
        municipio: "",
        cod_postal: "",
    })
}