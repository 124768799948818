import React, { Component } from 'react'
import Skeleton from '@mui/material/Skeleton';

/**
 * SKELETON
 */
export default class SkeletonTutoria extends Component {

  //-------------------------------------------------------
  //-------------------- CLASS FUNCTIONS ------------------
  //-------------------------------------------------------


  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
        <div className='container-fluid px-10'>
            <div className="row">
                <div className='col-md-2'>
                    <Skeleton variant="rounded" width={"80%"} height={"100%"}/>
                </div>
                <div className='col-md-5 d-flex flex-column justify-content-center'>
                    <Skeleton variant="rounded" width={"100%"} height={40} className="my-2"/>
                    <Skeleton variant="rounded" width={"100%"} height={40} className="my-2"/>
                    <Skeleton variant="rounded" width={"100%"} height={40} className="my-2"/>
                </div>
                <div className='col-md-5 d-flex flex-column justify-content-center'>
                    <Skeleton variant="rounded" width={"100%"} height={40} className="my-2"/>
                    <Skeleton variant="rounded" width={"100%"} height={40} className="my-2"/>
                    <Skeleton variant="rounded" width={"100%"} height={40} className="my-2"/>
                </div>
            </div>

            <div className="row mt-10">
                <div className='col-md-12'>
                    <Skeleton variant="text" sx={{ fontSize: '1.5em' }} width={"30%"}/>
                </div>
                
                <div className='col-md-6 d-flex flex-column'>
                    <Skeleton variant="rounded" width={"100%"} height={40} className="my-2"/>
                    <Skeleton variant="rounded" width={"100%"} height={40} className="my-2"/>
                    <Skeleton variant="rounded" width={"100%"} height={40} className="my-2"/>
                </div>

                <div className='col-md-6 d-flex flex-column'>
                    <Skeleton variant="rounded" width={"100%"} height={40} className="my-2"/>
                    <Skeleton variant="rounded" width={"100%"} height={40} className="my-2"/>
                </div>
            </div>
        </div>
    )
  }
}
