

import './style.css'
import React from 'react'
import { v4 as uuid } from 'uuid'
import TableColumn from '../TableColumn'
import { H3, Text } from 'components/texts'
import Statics from 'objects/common/Statics'

export default function LibrosPedidos({ librosPedidos, cursoEscolar }) {
    const librosToRender = cursoEscolar ? librosPedidos.filter(item => item.id_curso_escolar == cursoEscolar && item.deleted != true) ?? [] : []
    const sumPedidas     = Statics.sumParamsOfObjectArray(librosToRender, "cantidad_pedida")
    const sumLlegadas    = Statics.sumParamsOfObjectArray(librosToRender, "cantidad_llegada")

    return (
        <React.Fragment>
            <H3>Libros pedidos: </H3>
            <table className='libros-pedidos-table'>
                <thead>
                    <tr>
                        <th><Text fontWeight={"bold"}>Fecha</Text></th>
                        <th><Text fontWeight={"bold"}>Unidades pedidas</Text></th>
                        <th><Text fontWeight={"bold"}>Unidades llegadas</Text></th>
                    </tr>
                </thead>
                <tbody className='no-border'>
                    {librosToRender.map((item) => (
                        <tr key={uuid()}>
                            <TableColumn defaultValue={item.fecha} type={"date"} disabled/>

                            <TableColumn defaultValue={item.cantidad_pedida} disabled/>

                            <TableColumn defaultValue={item.cantidad_llegada} disabled/>
                        </tr>
                    ))}

                    <tr className="total-unidades">
                        <td className='bg-white'><Text fontWeight="bold">Total unidades</Text></td>
                        <td><Text>{sumPedidas}</Text></td>
                        <td><Text>{sumLlegadas}</Text></td>
                    </tr>
                </tbody>
            </table>

        </React.Fragment>
    ) 
}