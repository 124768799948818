import React from 'react'
import { Grid } from '@mui/material'
import Statics from 'objects/common/Statics'
import { Checkbox, Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Formulario con las actividades del colegio
 */
export default function DataFormActividadesColegio({ actividad, register, errors, isMounted = false, wantToReset = false }) {

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container mt={2} px={4} spacing={2}>
            <Grid item xs={8}>
                <Input label="Nombre abreviado" register={register} errors={errors} className="w-100" name={"nombre"} defaultValue={actividad?.nombre ?? ""} uppercase />
            </Grid>

            <Grid item xs={4}>
                <Checkbox name={"intensivo"} label={"Curso intensivo"} register={register} defaultChecked={actividad?.intensivo ? true : false} />
            </Grid>

            <Grid item xs={6}>
                {isMounted ? <ItemsSelect name={"id_tipo_actividad"} keyValue={"tipo_actividad"} register={register} errors={errors} defaultValue={actividad?.tipo_actividad_id ?? ""} wantToReset={wantToReset} /> : false}
            </Grid>

            <Grid item xs={6}>
                {isMounted ? <ItemsSelect name={"id_actividad"} keyValue={"actividad"} register={register} errors={errors} defaultValue={actividad?.actividad_id ?? ""} wantToReset={wantToReset} /> : false}
            </Grid>

            <Grid item xs={6}>
                {isMounted ? <ItemsSelect name={"id_modalidad_cursos"} keyValue={"modalidad_cursos"} register={register} errors={errors} defaultValue={actividad?.modalidad_cursos_id ?? ""} wantToReset={wantToReset} /> : false}
            </Grid>

            <Grid item xs={6}>
                {isMounted ? <ItemsSelect
                    label={"Colegio donde se imparte"}
                    name={"id_colegio"}
                    keyValue={"colegio"}
                    register={register}
                    errors={errors}
                    defaultValue={actividad?.entity_id ?? ""}
                    wantToReset={wantToReset}
                    searcheable /> : false}
            </Grid>

            <Grid item xs={4}>
                <Input label="Precio Act." register={register} errors={errors} className="w-100" name={"precio"} defaultValue={actividad?.precio ? Statics.changeDotAndComma(actividad.precio) : ""} uppercase />
            </Grid>

            <Grid item xs={4}>
                <Input label="Matrícula" register={register} errors={errors} className="w-100" name={"matricula"} defaultValue={actividad?.matricula ? Statics.changeDotAndComma(actividad.matricula) : ""} uppercase />
            </Grid>

            <Grid item xs={4}>
                <Input label="Precio Junio" register={register} errors={errors} className="w-100" name={"precio_junio"} defaultValue={actividad?.precio_junio ? Statics.changeDotAndComma(actividad.precio_junio) : ""} uppercase />
            </Grid>
        </Grid>
    )
}
