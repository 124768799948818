import React from 'react'
import { Button } from 'components/forms/components'
import { Edit as EditIcon } from '@mui/icons-material'
import { Grid, Stack } from '@mui/material'
import { H3, Text } from 'components/texts'
import { Line } from 'components/data_display'
import { Checkbox } from 'components/forms/components'
import ProgressSheetTable from './parts/ProgressSheetTable'
import SkeletonIdiomasData from './skeletonIdiomasData'
import { Link } from 'components/data_display'


export default function IdiomasData({ grupo }) {
    return (
        <Grid container px={5}>
            {!grupo ?
                <SkeletonIdiomasData />
                : (
                    <>
                        <Grid container py={5}>
                            <Grid container spacing={2} px={5}>
                                <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                    <H3>Datos de los idiomas:</H3>

                                    <Link to="editar">
                                        <Button variant={"outlined"} startIcon={<EditIcon />}>
                                            Editar
                                        </Button>
                                    </Link>
                                </Grid>

                                <Grid item xs={6}>
                                    <Stack>
                                        <Text fontWeight={"bold"}>Libro principal:</Text>
                                        <Text>{grupo?.libro?.nombre ?? ""}</Text>
                                    </Stack>

                                </Grid>
                                <Grid item xs={6}>

                                    <Stack>
                                        <Text fontWeight={"bold"}>Libro asociado:</Text>
                                        <Text>{grupo?.libro?.libro_asociado ?? ""}</Text>
                                        <Checkbox disabled name={"usar_libro_asociados"} label={"Usa libro asociado"} className="mt-2" defaultChecked={grupo?.usar_libro_asociado ? true : false} />
                                    </Stack>

                                </Grid>
                            </Grid>

                            <Grid container my={3} px={5}>
                                <Line />
                            </Grid>

                            <Grid container px={5}>

                                <Grid item xs={12}>
                                    <Stack>
                                        <H3>Progress sheet</H3>
                                        <Checkbox disabled name={"usar_progress_sheet"} label={"Se usa Progress Sheet"} defaultChecked={grupo?.usar_progress_sheet ? true : false} />
                                    </Stack>
                                </Grid>

                                <ProgressSheetTable />
                            </Grid>
                        </Grid>
                    </>
                )
            }
        </Grid>
    )
}


