
export const months  = [
    {value: 11, label: 'Nov'},
    {value: 12, label: 'Dic'},
    {value: 1,  label: 'Ene'},
    {value: 2,  label: 'Feb'},
    {value: 3,  label: 'Mar'},
    {value: 4,  label: 'Abr'},
    {value: 5,  label: 'May'},
    {value: 6,  label: 'Jun'},
];