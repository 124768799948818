import { CircularProgress } from "@mui/material";
import React from "react";

/**
 * LoadingScreen, pantalla de carga para el fallback del suspense
 */
export default function LoadingScreen() {

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <div className="d-flex justify-content-center align-items-center">
            <CircularProgress color="standard" />
        </div>
    );
}
