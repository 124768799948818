import { H3 } from 'components/texts'
import { useSelector } from 'react-redux'
import { Box, Container, Paper } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import React, { useState, useEffect, useContext } from 'react'
import { ListTableHeader } from 'pages/logged/admin/components'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { ImportCLEmpleadosButton, ModalRolesPermisos } from './parts'
import { Accordion, BreadcrumbsList, Datagrid } from 'components/data_display'
import { basicColumns, columnsDatosPersonales, columnsDomicilio } from './columns.js'
import SearchFormEmpleado, { search } from 'components/forms/display/empleado/SearchForm'

/**
 * Listado de los empleados
 */
export default function ListadoEmpleados() {
  const roles = useSelector((state) => state.userState.roles)

  const basicColumnsArray = basicColumns(roles)

  const [data,         setData]          = useState({data: [], rowCount: 0})
  const [columns,      setColumns]       = useState(basicColumnsArray.concat(columnsDatosPersonales))
  const [loadingTable, setLoadingTable]  = useState(false)
  const [params,       setParams]        = useState(null)

  const { update, resetContextState } = useContext(EmpleadoListContext)

  useEffect(() => {
    setLoadingTable(false)
  }, [data])

  useEffect(resetContextState, [])

  /**
   * Callback para actualizar la tabla una vez hecha la petición
   * 
   * @param {Array} data 
   * @param {Int}   rowCount 
   */
  const updateTable = (data, rowCount, page, params) => {
    setData({data, rowCount})
    update(rowCount, data, params, page)
    setParams(params)
  }
  
  /**
   * Callback para cuando se cambia el search
   *
   * @param {Int} index 
   */
  const onChangeSearchIndex = (index) => {
    setColumns(basicColumnsArray.concat(index == 0 ? columnsDatosPersonales : columnsDomicilio))
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { to: "/",  label: "Inicio" },
        { to: "./", label: "Empleados", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar empleados</H3></Box>}>
        <SearchFormEmpleado
          searchCallback      = {updateTable}
          onChangeSearchIndex = {onChangeSearchIndex}
          onLoadingCallback   = {setLoadingTable} />
      </Accordion>

      <Paper elevation={2} sx={{mx: 8, my: 2, p: 5}}>
        <ListTableHeader 
          rowCount     = {data.rowCount} 
          params       = {params} 
          keyValue     = {"empleados"} 
          extraButtons = {<ImportCLEmpleadosButton />} />

        <Datagrid
          rows         = {data.data}
          columns      = {columns}
          loading      = {loadingTable}
          rowCount     = {data.rowCount}
          onPageChange = {(page) => { search(page, () => setLoadingTable(true), updateTable) }}
          serverSide />
      </Paper>
    </Container>
  )
}
