import Skeleton from './skeleton'
import React, { Component } from 'react'
import { H3, Text } from 'components/texts'
import { Alert, Grid, Stack } from '@mui/material'

/**
 * Informacion de la domiciliacion bancaria del alumno
 */
export default class DomiciliacionData extends Component {

  constructor(props) {
    super(props)

    this.state = {
      domiciliacionBancaria: this.props.domiciliacionBancaria ?? null
    }
  }


  /**
   * ComponentDidUpdate
   * 
   * @param {Object} prevProps 
   */
  componentDidUpdate(prevProps) {
    if(this.props.domiciliacionBancaria != prevProps.domiciliacionBancaria)
        this.setState({domiciliacionBancaria: this.props.domiciliacionBancaria})
  }

  //-------------------------------------------------------
  //-------------------- CLASS FUNCTIONS ------------------
  //-------------------------------------------------------


  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    let { domiciliacionBancaria } = this.state
    return (
        <Grid container>
            {!domiciliacionBancaria ? <Skeleton /> : (
                <>
                    {domiciliacionBancaria.length == 0 ? <Alert severity="info" className='w-100'><H3 className="standard">Este alumno no tiene ninguna domiciliación bancaria.</H3></Alert> : null }
                    
                    {domiciliacionBancaria[0] ? (
                      <>
                        <Grid item xs={12}>
                          <H3>Datos de pago:</H3>
                        </Grid>

                        <Grid item xs={3}>
                          <Stack>
                            <Text fontWeight={"bold"}>Titular:</Text>
                            <Text fontWeight={"bold"}>Cuenta bancaria:</Text>
                            <Text fontWeight={"bold"}>IBAN:</Text>
                            <Text fontWeight={"bold"}>Número de control:</Text>
                          </Stack>
                        </Grid>

                        <Grid item xs={3}>
                          <Stack>
                            <Text>{domiciliacionBancaria[0].nombre_deudor ?? "Sin rellenar"}</Text>
                            <Text>{domiciliacionBancaria[0].cuenta1 ?? domiciliacionBancaria[0].cuenta2 ?? domiciliacionBancaria[0].cuenta3 ?? domiciliacionBancaria[0].cuenta4 ?? domiciliacionBancaria[0].cuenta5 ?? "Sin rellenar"}</Text>
                            <Text>{domiciliacionBancaria[0].iban ?? "Sin rellenar"}</Text>
                            <Text>{domiciliacionBancaria[0].numero_control ?? "Sin rellenar"}</Text>
                          </Stack>
                        </Grid>

                        <Grid item xs={3}>
                          <Stack>
                            <Text fontWeight={"bold"}>Fecha último recibo:</Text>
                            <Text fontWeight={"bold"}>Fecha mandato:</Text>
                          </Stack>
                        </Grid>

                        <Grid item xs={3}>
                          <Stack>
                            <Text>{domiciliacionBancaria[0].ultimo_recibo ? domiciliacionBancaria[0].ultimo_recibo.split("-").reverse().join("/") : ""}</Text>
                            <Text>{domiciliacionBancaria[0].fecha_mandato ? domiciliacionBancaria[0].fecha_mandato.split("-").reverse().join("/") : ""}</Text>
                          </Stack>
                        </Grid>
                      </>
                    ) : null}
                </>
            )}
        </Grid>
    )
  }
}
