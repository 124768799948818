import { Text } from 'components/texts'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { useParams, useNavigate } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangesContext } from 'contexts/ChangesContext'
import { EditHeader } from 'pages/logged/admin/components'
import { Box, Container, Paper, Stack } from '@mui/material'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import React, { useEffect, useRef, useState, useContext } from 'react'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { validationSchema } from 'components/forms/display/alumno/DataForm/validation.js'
import { DataFormAlumno, DataDomiciliacionBancariaFormAlumno } from 'components/forms/display'
import AlumnoDataSkeleton from 'pages/logged/admin/alumnos/alumno/single/parts/AlumnoData/skeleton'
import AlumnoDomiciliacionDataSkeleton from 'pages/logged/admin/alumnos/alumno/single/parts/DomiciliacionData/skeleton'

export default function Wrapper() {
  const navigationParams = useParams()

  const { items, updateItem } = useContext(EmpleadoListContext)

  const [alumno, setAlumno] = useState(null)
  const [id_alumno, setIdAlumno] = useState(navigationParams.id_alumno)

  const updateAlert = (message, variant = "error") => {
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }

  const getAlumno = (getFromServer = false) => {
    const item = items.find(item => item?.id == id_alumno)
    if (items.length == 0 || !item || getFromServer) {
      new Requests().get(id_alumno, (alumno) => {
        setAlumno(alumno)
        updateItem(alumno)
      }, () => updateAlert("No se ha podido recuperar los datos del alumno."))
    } else {
      setAlumno(item)
    }
  }

  useEffect(getAlumno, [id_alumno])

  return (
    <React.Fragment>
      <EditAlumnos key={alumno?.id ?? 0} alumno={alumno} id_alumno={id_alumno} setIdAlumno={setIdAlumno} setAlumno={setAlumno} updateAlert={updateAlert} onSaveFinish={() => getAlumno(true)} />

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )

}

function EditAlumnos({ alumno, id_alumno, setIdAlumno, setAlumno, updateAlert, onSaveFinish }) {
  const navigate = useNavigate()

  const { updateChanges } = useContext(ChangesContext)

  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) })

  const headerRef = useRef()

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])

  /**
   * Controla el envío del formulario
   */
  const onSubmitPress = () => {
    editAlumno(editDatosBancarios)
  }

  const updateHeaderAndAlert = ({ message, severity = "error" }, loading = false) => {
    headerRef.current.button.setLoading(loading)
    updateAlert(message, severity)
  }

  /**
   * Hace la petición para editar los datos basicos del alumno
   * 
   * @param {Function} callback
   */
  const editAlumno = (callback) => {
    headerRef.current.button.setLoading(true)

    new Requests().editAlumno(id_alumno, getValues, callback, (res) => {
      updateHeaderAndAlert({ message: res })
    })
  }


  /**
   * Hace la petición para editar los datos bancarios del alumno
   */
  const editDatosBancarios = () => {
    new Requests().editDatosBancarios(id_alumno, getValues, () => {
      updateHeaderAndAlert({ message: "Se ha actualizado el alumno.", severity: "success" })
      reset({}, { keepValues: true })
      updateChanges(false)
      onSaveFinish()
    }, (res) => {
      updateHeaderAndAlert({ message: res })
    })
  }

  const updateIdAlumno = (id) => {
    navigate(`../alumnos/${id}/editar`)
    setIdAlumno(id)
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">

        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/", label: "Inicio" },
            { to: "./../../", label: "Alumnos" },
            { to: "./../", label: "Alumno #" + (id_alumno ?? "0000") },
            { to: "./", label: "Editar alumno", actualItem: true },
          ]} />

          <MoveButtons
            actualItemId={id_alumno}
            beforeFetchCallback={() => setAlumno(null)}
            onUpdateActualItem={updateIdAlumno} />
        </Stack>

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <EditHeader
            ref={headerRef}
            title={"Editar alumno"}
            item={alumno}
            handleSubmit={handleSubmit(onSubmitPress)} />

          <Line className={"mb-5"} />
          
          {alumno ? <DataFormAlumno alumno={alumno} register={register} errors={errors} /> : <Box px={5}><AlumnoDataSkeleton /></Box>}
        </Paper>

        <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
          {alumno ? <DataDomiciliacionBancariaFormAlumno domiciliacion_bancaria={alumno?.domiciliacion_bancaria?.[0] ?? null} register={register} errors={errors} /> : <AlumnoDomiciliacionDataSkeleton />}
        </Paper>

      </form>
    </Container>
  )
}