import { COLOR } from 'theme/Colors'
import { columns } from './columns.js'
import React, { useState, useContext, useEffect } from 'react'
import { H2, H3 } from 'components/texts'
import { Button } from 'components/forms/components'
import { Box, Container, Paper } from '@mui/material'
import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material'
import { Accordion, BreadcrumbsList, Datagrid, Link } from 'components/data_display'
import SearchFormAula, { search } from 'components/forms/display/academia/aula/SearchForm'
import { EmpleadoListContext } from 'contexts/employee/ListContext'

export default function ListadoAulasAcademia() {
  const [data, setData] = useState({ data: [], rowCount: 0 })
  const [loadingTable, setLoadingTable] = useState(false)

  const { update, resetContextState } = useContext(EmpleadoListContext)

  useEffect(() => {
    setLoadingTable(false)
  }, [data])

  useEffect(resetContextState, [])

  /**
   * Callback para actualizar la tabla una vez hecha la petición
   * 
   * @param {Array} data 
   * @param {Int}   rowCount 
   */
  const updateTable = (data, rowCount, page, params) => {
    setData({ data, rowCount })
    update(rowCount, data, params, page)
  }
  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { to: "/", label: "Inicio" },
        { to: "../", label: "Academias" },
        { to: "./", label: "Aulas", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar Aulas</H3></Box>}>
        <SearchFormAula
          searchCallback={updateTable}
          onLoadingCallback={setLoadingTable} />
      </Accordion>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <Box display={"flex"} justifyContent={"space-between"} mb={5}>
          <H2>Listado de Aulas</H2>

          <Link to={"./crear"}>
            <Button color={'success'} startIcon={<AddIcon style={{ color: COLOR.white.main }} />}>
              Añadir nueva aula
            </Button>
          </Link>
        </Box>

        <Datagrid
          rows={data.data}
          columns={columns}
          loading={loadingTable}
          rowCount={data.rowCount}
          onPageChange={(page) => {
            search(page, () => setLoadingTable(true), updateTable)
          }}
          serverSide />
      </Paper>
    </Container>
  )
}