import React from 'react'
import Skeleton from './skeleton'
import { Text } from 'components/texts'
import { ASSETS } from 'constants/Assets'
import Statics from 'objects/common/Statics'
import { Line } from 'components/data_display'
import { Grid, Stack, Avatar } from '@mui/material'
import { EmpleadoBasicItem } from 'components/data_display/users'

export default function GrupoData({ grupo }) {
	return (
		<Grid container mt={2} p={5}>
			{grupo ? (
				<React.Fragment>
					<Grid container mt={2} alignItems={"center"}>
						<Grid item xs={3}>
							<Avatar
								alt = {"Foto de: " + (grupo.clave ?? "")}
								src = {`${ASSETS.images + (grupo.image ?? 'private/grupo_colegio/perfiles/0/default.png')}`}
								sx  = {{ width: "80%", height: "20%", borderRadius: 2}}/>
						</Grid> 

						<Grid item xs={9}>
							<Grid item container spacing={2}>
								<Grid item xs={4}>
									<Stack>
										<Text fontWeight={"bold"}>Edad del grupo</Text>
										<Text>{grupo.edadGrupo ?? "Sin especificar"}</Text>
									</Stack>
								</Grid> 

								<Grid item xs={4}>
									<Stack>
										<Text fontWeight={"bold"}>Clave del grupo</Text>
										<Text>{grupo.clave ?? "Sin especificar"}</Text>
									</Stack>
								</Grid>

								<Grid item xs={4}>
									<Stack>
										<Text fontWeight={"bold"}>Año</Text>
										<Text>{grupo.curso_escolar?.valor ?? "Sin especificar"}</Text>
									</Stack>
								</Grid>

								<Grid item xs={4}>
									<Stack>
										<Text fontWeight={"bold"}>Colegio</Text>
										<Text>{grupo.colegio?.nombre ?? "Sin especificar"}</Text>
									</Stack>
								</Grid>

								<Grid item xs={4}>
									<Stack>
										<Text fontWeight={"bold"}>Actividad</Text>
										<Text>{grupo.registro_actividad?.nombre ?? "Sin especificar"}</Text>
									</Stack>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid container spacing={2} mt={1}>
						<Grid item xs={3}>
							<Stack>
								<Text fontWeight={"bold"}>Horario</Text>
								<Text>{grupo.descripcion_horario?.horario_formatted ?? "Sin especificar"}</Text>
							</Stack>
						</Grid>

						<Grid item xs={3}>
							<Stack>
								<Text fontWeight={"bold"}>Nivel Grupo</Text>
								<Text>{grupo.nivel?.valor ?? "Sin especificar"}</Text>
							</Stack>
						</Grid>

						<Grid item xs={3}>
							<Stack>
								<Text fontWeight={"bold"}>Curso escolar</Text>
								<Text>{grupo.curso_escolar_string ?? "Sin especificar"}</Text>
							</Stack>
						</Grid>

						<Grid item xs={3}>
							<Stack>
								<Text fontWeight={"bold"}>Nº Grupo</Text>
								<Text>{grupo.num_grupo ?? "Sin especificar"}</Text>
							</Stack>
						</Grid>

						<Grid item xs={3}>
							<Stack>
								<Text fontWeight={"bold"}>Fecha inicio</Text>
								<Text>{grupo.fecha_inicio ? Statics.formatDate(grupo.fecha_inicio) : "Sin especificar"}</Text>
							</Stack>
						</Grid>

						<Grid item xs={3}>
							<Stack>
								<Text fontWeight={"bold"}>Fecha fin</Text>
								<Text>{grupo.fecha_fin ? Statics.formatDate(grupo.fecha_fin) : "Sin especificar"}</Text>
							</Stack>
						</Grid>

						<Grid item xs={3}>
							<Stack>
								<Text fontWeight={"bold"}>Min. Alumnos</Text>
								<Text>{grupo.min_alumnos ?? "Sin especificar"}</Text>
							</Stack>
						</Grid>
						<Grid item xs={3}>
							<Stack>
								<Text fontWeight={"bold"}>Max. Alumnos</Text>
								<Text>{grupo.max_alumnos ?? "Sin especificar"}</Text>
							</Stack>
						</Grid>
					</Grid>

					<Grid container mt={4}>
						<Line />
					</Grid>

					<Grid container>
						<Grid item xs={6} p={3}>
							<Stack>
								<Text fontWeight={"bold"}>Profesor del grupo</Text>
								{grupo.profesor_actual?.empleado ? <EmpleadoBasicItem empleado={grupo.profesor_actual?.empleado}/> : null}
							</Stack>
						</Grid>

						<Grid item xs={6} style={{borderLeft: "1px solid #D9D9D9"}} p={3}>
							<Stack>
								<Text fontWeight={"bold"}>Observaciones del grupo:</Text>
								<Text>{grupo.observaciones ?? "Sin especificar"}</Text>
							</Stack>
						</Grid>
					</Grid>
				</React.Fragment>
			) : <Skeleton />}
		</Grid>
	)
}