import { Grid } from "@mui/material";
import { Horario } from 'components/data_display'
import { useEffect, useState } from "react";
import { ItemsSelect } from "components/forms/components/Select/custom";
import Skeleton from './skeleton'

/**
 * Pagina con los datos de los horarios de la clase particular
 */
export default function ClaseParticularHorarios({ claseParticular }) {
    //STATE
    const [lugarClaseId,    setLugarClaseId]    = useState(claseParticular?.lugar_clase_id ?? "")
    const [aulaId,          setAulaId]          = useState(null)
    const [hideAula,        setHideAula]        = useState((lugarClaseId == 2) ? true : false)
    const [horarioToShow,   setHorarioToShow]   = useState([])

    const horariosArrayCL           = claseParticular?.horarios ? ( (claseParticular?.horarios).filter(horario => horario.aula_id != null) ) : []
    const horariosArrayDomicilio    = claseParticular?.horarios ? ( (claseParticular?.horarios).filter(horario => horario.aula_id == null) ) : []

    useEffect(() => {
        if (lugarClaseId == 2) {
            setHideAula(true)
            setHorarioToShow(horariosArrayDomicilio)
        } else {
            setHideAula(false)
            if(aulaId)
                setHorarioToShow(horariosArrayCL.filter(horario => horario.aula_id == aulaId))
        }
    }, [lugarClaseId, aulaId])

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container p={5}>
            {claseParticular ?
                <>
                    <Grid container justifyContent="space-around">
                        <Grid item xs={5}>
                            <ItemsSelect
                                name={"lugar_clase_id"}
                                keyValue={"lugar_clase"}
                                defaultValue={claseParticular?.lugar_clase_id ?? ""}
                                onChange={
                                    (_, item) => { setLugarClaseId(item.id) }
                                }
                            />
                        </Grid>

                        {hideAula ? null :
                            <Grid item xs={5}>
                                <ItemsSelect
                                    name={"aula_id"}
                                    keyValue={"aula"}
                                    defaultValue={claseParticular?.aula_id ?? ""}
                                    onChange={
                                        (_, item) => { setAulaId(item.id) }
                                    }
                                />
                            </Grid>
                        }
                    </Grid>

                    <Grid container mt={5}>
                        <Horario horarios={horarioToShow} type="clases-particulares" isSingle />
                    </Grid>
                </>
                : <Skeleton />}
        </Grid>
    )
}