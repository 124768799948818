import Statics from 'objects/common/Statics'
import { Link } from 'components/data_display'
import { auxFieldName } from 'constants/Responses'
import { Button } from 'components/forms/components'

export const columns = [
    {
        field: "actions",
        type: 'actions',
        headerName: "Acciones",
        minWidth: 200,
        getActions: (params) => [<Link to={"" + params.row.id}><Button size={"small"}>Ver</Button></Link>, <Link to={"" + params.row.id + "/editar"}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>]
    },

    { field: "id",              headerName: "ID",           maxWidth: 65 },
    { field: "alumno",          headerName: "Alumno",       minWidth: 100, flex: 1, valueGetter: (params) => { 
        const { alumno } = params.row
        if(!alumno) return ""

        return (alumno.apellido1 ? alumno.apellido1 + " " : "") + (alumno.apellido2 ? alumno.apellido2 + " " : "") + (alumno.nombre ? alumno.nombre + " " : "")}
    },
    { field: "curso_escolar",   headerName: "Año",          minWidth: 100, valueGetter: (params) => { return params.row.curso_escolar ? params.row.curso_escolar[auxFieldName.cursoEscolar] : "" } },
    { field: "fecha",           headerName: "Fecha",        minWidth: 100, valueGetter: (params) => { return params.row.fecha ? Statics.formatDate(params.row.fecha) : "" } },
    { field: "motivo",          headerName: "Motivo",       minWidth: 100, flex: 1 },
    { field: "solucionada",     headerName: "Solucionada",  minWidth: 100, valueGetter: (params) => { return params.row.solucionada ? "Sí" : "No"} },
]
