import Statics from 'objects/common/Statics'
import { RequestGrupoAcademia } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
* Devuelve grupo
* 
* @param {Int}      id 
* @param {Function} callbackOk 
* @param {Function} callbackError 
*/
    getGrupo(id, callbackOk = () => { }, callbackError = () => { }) {
        const params = { appends: ['curso_escolar', 'registro_actividad', 'nivel_grupo', 'horarios'] }
        new RequestGrupoAcademia().get(id, params, (res) => {
            res ? callbackOk(res) : callbackError(res)
        })
    }

    /**
     * Hace la petición para edit el grupo
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editGrupo(id, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData
        formData.append("clave_grupo", getValues("clave").toUpperCase())
        formData.append("curso_escolar_id", getValues("curso_escolar_id"))
        formData.append("registro_actividad_id", getValues("registro_actividad_id"))
        formData.append("nivel_grupo_id", getValues("nivel_grupo_id") ?? "")
        formData.append("num_grupo", getValues("num_grupo") ?? "")

        const fecha_inicio = getValues("fecha_inicio")
        formData.append('fecha_inicio', Statics.datePickerTimeFormat(fecha_inicio))

        const fecha_fin = getValues("fecha_fin")
        formData.append('fecha_fin', Statics.datePickerTimeFormat(fecha_fin))

        formData.append("min_alumnos", getValues("min_alumnos") ?? "")
        formData.append("max_alumnos", getValues("max_alumnos") ?? "")
        formData.append("operador_id", getValues("operador_id") ?? "")
        formData.append("observaciones_grupo", getValues("observaciones_grupo").toUpperCase() ?? "")

        // //IDIOMAS
        formData.append("libro_id", getValues("libro_id") ?? "")
        formData.append("usar_libro_asociado", getValues("usar_libro_asociado") ? 1 : 0)
        formData.append("usar_progress_sheet", getValues("usar_progress_sheet") ? 1 : 0)

        //PROGRAMACIONES
        formData.append("programacion_1t_enviada", "0")
        formData.append("programacion_2t_enviada", "0")
        formData.append("programacion_3t_enviada", "0")

        new RequestGrupoAcademia().edit(id, formData, callbackOk, callbackError)
    }
}
