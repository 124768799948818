import React, { Component } from 'react'

export default class Extramini extends Component {

  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    return (
      <span className='extramini-text'>{this.props.children ?? ""}</span>
    )
  }
}
