import RequestMaster from '../RequestMaster'

export default class RequestClasesParticulares extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/clase-particular'
    }

    /**
     * Obtiene todos los registros
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    all(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "all"
        this.get(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Crea una clase particular
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    create(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Edita una clase particular
     *
     * @param {Int}      id
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    edit(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "" + id
        this.put(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Hace la búsqueda de las clases particulares
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    search(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.get(path, params, callback, callbackError, callbackFinally)
    }
}
