import React from 'react'
import { Grid } from '@mui/material';
import { CardButton } from 'components/forms/components';

/**
 * Formulario con los comentarios
 */
export default function ListadosDataForm() {
    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    return (
        <Grid container px={5} mt={4} justifyContent="center">

            <Grid item xs={6} mt={4}>
                <CardButton title={'Grupos del profesor'} />
            </Grid>

            <Grid item mt={4}>
                <CardButton title={'Horario del profesor'} />
            </Grid>

        </Grid>
    )
}


