import { useEffect, useRef, useState } from 'react'
import Requests from './Requests/Requests.js'
import { Box, Container, Paper, Tab, Tabs } from '@mui/material'
import { BreadcrumbsList, Line } from 'components/data_display'
import { CreateHeader } from 'pages/logged/admin/components'
import { DataFormInscripcionAcademias, DataFormGrupoAcademias } from 'components/forms/display'
import LibroInscripcionAcademiaDataForm from 'components/forms/display/academia/inscripciones/DataForm/LibroDataForm/index.jsx'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { TabContext } from '@mui/lab'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { COLOR } from 'theme/Colors.js'
import { Text } from 'components/texts/index.js'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import PagoInscripcionAcademiaDataForm from 'components/forms/display/academia/inscripciones/DataForm/PagoDataForm/index.jsx'
import Statics from 'objects/common/Statics.js'
import { validationSchema } from 'components/forms/display/academia/inscripciones/DataForm/validation'


/**
 * Creación de una inscripción a academia
 */
export default function CreateInscripcionesAcademia() {

  //HOOKS
  const { register, handleSubmit, formState: { errors }, getValues, setValue, control, clearErrors } = useForm({ resolver: yupResolver(validationSchema) })
  const navigate = useNavigate()
  const headerRef = useRef()

  //STATE
  const [isMounted, setIsMounted] = useState(false)
  const [tab, setTab] = useState("1")
  const [libroSolicitado, setLibroSolicitado] = useState(getValues("libro_solicitado"))
  const [libroAsociadoSolicitado, setLibroAsociadoSolicitado] = useState(getValues("libro_asociado_solicitado"))
  const [actividad, setActividad] = useState()
  const [grupo, setGrupo] = useState()

  const [cursoEscolar, setCursoEscolar] = useState()

  //REQUESTS
  const requests = new Requests()

  useEffect(() => {
    setIsMounted(true)
    getValues('precio')
  }, []);

  const updateHeaderAndAlert = ({ message, variant = "error" }, loading = false) => {
    headerRef.current.button.setLoading(loading)
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }
  //-------------------------------------------------------
  //----------------------- REQUESTS ----------------------
  //-------------------------------------------------------+

  const createInscripcionAcademia = (pagos) => {
    headerRef.current.button.setLoading(true)

    requests.createInscripcion(getValues, (res) => {

      if (res)
        enqueueSnackbar(<Text>Se ha guardado la inscripción correctamente.</Text>, { variant: "success" })

      const promisePagos = new Promise((resolve, reject) => {
        requests.createPagos(res.id, pagos, resolve, reject);
      })

      const promiseLibro = new Promise((resolve, reject) => {
        requests.crudLibro(res, getValues, resolve, reject)
      })

      const promiseLibroAsociado = new Promise((resolve, reject) => {
        requests.crudLibroAsociado(res, getValues, resolve, reject)
      })

      Promise.allSettled([promisePagos, promiseLibro, promiseLibroAsociado]).then((values) => {
        for (const fail of values.filter(item => item?.status == "rejected")) {
          enqueueSnackbar(<Text>{fail.reason.message}</Text>, { variant: "error" })
        }
        headerRef.current.button.setLoading(false)
      })

    }, (err) => {
      const message = Statics.errorArrayResponseToString(err)
      updateHeaderAndAlert({ message })

      headerRef.current.button.setLoading(false)
    })
  }


  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <BreadcrumbsList breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "Academias" },
          { to: "./", label: "Crear inscripción Academia", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <CreateHeader
            ref={headerRef}
            title={"Crear inscripción para la academia"}
            handleSubmit={handleSubmit(createInscripcionAcademia, (errors) => {
              console.error(errors);
            })} />

          <Line className={"my-5"} />

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
              <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS DE LA INSCRIPCIÓN</Text>} value={"1"} />
                <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>DATOS DEL GRUPO</Text>} value={"2"} />
                <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>DATOS DE LOS PAGOS</Text>} value={"3"} />
                <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>LIBROS</Text>} value={"4"} />
              </Tabs>
            </Box>

            <TabPanel value={"1"}>
              <DataFormInscripcionAcademias
                getValues={getValues}
                register={register}
                setValue={setValue}
                errors={errors}
                clearErrors={clearErrors}
                isMounted={isMounted}
                onChangeCursoEscolar={setCursoEscolar}
                onChangeActividad={setActividad}
                control={control} />
            </TabPanel>

            <TabPanel value={"2"}>
              <DataFormGrupoAcademias
                getValues={getValues}
                register={register}
                errors={errors}
                clearErrors={clearErrors}
                isMounted={isMounted}
                setValue={setValue}
                onGrupoChange={setGrupo}
                control={control}
                actividad={actividad}
                cursoEscolar={cursoEscolar}
              />
            </TabPanel>

            <TabPanel value={"3"}>
              <PagoInscripcionAcademiaDataForm
                getValues={getValues}
                register={register}
                errors={errors}
                clearErrors={clearErrors}
                isMounted={isMounted}
                setValue={setValue}
                control={control} />
            </TabPanel>

            <TabPanel value={"4"}>
              <LibroInscripcionAcademiaDataForm
                actividad={actividad}
                grupo={grupo}
                getValues={getValues}
                register={register}
                setLibroSolicitado={setLibroSolicitado}
                setLibroAsociadoSolicitado={setLibroAsociadoSolicitado}
                errors={errors}
                clearErrors={clearErrors}
                isMounted={isMounted}
                setValue={setValue}
                control={control} />
            </TabPanel>
          </TabContext>

        </Paper>

        <SnackbarProvider autoHideDuration={3000} />
      </form>
    </Container>
  )
}