import {
    AcademiaTemplate, ColegiosTemplate, CoordinadoresTemplate, ParticularesTemplate,
    SimulacrosYExamenesTemplate, ExaOficialesTemplate, EmpresasTemplate, InmersionLinguisticaTemplate,
    ProfesoradoTemplate, CursosVeranoTemplate, CursoExtranjeroTemplate
} from 'components/forms/display/comunicaciones'
import { Box } from "@mui/material";

// constants
export const tabItems = [
    {
        id: "1",
        title: "Academia",
        content: AcademiaTemplate,
    },
    {
        id: "2",
        title: "Colegios",
        content: ColegiosTemplate,
    },
    {
        id: "3",
        title: "Coordinadores",
        content: CoordinadoresTemplate,
    },
    {
        id: "4",
        title: "Profesorado",
        content: ProfesoradoTemplate,
    },
    {
        id: "5",
        title: "Directores",
        content: ProfesoradoTemplate,
    },
    {
        id: "6",
        title: "Empresas",
        content: EmpresasTemplate,
    },
    {
        id: "7",
        title: "Particulares",
        content: ParticularesTemplate,
    },
    {
        id: "8",
        title: "Cursos en el Exanjero",
        content: CursoExtranjeroTemplate,
    },
    {
        id: "9",
        title: "Simulacros y Exámenes",
        content: SimulacrosYExamenesTemplate,
    },
    {
        id: "10",
        title: "Exa. Oficiales",
        content: ExaOficialesTemplate,
    },
    {
        id: "11",
        title: "Inm. lingüistica",
        content: InmersionLinguisticaTemplate,
    },
    {
        id: "12",
        title: "Cursos Verano",
        content: CursosVeranoTemplate,
    },
];

export const grupo_1 = [
    {
        id: "1",
        label: "Academia",
        isSelected: true
    },
    {
        id: "2",
        label: "Colegios",
        isSelected: false
    },
    {
        id: "3",
        label: "Coordinadores",
        isSelected: false
    },
];

export const grupo_2 = [
    {
        id: "4",
        label: "Profesorado",
        isSelected: false
    },
    {
        id: "5",
        label: "Directores",
        isSelected: false
    },
];

export const grupo_3 = [
    {
        id: "6",
        label: "Empresas",
        isSelected: false
    },
    {
        id: "7",
        label: "Particulares",
        isSelected: false
    },
    {
        id: "8",
        label: "Cursos en el Exanjero",
        isSelected: false
    },
];

export const grupo_4 = [
    {
        id: "9",
        label: "Simulacros y Exámenes",
        isSelected: false
    },
    {
        id: "10",
        label: "Exa. Oficiales",
        isSelected: false
    },
];

export const grupo_5 = [
    {
        id: "11",
        label: "Inm. lingüistica",
        isSelected: false
    },
    {
        id: "12",
        label: "Cursos Verano",
        isSelected: false
    },
];


export const placeholderText = `Academia CL Granada S.L.
C/ Puentezuelas, nº 32, 1ª Planta, 18002 Granada
Tlf: 958 53 52 53

-Aviso Legal
De acuerdo con lo establecido en el Reglamento UE 2016/679 de Protección de Datos, de ahora en adelante RGPD, les informamos que los datos que figuran en esta comunicación están incluidos en un registro de actividad propiedad de CL GRANADA S.L., con la finalidad de la prestación del servicio y comunicaciones comerciales. Para el ejercicio de los derechos de acceso, rectificación, supresión, oposición y portabilidad de datos, podrán dirigirse en cualquier momento a dicha entidad, en el domicilio situado en C/ Puentezuelas, nº 32, 1ª Planta, 18002 Granada o enviando mail a calidad@clgranada.com.

Este mensaje se dirige exclusivamente a su destinatario y puede contener información CONFIDENCIAL sometida a secreto profesional o cuya divulgación esté prohibida en virtud de la legislación vigente. Si ha recibido este mensaje por error, le rogamos que nos lo comunique inmediatamente por esta misma vía y proceda a su destrucción. CL GRANADA S. L  se reserva las acciones legales que le correspondan contra todo tercero que acceda de forma ilegítima al contenido de cualquier mensaje externo procedente del mismo. Para informacion y consultas visite nuestra web http://www.clgranada.com
En caso de que no desee recibir más información sobre los servicios que ofrecemos puede enviar un mensaje con la palabra 'BAJA', a la siguiente dirección de correo electrónico: baja@clgranada.com
`;
