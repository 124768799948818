import React, { Component } from "react";
import SvgIcon from '@mui/material/SvgIcon';

export default class Icon extends Component {
  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
        <SvgIcon component={this.props.component} color={this.props.color ?? 'standard'} inheritViewBox />
    );
  }
}
