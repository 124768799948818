import React from 'react'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { Button, Input } from 'components/forms/components'

//VALIDACION FORMULARIO
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from "./validation.js"
import { RequestAlumnoResponsable, RequestResponsable } from 'objects/requests'
import { Grid } from '@mui/material'

/**
 * Formulario para crear un responsable de forma rápida y mas simple, es necesario enviar la id del alumno al que se le va a asociar
 */
export default function FastCreateForm({ id_alumno, callbackOk, callbackError }) {

    const { register, handleSubmit, formState: { errors }, getValues } = useForm({ resolver: yupResolver(validationSchema) })

    //-------------------------------------------------------
    //-------------------- CLASS FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Prepara los values y hace la petición para crear un responsable
     */
    const createResponsable = () => {
        var formData = new FormData()
        formData.append("nombre", getValues("nombre").toUpperCase())
        formData.append("apellido1", getValues("apellido1").toUpperCase())
        formData.append("apellido2", getValues("apellido2").toUpperCase())
        formData.append("email", getValues("email"))
        formData.append("numero_documento", getValues("numero_documento").toUpperCase())
        formData.append("id_tipo_documento", getValues("id_tipo_documento"))

        new RequestResponsable().create(formData, (res) => {
            createRelation(res.responsable)
        }, callbackError)
    }


    /**
     * Hace la petición para crear la relacion entre el responsable y el alumno
     * 
     * @param {Object} responsable
     */
    const createRelation = (responsable) => {
        var formData = new FormData()

        formData.append("id_responsable", responsable.id)
        formData.append("id_alumno", id_alumno)
        formData.append("id_tipo_relacion", getValues("id_tipo_relacion"))

        new RequestAlumnoResponsable().create(formData, callbackOk, () => {
            callbackError("Se ha creado el responsable pero no se ha podido asociar al alumno.")
        })
    }
    
    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container pt={2} spacing={2}>
            <Grid item xs={6}>
                <Input label="Nombre" register={register} errors={errors} className="w-100" name={"nombre"} uppercase />
            </Grid>

            <Grid item xs={6}>
                <Input label="Primer apellido" register={register} errors={errors} className="w-100" name={"apellido1"} uppercase />
            </Grid>

            <Grid item xs={6}>
                <Input label="Segundo apellido" register={register} errors={errors} className="w-100" name={"apellido2"} uppercase />
            </Grid>

            <Grid item xs={6}>
                <Input label="Email" register={register} errors={errors} className="w-100" name={"email"} />
            </Grid>

            <Grid item xs={6}>
                <Input label="Número de documento" register={register} errors={errors} className="w-100" name={"numero_documento"} uppercase />
            </Grid>

            <Grid item xs={6}>
                <ItemsSelect name={"id_tipo_documento"} keyValue={"tipo_documento"} register={register} errors={errors} />
            </Grid>

            <Grid item xs={6}>
                <ItemsSelect name={"id_tipo_relacion"} keyValue={"tipo_relacion"} register={register} errors={errors}/>
            </Grid>

            <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                <Button onClick={handleSubmit(createResponsable)}>
                    Guardar
                </Button>
            </Grid>
        </Grid>
    )
}
