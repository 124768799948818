import React from 'react'
import { employeeRoutes } from 'router/routes'
import { TopMenuButton, LogoutButton } from '../components'
import { Link } from 'components/data_display'
import { Stack } from '@mui/material'

/**
 * EmployeeHeader
 */
export default class AdminHeader extends React.Component {
  
  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    return (
      <div id="kt_app_header" className={"app-header app-header-custom justify-content-between"}>
        <Stack flexDirection={"row"} display={"flex"}>
          <Link to={employeeRoutes.LISTADO_ALUMNOS}>
            <TopMenuButton>Alumnos</TopMenuButton>
          </Link>

          <Link to={employeeRoutes.LISTADO_EMPLEADOS}>
            <TopMenuButton>Empleados</TopMenuButton>
          </Link>

          <Link to={employeeRoutes.LISTADO_COLEGIOS}>
            <TopMenuButton>Colegios</TopMenuButton>
          </Link>

          <Link to={employeeRoutes.SEGUIMIENTO_CURSO}>
            <TopMenuButton>Seguimiento del curso</TopMenuButton>
          </Link>

          <Link to={employeeRoutes.LISTADO_LIBROS}>
            <TopMenuButton>Libros</TopMenuButton>
          </Link>

          <Link to={employeeRoutes.LISTADO_ACADEMIAS}>
            <TopMenuButton>Academias</TopMenuButton>
          </Link>

          <Link to={employeeRoutes.CLASES_PARTICULARES}>
            <TopMenuButton>Clases particulares</TopMenuButton>
          </Link>

          <Link to={employeeRoutes.COMUNICACIONES}>
            <TopMenuButton>Comunicaciones</TopMenuButton>
          </Link>

        </Stack>

        <LogoutButton />
      </div>
    )
  }

}
