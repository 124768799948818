import { Grid } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

export default function SkeletonGroupData() {

    return (
        <Grid container px={5}>
            <Grid container spacing={2} mt={2}>

                <Grid item xs={12}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>

                <Grid item xs={12}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>

                <Grid item xs={12}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>

                <Grid item xs={12}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>

            </Grid>

        </Grid>
    )
}
