import React, { Component } from 'react'

/**
 * Menu Item Group
 */
export default class MenuItemGroup extends Component {

  //===================================================================================================
  //======================================= RENDER METHODS ============================================
  //===================================================================================================

  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    return (
      <div className={"menu-item menu-accordion" + (this.props.defaultOpen ? " hover show" : "")} data-kt-menu-trigger="click">
            <span className="menu-link">
              <span className="menu-icon">
                {this.props.icon ?? (
                  <span className="svg-icon svg-icon-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                      <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                      <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                      <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg>
                  </span>
                )}
              </span>
              
              <span className="menu-title">{this.props.title ?? ""}</span>
              <span className="menu-arrow"></span>
            </span>

            <div className={"menu-sub menu-sub-accordion"}>
              {this.props.children}
            </div>
      </div>
    )
  }
}
