import React from 'react';
import { Card, CardContent, Typography, Button, Grid } from '@mui/material';
import { PictureAsPdfRounded as PdfIcon, } from '@mui/icons-material'

export default function CardButton({ title, buttonOnClick, disabled }) {

    return (
        <Grid container spacing={2} p={2}>
            <Card
                sx={{
                    border: '1px solid #4B799E',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',

                }}>
                <CardContent >
                    <Typography variant="body1" color="#7F8790">
                        {title}
                    </Typography>
                    <Button
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '200px',
                            height: '50px',
                            backgroundColor: '#4B799E',
                            marginTop: '1.5rem',
                            '&:hover': {
                                backgroundColor: 'lightgray',
                            },
                        }}
                        variant="contained"
                        startIcon={<PdfIcon style={{ fontSize: '2rem' }} />}
                        onClick={buttonOnClick}
                        disabled={disabled}
                    >
                    </Button>
                </CardContent>
            </Card>
        </Grid >
    )
}

