import { Grid } from '@mui/material'
import React, { Component } from 'react'
import Skeleton from '@mui/material/Skeleton'

/**
 * Informacion básica del alumno
 */
export default class SkeletonLibrosDataAcademia extends Component {

  /**
   * Render
   *
   * @returns {Component}
   */

  render() {

    return (
      <Grid container direction="column"      >

        <Grid item xs={12} px={3}>
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
        </Grid>

        <Grid item xs={4}>
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
        </Grid>

        <Grid item xs={4}>
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
        </Grid>

        <Grid item xs={4}>
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
        </Grid>

        <Grid item xs={6}>
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
        </Grid>

        <Grid item xs={6} >
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
        </Grid>

        <Grid item xs={6} md={2}>
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
        </Grid>

        <Grid item xs={4} ml={2}>
          <Skeleton variant="text" sx={{ fontSize: '1em' }} />
        </Grid>

        <Grid item xs={12}>
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
        </Grid>

        <Grid item xs={6}>
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
        </Grid>

        <Grid item xs={6}>
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
        </Grid>

        <Grid item xs={10} m={2}>
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
        </Grid>

      </Grid>
    )
  }
}
