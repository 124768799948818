import React, { Component } from 'react'
import { Grid, Stack } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

/**
 * Informacion básica del alumno
 */
export default class SkeletonAlumno extends Component {

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {

    return (
        <Grid container spacing={2} mt={2} px={5}>

            <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Skeleton variant="text" sx={{ fontSize: '2.5em' }} width={"20%"}/>

                <Skeleton variant="rounded" height={40} width={"10%"}/>
            </Grid>

            <Grid item xs={4}>
                <Stack>
                    <Skeleton variant="text" sx={{ fontSize: '1.2em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }}   width={"90%"}/>
                </Stack>
            </Grid>

            <Grid item xs={4}>
                <Stack alignItems={"center"}>
                    <Skeleton variant="text" sx={{ fontSize: '1.2em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }}   width={"90%"}/>
                </Stack>
            </Grid>

            <Grid item xs={4}>
                <Stack alignItems={"end"}>
                    <Skeleton variant="text" sx={{ fontSize: '1.2em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }}   width={"90%"}/>
                </Stack>
            </Grid>

            <Grid item xs={4}>
                <Stack>
                    <Skeleton variant="text" sx={{ fontSize: '1.2em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }}   width={"90%"}/>
                </Stack>
            </Grid>

            <Grid item xs={4}>
                <Stack alignItems={"center"}>
                    <Skeleton variant="text" sx={{ fontSize: '1.2em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }}   width={"90%"}/>
                </Stack>
            </Grid>

            <Grid item xs={4}>
                <Stack alignItems={"end"}>
                    <Skeleton variant="text" sx={{ fontSize: '1.2em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }}   width={"90%"}/>
                </Stack>
            </Grid>

            <Grid item xs={4}>
                <Stack>
                    <Skeleton variant="text" sx={{ fontSize: '1.2em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }}   width={"90%"}/>
                </Stack>
            </Grid>

            <Grid item xs={4}>
                <Stack alignItems={"center"}>
                    <Skeleton variant="text" sx={{ fontSize: '1.2em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }}   width={"90%"}/>
                </Stack>
            </Grid>

            <Grid item xs={4}>
                <Stack alignItems={"end"}>
                    <Skeleton variant="text" sx={{ fontSize: '1.2em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }}   width={"90%"}/>
                </Stack>
            </Grid>
        </Grid>
    )
  }
}
