import React, { Component } from 'react'
import { SnackbarAlert } from '../../../../../../../../components/data_display'
import { Modal } from '../../../../../../../../components/modals'
import DataFormEvent from '../form'
import Requests from '../../Requests'

/**
 * Modal para crear/editar eventos
 */
export default class EventModal extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            date: this.props.date ?? null
        }

        this.requests = new Requests()
    }


    componentDidUpdate(prevProps) {
        if(this.props.date != prevProps.date)
            this.setState({date: this.props.date})
    }


    /**
     * Abre el modal y actualiza la fecha
     * 
     * @param {String} date
     */
    open = (date) => {
        this.setState({date}, () => {
            this.modal.open()
        })
    }


    /**
     * Cierra el modal
     */
    close = () => {
        this.setState({date: null}, () => {
            this.modal.close()
        })
    }

    
    /**
     * Callback para cuando se vaya a crear un evento
     * 
     * @param {Array} values
     */
    onCreate = (values) => {
        values.date = this.state.date

        this.requests.createEvento(values, (res) => {
            this.close()
            this.snackbarAlert.updateState({ message: "Se ha creado el evento correctamente.", severity: "success", open: true })

            if(this.props.onCreateEvento)
                this.props.onCreateEvento(res.evento)
        }, (res) => {
            this.snackbarAlert.updateState({ message: "No se ha podido crear el evento: " + res, severity: "error", open: true })
        })
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <>
                <Modal ref={(ref) => this.modal = ref} title={"Crear evento para el día: " + (this.state.date ? this.state.date.split('-').reverse().join('/') : "")} style={{minWidth: "60%"}}>
                    <DataFormEvent onClick={this.onCreate} btnText={"Crear evento"}/>
                </Modal>
                
                <SnackbarAlert ref={(ref) => this.snackbarAlert = ref} />
            </>
        )
    }
}
