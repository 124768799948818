import { Grid } from "@mui/material"
import { Input } from "components/forms/components"
import { Component } from "react"
import { Text } from "components/texts"
import { ItemsSelect } from "components/forms/components/Select/custom"

export default class ClasesParticularesAlumnosTemplate extends Component {
  /**
   * Constructor
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props)
  }

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    const { register, errors } = this.props
    return (
      <>
        <Grid container mt={2} mb={1}>
          <Text fontWeight={"bold"}>Según datos de:</Text>
          <br />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Input
              label="Nombre"
              width={"100%"}
              name={"nombre_alumno"}
              register={register}
              errors={errors}
              uppercase
            />
          </Grid>

          <Grid item xs={3}>
            <Input
              label="Primer apellido"
              width={"100%"}
              name={"apellido1_alumno"}
              register={register}
              errors={errors}
              uppercase
            />
          </Grid>

          <Grid item xs={3}>
            <Input
              label="Segundo apellido"
              width={"100%"}
              name={"apellido2_alumno"}
              register={register}
              errors={errors}
              uppercase
            />
          </Grid>

          <Grid item xs={3}>
            <ItemsSelect name={"curso_escolar_id"} keyValue={"curso_escolar"} register={register} errors={errors} wantToReset={this.props.reset} />
          </Grid>
        </Grid>
      </>
    )
  }
}
