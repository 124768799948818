import Statics from "objects/common/Statics"

export function columns() {
    return [
        { 
            field: "nombre",         
            headerName: "Actividad",       
            flex: 1,
            align:'center',
            headerAlign: 'center',
            valueGetter: (params) => { return params.row.registro_actividad?.actividad?.valor ?? "" } 
        },
        { 
            field: "num_alumnos",      
            headerName: "Alumnos",       
            maxWidth: 100,
            editable: true,
            align:'center',
            headerAlign: 'center',   
            valueSetter: ({row, value}) => row,
        },
        { 
            field: "precio",
            headerName: "Precio",
            maxWidth: 100, 
            align:'center',
            headerAlign: 'center',
            valueGetter: (params) => { return params.row.precio_actividad ? Statics.changeDotAndComma(params.row.precio_actividad) : "" },
        },
        { 
            field: "total",    
            headerName: "Total", 
            maxWidth: 100, 
            type: 'number',
            align:'center',
            headerAlign: 'center',
            valueGetter: (params) => { 
                const { precio_actividad, num_alumnos } = params.row
                return precio_actividad && num_alumnos ? Statics.changeDotAndComma((precio_actividad * num_alumnos).toFixed(2)) : "" 
            },
        },
    ]
}