import { Grid, Stack } from '@mui/material'
import { H3, Text } from 'components/texts'
import { useForm } from 'react-hook-form'
import { columns } from './parts/columns'
import Requests from './Requests/Requests'
import ImportForm from './parts/ImportForm'
import { Datagrid } from 'components/data_display'
import { Button } from 'components/forms/components'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState, useRef } from 'react'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { validationSchema } from './parts/ImportForm/validation'

export default function ActividadesData({ id_colegio }) {
  const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm({ resolver: yupResolver(validationSchema) })
  
  const [detalles, setDetalles] = useState([])
  const detallesRef = useRef(detalles)

  useEffect(() => {
    detallesRef.current = detalles
  }, [detalles])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const canExit = checkCanChange()
      if (!canExit) {
        event.preventDefault()
        event.returnValue = ""
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  /**
   * Hace la peticion para obtener los detalles de las actividades actuales
   * 
   * @param {Int} id_curso_escolar
   */
  const getActividadesByCurso = (id_curso_escolar) => {
    new Requests().getActividadesByCurso(id_curso_escolar, id_colegio, (detalles) => {
      detalles.map(item => item.og = true)
      setDetalles(detalles)
    }, 
    () => setDetalles([]))
  }

  /**
   * Actualiza las condiciones de todos los detalles que se están mostrando
   * 
   * @param {Event} e
   */
  const onUpdateConditionsClick = () => {
    if(detalles.length == 0) {
      enqueueSnackbar(<Text>No hay ninguna actividad para poder actualizar las condiciones.</Text>, { variant: "error" })
    } else {
      for(const detalle of detalles) {
        if(!detalle.deleted) {
          detalle.id_tipo_condicion_actividad = getValues('id_tipo_condicion')
          detalle.tipo_condicion              = getValues('tipo_condicion')
          detalle.condiciones                 = getValues("condicion")
        }
      }

      setDetalles([...detalles])
    }
  }

  /**
   * Hace la peticion para obtener las actividades que no tienen detalles aun
   */
  const importActividades = () => {
    new Requests().getNewActividades(getValues("id_curso_escolar"), id_colegio, filterNewActivities, (res) => {
      if(detalles.find(item => item.deleted)) {
        detalles.map(item => item.deleted = false)
        setDetalles([...detalles])
      } else {
        enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
      }
    })
  }
  
  
  /**
   * Hace la peticion para obtener las actividades del año anterior que no tienen detalle
   */
  const importActividadesCursoAnterior = () => {
    new Requests().getActividadesCursoAnterior(getValues("id_curso_escolar"), id_colegio, (actividades) => filterNewActivities(actividades, false), (res) => {
      enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
    })
  }


  const filterNewActivities = (actividades, resetAll = true) => {
    const newDetalles = []
    for (const actividad of actividades) {
      const index = detalles.findIndex(item => item.actividad.id == actividad.id)
      if(index == -1) {
        const detalle = {...actividad, id: Math.random(), activa: 0, id_tipo_condicion_actividad: null, tipo_condicion: null, actividad, id_curso_escolar: getValues('id_curso_escolar')}
        newDetalles.push(detalle)
      } else if(index != -1 && !resetAll && detalles[index].deleted)
        detalles[index].deleted = false
    }

    if(resetAll)
      detalles.map(item => item.deleted = false)

    setDetalles([...detalles, ...newDetalles])
  }
  
  
  /**
   * Actualiza un detalle
   * 
   * @param {Int}    id    ID del detalle a editar
   * @param {String} field Nombre del campo
   * @param {*}      value Valor nuevo
   */
  const updateDetalle = ({id, field, value}) => {
    const index = detalles.findIndex(item => item.id == id)
    if(index != -1) {
      detalles[index][field] = value
      if(field == "id_tipo_condicion_actividad") {
        detalles[index]["id_tipo_condicion_actividad"] = value.id
        detalles[index]["tipo_condicion"]              = value
        detalles[index]['edited']                      = true
      }
    }

    setDetalles([...detalles])
  }

  const deleteDetalle = (id) => {
    const index = detalles.findIndex(item => item.id == id)
    if(index != -1)
      detalles[index]["deleted"] = true

    setDetalles([...detalles])
  }
    
  const actions = (params) => {
    return [<Button onClick={() => deleteDetalle(params.id)}>Borrar</Button>]
  }

  const saveChanges = () => {
    const itemsToCreateOrUpdate = detalles.filter(item => item.deleted != true)
    new Requests().createOrUpdateMultiple(itemsToCreateOrUpdate, () => {
      enqueueSnackbar(<Text>Se han guardado correctamente los detalles.</Text>, { variant: "success" })
    }, (res) => {
      enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
    }, () => {
      const itemsToDelete = detalles.filter(item => item.deleted && item.og)
      new Requests().deleteMultiple(itemsToDelete, () => {
      }, (res) => {
        enqueueSnackbar(<Text>{"No se han podido borrar los detalles: " + res}</Text>, { variant: "error" })
      }, () => getActividadesByCurso(getValues('id_curso_escolar'))
      )
    })
  }

  const checkCanChange = () => {
    const itemChange = detallesRef.current.find(
      (item) => (item.og && item.deleted) || !item.og || (item.og && item.edited)
    )
    return itemChange ? false : true
  }

  return (
    <Grid container p={5}>
      <ImportForm 
        register                       = {register}
        setValue                       = {setValue}
        actividades                    = {detalles}
        errors                         = {errors}
        getValues                      = {getValues}
        handleSubmit                   = {handleSubmit(onUpdateConditionsClick)}
        importActividades              = {importActividades}
        importActividadesCursoAnterior = {importActividadesCursoAnterior}
        checkCanChange                 = {checkCanChange}
        onCursoChange                  = {({target}) => getActividadesByCurso(target.value)} />

      <Grid item xs={12} mt={5}>
        <Stack display="flex" flexDirection={"row"} alignItems="center" justifyContent={"space-between"} className='mb-5'>
          <H3 className="mb-0">Actividades:</H3>
          <Button onClick={saveChanges}>Guardar cambios</Button>
        </Stack>
        <Datagrid
          rows               = {detalles.filter(item => !item.deleted)}
          columns            = {columns(actions)}
          pageSize           = {100}
          rowsPerPageOptions = {[50, 100]}
          onCellEditCommit   = {updateDetalle}/>
      </Grid>

      <SnackbarProvider autoHideDuration={3000}/>
    </Grid>
  )
}