import React, { useState, useEffect } from "react";
import { Grid, Button, useMediaQuery, useTheme, TextField } from "@mui/material";

export default function GroupButtons({ labels, onChange, searchable, disabled }) {

    //STATES
    const [buttonStates, setButtonStates] = useState(labels);
    const [filterValue, setFilterValue] = useState("");
    const [showAll, setShowAll] = useState(false);

    //MEDIA QUERY
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only("xs"));
    const isMd = useMediaQuery(theme.breakpoints.only("md"));
    const isXl = useMediaQuery(theme.breakpoints.only("xl"));
    const buttonSize = isXs ? "small" : isMd ? "medium" : isXl ? "large" : "medium";

    //FUNCTIONS
    const filteredButtons = showAll
        ? buttonStates
        : buttonStates.filter((button) =>
            button.label.toLowerCase().includes(filterValue.toLowerCase())
        );

    const handleFilterChange = (event) => {
        const { value } = event.target;
        setFilterValue(value);
        setShowAll(false); // Ocultar todos los botones cuando se inicia el filtrado
    };

    const handleClick = (index) => {
        const newButtonStates = [...buttonStates]
        newButtonStates[index] = { ...newButtonStates[index], isSelected: !newButtonStates[index].isSelected };
        setButtonStates(newButtonStates);
        onChange(newButtonStates[index])
        setShowAll(true);
        setFilterValue("");
    };

    return (
        <Grid container direction={"row"}>
            {/*filtrar (solo si searchable es true) */}
            {searchable && (
                <Grid item xs={12} mb={2}>
                    <TextField
                        type="text"
                        value={filterValue}
                        onChange={handleFilterChange}
                        placeholder="Filtrar botones..."
                        size="small"
                    />
                </Grid>
            )}
            {/* Renderiza los botones filtrados o todos si showAll es true */}
            {filteredButtons.map((button, index) => (
                <Grid item mr={2} mb={1} key={index}>
                    <Button
                        onClick={() => handleClick(index)}
                        variant={button.isSelected ? "contained" : "outlined"}
                        size={buttonSize}
                        fullWidth
                        disabled={disabled}
                        style={{
                            backgroundColor: button.isSelected ? "#4B799E" : "",
                            color: button.isSelected ? "#fff" : "",
                        }}>
                        {button.label}
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
}
