import { Grid } from '@mui/material'
import React, { Component } from 'react'
import Skeleton from '@mui/material/Skeleton'

/**
 * SKELETON
 */
export default class  SkeletonColegioData extends Component {

  //-------------------------------------------------------
  //-------------------- CLASS FUNCTIONS ------------------
  //-------------------------------------------------------


  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Skeleton variant="rounded" width={"30%"} height={40}/>
            </Grid>

            <Grid item xs={12} mt={2}>
                <Skeleton variant="rounded" width={"20%"} height={30}/>
            </Grid>

            <Grid item xs={12}>
                <Skeleton variant="rounded" width={"100%"} height={300}/>
            </Grid>
        </Grid>
    )
  }
}
