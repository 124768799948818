import React, { useEffect } from 'react'
import { Text } from 'components/texts'
import { Select } from 'components/forms/components'
import { ActividadTemplate } from './parts'
import Statics from 'objects/common/Statics';
import { RequestRegistroActividad } from 'objects/requests'

//VALIDACION FORMULARIO
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import validationSchema from "./validation"
import { Grid } from '@mui/material';
import { SearchButtons } from '../../../components';

const itemsOrder = [{
    value: '{"actividad": "ASC"}', label: "Actividad"
},
{
    value: '{"modalidad_cursos": "ASC"}', label: "Modalidad"
},
{
    value: '{"tipo_actividad": "ASC"}', label: "Tipo de Actividad"
},
{ value: '{"nombre": "ASC"}', label: "Academias" }]

let prepareParamsGlobal = null

//Formulario de busqueda de academias
export default function SearchFormActividadesAcademia({ onLoadingCallback, searchCallback }) {

    //Form
    const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) })

    //State
    const [resetFormState, setResetFormState] = React.useState(false);

    useEffect(() => {
        if (resetFormState == true) {
            resetForm(reset)
            setResetFormState(false)
        }
    })

    //-------------------------------------------------------
    //---------------------- FUNCTIONS ----------------------
    //-------------------------------------------------------

    /**
     * Prepara los parametros que se van a usar
     * 
     * @param {Int} page
     */
    const prepareParams = (page) => {
        const params = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()));
        params.entity_type = 'academia'
        params.relations = 'tipo_actividad,modalidad_cursos,actividad'
        params.page = page

        return params
    }

    prepareParamsGlobal = prepareParams

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    return (
        <Grid container p={2}>
            <Grid container>
                <Grid item xs={4} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Ordenar resultados por:</Text><br />
                    <Select width={"60%"} name={"order"} items={itemsOrder} defaultValue={itemsOrder[0].value} register={register} errors={errors} />
                </Grid>
            </Grid>

            <ActividadTemplate reset={resetFormState} register={register} errors={errors} />

            <SearchButtons searchText={"Buscar actividades"} onResetClick={() => { setResetFormState(true) }} onSubmitClick={handleSubmit(() => { search(1, onLoadingCallback, searchCallback) })} />
        </Grid>
    )
}



/**
 * Hace la busqueda de alumnos
 * 
 * @param {Int}      page
 * @param {Function} onLoadingCallback
 * @param {Function} searchCallback
 * @param {Function} prepareParams
 */
export const search = (page = 1, onLoadingCallback, searchCallback) => {
    //Callback para controlar cuando esta cargando
    if (onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal(page)
    new RequestRegistroActividad().search(params, (res) => {
        if (searchCallback)
            searchCallback(res.actividades.data, res.actividades.total, page, params)
    }, () => {
        searchCallback([], 0, page, params)
    })
}


/**
 * Resetea todo el formulario
 * 
 * @param {Function} reset
 */
const resetForm = (reset) => {
    reset({
        nombre: "",
        nombre_academia: "",
    })
}