import React from 'react'
import { useForm } from 'react-hook-form'
import Requests from './request/Request'
import { useNavigate } from "react-router-dom"
import { Container, Paper } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreateHeader } from 'pages/logged/admin/components'
import { DataFormActividadAcademia } from 'components/forms/display'
import { BreadcrumbsList, Line } from 'components/data_display'
import { validationSchema } from 'components/forms/display/academia/actividades/DataForm/validation'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { Text } from 'components/texts'
import { useRef } from 'react'

export default function Wrapper() {

  const { register, handleSubmit, formState: { errors }, getValues } = useForm({ resolver: yupResolver(validationSchema) })
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <CreateActividadesAcademia register={register} handleSubmit={handleSubmit} errors={errors} getValues={getValues} navigate={navigate} />
      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}

function CreateActividadesAcademia() {

  const { register, formState: { errors }, getValues } = useForm({ resolver: yupResolver(validationSchema) })
  const headerRef = useRef()


  const updateHeaderAndAlert = ({ message, variant = "error" }, loading = false) => {
    headerRef.current.button.setLoading(loading)
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }
  /**
   * Hace la petición para crear los datos del Academia
   */
  const createActividad = () => {
    headerRef.current.button.setLoading(true)

    new Requests().createActividad(getValues, (res) => {
      updateHeaderAndAlert({ message: "Se ha creado la actividad correctamente.", variant: "success" })
    }, (res) => {
      updateHeaderAndAlert({ message: res })
    })
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <BreadcrumbsList breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./", label: "Academias" },
          { to: "./../", label: "Actividad en academia" },
          { to: "./../../", label: "Crear actividad", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <CreateHeader
            ref={headerRef}
            title={"Crear actividad"}
            btnText={"Guardar"}
            handleSubmit={createActividad} />
          <Line className={"my-5"} />
          <DataFormActividadAcademia register={register} errors={errors} isMounted={true} />
        </Paper>
      </form>
    </Container>
  )
}
