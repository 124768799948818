import { AulaData } from './parts'
import React, { useState, useEffect, useContext } from 'react'
import { Container, Paper, Stack } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { BreadcrumbsList, MoveButtons } from 'components/data_display'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import Requests from './Requests/Requests.js'
import { enqueueSnackbar } from 'notistack'
import { Text } from 'components/texts'
import SkeletonAulaData from './parts/AulaData/skeleton'

export default function SingleAula() {
  const navigationParams = useParams()
  const navigate = useNavigate()

  const { items, updateItem } = useContext(EmpleadoListContext)

  const [id_aula, setIdAula] = useState(navigationParams.id_aula)
  const [aula, setAula] = useState()

  const updateId = (id) => {
    navigate(`../academias/aulas/${id}`);
    setIdAula(id);
  }

  const getAula = (getFromServer = false) => {
    const aula = items.find(item => item.id == id_aula)
    if (items.length == 0 || !aula || getFromServer) {
      new Requests().getAula(id_aula, (aula) => {
        setAula(aula)
        updateItem(aula)
      }, () => enqueueSnackbar(<Text>No se ha podido obtener el Aula.</Text>, { variant: "error" }))
    } else {
      setAula(aula)
    }

  }

  useEffect(getAula, [id_aula])

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

      <Stack direction="row" justifyContent={"space-between"} marginX={8}>
        <BreadcrumbsList mx={0} breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "Aulas" },
          { to: "./", label: "Aula #" + (id_aula ?? "0000"), actualItem: true },
        ]} />

        <MoveButtons
          actualItemId={id_aula}
          beforeFetchCallback={() => setAula(null)}
          onUpdateActualItem={updateId} />
      </Stack>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        {aula ?
          <AulaData aula={aula} />
          : <SkeletonAulaData />}
      </Paper>

    </Container>
  )
}
