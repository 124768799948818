import Statics from 'objects/common/Statics'
import { RequestRegistroActividad } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para editar la actividad
     * 
     * @param {Int} id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editActividad(id, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()

        formData.append("nombre", getValues("nombre").toUpperCase())
        formData.append("intensivo", getValues("intensivo") ? 1 : 0)
        formData.append("id_tipo_actividad", getValues("id_tipo_actividad"))
        formData.append("id_actividad", getValues("id_actividad"))
        formData.append("id_modalidad_cursos", getValues("id_modalidad_cursos"))
        formData.append("precio", Statics.changeDotAndComma(getValues("precio"), false))
        formData.append("matricula", Statics.changeDotAndComma(getValues("matricula"), false))
        formData.append("precio_junio", Statics.changeDotAndComma(getValues("precio_junio"), false))

        new RequestRegistroActividad().edit(id, formData, callbackOk, callbackError)
    }


    /**
     * Devuelve la actividad
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getActividad(id, callbackOk = () => { }, callbackError = () => { }) {
        new RequestRegistroActividad().get(id, {}, (res) => {
            res.actividad ? callbackOk(res.actividad) : callbackError(res)
        })
    }
}
