
export const ACTIVIDADES_VALUE = "actividades"
export const PROFESORES_VALUE  = "profesor"

export const itemsSearch = [
    { value: ACTIVIDADES_VALUE, label: "Grupos de los grupos por Actividades"}, 
    { value: PROFESORES_VALUE,  label: "Grupos de los grupos por Profesores" }
]

export const itemsOrder  = {
    actividades: [
        { value: '{"actividad"}', label: "Actividades" }, 
        { value: '{"clave_grupo"}',  label: "Clave de Grupo" }, 
        { value: '{"id"}',           label: "Id. Grupo" }, 
    ],
    profesor: [
        { value: '{"apellido1", "apellido2", "nombre"}', label: "Profesores" }
    ]
}