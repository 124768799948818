import React, { useRef } from 'react'
import { COLOR } from "../../../../theme/Colors";
import { H3, Text } from "../../../texts";
import { LoadingButton, Input } from "../../components";
import { SnackbarAlert } from '../../../data_display';
import { RequestAuth } from "../../../../objects/requests";
import { publicRoutes } from '../../../../router/routes';
//VALIDACION FORMULARIO
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import validationSchema from "./validation"

export default function ChangePassForm({ token, setEnded }) {

    const { register, handleSubmit, formState: { errors }, getValues } = useForm({ resolver: yupResolver(validationSchema) })
	
	const buttonRef 	   = useRef(null);
	const snackBarAlertRef = useRef(null);

    /**
	 * Hace la petición para cambiar la contraseña
	 */
	const changePass = () => {
		buttonRef.current.setLoading(true)

		var formData = new FormData()
		formData.append("password", getValues('password'))
		formData.append("token", 	token)

		var requestAuth = new RequestAuth()
		requestAuth.changePass(formData, (res) => {
			setEnded(true)
			
			buttonRef.current.setLoading(false)
		}, (res) => {
			snackBarAlertRef.current.updateState({message: res, severity: "error", open: true})

			buttonRef.current.setLoading(false)
		})
	}

    //RETURN
    return (
        <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
            <H3>Cambiar contraseña</H3>
            <Text>Escribe la nueva contraseña para cambiarla.</Text>

            <div className="d-flex flex-column my-2">
				<Input label="Nueva contraseña" type="password" className="my-2" register={register} errors={errors} name={"password"} />
				
                <Input label="Repetir contraseña" type="password" className="my-2" register={register} errors={errors} name={"repassword"} />

                <LoadingButton
					ref		  = {buttonRef}
					className = {"mt-5 align-self-start"}
					size	  = "large"
					onClick	  = {handleSubmit(changePass)}>

					Actualizar contraseña
				</LoadingButton>

                <SnackbarAlert ref={snackBarAlertRef}/>
            </div>
        </form>
    )
}