import { Link } from 'components/data_display'
import { Button } from 'components/forms/components'

export const columns = [
    {
        field: "actions",
        type: 'actions',
        headerName: "Acciones",
        minWidth: 300,
        getActions: (params) => [<Link to={"" + params?.row?.id}><Button size={"small"}>Ver</Button></Link>, <Link to={"" + params?.row?.id + "/editar"}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>]
    },

    { field: "id", headerName: "ID", maxWidth: 250 },
    {
        field: "Alumno", headerName: "Alumno", maxWidth: 250, flex: 1, valueGetter: (params) => {
            return params?.row?.alumno?.nombre+ ' ' + params?.row?.alumno?.apellido1 + ' ' + params?.row?.alumno?.apellido2
        }
    },
    {
        field: "Año", headerName: "Año", maxWidth: 250, flex: 1, valueGetter: (params) => {
            return params?.row?.curso_escolar?.valor
        }
    },
    {
        field: "registro_actividad_id", headerName: "Actividad", maxWidth: 250, flex: 1, valueGetter: (params) => {
            return params?.row?.actividad?.nombre
        }
    },
]