import RequestMaster from '../RequestMaster'

export default class RequestAula extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/aula'
    }

    /**
     * Hace la busqueda de las aulas
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    search(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.get(path, params, callback, callbackError, callbackFinally)
    }

    /**
        * Devuelve todos las aulas
        *
        * @param {FormData} params
        * @param {Function} callback
        * @param {Function} callbackError
        * @param {Function} callbackFinally
        */
    all(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "all"
        this.get(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Crea un aula
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    create(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    /**
   * Hace el edit del aula
   *
   * @param {FormData} params
   * @param {Function} callback
   * @param {Function} callbackError
   * @param {Function} callbackFinally
   */
    edit(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "" + id
        this.put(path, params, callback, callbackError, callbackFinally)
    }
}
