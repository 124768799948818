import moment from 'moment'
import Statics from 'objects/common/Statics'
import { Link } from 'components/data_display'
import { Button } from 'components/forms/components'
import { PermisosButton } from './parts'

export const basicColumns = (roles) => {
    return [
        {
            field: "actions",
            type: 'actions',
            headerName: "Acciones",
            minWidth: roles.includes('admin') ? 400 : 200,
            getActions: (params) => {
                const actions = [
                    <Link to={"" + params.row.id}><Button size={"small"}>Ver</Button></Link>, 
                    <Link to={"" + params.row.id + "/editar"}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>,
                ]
                
                if(roles.includes('admin'))
                    actions.push(<PermisosButton row={params.row}/>)

                return actions
            }
        },
        { field: "id", headerName: "ID", maxWidth: 65 },
        { field: "tipo_documento", headerName: "Tipo de documento", minWidth: 100, valueGetter: (params) => { return params.row.tipo_documento?.nombre } },
        { field: "numero_documento", headerName: "Número", minWidth: 100 },
        { field: "activo", headerName: "Activo", maxWidth: 50, valueGetter: (params) => { 
            const { user } = params.row
            if(!user)
                return "No"
    
            const today = moment().format('YYYY-MM-DD');
            return (user.fecha_inicio == null || user.fecha_inicio > today || (user.fecha_inicio && (user.fecha_fin != null && user.fecha_fin <= today))) ? "No" : "Sí"
        }},
        { field: "fecha_inicio", headerName: "Fecha Inicio", maxWidth: 100, valueGetter: (params) => { 
            const { user } = params.row
            if(!user) return ""
    
            return Statics.formatDate(user.fecha_inicio)
        }},
        { field: "fecha_fin", headerName: "Fecha Fin", maxWidth: 100, valueGetter: (params) => { 
            const { user } = params.row
            if(!user) return ""
    
            return Statics.formatDate(user.fecha_fin)
        }},
    ]
} 

export const columnsDatosPersonales = [
    { field: "apellido1", headerName: "1. Apellido", minWidth: 150,},
    { field: "apellido2", headerName: "2. Apellido", minWidth: 150, },
    { field: "nombre", headerName: "Nombre", minWidth: 150, },
    { field: "email", headerName: "Email", minWidth: 200,},
    { field: "sexo", headerName: "Sexo", minWidth: 100, valueGetter: (params) => { return params.row.sexo?.valor } },
]

export const columnsDomicilio = [
    { field: "nombre_completo", headerName: "Nombre", minWidth: 100, flex: 1, valueGetter: (params) => { return (params.row.nombre ?? "") + " " + (params.row.apellido1 ?? "") + " " + (params.row.apellido2 ?? "") } },
    { field: "direccion", headerName: "Domicilio", minWidth: 100, flex: 1, },
    { field: "cod_postal", headerName: "C.P.", minWidth: 100, },
    { field: "municipio", headerName: "Municipio", minWidth: 100, flex: 1 },
    { field: "provincia", headerName: "Provincia", minWidth: 100, valueGetter: (params) => { return params.row.provincia?.nombre }, flex: 1 },
    { field: "tipo_via", headerName: "Tipo vía", minWidth: 100, valueGetter: (params) => { return params.row.tipo_via?.valor }, flex: 1 },
]