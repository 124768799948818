import { COLOR } from 'theme/Colors'
import { TabContext } from '@mui/lab'
import { Text } from 'components/texts'
import { Header, LibroData } from './parts'
import Requests from './Requests/Requests.js'
import { useParams, useNavigate } from 'react-router-dom'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import React, { useState, useEffect, useContext } from 'react'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { Box, Container, Paper, Stack, Tab, Tabs } from '@mui/material'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { BreadcrumbsList, MoveButtons, Line } from 'components/data_display'
import { DataLibroNiveles, DataLibroProgramaciones, DataLibroAlmacen } from 'components/data_display/libro'
import { DataFormLibroSkeleton, DataFormLibroNivelesSkeleton, DataFormControlAlmacenSkeleton, DataFormLibroProgramacionesSkeleton } from 'components/forms/display/libro/libro/skeletons'


export default function SingleAlumnos() {
  const navigationParams = useParams()
  const navigate = useNavigate()

  const { items } = useContext(EmpleadoListContext)

  const [id_libro, setIdLibro] = useState(navigationParams.id_libro)
  const [libro,    setLibro]   = useState(null)
  const [tab,      setTab]     = useState("1")

  /**
   * Hace la petición para obtener el usuario
   */
  const getLibro = () => {
    const item = items.find(item => item?.id == id_libro)
    if(items.length == 0 || !item) {
      new Requests().getLibro(id_libro, setLibro, () => enqueueSnackbar(<Text>No se ha podido recuperar el libro.</Text>, { variant: "error" }))
    } else {
      setLibro(item)
    }
  }
  
  const updateIdAlumno = (id) => {
    navigate(`../libros/${id}`)
    setIdLibro(id)
  }

  useEffect(getLibro, [id_libro])

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/",     label: "Inicio" },
            { to: "./../", label: "Libros" },
            { to: "./",    label: "Libro #" + id_libro, actualItem: true },
          ]} />

          <MoveButtons 
            actualItemId        = {id_libro}
            beforeFetchCallback = {() => setLibro(null)} 
            onUpdateActualItem  = {updateIdAlumno} />
        </Stack>
        
        <Paper elevation={2} sx={{mx: 8, my: 2, p: 5}}>
         {libro ? <Header libro={libro}/> : null}

          <Line className={"my-5"}/>

          {libro ? <LibroData libro={libro} /> : <DataFormLibroSkeleton />}

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={5}>
              <Tabs value={tab} onChange={(_, tab) => setTab(tab)} TabIndicatorProps={{style: {backgroundColor: COLOR.standard.main}}}>
                <Tab label={<Text fontWeight={tab == "1" ? "bold" : null} color={tab == "1" ? COLOR.standard.main : null}>CONTROL DEL AMACÉN</Text>} value={"1"}/>
                <Tab label={<Text fontWeight={tab == "2" ? "bold" : null} color={tab == "2" ? COLOR.standard.main : null}>NIVELES</Text>}            value={"2"}/>
                <Tab label={<Text fontWeight={tab == "3" ? "bold" : null} color={tab == "3" ? COLOR.standard.main : null}>PROGRAMACIONES</Text>}     value={"3"}/>
              </Tabs>
            </Box>

            <TabPanel value={"1"}>
              {libro ? <DataLibroAlmacen libro={libro}/> : <DataFormControlAlmacenSkeleton />}
            </TabPanel>

            <TabPanel value={"2"}>
              {libro ? <DataLibroNiveles libro={libro} /> : <DataFormLibroNivelesSkeleton/>}
            </TabPanel>

            <TabPanel value={"3"}>
              {libro ? <DataLibroProgramaciones libro={libro} /> : <DataFormLibroProgramacionesSkeleton />}
            </TabPanel>
          </TabContext>
        </Paper>

        <SnackbarProvider autoHideDuration={3000} />
      </Container>
  )
}
