import React, { useContext, useEffect, useRef, useState } from 'react'
import Requests from './Requests/Requests.js'
import { Box, Container, Paper, Stack, Tab, Tabs } from '@mui/material'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { EditHeader } from 'pages/logged/admin/components'
import { DataFormInscripcionClasesParticulares, DataFormPagoClasesParticulares, DataFormLibroClasesParticulares, DataFormAnotacionesClasesParticulares, DataFormHorariosClasesParticulares } from 'components/forms/display/index.js'
import { useNavigate, useParams } from 'react-router-dom'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { TabContext } from '@mui/lab'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { COLOR } from 'theme/Colors.js'
import { Text } from 'components/texts/index.js'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from 'components/forms/display/clases-particulares/DataForm/validation.js'
import { DataFormInscripcionClasesParticularesSkeleton, DataFormPagoClasesParticularesSkeleton, DataFormLibroClasesParticularesSkeleton, DataFormAnotacionesClasesParticularesSkeleton, DataFormHorariosClasesParticularesSkeleton } from './Skeleton'

/**
 * Pagina para editar una clase particular
 */
export default function Wrapper() {
    //PARAMS
    const { id_clase_particular } = useParams()

    //CONTEXT
    const { items, updateItem } = useContext(EmpleadoListContext)

    //STATE
    const [claseParticular, setClaseParticular] = useState(null)
    const [tab, setTab] = useState("1")
    const [idClaseParticular, setIdClaseParticular] = useState(id_clase_particular)

    const updateAlert = (message, variant = "error") => {
        enqueueSnackbar(<Text>{message}</Text>, { variant })
    }

    const getClaseParticular = (getFromServer = false) => {
        const item = items.find(item => item?.id == idClaseParticular)
        if (items.length == 0 || !item || getFromServer) {
            new Requests().getClaseParticular(idClaseParticular, (claseParticular) => {
                setClaseParticular({ ...claseParticular })
                updateItem(claseParticular)
            }, () => updateAlert("No se ha podido recuperar la clase particular."))
        } else {
            setClaseParticular(item)
        }
    }

    useEffect(getClaseParticular, [idClaseParticular])

    return (
        <React.Fragment>
            <EditClasesParticulares key={claseParticular?.id ?? 0} claseParticular={claseParticular} idClaseParticular={idClaseParticular} setIdClaseParticular={setIdClaseParticular} setClaseParticular={setClaseParticular} tab={tab} setTab={setTab} updateAlert={updateAlert} onSaveFinish={() => getClaseParticular(true)} />

            <SnackbarProvider autoHideDuration={3000} />
        </React.Fragment>
    )
}

function EditClasesParticulares({ claseParticular, idClaseParticular, setIdClaseParticular, setClaseParticular, updateAlert, tab, setTab, onSaveFinish }) {
    //NAVIGATE
    const navigate = useNavigate()

    const defaultValues = {profesor_id: claseParticular?.profesor_id ?? ""}

    //HOOKS
    const { register, handleSubmit, formState: { errors }, getValues, control, setValue, clearErrors } = useForm({ resolver: yupResolver(validationSchema), defaultValues })

    //STATE
    const [lugarClase, setLugarClase] = useState(claseParticular?.lugar_clase_id ?? null)

    //REQUESTS
    const requests = new Requests()

    //REFS
    const header = useRef()
    const dataFormPagosRef = useRef()

    //-------------------------------------------------------
    //----------------------- REQUESTS ----------------------
    //-------------------------------------------------------

    /**
     * Hace la petición para obtener la clase particular
     * 
     * @param {Function} callback
     */
    const getClaseParticular = () => {
        requests.getClaseParticular(idClaseParticular, (clase_particular) => {
            setClaseParticular(clase_particular)
        }, (res) => {
            enqueueSnackbar(<Text>{"No se ha podido cargar la clase particular: " + res}</Text>, { variant: "error" })
        })
    }

    useEffect(getClaseParticular, [])

    /**
     * Hace la petición para editar la clase particular
     * 
     * @param {Function} callback
     */
    const editClaseParticular = (pagos) => {
        header.current.button.setLoading(true)
        
        requests.editClaseParticular(idClaseParticular, getValues, (res) => {
            if (res.item)
                enqueueSnackbar(<Text>{"Se ha editado la clase particular correctamente."}</Text>, { variant: "success" })

            const promisePagos = new Promise((resolve, reject) => {
                requests.createPagos(res.item.id, pagos, resolve, reject);
            })

            const promiseLibro = new Promise((resolve, reject) => {
                requests.crudLibro(claseParticular, getValues, resolve, reject)
            })

            const promiseLibroAsociado = new Promise((resolve, reject) => {
                requests.crudLibroAsociado(claseParticular, getValues, resolve, reject)
            })

            Promise.allSettled([promiseLibro, promiseLibroAsociado, promisePagos]).then((values) => {
                for (const fail of values.filter(item => item.status == "rejected"))
                    enqueueSnackbar(<Text>{fail.reason}</Text>, { variant: "error" })

                header.current.button.setLoading(false)
                onSaveFinish()
            })

        }, (res) => {
            enqueueSnackbar(<Text>{"No se ha podido editar la clase particular. " + res}</Text>, { variant: "error" })
            header.current.button.setLoading(false)
        })
    }

    const updateIdClaseParticular = (id) => {
        navigate(`../clases_particulares/${id}/editar`)
        setIdClaseParticular(id)
    }

    const handleSubmitCustom = async (e) => {
        let handleCallbacks = handleSubmit(() => editClaseParticular(getValues("pagos")), (errors) => {
            enqueueSnackbar(<Text>{"No se ha podido editar la clase particular. Revisa los campos requeridos."}</Text>, { variant: "error" })
        })
        handleCallbacks(e)
    }

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} marginX={8}>
                    <BreadcrumbsList mx={0} breadcrumbsList={[
                        { to: "/", label: "Inicio" },
                        { to: "./../", label: "Clases particulares" },
                        { to: "./", label: "Clase particular #" + idClaseParticular },
                        { to: "./", label: "Editar", actualItem: true },
                    ]} />

                    <MoveButtons
                        actualItemId={idClaseParticular}
                        beforeFetchCallback={() => setClaseParticular(null)}
                        onUpdateActualItem={updateIdClaseParticular} />
                </Stack>

                <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
                    <EditHeader
                        ref={header}
                        title={"Editar clase particular"}
                        item={claseParticular}
                        handleSubmit = {handleSubmitCustom} />

                    <Line className={"mb-5"} />

                    <TabContext value={tab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                            <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                                <Tab label={<Text fontWeight={tab == "1" ? "bold" : null} color={tab == "1" ? COLOR.standard.main : null}>DATOS DE LA INSCRIPCIÓN</Text>} value={"1"} />
                                <Tab label={<Text fontWeight={tab == "2" ? "bold" : null} color={tab == "2" ? COLOR.standard.main : null}>DATOS DE LOS PAGOS</Text>} value={"2"} />
                                <Tab label={<Text fontWeight={tab == "3" ? "bold" : null} color={tab == "3" ? COLOR.standard.main : null}>LIBROS</Text>} value={"3"} />
                                <Tab label={<Text fontWeight={tab == "4" ? "bold" : null} color={tab == "4" ? COLOR.standard.main : null}>ANOTACIONES</Text>} value={"4"} />
                                <Tab label={<Text fontWeight={tab == "5" ? "bold" : null} color={tab == "5" ? COLOR.standard.main : null}>HORARIOS</Text>} value={"5"} />
                            </Tabs>
                        </Box>

                        <TabPanel value={"1"}>
                            {claseParticular ?
                                <DataFormInscripcionClasesParticulares
                                    setValue={setValue}
                                    getValues={getValues}
                                    errors={errors}
                                    register={register}
                                    clearErrors={clearErrors}
                                    control={control}
                                    isMounted={true}
                                    setLugarClase={setLugarClase}
                                    claseParticular={claseParticular} /> : <DataFormInscripcionClasesParticularesSkeleton />}
                        </TabPanel>

                        <TabPanel value={"2"}>
                            {claseParticular ?
                                <DataFormPagoClasesParticulares
                                    ref={dataFormPagosRef}
                                    setValue={setValue}
                                    getValues={getValues}
                                    errors={errors}
                                    register={register}
                                    clearErrors={clearErrors}
                                    control={control}
                                    isMounted
                                    claseParticular={claseParticular} /> : <DataFormPagoClasesParticularesSkeleton />}
                        </TabPanel>

                        <TabPanel value={"3"}>
                            {claseParticular ?
                                <DataFormLibroClasesParticulares
                                    setValue={setValue}
                                    getValues={getValues}
                                    errors={errors}
                                    register={register}
                                    clearErrors={clearErrors}
                                    control={control}
                                    isMounted
                                    // setLibroSolicitado={setLibroSolicitado}
                                    // setLibroAsociadoSolicitado={setLibroAsociadoSolicitado}
                                    claseParticular={claseParticular} /> : <DataFormLibroClasesParticularesSkeleton />}
                        </TabPanel>

                        <TabPanel value={"4"}>
                            {claseParticular ?
                                <DataFormAnotacionesClasesParticulares
                                    setValue={setValue}
                                    getValues={getValues}
                                    errors={errors}
                                    register={register}
                                    clearErrors={clearErrors}
                                    control={control}
                                    isMounted
                                    claseParticular={claseParticular} /> : <DataFormAnotacionesClasesParticularesSkeleton />}
                        </TabPanel>

                        <TabPanel value={"5"}>
                            {claseParticular ?
                                <DataFormHorariosClasesParticulares
                                    setValue={setValue}
                                    register={register}
                                    getValues={getValues}
                                    errors={errors}
                                    handleSubmit={handleSubmit}
                                    clearErrors={clearErrors}
                                    isMounted
                                    control={control}
                                    lugarClase={lugarClase}
                                    claseParticular={claseParticular} /> : <DataFormHorariosClasesParticularesSkeleton />}

                        </TabPanel>
                    </TabContext>
                </Paper>

                <SnackbarProvider autoHideDuration={3000} />
            </form>
        </Container>
    )
}