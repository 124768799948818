import React, { Component } from 'react'
import { MenuItem, MenuItemGroup } from '../../../../../../../components/navigation'
import { 
    Send as SendIcon,
    Toc as DatosColegioIcon,
} from '@mui/icons-material'

export default class AlumnoMenu extends Component {

    /**
     * ComponentDidMount
     * 
     * Inicializamos los componentes necesarios del tema
     */
    componentDidMount() {
        window.KTMenu?.createInstances()
        window.KTToggle?.createInstances()
    }


	/**
	 * Render
	 * 
	 * @returns {Component}
	 */
	render() {
		return (
            <div className="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
                <div className="menu-item pt-5">
                    <div className="menu-content">
                        <span className="menu-heading fw-bold text-uppercase fs-7">Menú libros</span>
                    </div>
                </div>

                <MenuItem title="Generar envíos" to="./libros/generar-envios" icon={<SendIcon />}/>
                <MenuItem title="Listados" to="./libros/listados" icon={<DatosColegioIcon />} />

            </div>
		)
	}
}
