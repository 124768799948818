import Statics from 'objects/common/Statics'
import { RequestRegistroActividad } from "objects/requests"

export default class Requests {

    /**
     * Hace la petición para editar la actividad
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createActividad(getValues, callbackOk = () => { }, callbackError = () => { }) {
        var formData = new FormData()
        formData.append("entity_type", "academia")
        formData.append("nombre", getValues("nombre").toUpperCase())
        formData.append("intensivo", getValues("intensivo") ? 1 : 0)
        formData.append("tipo_actividad_id", getValues("id_tipo_actividad"))
        formData.append("actividad_id", getValues("id_actividad"))
        formData.append("modalidad_cursos_id", getValues("id_modalidad_cursos"))
        formData.append("precio", Statics.changeDotAndComma(getValues("precio"), false))
        formData.append("matricula", Statics.changeDotAndComma(getValues("matricula"), false))
        formData.append("precio_junio", Statics.changeDotAndComma(getValues("precio_junio"), false))

        new RequestRegistroActividad().create(formData, callbackOk, callbackError)
    }
}













