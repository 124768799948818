import { H3 } from 'components/texts'
import React, { Component } from 'react'
import { Grid, Skeleton } from '@mui/material'
import { Line } from 'components/data_display'
import TableMesesPagados from './parts/TableMesesPagados'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { Input, DatePicker, Checkbox } from 'components/forms/components'

/**
 * Formulario con los datos generales de la inscripcion a un colegio
 */
export default class PagoInscripcionColegioDataForm extends Component {

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    componentDidUpdate(prevProps) {
        if(this.props.actividad?.id != prevProps.actividad?.id) 
            this.updateActividadPrecios(this.props.actividad)
    }

    /**
     * Actualiza los campos de precios con los de la actividad
     */
    updateActividadPrecios = (actividad) => {
        this.cantidad_matricula.setValue(actividad?.matricula ?? "")
        this.props.setValue("cantidad_matricula", actividad?.matricula ?? "")

        this.cantidad.setValue(actividad?.precio ?? "")
        this.props.setValue("cantidad", actividad?.precio ?? "")
    }

    /**
     * Render
     *
     * @returns {Component}
    */
   render() {
    const { inscripcion, isMounted, register, errors, setValue } = this.props

    return (
        <Grid container py={3}>
            <Grid item xs={12} px={3}>
                {isMounted ? 
                    <ItemsSelect 
                        name         = {"id_forma_pago"} 
                        keyValue     = {"forma_pago"}
                        register     = {register} 
                        errors       = {errors}
                        className    = {"w-50"}
                        defaultValue = {inscripcion?.id_forma_pago ?? ""}  /> : <Skeleton variant="rounded" width={"100%"} height={40} />}
            </Grid>

            <Grid item xs={12} mt={3} px={3}>
                <Line />
            </Grid>

            <Grid container item xs={12} xl={7} p={3} spacing={2} display={"flex"} alignItems={"center"}>
                <Grid item xs={4}>
                    {isMounted ?
                        <ItemsSelect 
                            name         = {"id_tipo_matricula"}
                            keyValue     = {"tipo_matricula"}
                            register     = {register} 
                            errors       = {errors}
                            className    = {"w-100"}
                            defaultValue = {inscripcion?.id_tipo_matricula ?? ""} /> : <Skeleton variant="rounded" width={"100%"} height={40} />}
                </Grid>

                <Grid item xs={4}>
                    <Input 
                        ref          = {(ref) => this.cantidad_matricula = ref} 
                        label        = "Cantidad matrícula" 
                        register     = {register} 
                        errors       = {errors} 
                        className    = "w-100" 
                        name         = {"cantidad_matricula"} 
                        defaultValue = {inscripcion?.cantidad_matricula ? inscripcion.cantidad_matricula : ""}
                        type         = {"number"}
                        uppercase 
                        useValue />
                </Grid>

                <Grid item xs={4}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_pago_matricula"} label={"Fecha de pago"} defaultValue={inscripcion?.fecha_pago_matricula} setValue={setValue} clearable/>
                </Grid>

                <Grid item xs={6}>
                    {isMounted ?
                        <ItemsSelect 
                            name         = {"id_tipo_pago"}
                            keyValue     = {"tipo_pago"}
                            register     = {register} 
                            errors       = {errors}
                            defaultValue = {inscripcion?.id_tipo_pago ?? ""} /> : <Skeleton variant="rounded" width={"100%"} height={40} />}
                </Grid>
                <Grid item xs={6}>
                    <Input 
                        ref          = {(ref) => this.cantidad = ref} 
                        label        = "Cantidad"
                        register     = {register} 
                        errors       = {errors} 
                        className    = "w-100" 
                        name         = {"cantidad"} 
                        defaultValue = {inscripcion?.cantidad ? inscripcion.cantidad : ""} 
                        type         = {"number"}
                        uppercase 
                        useValue/>
                </Grid>
            </Grid>

            <Grid container item xs={12} xl={5} style={{borderLeft: "1px solid #D9D9D9"}} p={3}>
                <Grid item xs={12}>
                    <H3>Puertas abiertas</H3>
                </Grid>

                <Grid item xs={4}>
                    <Checkbox name={"incluido_seneca"} label={"Incluido en SENECA"} register={register} defaultChecked={inscripcion?.incluido_seneca ? true : false} />
                </Grid>

                <Grid item xs={4}>
                    <Checkbox name={"aplicar_subvencion"} label={"Aplicar subvención"} register={register} defaultChecked={inscripcion?.aplicar_subvencion ? true : false} />
                </Grid>

                <Grid item xs={4} />

                <Grid item xs={4}>
                    <Checkbox name={"subvencionado"} label={"Subvencionado"} register={register} defaultChecked={inscripcion?.alumno?.subvencion_ppaa?.subvencionado ? true : false} />
                </Grid>

                <Grid item xs={4}>
                    <Checkbox name={"comprobado"} label={"Comprobado"} register={register} defaultChecked={inscripcion?.alumno?.subvencion_ppaa?.comprobado ? true : false} />
                </Grid>

                <Grid item xs={4}>
                    <Checkbox name={"definitiva"} label={"Definitiva"} register={register} defaultChecked={inscripcion?.alumno?.subvencion_ppaa?.subvencion_definitiva ? true : false} />
                </Grid>

                <Grid container mt={1} spacing={2}>
                    <Grid item xs={6}>
                        {isMounted ?
                            <ItemsSelect 
                                name         = {"id_tipo_subvencion"}
                                keyValue     = {"tipo_subvencion"}
                                register     = {register} 
                                errors       = {errors}
                                defaultValue = {inscripcion?.alumno?.subvencion_ppaa?.id_tipo_subvencion ?? ""} /> : <Skeleton variant="rounded" width={"100%"} height={40} />}
                    </Grid>

                    <Grid item xs={6}>
                        <DatePicker register={register} errors={errors} className="w-100" name={"fecha_ppaa"} label={"Fecha"} defaultValue={inscripcion?.alumno?.subvencion_ppaa?.fecha} setValue={setValue} clearable/>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container mx={3} mt={5} pb={2} sx={{overflowX: "auto"}} className={"darker-scrollbar"}>
                <TableMesesPagados inscripcion={inscripcion} setValue={this.props.setValue} register={register} />
            </Grid>

            <Grid container px={3} mt={5}>
                <Input placeholder={"Observaciones de los pagos..."} register={register} errors={errors} className="w-100" name={"observaciones_pagos"} defaultValue={inscripcion?.observaciones_pagos ?? null}  multiline minRows={6} maxRows={10} uppercase/>
            </Grid>
        </Grid>
    )
   }
}
