import { Text } from 'components/texts'
import React, { Component } from 'react'
import { Grid, Stack } from '@mui/material'
import Modal from 'components/modals/Modal.jsx'
import { Button } from 'components/forms/components'


export default class AlertModal extends Component {

    constructor(props) {
        super(props)
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Modal ref={(ref) => this.modal = ref} title={"¡Demasiados registros!"}>
                <Grid container px={10} py={5}>
                    <Grid item xs={12} display="flex" justifyContent={"center"}>
                        <Text>{`¿Estás seguro de que quieres continuar? Vas a exportar ${this.props.rowCount} registros, esta operación puede tardar un rato.`}</Text>
                    </Grid>

                    <Grid item xs={12} display="flex" justifyContent={"center"}>
                        <Stack display="flex" flexDirection={"row"} mt={2}>
                            <Button color={"cancel"} className="mx-2" onClick={this.props.onGoBack}>
                                Volver
                            </Button>

                            <Button className="mx-2" onClick={this.props.onContinue}>
                                Continuar
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Modal>
        )
    }
}
