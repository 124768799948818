import { Grid } from '@mui/material';
import React, { Component } from 'react'
import { LoadingButton } from 'components/forms/components';
import { H3, Text } from 'components/texts';

/**
 * Header del editar incidencia, tutoria y datos-colegio
 */
export default class EditHeader extends Component {

  constructor(props) {
    super(props)

    this.state = {
      item: this.props.item ?? null
    }
  }


  /**
   * ComponentDidUpdate
   * 
   * @param {Object} prevProps 
   */
  componentDidUpdate(prevProps) {
    if(this.props.item != prevProps.item)
      this.setState({item: this.props.item})
  }


  /**
   * Callback para el envío del formulario
   * 
   * @param {Event} e 
   */
  handleSubmit = (e) => {
    this.props.handleSubmit(e)
  }

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
      <Grid container alignItems={"center"} px={4} py={2}>
        <Grid item xs={10} display={"flex"}>
          <H3 className={"m-0"}>{this.props.title ?? ""}</H3>
          <Text className="standard mx-5">{this.state.item ? "#" + this.state.item.id : ""}</Text>
        </Grid>

        {this.state.item ? (
          <Grid item xs={2} display={"flex"} justifyContent={"end"}>
            <LoadingButton ref={(ref) => this.button = ref} color={'success'} size={"small"} onClick={(e) => {this.handleSubmit(e)}}>
              Guardar cambios
            </LoadingButton>
          </Grid>
        ) : null}
      </Grid>
    )
  }
}
