import React from 'react'
import { H3 } from 'components/texts'
import { FORMATS } from './constants'
import { Grid, Stack } from '@mui/material'
import Statics from 'objects/common/Statics'
import { Line } from 'components/data_display'
import { RequestFile } from 'objects/requests'
import { Button, Input } from 'components/forms/components'

/**
 * Formulario con los datos de las programaciones de un libro
 */
export default function DataFormLibroProgramaciones({ libro }) {

    /**
     * Abre el archivo seleccionado en una pestaña nueva
     * 
     * @param {String} value 
     * @param {Ref} ref 
     */
    const showFile = (field) => {
        if(libro[field]) {
            const array = libro[field].split('.')
            const type = array[array.length - 1]
            new RequestFile().file(libro[field], FORMATS["." + type], {}, (data) => Statics.openFileInExplorer(data, FORMATS["." + type]))
        }
    }


  /**
   * Render
   *
   * @returns {Component}
   */
  return (
    <Grid container pb={5}>
        <Grid container spacing={2} my={2}>
            <Grid item xs={12} xl={8}>
                <Stack>
                    <H3>1º Trimestre</H3>
                    <Input className="w-100" defaultValue={libro?.programacion_1t ?? "..."} disabled/>
                </Stack>
            </Grid>

            <Grid item xs={12} xl={4} display={"flex"} alignItems={"end"}>
                <Stack direction={"row"} spacing={2} className={"w-100"}>
                    <Button className={"w-100 py-3"} color={"cancel"} onClick={() => showFile("programacion_1t")}>
                        Ver programación
                    </Button>
                </Stack>
            </Grid>

        </Grid>

        <Grid item xs={12} mt={2}>
            <Line />
        </Grid>

        <Grid container spacing={2} my={2}>
            <Grid item xs={12} xl={8}>
                <Stack>
                    <H3>2º Trimestre</H3>
                    <Input className="w-100" defaultValue={libro?.programacion_2t ?? "..."} disabled/>
                </Stack>
            </Grid>

            <Grid item xs={12} xl={4} display={"flex"} alignItems={"end"}>
                <Stack direction={"row"} spacing={2} className={"w-100"}>
                    <Button className={"w-100 py-3"} color={"cancel"} onClick={() => showFile("programacion_2t")}>
                        Ver programación
                    </Button>
                </Stack>
            </Grid>
        </Grid>

        <Grid item xs={12} mt={2}>
            <Line />
        </Grid>

        <Grid container spacing={2} my={2}>
            <Grid item xs={12} xl={8}>
                <Stack>
                    <H3>3º Trimestre</H3>
                    <Input className="w-100" defaultValue={libro?.programacion_3t ?? "..."} disabled />
                </Stack>
            </Grid>

            <Grid item xs={12} xl={4} display={"flex"} alignItems={"end"}>
                <Stack direction={"row"} spacing={2} className={"w-100"}>
                    <Button className={"w-100 py-3"} color={"cancel"} onClick={() => showFile("programacion_3t")}>
                        Ver programación
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    </Grid>
  )
}