import React, { Component } from 'react'
import { Modal } from '../../../../../../../../components/modals'
import Requests from '../../Requests'
import { Button } from '../../../../../../../../components/forms/components'
import { Grid } from '@mui/material'
import { Text } from '../../../../../../../../components/texts'

/**
 * Modal para borrar eventos
 */
export default class DeleteEventModal extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            evento: null
        }

        this.requests = new Requests
    }


    /**
     * Abre el modal y actualiza la evento
     * 
     * @param {String} event
     */
    open = (evento) => {
        this.setState({evento}, () => {
            this.modal.open()
        })
    }


    /**
     * Cierra el modal
     */
    close = () => {
        this.setState({evento: null}, () => {
            this.modal.close()
        })
    }

    
    /**
     * Callback para borrar un evento
     * 
     * @param {Array} values
     */
    delete = () => {
        this.requests.deleteEvento(this.state.evento.id, (res) => {
            if(this.props.onDelete)
                this.props.onDelete()
        }, (res) => {
            if(this.props.showSnackBar)
                this.props.showSnackBar("No se ha podido borrar el evento: " + res, "error")
        })
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Modal ref={(ref) => this.modal = ref} title={"Borrar evento"} style={{minWidth: "40%"}}>
                <Grid container p={5}>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <Text>¿Estás seguro/a de que quieres borrar el evento "{(this.state.evento ? this.state.evento.title : "")}"?</Text>
                    </Grid>

                    <Grid item xs={12} display={"flex"} justifyContent={"end"} mt={2}>
                        <Button color={"error"} onClick={this.delete}>
                            Borrar
                        </Button>
                    </Grid>
                </Grid>
            </Modal>
        )
    }
}
