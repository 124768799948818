import React from 'react'
import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import { Select } from 'components/forms/components'

/**
 * Esta es la plantilla para cuando se busque por los datos del domicilio del alumno
 */
const roles = [
    {value: 'admin',       label: 'Admin'},
    {value: 'profesor',    label: 'Profesor'},
    {value: 'coordinador', label: 'Coordinador'},
]

export default function RolTemplate({ register }){
    return (
        <React.Fragment>
            <Grid container mt={2}>
                <Text fontWeight={'bold'}>Por roles:</Text><br />
            </Grid>

            <Grid container>
                <Grid item xs={12} md={4} mt={1}>
                    <Select label={"Rol"} name={"roles"} items={roles} register={register} multiple />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
