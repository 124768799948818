import React, { Component } from 'react'
import Skeleton from '@mui/material/Skeleton'

/**
 * Informacion básica del alumno
 */
export default class SkeletonAlumno extends Component {

  //-------------------------------------------------------
  //-------------------- CLASS FUNCTIONS ------------------
  //-------------------------------------------------------


  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
        <>
            <div className="row">
                <div className='col-md-12'>
                    <Skeleton variant="text" sx={{ fontSize: '2em' }} width={300}/>
                </div> 
            </div>

            <div className="row mt-5">
                <div className='col-md-3'>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={100}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={100}/>
                </div>

                <div className='col-md-3'>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={300}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={300}/>
                </div>

                <div className='col-md-3'>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={300}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={300}/>
                </div>

                <div className='col-md-3'>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={300}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={300}/>
                </div>
            </div>
        </>
    )
  }
}
