import React, { Component } from 'react'
import { Text } from 'components/texts'
import { ASSETS } from 'constants/Assets'
import { Link } from 'components/data_display'
import { Avatar, Box, Grid, Paper, Tooltip } from '@mui/material'
import { Visibility as SeeIcon, OpenInNewOff as RemoveAsociationIcon } from '@mui/icons-material'

/**
 * Item que muestra la información de un alumno
 */
export default class AlumnoItem extends Component {

    constructor(props) {
        super(props)

        this.state = {
            alumno: this.props.alumno ?? null
        }
    }


    /**
     * ComponentDidUpdate
     * 
     * @param {Object} prevProps
     */
    componentDidUpdate(prevProps) {
        if (prevProps.alumno != this.props.alumno)
            this.setState({ alumno: this.props.alumno })
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        const { alumno } = this.state
        return (
            <Grid item xs={6} xl={4}>
                <Paper elevation={0} sx={{px: 3, pt: 2, pb: 3, borderRadius: 2, border: "1px solid var(--greyLight)"}}>
                    <Box justifyContent={"end"} alignItems={"center"} display={"flex"}>
                        {this.props.hideSeeIcon ? null :
                            (<Link to={"/empleado/alumnos/" + alumno.id}>
                                <Tooltip title={<Text color={"white"}>Ver alumno</Text>}>
                                    <SeeIcon color={"standard"} className={"mx-2"} />
                                </Tooltip>
                            </Link>)
                        }

                        {this.props.hideDeleteIcon ? null :
                            (<Tooltip title={<Text color={"white"}>Borrar relación</Text>}>
                                <div onClick={() => { this.props.openDeleteRelacionModal(alumno) }}>
                                    <RemoveAsociationIcon color={"error"} className={"clickable mx-2"} />
                                </div>
                            </Tooltip>)
                        }
                    </Box>

                    <Box alignItems={"center"} display={"flex"}>
                        <Avatar alt={alumno.nombre ?? ""} src={`${ASSETS.images + (alumno.foto_logo ?? 'private/alumno/perfiles/0/default.png')}`} sx={{ width: 75, height: 75 }} />

                        <Box mx={3} display={"flex"} flexDirection={"column"}>
                            <Text>{(alumno.nombre ?? "") + " " + (alumno.apellido1 ?? "") + " " + (alumno.apellido2 ?? "")}</Text>
                            <Text>{(alumno.numero_documento ?? "") + " - " + (alumno.tipo_documento ? alumno.tipo_documento.nombre : "")}</Text>
                            {alumno.tipo_relacion ? <Text>{"Relación con el alumno: " + (alumno.tipo_relacion ?? "")}</Text> : null}
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        )
    }
}