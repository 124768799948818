import './style.css'
import { H3, Text } from 'components/texts'
import { v4 as uuid } from 'uuid'
import { months } from './constants'
import { Grid } from '@mui/material'
import React, { Component } from 'react'

/**
 * Sección para elegir el alumno
 */
export default class ProgressSheetTable extends Component {

  constructor(props) {
    super(props)
  }

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
        <Grid item xs={12}>
          <table className='progress-sheet-table'>
            <thead>
              <tr>
                <th className={"no-border"}></th>
                {months.map(month => (
                    <th key={month.value}><H3 className={"m-0"}>{month.label}</H3></th>  
                ))} 
              </tr>  
            </thead>  

            <tbody>  
              <tr>
                <td><Text>Libro principal</Text></td>
                {months.map(month => (
                    <td key={uuid()}></td> 
                ))}
              </tr>
              <tr>
                <td><Text>Libro asociado</Text></td>
                {months.map(month => (
                    <td key={uuid()}></td> 
                ))}
              </tr>
              <tr>
                <td><Text>Ok</Text></td>
                {months.map(month => (
                    <td key={uuid()}></td> 
                ))}
              </tr>
            </tbody>
          </table> 
        </Grid>
    )
  }
}
