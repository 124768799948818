import * as Yup from 'yup'
import { REGEX } from 'constants/Regex'

export const validationSchema = Yup.object().shape({
    nombre:            Yup.string().required('El nombre es obligatorio'),
    apellido1:         Yup.string().required('El primer apellido es obligatorio'),
    apellido2:         Yup.string().required('El segundo apellido es obligatorio'),
    email:             Yup.string().required('El email es obligatorio').email('El formato del email no es correcto.'),
    email2:            Yup.string().email('El formato del email no es correcto.'),
    numero_documento:  Yup.string().required('El número de documento es obligatorio'),
    id_tipo_documento: Yup.string().required('El tipo de documento es obligatorio'),
    id_sexo:           Yup.string().required('El género es obligatorio'),
    telefono1:         Yup.string().required('El teléfono principal es obligatorio').matches(REGEX.telefono.validator, {message: REGEX.telefono.message, excludeEmptyString: true}),
    telefono2:         Yup.string().matches(REGEX.telefono.validator, {message: REGEX.telefono.message, excludeEmptyString: true}),
    tlfno_movil1:      Yup.string().matches(REGEX.telefono.validator, {message: REGEX.telefono.message, excludeEmptyString: true}),
    tlfno_movil2:      Yup.string().matches(REGEX.telefono.validator, {message: REGEX.telefono.message, excludeEmptyString: true}),
    cod_postal:        Yup.string().matches(REGEX.codigo_postal.validator, {message: REGEX.codigo_postal.message, excludeEmptyString: true}),
})