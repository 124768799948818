import { 
    ClasesParticularesAlumnosTemplate,
    ClasesParticularesMateriasTemplate,
    ClasesParticularesPendientesTemplate,
    ClasesParticularesProfesoresTemplate,
    ClasesParticularesPendientesProfesorTemplate,
    ClasesParticularesColectivosTemplate } from "./parts"

const itemsOrderAlumno  = [
    { value: '{"alumno.nombre": "ASC"}',  label: "Alumnos" },
]

const itemsOrderProfesor  = [
    { value: '{"empleado.nombre": "ASC"}',  label: "Profesor" },
]

export const itemsSearch = [
    { value: 0, columnKey: "alumnos",               label: "Clases particulares por alumnos",                       template: ClasesParticularesAlumnosTemplate,            itemsOrder: itemsOrderAlumno },
    { value: 1, columnKey: "materias",              label: "Clases particulares por Materias",                      template: ClasesParticularesMateriasTemplate,           itemsOrder: itemsOrderAlumno},
    { value: 2, columnKey: "pendientes",            label: "Clases particulares Pendientes",                        template: ClasesParticularesPendientesTemplate,         itemsOrder: itemsOrderAlumno},
    { value: 3, columnKey: "profesores",            label: "Clases particulares por Profesores",                    template: ClasesParticularesProfesoresTemplate,         itemsOrder: itemsOrderProfesor},
    { value: 4, columnKey: "pendientes_profesor",   label: "Clases particulares Pendientes por tipo de profesor",   template: ClasesParticularesPendientesProfesorTemplate, itemsOrder: itemsOrderAlumno },
    { value: 5, columnKey: "colectivos",            label: "Clases particulares por Colectivos",                    template: ClasesParticularesColectivosTemplate,         itemsOrder: itemsOrderAlumno },
]