import Statics from 'objects/common/Statics'
import { RequestRegistroActividad } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para editar la actividad
     * 
     * @param {Int} id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editActividad(id, getValues, callbackOk = () => {}, callbackError = () => {}) {
        const formData = new FormData()

        formData.append("nombre",              getValues("nombre").toUpperCase())
        formData.append("intensivo",           getValues("intensivo") ? 1 : 0)
        formData.append("tipo_actividad_id",   getValues("id_tipo_actividad"))
        formData.append("actividad_id",        getValues("id_actividad"))
        formData.append("modalidad_cursos_id", getValues("id_modalidad_cursos"))
        formData.append("entity_id",           getValues("id_colegio"))
        formData.append("precio",              Statics.changeDotAndComma(getValues("precio"),       false))
        formData.append("matricula",           Statics.changeDotAndComma(getValues("matricula"),    false))
        formData.append("precio_junio",        Statics.changeDotAndComma(getValues("precio_junio"), false))

        new RequestRegistroActividad().edit(id, formData, callbackOk, callbackError)
    }


    /**
     * Devuelve la actividad
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getActividad(id, callbackOk = () => {}, callbackError = () => {}) {
        new RequestRegistroActividad().get(id, {}, (res) => {
            res.actividad ? callbackOk(res.actividad) : callbackError(res)
        })
    }
}
