import './style.css'
import Box from '@mui/material/Box';
import TableColumn from '../TableColumn'
import React, { Component } from 'react';
import { Text, H3 } from 'components/texts'


export default class LibrosDevueltos extends Component {

    constructor(props){
        super(props)

        this.state = {
            cursoEscolar: null,
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.cursoEscolar != this.props.cursoEscolar)
            this.setState({cursoEscolar: this.props.cursoEscolar})
    }

    
    getTotal = () => {
        const unidadesLlegadas  = this.getUnidades(this.props.librosPedidos,   "cantidad_llegada")
        const unidadesDevueltas = this.getUnidades(this.props.librosDevueltos, "cantidad_devuelta")
        const unidadesVendidas  = 0

        return unidadesLlegadas - unidadesDevueltas - unidadesVendidas
    }


    /**
     * Devuelve las unidades de un array, primero lo filtra por el curso escolar actual y luego suma todos los valores del parametro del objeto indicado
     * 
     * @param {Array} array 
     * @param {String} param 
     * 
     * @returns {Int}
     */
    getUnidades = (array, param) => {
        const unidades = array.filter(item => item.id_curso_escolar == this.state.cursoEscolar).reduce((accumulator, currentValue) => accumulator + (currentValue[param] ? parseInt(currentValue[param]) : 0), 0) ?? 0;
        return unidades
    }


    render() {
        return (
            <Box px={2}>
                <H3>Almacén</H3>
                <table className='almacen-table'>
                    <tbody>
                        <tr className='total-unidades'>
                            <td className='bg-white'><Text>Total de unidades en el almacén:</Text></td>
                            <TableColumn key={Math.random()} defaultValue={this.getTotal()} disabled/>
                            
                            <td className='bg-white' colSpan={2}></td>
                            <td className='bg-white'><Text>Total de unidades vendidas:</Text></td>
                            <TableColumn key={Math.random()} defaultValue={this.props.libro?.ventas[this.state.cursoEscolar] ?? 0} disabled/>
                        </tr>
                    </tbody>
                </table>
            </Box>
        ); 
    }
};