import React, { useState } from 'react';
import { Text } from 'components/texts';
import { Grid, FormControlLabel, Switch } from '@mui/material';
import { Button, Input } from 'components/forms/components';
import { GroupButtons } from 'components/data_display';

const labels = [
  { label: "Vehículo", isSelected: false },
  { label: "Nativo", isSelected: false },
  { label: "Habla Español", isSelected: false },
];

export default function CandidatosActividadesTemplate({ onChange }) {

  //******* functions *******//

  const handleButtonChange = (selectedButtons) => {
    // console.log("Botones seleccionados:", selectedButtons);
    if (onChange) {
      onChange(selectedButtons);
    }
  };

  return (
    <>
      <Grid container mt={2} mb={1}>
        <Text fontWeight={'bold'}>Según datos de:</Text><br />
      </Grid>

      <Grid container>
        
        <Grid item>
          <GroupButtons labels={labels} onChange={handleButtonChange} searchable={true}/>
        </Grid>

      </Grid>
    </>
  );
}
