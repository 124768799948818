import React from 'react'
import { Box } from '@mui/material'


export default function CoordinadoresTemplate() {
  return (
   <>
   <Box border={'1px solid back'} p={2} my={4}>

   </Box>
   </>
  )
}
