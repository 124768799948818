import React from 'react'
import { Stack, Grid } from '@mui/material'
import { Text } from 'components/texts'
import SkeletonCurriculum from './skeletonCurriculum'


export default function DatosCurriculumData({ candidato }) {
    return (
        <Grid container alignItems="center" direction={'row'} mt={4} ml={2} >
            {!candidato ?
                <SkeletonCurriculum />
                : (
                    <>
                        <Grid item xs={12} mt={2}>
                            <Stack>
                                <Text fontWeight={"bold"}>Descripción</Text>
                                <Text>{candidato?.descripcion ?? ""}</Text>
                            </Stack>
                        </Grid>

                        <Grid item xs={10} mt={2}>
                            <Stack>
                                <Text fontWeight={"bold"}>Observaciones</Text>
                                <Text>{candidato?.observaciones ?? ""}</Text>
                            </Stack>
                        </Grid>

                        <Grid item xs={2} mt={2}>
                            <Stack>
                                <Text fontWeight={"bold"}>Fecha Inicio</Text>
                                <Text>{candidato?.fecha_inicio ?? ""}</Text>
                            </Stack>
                        </Grid>

                        <Grid item xs={10} mt={2}>
                            <Stack>
                                <Text fontWeight={"bold"}>Comentarios</Text>
                                <Text>{candidato?.comentarios ?? ""}</Text>
                            </Stack>
                        </Grid>

                        <Grid item xs={2} mt={2}>
                            <Stack>
                                <Text fontWeight={"bold"}>Fecha Fin</Text>
                                <Text>{candidato?.fecha_fin ?? ""}</Text>
                            </Stack>
                        </Grid>
                    </>
                )
            }
        </Grid>
    )
}
