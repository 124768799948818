import { RequestAuth } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    updateUserRoles(user_id, roles, callbackOk = () => {}, callbackError = () => {}, callbackFinally = () => {}) {
        const formData = new FormData

        for(const role of roles) {
            formData.append('roles[]', role)
        }

        new RequestAuth().updateUserRoles(user_id, formData, callbackOk, callbackError, callbackFinally)
    }


    updateUserPermissions(user_id, permissions, callbackOk = () => {}, callbackError = () => {}, callbackFinally = () => {}) {
        const formData = new FormData

        for(const permission of permissions) {
            formData.append('permissions[]', permission)
        }

        new RequestAuth().updateUserPermissions(user_id, formData, callbackOk, callbackError, callbackFinally)
    }
    
    getUserRoles(user_id, callbackOk = () => {}, callbackError = () => {}, callbackFinally = () => {}) {
        new RequestAuth().userRoles(user_id, callbackOk, callbackError, callbackFinally)
    }

    getUserPermissions(user_id, callbackOk = () => {}, callbackError = () => {}, callbackFinally = () => {}) {
        new RequestAuth().userPermissions(user_id, callbackOk, callbackError, callbackFinally)
    }
}