import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import React, { Component } from 'react'
import { Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Esta es la plantilla para cuando se busque por los datos del domicilio del alumno
 */
export default class DomicilioTemplate extends Component {
    
    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)
    }

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    render() {
        const { register, errors, reset } = this.props
        return (
            <>
                <Grid container mt={2} mb={1}>
                    <Text fontWeight={'bold'}>Según datos de:</Text><br />
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <ItemsSelect name={"id_tipo_via"} keyValue={"tipo_via"} register={register} errors={errors} wantToReset={reset} searcheable/>
                    </Grid>

                    <Grid item xs={4}>
                        <Input label="Dirección" width={"100%"} name={"direccion"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={4}>
                        <Input label="Municipio" width={"100%"} name={"municipio"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={4}>
                        <Input label="Código postal" width={"100%"} name={"cod_postal"} register={register} errors={errors}/>
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect name={"id_provincia"} keyValue={"provincia"} register={register} errors={errors} wantToReset={reset} searcheable/>
                    </Grid>
                </Grid>
            </>
        )
    }
}
