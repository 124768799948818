import RequestMaster from "../RequestMaster";

export default class RequestListadosLibro extends RequestMaster {
    constructor() {
        super();
        this.URL_BASE = this.URL_BASE + "/libro/listados";
    }

    /**
     * Devuelve inventario de los libros
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    inventario(
        params,
        callback = () => { },
        callbackError = () => { },
        callbackFinally = () => { }
    ) {
        const path = "inventario";
        this.get(path, params, callback, callbackError, callbackFinally);
    }

    /**
     * Devuelve DetalleLibros de los libros
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    DetalleLibros(
        params,
        callback = () => { },
        callbackError = () => { },
        callbackFinally = () => { }
    ) {
        const path = "DetalleLibros";
        this.get(path, params, callback, callbackError, callbackFinally);
    }

    /**
     * Devuelve PendientesEntregar de los libros
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    PendientesEntregar(
        params,
        callback = () => { },
        callbackError = () => { },
        callbackFinally = () => { }
    ) {
        const path = "PendientesEntregar";
        this.get(path, params, callback, callbackError, callbackFinally);
    }

    /**
     * Devuelve StockLibros de los libros
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    StockLibros(
        params,
        callback = () => { },
        callbackError = () => { },
        callbackFinally = () => { }
    ) {
        const path = "StockLibros";
        this.get(path, params, callback, callbackError, callbackFinally);
    }

    /**
     * Devuelve Contabilidad Libros de los libros
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    ContabilidadLibros(
        params,
        callback = () => { },
        callbackError = () => { },
        callbackFinally = () => { }
    ) {
        const path = "ContabilidadLibros";
        this.get(path, params, callback, callbackError, callbackFinally);
    }

    /**
     * Devuelve Pagos Pendientes Libros Libros de los libros
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    PagosPendientesLibros(
        params,
        callback = () => { },
        callbackError = () => { },
        callbackFinally = () => { }
    ) {
        const path = "PagosPendientesLibros";
        this.get(path, params, callback, callbackError, callbackFinally);
    }

    /**
     * Devuelve EstadisticasLibrosPedidos Libros de los libros
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    EstadisticasLibrosPedidos(
        params,
        callback = () => { },
        callbackError = () => { },
        callbackFinally = () => { }
    ) {
        const path = "EstadisticasLibrosPedidos";
        this.get(path, params, callback, callbackError, callbackFinally);
    }

    /**
  * Devuelve DevolucionLibros Libros de los libros
  *
  * @param {FormData} params
  * @param {Function} callback
  * @param {Function} callbackError
  * @param {Function} callbackFinally
  */
    DevolucionLibros(
        params,
        callback = () => { },
        callbackError = () => { },
        callbackFinally = () => { }
    ) {
        const path = "DevolucionLibros";
        this.get(path, params, callback, callbackError, callbackFinally);
    }

    /**
* Devuelve Resumen Cuentas Libros de los libros
*
* @param {FormData} params
* @param {Function} callback
* @param {Function} callbackError
* @param {Function} callbackFinally
*/
    ResumenCuentas(
        params,
        callback = () => { },
        callbackError = () => { },
        callbackFinally = () => { }
    ) {
        const path = "ResumenCuentas";
        this.get(path, params, callback, callbackError, callbackFinally);
    }

    /**
* Devuelve Precio Libros Activos Libros de los libros
*
* @param {FormData} params
* @param {Function} callback
* @param {Function} callbackError
* @param {Function} callbackFinally
*/
    PrecioLibrosActivos(
        params,
        callback = () => { },
        callbackError = () => { },
        callbackFinally = () => { }
    ) {
        const path = "PrecioLibrosActivos";
        this.get(path, params, callback, callbackError, callbackFinally);
    }
    PrecioLibrosActivos
}
