import { Text } from 'components/texts'
import { itemsOrder } from './constants'
import React, { useEffect } from 'react'
import { FichajeTemplate } from './parts'
import Statics from 'objects/common/Statics'
import { RequestFichaje } from 'objects/requests'
import { Select } from 'components/forms/components'
import { SearchButtons } from 'components/forms/display/components'
//VALIDACION FORMULARIO
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import validationSchema from "./validation"
import { yupResolver } from '@hookform/resolvers/yup'

let prepareParamsGlobal = null

//Formulario de busqueda de alumnos
export default function SearchFormFichaje({ onLoadingCallback, searchCallback }) {

    //Form
	const { register, handleSubmit, formState: { errors }, getValues, reset, setValue } = useForm({ resolver: yupResolver(validationSchema) })

    //State
    const [resetFormState, setResetFormState] = React.useState(false)

    useEffect(() => {
        if(resetFormState == true) {
            resetForm(reset)
            setResetFormState(false)
        }
    })

    //-------------------------------------------------------
    //---------------------- FUNCTIONS ----------------------
    //-------------------------------------------------------
      
    /**
     * Prepara los parametros que se van a usar
     * 
     * @param {Int} page
     */
    const prepareParams = (page) => {
        const params     = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()))
        params.relations = 'empleado.tipo_documento'
        params.page      = page

        return params
    }

    prepareParamsGlobal = prepareParams

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    return (
        <Grid container p={2}>
            <Grid container>
                <Grid item xs={8} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Ordenar resultados por:</Text><br />
                    <Select width={"60%"} name={"order"} items={itemsOrder} defaultValue={itemsOrder[0].value} register={register} errors={errors} />
                </Grid>
            </Grid>
            
            <FichajeTemplate reset={resetFormState} register={register} errors={errors} setValue={setValue}/>
            
            <SearchButtons searchText={"Buscar fichajes"} onResetClick={() => setResetFormState(true)} onSubmitClick={handleSubmit(() => search(1, onLoadingCallback, searchCallback))}/>
        </Grid>
    )
}



/**
 * Hace la busqueda de alumnos
 * 
 * @param {Int}      page
 * @param {Function} onLoadingCallback
 * @param {Function} searchCallback
 * @param {Function} prepareParams
 */
export const search = (page = 1, onLoadingCallback, searchCallback) => {
    //Callback para controlar cuando esta cargando
    if(onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal(page)
    new RequestFichaje().search(params, (res) => {
        if(searchCallback)
            searchCallback(res.items.data, res.items.total, page, params)
    }, () => {
        searchCallback([], 0, page, params)
    })
}


/**
 * Resetea todo el formulario
 * 
 * @param {Function} reset
 */ 
const resetForm = (reset) => {
    reset({
        nombre: "",
        apellido1: "",
        apellido2: "",
        numero_documento: "",
    })
}