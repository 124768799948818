import React, { useState, useContext, useEffect, useRef } from "react"
import { useParams } from 'react-router'
import { Stack } from "@mui/material"
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Paper, Box } from '@mui/material'
import { EditHeader } from 'pages/logged/admin/components'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { Text } from 'components/texts'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { DataFormActividadAcademia } from "components/forms/display"
import SkeletonActividadesAcademia from "pages/logged/admin/academias/actividad/single/parts/ActividadesAcademiaData/skeletonActividadesAcademia.jsx"
import { validationSchema } from 'components/forms/display/academia/aula/DataForm/validation.js'

export default function Wrapper() {

  const navigationParams = useParams()
  const [academia, setAcademia] = useState(null)
  const [id_academia, setIdAcademia] = useState(navigationParams.id_academia)

  const { items } = useContext(EmpleadoListContext)

  useEffect(() => {
    const academia = items.find(item => item?.id == id_academia)
    if (items.length == 0 || !academia) {
      new Requests().getActividad(id_academia, setAcademia, () => enqueueSnackbar(<Text>No se ha podido recuperar las actividades de la academia.</Text>, { variant: "error" }))
    } else {
      setAcademia(academia)
    }
  }, [id_academia])



  return (
    <React.Fragment>
      <EditActividadesAcademia key={academia?.id ?? 0} academia={academia} id_academia={id_academia} setIdAcademia={setIdAcademia} setAcademia={setAcademia} />
      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}

function EditActividadesAcademia({ academia, id_academia, setIdAcademia, setAcademia }) {
  const { updateItem } = useContext(EmpleadoListContext)

  const navigate = useNavigate()

  const { register, handleSubmit, formState: { errors }, getValues, control } = useForm({ resolver: yupResolver(validationSchema) })
  const headerRef = useRef()

  const updateId = (id) => {
    navigate(`../academias/actividades/${id}/editar`);
    setIdAcademia(id);
  }
  const updateHeaderAndAlert = ({ message, variant = "error" }, loading = false) => {
    headerRef.current.button.setLoading(loading)
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }


  /**
   * Hace la petición para editar los datos del Academia
   */
  const editActividad = () => {
    headerRef.current.button.setLoading(true)
    new Requests().editActividad(id_academia, getValues, (res) => {
      updateItem(res.actividad)
      updateHeaderAndAlert({ message: "Se ha editado la actividad correctamente.", variant: "success" })
    }, (res) => {
      updateHeaderAndAlert({ message: res })
    })
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">

        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/", label: "Inicio" },
            { to: "./", label: "Academias" },
            { to: "./../", label: "Actividades" },
            { to: "./../../", label: "Editar Actividad #" + (id_academia ?? "0000"), actualItem: true },
          ]} />

          <MoveButtons
            actualItemId={id_academia}
            beforeFetchCallback={() => setAcademia(null)}
            onUpdateActualItem={updateId} />
        </Stack>

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <EditHeader
            ref={headerRef}
            title={"Editar Actividad"}
            item={academia}
            btnText={"Guardar cambios"}
            handleSubmit={editActividad} />

          <Line />

          {academia ?
            <DataFormActividadAcademia
              academia={academia}
              register={register}
              errors={errors}
              control={control}
              isMounted={true}
              hideButton />
            :
            <Box px={5}><SkeletonActividadesAcademia /></Box>
          }
        </Paper>
      </form>
    </Container>
  )
}