import Statics from 'objects/common/Statics'
import { RequestInscripcionAcademia, RequestVentaLibro } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para crear el grupo
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createInscripcion(getValues, callbackOk = () => { }, callbackError = () => { }) {
        //-- GENERAL --//
        const formData = new FormData
        formData.append('alumno_id', getValues('alumno_id'))
        formData.append('curso_escolar_id', getValues('curso_escolar_id'))
        formData.append('forma_pago_id', getValues('forma_pago_id'))
        // formData.append('operador_id', '5')

        formData.append('forma_recogida_id', getValues('forma_recogida_id'))
        formData.append('forma_conocimiento_id', getValues('forma_conocimiento_id'))
        formData.append('registro_actividad_id', getValues('registro_actividad_id'))
        formData.append('matricula', getValues('matricula'))
        formData.append('tipo_matricula_id', 3)
        formData.append('cantidad_pago', getValues('cantidad_pago'))
        formData.append('tipo_pago_id', getValues('tipo_pago_id'))

        formData.append('preferencia_horario_id', getValues('preferencia_horario_id'))
        formData.append('motivo_baja_id', getValues('motivo_baja_id'))
        formData.append('comentarios_horario', getValues('comentarios_horario'))
        formData.append('hermanos', getValues('hermanos') ? 1 : 0)
        formData.append('empresa_colectivo', getValues('empresa_colectivo') ? 1 : 0)

        const fecha_inscripcion = getValues("fecha_inscripcion")
        formData.append("fecha_inscripcion", Statics.datePickerTimeFormat(fecha_inscripcion))

        const fecha_incorporacion = getValues("fecha_incorporacion")
        formData.append("fecha_incorporacion", Statics.datePickerTimeFormat(fecha_incorporacion))

        const fecha_baja = getValues("fecha_baja")
        formData.append("fecha_baja", Statics.datePickerTimeFormat(fecha_baja))

        const fecha_pago_matricula = getValues("fecha_pago_matricula")
        formData.append("fecha_pago_matricula", Statics.datePickerTimeFormat(fecha_pago_matricula))
        formData.append('alumno_colectivo', getValues('alumno_colectivo') ? 1 : 0)

        //-- LIBRO --//


        const fecha_solicitud = getValues("fecha_solicitud")
        const fecha_pago = getValues("fecha_pago")
        const fecha_entrega = getValues("fecha_entrega")
        const fecha_asociado_solicitud = getValues("fecha_asociado_solicitud")
        const fecha_pago_asociado = getValues("fecha_pago_asociado")
        const fecha_entrega_asociado = getValues("fecha_entrega_asociado")

        formData.append("fecha_solicitud", Statics.datePickerTimeFormat(fecha_solicitud))
        formData.append("fecha_pago", Statics.datePickerTimeFormat(fecha_pago))
        formData.append("fecha_entrega", Statics.datePickerTimeFormat(fecha_entrega))
        formData.append("fecha_asociado_solicitud", Statics.datePickerTimeFormat(fecha_asociado_solicitud))
        formData.append("fecha_pago_asociado", Statics.datePickerTimeFormat(fecha_pago_asociado))
        formData.append("fecha_entrega_asociado", Statics.datePickerTimeFormat(fecha_entrega_asociado))
        new RequestInscripcionAcademia().create(formData, callbackOk, callbackError)
    }


    /**
     * Comprueba que petición tiene que hacer (para los libros asociados)
     */
    crudLibroAsociado(inscripcion, getValues, resolve, reject) {
        const libroAsociadoSolicitado = getValues("libro_asociado_solicitado")

        libroAsociadoSolicitado ? this.createVentaLibroAsociado(inscripcion.id, getValues, resolve, reject) : resolve()
    }

    /**
 * Método para crear un registro de una venta de un libro
 * 
 * @param {Int}      inscripcion_id 
 * @param {Function} getValues 
 * @param {Function} callbackOk 
 * @param {Function} callbackError 
 */
    createVentaLibro(inscripcion_id, getValues, callbackOk = () => { }, callbackError = () => { }) {
        let formData = this.ventaLibroFormdata(getValues)
        formData.append('id', inscripcion_id)

        new RequestVentaLibro().create(formData, callbackOk, callbackError)
    }


    /**
     * Método para crear un registro de una venta de un libro asociado
     * 
     * @param {Int}      inscripcion_id
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createVentaLibroAsociado(inscripcion_id, getValues, callbackOk = () => { }, callbackError = () => { }) {
        let formData = this.ventaLibroFormdata(getValues, true)
        formData.append('inscripcion_id', inscripcion_id)

        new RequestVentaLibro().create(formData, callbackOk, callbackError)
    }

    /**
     * Crea y devuelve el FormData para las ventaLibro
     * 
     * @param {Function} getValues 
     */
    ventaLibroFormdata(getValues, asociado = false) {
        const sufix = asociado ? "_asociado" : ""

        let formData = new FormData
        formData.append('curso_escolar_id', getValues('id_curso_escolar'))
        formData.append('libro_id', getValues(`libro${sufix}_id`) ?? "")
        formData.append('precio', getValues(`precio${sufix}`))
        formData.append('inscripcion_type', 'inscripcion-academia')
        if (asociado)
            formData.append('asociado', 1)

        const fecha_peticion = getValues(`fecha${sufix}_peticion`)
        formData.append("fecha_peticion", Statics.datePickerTimeFormat(fecha_peticion))

        const fecha_pago = getValues(`fecha_pago${sufix}`)
        formData.append("fecha_pago", Statics.datePickerTimeFormat(fecha_pago))

        const fecha_entrega = getValues(`fecha_entrega${sufix}`)
        formData.append("fecha_entrega", Statics.datePickerTimeFormat(fecha_entrega))

        return formData
    }


    /**
     * Método para crear un registro de una venta de un libro
     * 
     * @param {Int}      id_clase_particular 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createVentaLibro(id_clase_particular, getValues, callbackOk = () => { }, callbackError = () => { }) {
        let formData = this.ventaLibroFormdata(getValues)
        formData.append('inscripcion_id', id_clase_particular)

        new RequestVentaLibro().create(formData, callbackOk, callbackError)
    }

    /**
     * Método para crear un registro de una venta de un libro asociado
     * 
     * @param {Int}      id_inscripcion 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createVentaLibroAsociado(id_ventaLibro, getValues, callbackOk = () => { }, callbackError = () => { }) {
        let formData = this.ventaLibroFormdata(getValues, true)
        formData.append('inscripcion_id', id_ventaLibro)

        new RequestVentaLibro().create(formData, callbackOk, callbackError)
    }
}
