import { Grid } from "@mui/material"
import { H3, Text } from "components/texts"
import { Checkbox, ColorInput, Input, LoadingButton } from 'components/forms/components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Horario } from 'components/data_display'
import { useEffect, useRef, useState } from "react"
import { ItemsSelect } from "components/forms/components/Select/custom"
import Requests from './Requests/Requests'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import validationSchema from './validation';

export default function DataFormHorariosAcademias({ grupo, hideButton = false, loading = false }) {
    //REF
    const loadingButton = useRef()

    //STATE
    const [horarios, setHorarios] = useState(grupo?.horarios ?? [])
    const [horario, setHorario] = useState(null)
    const [aula, setAula] = useState(null)

    const defaultValues = { curso_escolar_id: grupo?.curso_escolar_id ?? "" }

    const { register, formState: { errors }, getValues } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    //REQUEST
    const request = new Requests()

    useEffect(() => {
        setAula(null)
    }, [])

    useEffect(() => {
        const search = aula
        const horarioToShow = horarios.find(horario => horario.aula_id === search)
        setHorario(horarioToShow)

    }, [aula])

    useEffect(() => {
        if (loadingButton.current)
            loadingButton.current.setLoading(loading)
    })

    //-------------------------------------------------------
    //----------------------- REQUESTS ----------------------
    //-------------------------------------------------------

    /**
     * Función para crear una definición del horario
     * 
     */

    const createHorario = () => {
        const horaInicio = getValues("hora_inicio");
        const horaFin = getValues("hora_fin");
        if (!horaInicio || !horaFin) {
            enqueueSnackbar(<Text>Por favor, ingresa ambas horas.</Text>, { variant: "error" });
            return;
        }

        if (horaInicio >= horaFin) {
            enqueueSnackbar(<Text>La hora de fin debe ser posterior a la hora de inicio.</Text>, { variant: "error" });
            return;
        }
        request.createHorario(grupo?.id, getValues, (res) => {
            if (res.item) {
                setHorario(res.item)
                horarios.push(res.item)
                setHorarios(horarios)
                enqueueSnackbar(<Text>Se ha creado el horario correctamente.</Text>, { variant: "success" })
            }
        }, (res) => {
            enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
        })
    }

    /**
     * Función para eliminar una definición del horario
     * 
     * @param id_definicion_horario
     */
    const deleteHorario = (id_definicion_horario) => {
        request.deleteDefinicionHorario(id_definicion_horario, (res) => {

            if (res.item) {
                //Buscar el index en horarios, donde res.item.id == horario.item, horarios[index] = res.item -> setHorarios(horarios)
                res.item.definiciones = res.item.definiciones.filter(definicion => definicion.id != id_definicion_horario)

                setHorario(res.item)

                horarios.forEach(horario => {
                    if (horario.id == res.item.id) {
                        horario.definiciones = horario.definiciones.filter(definicion => definicion.id != id_definicion_horario)

                        const existeHorario = horarios.some(h => h.id === horario.id);
                        if (!existeHorario)
                            setHorarios(prevHorarios => [...prevHorarios, horario]);
                    }
                });

                enqueueSnackbar(<Text>Se ha eliminado la definición del horario correctamente.</Text>, { variant: "success" })
            }

        }, (res) => {
            enqueueSnackbar(<Text>Ha habido un error al crear la definición del horario correctamente.</Text>, { variant: "error" })
        })
    }

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <>
            <Grid container>

                {/* SEARCH FORM */}
                <Grid container mt={2} px={4} spacing={2}>
                    <Grid item xs={12}>
                        <H3>Generar horario</H3>
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect name={"aula_id"} keyValue={"aula"} register={register} errors={errors} defaultValue={grupo?.aula_id ?? ""} onChange={
                            ((_, item) => setAula(item.id))
                        } />

                    </Grid>

                    <Grid item xs={2} p={4}>
                        <Input type={"time"} label="Hora inicio:" required register={register} errors={errors} className="w-100" name={"hora_inicio"} InputLabelProps={{ shrink: true }} uppercase />
                    </Grid>

                    <Grid item xs={2}>
                        <Input type={"time"} label="Hora fin:" required register={register} errors={errors} className="w-100" name={"hora_fin"} InputLabelProps={{ shrink: true }} uppercase />
                    </Grid>

                    <Grid item xs={3} display={"flex"} justifyContent={"center"}>
                        <Checkbox name={"l"} label={"L"} className={"flex-column"} register={register} required />
                        <Checkbox name={"m"} label={"M"} className={"flex-column"} register={register} required />
                        <Checkbox name={"x"} label={"X"} className={"flex-column"} register={register} required />
                        <Checkbox name={"j"} label={"J"} className={"flex-column"} register={register} required />
                        <Checkbox name={"v"} label={"V"} className={"flex-column"} register={register} required />
                        <Checkbox name={"s"} label={"S"} className={"flex-column"} register={register} required />
                    </Grid>

                    <Grid item xs={2}>
                        <ColorInput name={"color"} register={register} errors={errors} />
                    </Grid>
                    <Grid item xs={12} justifyContent="end" display={"flex"}>
                        <LoadingButton ref={loadingButton} onClick={createHorario} disabled={!aula}>
                            Añadir al horario
                        </LoadingButton>
                    </Grid>
                </Grid>

                {/* HORARIO */}
                <Grid container mt={2} px={4} spacing={2}>
                    <Horario horarios={horario ? [horario] : []} type="academia" onDeleteClick={deleteHorario} />
                </Grid>
            </Grid>

            <SnackbarProvider autoHideDuration={3000} />
        </>

    )
}