import RequestMaster from '../RequestMaster'

export default class RequestAuth extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/auth'
    }


    /**
     * Hace el logueo del usuario
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    login(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "login"
        this.post(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Hace la petición para hacer la recuperación de contraseña
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    recoverPass(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "recover_pass"
        this.post(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Hace la petición para hacer el cambio de contraseña
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    changePass(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "change_pass"
        this.post(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Hace la petición para comprobar si el token es correcto
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    checkToken(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "check_token"
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    
    /**
     * Devuelve todos los roles que existen
     * 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    allRoles(callbackOk = () => {}, callbackError = () => {}, callbackFinally = () => {}) {
        const path = "roles/all"
        this.get(path, {}, callbackOk, callbackError, callbackFinally)
    }

    /**
     * Hace la peticion para obtener todos los roles de un usuario
     * 
     * @param {Int}      user_id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    userRoles(user_id, callbackOk = () => {}, callbackError = () => {}, callbackFinally = () => {}) {
        const path = `${user_id}/roles`
        this.get(path, {}, callbackOk, callbackError, callbackFinally)
    }

    /**
     * Hace la peticion para actualizar los roles de un usuario
     * 
     * @param {Int}      user_id 
     * @param {Object}   formData 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    updateUserRoles(user_id, formData, callbackOk = () => {}, callbackError = () => {}, callbackFinally = () => {}) {
        const path = `${user_id}/roles`
        this.post(path, formData, callbackOk, callbackError, callbackFinally)
    }
    

    /**
     * Devuelve todos los permisos que existen
     * 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    allPermissions(callbackOk = () => {}, callbackError = () => {}, callbackFinally = () => {}) {
        const path = "permissions/all"
        this.get(path, {}, callbackOk, callbackError, callbackFinally)
    }


    /**
     * Hace la peticion para obtener todos los permisos de un usuario
     * 
     * @param {Int}      user_id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    userPermissions(user_id, callbackOk = () => {}, callbackError = () => {}, callbackFinally = () => {}) {
        const path = `${user_id}/permissions`
        this.get(path, {}, callbackOk, callbackError, callbackFinally)
    }

    
    /**
     * Hace la peticion para actualizar los permisos de un usuario
     * 
     * @param {Int}      user_id 
     * @param {Object}   formData 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    updateUserPermissions(user_id, formData, callbackOk = () => {}, callbackError = () => {}, callbackFinally = () => {}) {
        const path = `${user_id}/permissions`
        this.post(path, formData, callbackOk, callbackError, callbackFinally)
    }
}
