

import React, { useEffect, useState } from 'react'
import { H3, Text } from 'components/texts'
import './style.css'
import { hours, days, types } from './constants'

const ScheduleTable = ({ horarios = [], onDeleteClick = () => { }, type = "colegios", isSingle = false }) => {
    const [horariosToShow, setHorariosToShow] = useState([])

    useEffect(() => {
        const horariosToShow = types[type].formatter(horarios)
        setHorariosToShow(horariosToShow)
    }, [horarios])

    const calculateSteps = (horaInicial, horaFinal) => {
        let pasos = 0
        horaInicial = new Date(`1/1/2020 ${horaInicial}`)
        horaFinal = new Date(`1/1/2020 ${horaFinal}`)

        while (horaInicial < horaFinal) {
            pasos++
            horaInicial.setMinutes(horaInicial.getMinutes() + 30)
        }

        return pasos
    }

    return (
        <table className='horarios-table'>
            <thead>
                <tr>
                    <th><H3 className={"m-0"}>Horas</H3></th>
                    {days.map(day => (
                        <th key={day.value}><H3 className={"m-0"}>{day.label}</H3></th>
                    ))}
                </tr>
            </thead>

            <tbody>

                {hours.map((hour, i, array) => {
                    //Para que la ultima hora no se quede sola
                    if (i + 1 === array.length)
                        return null

                    return (
                        <tr key={hour}>
                            <td><Text>{hour} {hours[i + 1] ? "- " + hours[i + 1] : ""}</Text></td>

                            {days.map(day => {
                                const event = horariosToShow.find(event => event.dia == day.value && hour >= event.hora_inicio && hour < event.hora_fin)
                                if (event) {
                                    const first = hour == event.hora_inicio
                                    const Template = types[type].template
                                    return first ? (<Template day={day} event={event} calculateSteps={calculateSteps} onDeleteClick={onDeleteClick} isSingle={isSingle} />) : null
                                }

                                return <td key={day.value}></td>
                            })}
                        </tr>
                    )
                })}

            </tbody>

        </table >
    )
}
export default ScheduleTable