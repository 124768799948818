import { Text } from 'components/texts'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { useNavigate } from "react-router-dom"
import Statics from 'objects/common/Statics.js'
import { Container, Paper } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangesContext } from 'contexts/ChangesContext'
import { DataFormColegio } from 'components/forms/display'
import React, { useContext, useEffect, useRef } from 'react'
import { CreateHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { BreadcrumbsList, Line } from 'components/data_display'
import validationSchema from 'components/forms/display/colegio/colegio/DataForm/validation.js'

export default function CreateColegio(){
  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, setValue } = useForm({ resolver: yupResolver(validationSchema) })
  const navigate = useNavigate()

  const headerRef = useRef()

  const { updateChanges } = useContext(ChangesContext)

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])

  const createColegio = () => {
    headerRef.current.button.setLoading(true)

    new Requests().createColegio(getValues, async (res) => {
      headerRef.current.button.setLoading(false)
      enqueueSnackbar(<Text>Se ha creado el colegio. Redirigiendo a la pagina de edición..</Text>, { variant: "success" })
      updateChanges(false)

      await Statics.delay(1500).then(() => {navigate('./../' + res.colegio.id + "/editar")})
    }, (res) => {
      headerRef.current.button.setLoading(false)
      enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
    })
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <BreadcrumbsList breadcrumbsList={[
          { to: "/",     label: "Inicio" },
          { to: "./../", label: "Colegios" },
          { to: "./",    label: "Crear colegio", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{mx: 8, my: 2, pb: 5}}>
          <CreateHeader 
            ref          = {headerRef} 
            title        = {"Crear colegio"}
            handleSubmit = {handleSubmit(createColegio)} />

          <Line className={"my-5"} />

          <DataFormColegio register={register} errors={errors} setValue={setValue}/>
        </Paper>

        <SnackbarProvider autoHideDuration={3000}/>
      </form>
    </Container>
  )
}