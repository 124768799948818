import { yupResolver } from "@hookform/resolvers/yup"
import { Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { Button, ColorInput, Input } from "../../../../../../../../components/forms/components"
import { validationSchema } from './validation'

export default function DataFormEvent({ evento, btnText, onClick = () => {}}) {
    
    const { register, handleSubmit, formState: { errors }, getValues } = useForm({ resolver: yupResolver(validationSchema) })

    return (
        <Grid container p={5} spacing={3}>
            <Grid item xs={6}>
                <Input label="Nombre del evento" register={register} errors={errors} className="w-100" name={"title"} defaultValue={evento?.title ?? ""} uppercase />
            </Grid>

            <Grid item xs={3}>
                <Input type={"time"} label="Hora del evento" register={register} errors={errors} className="w-100" name={"hour"} InputLabelProps={{ shrink: true }} defaultValue={evento?.hour ?? ""}/>
            </Grid>

            <Grid item xs={3}>
                <ColorInput name={"color"} register={register} errors={errors} defaultValue={evento?.color ?? null} width={"100%"}/>
            </Grid>

            <Grid item xs={12}>
                <Input label="Descripción del evento" register={register} errors={errors} className="w-100" name={"description"} defaultValue={evento?.description ?? ""} multiline minRows={6} maxRows={10} />
            </Grid>

            <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                <Button onClick={handleSubmit(() => {onClick(getValues())})}>
                    {btnText ?? ""}
                </Button>
            </Grid>
        </Grid>
    )
}