import { formatearDefiniciones, formatearDefinicionesColegios } from './functions'
import { TemplateColegio, TemplateAcademiaClasesParticulares } from './templates'

export const days  = [
    {value: 1, label: 'Lunes',     short: "L"},
    {value: 2, label: 'Martes',    short: "M"},
    {value: 3, label: 'Miércoles', short: "X"},
    {value: 4, label: 'Jueves',    short: "J"},
    {value: 5, label: 'Viernes',   short: "V"},
    {value: 6, label: 'Sábado',    short: "S"},
];


export const types = {
    "colegios":            {formatter: formatearDefinicionesColegios, template: TemplateColegio },
    "academia":            {formatter: formatearDefiniciones,         template: TemplateAcademiaClasesParticulares},
    "clases-particulares": {formatter: formatearDefiniciones,         template: TemplateAcademiaClasesParticulares}, 
}

export const hours = ['07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00'];