import React from 'react'
import { Button } from 'components/forms/components'
import { Edit as EditIcon } from '@mui/icons-material'
import { Grid, Stack } from '@mui/material'
import { H3, Text } from 'components/texts'
import { Link } from 'components/data_display'

export default function aulaAlumnoData({ aula }) {

	return (
		<Grid container spacing={5}>
			<React.Fragment>
				<Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
					<H3>Datos del aula:</H3>

					<Link to="editar">
						<Button variant={"outlined"} startIcon={<EditIcon />}>
							Editar
						</Button>
					</Link>
				</Grid>

				<Grid item xs={8}>
					<Stack>
						<Text fontWeight={"bold"}>Denominación aula:</Text>
						<Text>{aula?.denominacion ?? ""}</Text>
					</Stack>
				</Grid>

				<Grid item xs={4}>
					<Stack>
						<Text fontWeight={"bold"}>Capacidad aula:</Text>
						<Text>{aula?.capacidad ?? ""}</Text>
					</Stack>
				</Grid>

			</React.Fragment>
		</Grid>
	)
}

