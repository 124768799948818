import React, { Component } from 'react'
import { Grid, Stack } from '@mui/material'
import { Line } from 'components/data_display'
import Skeleton from '@mui/material/Skeleton'

/**
 * Informacion del grupo asociado a la academia
 */
export default class SkeletonGeneralData extends Component {

    /**
     * Render
     *
     * @returns {Component}
     */
    render() {

        return (

            <Grid container px={5}>
                <Grid container spacing={1} mt={2} >

                    <Grid item xs={12}>
                        <Grid item container spacing={1} justifyContent="space-between">
                            <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                                <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                            </Grid>
                            <Grid item xs={2}>
                                <Stack>
                                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                                </Stack>
                            </Grid>

                            <Grid item xs={2}>
                                <Stack>
                                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                                </Stack>
                            </Grid>


                            <Grid item xs={2}>
                                <Stack>
                                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                                </Stack>
                            </Grid>

                            <Grid item xs={2}>
                                <Stack>
                                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                                </Stack>
                            </Grid>

                            <Grid item xs={4}>
                                <Stack>
                                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container mt={4}>
                    <Line />
                </Grid>
                <Grid container mt={4} justifyContent="space-between">

                    <Grid item xs={4}>
                        <Stack>
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                        </Stack>
                    </Grid>

                    <Grid item xs={2}>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                    </Grid>

                    <Grid item xs={2}>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                    </Grid>
                </Grid>

                <Grid container mt={4}>
                    <Line />
                </Grid>

                <Grid container spacing={2} mt={1} >

                    <Grid item xs={8}>
                        <Stack>
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                        </Stack>
                    </Grid>

                    <Grid item xs={4}>
                        <Stack>
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                        </Stack>
                    </Grid>

                </Grid>

                <Grid container spacing={2} mt={1}>

                    <Grid item xs={2}>
                        <Stack>
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                        </Stack>
                    </Grid>

                    <Grid item xs={3}>
                        <Stack>
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                        </Stack>
                    </Grid>

                    <Grid item xs={3}>
                        <Stack>
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                        </Stack>
                    </Grid>

                    <Grid item xs={2}>
                        <Stack>
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                        </Stack>
                    </Grid>

                    <Grid item xs={2}>
                        <Stack>
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                        </Stack>
                    </Grid>

                </Grid>

                <Grid container mt={4}>
                    <Line />
                </Grid>

                <Grid container>
                    <Grid item xs={6} p={3}>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                    </Grid>

                    <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9" }} p={3}>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                    </Grid>
                </Grid>
            </Grid>

        )
    }
}
