import { Box, Grid, Stack } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { Line } from 'components/data_display';

export default function SkeletonDataFormGeneralGruposColegio() {
    return (
        <Grid container px={5}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>

                <Grid item xs={3}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={5}>
                <Grid item xs={8}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>

                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={1}>
                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={1}>
                <Grid item xs={2}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>

            </Grid>

            <Grid container mt={4}>
                <Line />
            </Grid>

            <Grid container>
                <Grid item xs={6} py={3}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Skeleton variant="rounded" width={"40%"} height={30} />
                        <Stack direction={"row"} spacing={2} width={"50%"} px={2}>
                            <Skeleton variant="rounded" width={"50%"} height={40} />
                            <Skeleton variant="rounded" width={"50%"} height={40} />
                        </Stack>
                    </Box>
                </Grid>

                <Grid item xs={6} style={{borderLeft: "1px solid #D9D9D9"}} p={3}>
                    <Skeleton variant="rounded" width={"50%"} height={30} />

                    <Skeleton variant="rounded" width={"100%"} height={70} sx={{mt: 2}} />
                </Grid>
            </Grid>
            
        </Grid>
    )
}
