import { Text } from 'components/texts'
import { useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Paper, Stack } from '@mui/material'
import { EditHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'

//FORM
import { DataFormColegio } from 'components/forms/display'
import Skeleton from 'components/forms/display/responsable/DataForm/skeleton.jsx'
import validationSchema from 'components/forms/display/colegio/colegio/DataForm/validation.js'
import { ChangesContext } from 'contexts/ChangesContext'

const Wrapper = () => {
  const navigationParams = useParams()

  const { items, updateItem } = useContext(EmpleadoListContext)

  const [colegio,    setColegio]   = useState(null)
  const [id_colegio, setIdColegio] = useState(navigationParams.id_colegio)

  const updateAlert = (message, variant = "error") => {
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }

  const getColegio = (getFromServer = false) => {
    const item = items.find(item => item?.id == id_colegio)
    if(items.length == 0 || !item || getFromServer) {
      new Requests().getColegio(id_colegio, (colegio) => {
        setColegio(colegio)
        updateItem(colegio)
      }, () => updateAlert("No se ha podido recuperar el colegio."))
    } else {
      setColegio(item)
    }
  }
    
  useEffect(getColegio, [id_colegio])

  return (
    <React.Fragment>
      <EditColegio key={colegio?.id ?? 0} colegio={colegio} id_colegio={id_colegio} setIdColegio={setIdColegio} setColegio={setColegio} updateAlert={updateAlert} onSaveFinish={() => getColegio(true)}/>

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}
export default Wrapper


function EditColegio({ colegio, id_colegio, setIdColegio, setColegio, updateAlert, onSaveFinish }) {
  const navigate = useNavigate()

  const { updateChanges } = useContext(ChangesContext)
  
  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, setValue, reset } = useForm({ resolver: yupResolver(validationSchema) })

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])

  const headerRef = useRef()

  const updateHeaderAndAlert = ({ message, severity = "error"}, loading = false) => {
    headerRef.current.button.setLoading(loading)
    updateAlert(message, severity)
  }

  const editColegio = () => {
    headerRef.current.button.setLoading(true)

    new Requests().editColegio(id_colegio, getValues, () => {
      updateHeaderAndAlert({message: "Se ha editado el colegio correctamente.", severity: "success"})
      reset({}, {keepValues: true})
      updateChanges(false)
      onSaveFinish()
    }, (res) => {
      updateHeaderAndAlert({message: res})
    })
  }

  const updateId = (id) => {
    navigate(`../colegios/${id}/editar`)
    setIdColegio(id)
  }

  return(
    <Container maxWidth={false} sx={{ mt: 5 }}>
        <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
          <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} marginX={8}>
            <BreadcrumbsList mx={0} breadcrumbsList={[
              { to: "/",        label: "Inicio" },
              { to: "./../../", label: "Colegios" },
              { to: "./../",    label: "Colegio #" + (id_colegio ?? "0000") },
              { to: "./",       label: "Editar", actualItem: true },
            ]} />

            <MoveButtons 
              actualItemId        = {id_colegio}
              beforeFetchCallback = {() => setColegio(null)} 
              onUpdateActualItem  = {updateId} />
          </Stack>

          <Paper elevation={2} sx={{mx: 8, my: 2, pb: 5}}>
            <EditHeader 
              ref          = {headerRef} 
              title        = {"Editar colegio"}
              item         = {colegio} 
              handleSubmit = {handleSubmit(editColegio)} />

            <Line className={"mb-5"} />

            {colegio ? <DataFormColegio colegio={colegio} register={register} errors={errors} setValue={setValue}/> : <Skeleton />}
          </Paper>
        </form>
      </Container>
  )

}