import React, { useState, useEffect } from "react";
import { Grid, Box, Stack, FormControlLabel, TextField } from "@mui/material";
import { ItemsSelect } from "components/forms/components/Select/custom";
import { Text } from "components/texts";
import { Checkbox, CustomFixedSizeList } from "components/forms/components";
import { RequestColegio } from "objects/requests";

const requestColegio = new RequestColegio();
const destinatario = "colegio"

export default function ColegiosTemplate({ errors, register, reset,getValues, setValue }) {
  const itemCount = 100; // Número total de elementos
  const itemsPerColumn = 6;
  const itemWidth = 110;
  const [colegios, setColegios] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [todosChecked, setTodosChecked] = useState(false); // Estado local para el checkbox "Todos"
  const [otherChecked, setotherChecked] = useState(false);
  const [selectedName, setSelectedName] = useState("todos");

  const handleCheckboxChange = (name, newChecked) => {
    if (name == "todos") {
      setTodosChecked(newChecked);
      setotherChecked(newChecked);
      setSelectedName("todos");
      setSearchTerm('')
    } else {
      if (todosChecked) {
        setotherChecked(false);
      }
    }

    if (name !== "todos") {
      const searchTermToSet = (newChecked === true) ? name : '';
      setSearchTerm(searchTermToSet)
    }
  };

  //TODO:
  // const { items } = useContext(EmpleadoListContext)

  useEffect(() => {
    if (!colegios || colegios.length === 0) {
      requestColegio.all({}, (response) => {
        setColegios(response["colegios"]);
      });
    }
  }, []);

  const filteredColegios = colegios.filter((colegio) => {
    const nombre = colegio.nombre.toLowerCase();
    return nombre.includes(searchTerm.toLowerCase());
  });

  const renderItem = (index) => {
    const colegio = filteredColegios[index];
    if (colegio) {
      return (
        <FormControlLabel
          sx={{ margin: 0.5, width: 250, paddingLeft: 4 }}
          key={index}
          control={<Checkbox name={`option${index}`} onClick={(clicked) => { 
            console.log(clicked)
           }} />}
          label={colegio?.nombre}
        />
      );
    } else { return null; }
  };

  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={4}>
      <Grid item xs={3}>
        <ItemsSelect
          name={`${destinatario}.id_curso_escolar`}
          keyValue={"curso_escolar"}
          register={register}
          errors={errors}
          wantToReset={reset}
        />
      </Grid>

      <Grid item xs={3}>
        <ItemsSelect
          name={`${destinatario}.id_tipo_actividad`}
          keyValue={"tipo_actividad"}
          register={register}
          errors={errors}
        />
      </Grid>

      <Grid item xs={3}>
        <ItemsSelect
          name={`${destinatario}.id_actividades`}
          keyValue={"actividad"}
          register={register}
          errors={errors}
          wantToReset={reset}
          searcheable
        />
      </Grid>

      <Grid item xs={3}>
        <ItemsSelect
          name={`${destinatario}.id_modalidad_cursos`}
          keyValue={"modalidad_cursos"}
          register={register}
          errors={errors}
          wantToReset={reset}
          searcheable
        />
      </Grid>

      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} p={2.5}>
        <Grid item xs={3} mt={2}>
          <ItemsSelect
            register={register}
            errors={errors}
            name={`${destinatario}.nivel_grupo_id`}
            keyValue={"nivel_grupo"}
          />
        </Grid>

        <Grid item xs={9} container>
          <Box
            component="fieldset"
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Stack mt={1.5} mr={2.5} ml={1}>
              <Text>Filtros: </Text>
            </Stack>
            <Stack mt={2} mr={4}>
              <Checkbox
                name={"todos"}
                label={"Todos"}
                register={register}
                defaultChecked={null}
                checked={todosChecked}
                onClick={(newChecked) =>
                  handleCheckboxChange("todos", newChecked)
                }
              />
            </Stack>
            <Stack mt={2} mr={4}>
              <Checkbox
                name={"ceip"}
                label={"C.E.I.P"}
                register={register}
                defaultChecked={otherChecked}
                disabled={otherChecked != false}
                onClick={(newChecked) =>
                  handleCheckboxChange("ceip", newChecked)
                }
              />
            </Stack>
            <Stack mt={2} mr={4}>
              <Checkbox
                name={"ies"}
                label={"I.E.S"}
                register={register}
                disabled={otherChecked != false}
                onClick={(newChecked) =>
                  handleCheckboxChange("ies", newChecked)
                }
              />
            </Stack>
            <Stack mt={2} mr={4}>
              <Checkbox
                name={"ave"}
                label={"AVE"}
                register={register}
                defaultChecked={null}
                disabled={otherChecked != false}
                onClick={(newChecked) =>
                  handleCheckboxChange("ave", newChecked)
                }
              />
            </Stack>
            <Stack mt={2} mr={4}>
              <Checkbox
                name={"colegio"}
                label={"Colegio"}
                register={register}
                defaultChecked={null}
                disabled={otherChecked != false}
                onClick={(newChecked) =>
                  handleCheckboxChange("colegio", newChecked)
                }
              />
            </Stack>
            <Stack mt={2} mr={4}>
              <Checkbox
                name={"san_santa"}
                label={"San/Santa"}
                register={register}
                disabled={otherChecked != false}
                onClick={(newChecked) =>
                  handleCheckboxChange("san/santa", newChecked)
                }
              />
            </Stack>
            <Stack mt={2}>
              <Checkbox
                name={"otros"}
                label={"Otros"}
                register={register}
                defaultChecked={null}
                disabled={otherChecked != false}
                onClick={(newChecked) => {
                  handleCheckboxChange("", newChecked)
                }}
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} p={2.5}>
        <Grid item xs={3}>
          <TextField
            label="Buscar colegio por nombre..."
            size="small"
            className="w-100"
            type="text"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
        </Grid>

        <Grid item xs={9} container>
          <CustomFixedSizeList
            itemCount={itemCount}
            itemsPerColumn={itemsPerColumn}
            itemWidth={itemWidth}
            renderItem={renderItem} />
        </Grid>
      </Grid>
    </Grid>
  );
}
