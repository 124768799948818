import { Text } from 'components/texts'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { useNavigate } from "react-router-dom"
import Statics from 'objects/common/Statics.js'
import { Container, Paper } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangesContext } from 'contexts/ChangesContext'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { DataFormDatosColegio } from 'components/forms/display'
import { BreadcrumbsList, Line } from 'components/data_display'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { CreateHeader, AlumnoSection } from 'pages/logged/admin/components'
import { validationSchema } from 'components/forms/display/datos-colegio/DataForm/validation.js'

export default function CreateDatosColegio() {
  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues } = useForm({ resolver: yupResolver(validationSchema) })
  const navigate = useNavigate()

  const headerRef        = useRef()
  const alumnoSectionRef = useRef()

  const [isMounted, setIsMounted] = useState(false)

  const { updateChanges } = useContext(ChangesContext)

  useEffect(() => setIsMounted(true), [])

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])

  const onValid = () => {
    if (alumnoSectionRef.current.isValid())
      createDatosColegio()
  }

  const onError = () => {
    alumnoSectionRef.current.isValid()
  }

  const createDatosColegio = () => {
    headerRef.current.button.setLoading(true)

    new Requests().createDatosColegio(getValues, alumnoSectionRef.current.getAlumno().id, async (res) => {
      const { id } = res.item
      enqueueSnackbar(<Text>Se han creado los datos del colegio del alumno correctamente. Redirigiendo a la pagina de edición..</Text>, { variant: "success" })
      updateChanges(false)
      
      await Statics.delay(1500).then(() => {navigate('./../' + id + "/editar")})
    }, (res) => {
      headerRef.current.button.setLoading(false)
      enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
    })
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <BreadcrumbsList breadcrumbsList={[
          { to: "/",        label: "Inicio" },
          { to: "./../../", label: "Alumnos" },
          { to: "./../",    label: "Datos en colegios"},
          { to: "./",       label: "Crear", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{mx: 8, my: 2, pb: 5}}>
          <CreateHeader
            ref          = {headerRef}
            title        = {"Crear datos"}
            handleSubmit = {handleSubmit(onValid, onError)} />

          <Line className="mt-5"/>

          <AlumnoSection 
            ref        = {alumnoSectionRef}
            title      = {"Elige el alumno para el que se van a crear los datos del colegio: "}
            buttonText = {"Elegir alumno para los nuevos datos"}
            onSelect   = {() => updateChanges(true)} />

          <DataFormDatosColegio 
            register  = {register} 
            errors    = {errors} 
            isMounted = {isMounted} 
            hideButton />
        </Paper>

        <SnackbarProvider autoHideDuration={3000}/>
      </form>
    </Container>
  )
}