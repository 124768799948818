import { Grid, Stack } from "@mui/material"
import { Text, H3 } from "components/texts"
import { Line } from 'components/data_display';
import SkeletonPersonalCandidato from './skeleton'

/**
 * Formulario con los datos del curriculum del candidato
 * 
 */
export default function DataPersonalCandidato({ candidato }) {

    return (
        <Grid container p={3}>
            {!candidato ?
                <SkeletonPersonalCandidato/>
                : (
                    <>
                        <Grid container item xs={12} px={4} mt={2} >

                            <Grid item xs={3} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Documento Identificador</Text>
                                    <Text>{candidato?.documento_identificador ?? ""}</Text>
                                </Stack>

                                <Grid container direction="row" spacing={2}>

                                    <Grid item >
                                        <Text fontWeight={"bold"}>Tipo Documento</Text>
                                    </Grid>

                                    <Grid item>
                                        <Text>{candidato?.tipo_documento ?? "numero documento"}</Text>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item xs={2} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Nombre</Text>
                                    <Text>{candidato?.nombre ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2.4} mt={2} >
                                <Stack>
                                    <Text fontWeight={"bold"}>1. Apellido</Text>
                                    <Text>{candidato?.apellido1 ?? ""}</Text>
                                </Stack>
                            </Grid>
                            <Grid item xs={2.4} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>2. Apellido</Text>
                                    <Text>{candidato?.apellido2 ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Sexo</Text>
                                    <Text>{candidato?.sexo ?? ""}</Text>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <Line />
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} px={4} mt={2} >

                            <Grid item xs={12}>
                                <H3>Datos de Nacimiento</H3>
                            </Grid>

                            <Grid item xs={3} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Fecha</Text>
                                    <Text>{candidato?.fecha_nacimiento ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Municipio</Text>
                                    <Text>{candidato?.municipio_nacimiento ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2.4} mt={2} >
                                <Stack>
                                    <Text fontWeight={"bold"}>Provincia</Text>
                                    <Text>{candidato?.provincia ?? ""}</Text>
                                </Stack>
                            </Grid>
                            <Grid item xs={2.4} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>País</Text>
                                    <Text>{candidato?.pais ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Line />
                            </Grid>

                        </Grid>


                        <Grid container item xs={12} px={4} mt={2} >
                            <Grid item xs={12}>
                                <H3>Otros datos</H3>
                            </Grid>

                            <Grid item xs={2} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Dirección</Text>
                                    <Text>{candidato?.direccion ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={1} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>C. Postal</Text>
                                    <Text>{candidato?.cp ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Municipio</Text>
                                    <Text>{candidato?.municipio ?? ""}</Text>
                                </Stack>
                            </Grid>
                            <Grid item xs={2} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Provincia</Text>
                                    <Text>{candidato?.provincia ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2.5} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Correo electrónico Principal</Text>
                                    <Text>{candidato?.correo_electronico_principal ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2.5} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Correo electrónico Adicional</Text>
                                    <Text>{candidato?.correo_electronico_adicional ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2} mt={2} >
                                <Stack>
                                    <Text fontWeight={"bold"}>Teléfono</Text>
                                    <Text>{candidato?.telefono ?? "665854515"}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={1} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Móvil</Text>
                                    <Text>{candidato?.movil ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Fax</Text>
                                    <Text>{candidato?.fax ?? ""}</Text>
                                </Stack>
                            </Grid>

                        </Grid>

                    </>
                )
            }
        </Grid>
    )
}