import { Box, Grid, Stack } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { Line } from 'components/data_display';

export default function SkeletonDataFormProgramacionesGrupoColegio() {
    return (
        <Grid container px={5} pb={5}>
            <Grid container spacing={2} my={2}>
                <Grid item xs={8}>
                    <Stack spacing={2}>
                        <Skeleton variant="rounded" width={"20%"} height={20} />
                        <Skeleton variant="rounded" width={"100%"} height={30} />
                    </Stack>
                </Grid>

                <Grid item xs={4} display={"flex"} alignItems={"end"}>
                    <Stack direction={"row"} spacing={2} className={"w-100"}>
                        <Skeleton variant="rounded" width={"100%"} height={40} />

                        <Skeleton variant="rounded" width={"100%"} height={40} />
                    </Stack>
                </Grid>
            </Grid>

            <Grid item xs={12} mt={2}>
                <Line />
            </Grid>

            <Grid container spacing={2} my={2}>
                <Grid item xs={8}>
                    <Stack spacing={2}>
                        <Skeleton variant="rounded" width={"20%"} height={20} />
                        <Skeleton variant="rounded" width={"100%"} height={30} />
                    </Stack>
                </Grid>

                <Grid item xs={4} display={"flex"} alignItems={"end"}>
                    <Stack direction={"row"} spacing={2} className={"w-100"}>
                        <Skeleton variant="rounded" width={"100%"} height={40} />

                        <Skeleton variant="rounded" width={"100%"} height={40} />
                    </Stack>
                </Grid>
            </Grid>

            <Grid item xs={12} mt={2}>
                <Line />
            </Grid>

            <Grid container spacing={2} my={2}>
                <Grid item xs={8}>
                    <Stack spacing={2}>
                        <Skeleton variant="rounded" width={"20%"} height={20} />
                        <Skeleton variant="rounded" width={"100%"} height={30} />
                    </Stack>
                </Grid>

                <Grid item xs={4} display={"flex"} alignItems={"end"}>
                    <Stack direction={"row"} spacing={2} className={"w-100"}>
                        <Skeleton variant="rounded" width={"100%"} height={40} />

                        <Skeleton variant="rounded" width={"100%"} height={40} />
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    )
}
