import { useEffect, useRef, useState } from "react";
import { H2 } from 'components/texts'
import { COLOR } from "theme/Colors";

const padNumber = (number) => { return number.toString().padStart(2, '0') }

const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    
    return `${padNumber(hours < 0 ? 0 : hours)}:${padNumber(minutes < 0 ? 0 : minutes)}:${padNumber(seconds < 0 ? 0 : seconds)}`;
};

/**
 * Contador para fichaje
 * 
 * @param {Object} param0 
 * @param {*} param0.timer 
 * @param {*} param0.setTimer 
 * @returns Component
 */

export default function Timer({ timer, setTimer }) {
    /** STATE */
    const intervalRef = useRef(null)

    const [seconds, setSeconds] = useState(timer.time)

    const wantToStart = () => {
        if(timer.wantToStart) {
            setSeconds(timer.time)
            intervalRef.current = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }, 1000);
            
            setTimer({ wantToStart: false, wantToFinish: false, wantToUpdate: false, time: timer.time, fichaje: timer.fichaje })
        }
    }

    const wantToUpdate = () => {
        if(timer.wantToUpdate) {
            setSeconds(timer.time)
            deleteInterval()
            
            intervalRef.current = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }, 1000);
            
            setTimer({ wantToStart: false, wantToFinish: false, wantToUpdate: false, time: timer.time, fichaje: timer.fichaje })
        }
    }

    const wantToFinish = () => {
        if(timer.wantToFinish) {
            setSeconds(0)
            deleteInterval()

            setTimer({ wantToStart: false, wantToFinish: false, wantToUpdate: false, time: 0, fichaje: null })
        }
    }

    useEffect(wantToStart,  [timer.wantToStart])
    useEffect(wantToUpdate, [timer.wantToUpdate])
    useEffect(wantToFinish, [timer.wantToFinish])

    const deleteInterval = () => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }

    return (
        <div className={`d-flex justify-content-center py-8`} style={{ border: "3px solid " + COLOR.standard.main, borderRadius: 5 }}>
            <H2 style={{ letterSpacing: 10, color: COLOR.standard.main }}>{formatTime(seconds)}</H2>
        </div>
    )
}