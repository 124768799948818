import dayjs from 'dayjs'
import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { Checkbox, DatePicker, Input, LoadingButton } from 'components/forms/components'

/**
 * Formulario con los datos de una incidencia
 */
export default function DataFormIncidencia({ incidencia, register, errors, handleSubmit, setValue, hideButton = false, isMounted = false }) {

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container mt={2} px={4} spacing={2}>
            <Grid item xs={4}>
                {isMounted ? <ItemsSelect name={"id_curso_escolar"} keyValue={"curso_escolar"} className="my-2" register={register} errors={errors} defaultValue={incidencia?.id_curso_escolar ?? ""} /> : false}
            </Grid>
            
            <Grid item xs={4}>
                <DatePicker register={register} errors={errors} className="w-100 my-2" name={"fecha"} defaultValue={incidencia?.fecha} setValue={setValue} />
            </Grid>

            <Grid item xs={4}>
                {isMounted ? <ItemsSelect name={"id_tipo_incidencia"} keyValue={"tipo_incidencia"} className="my-2" register={register} errors={errors} defaultValue={incidencia?.id_tipo_incidencia ?? ""} /> : false}
            </Grid>

            <Grid item xs={8}>
                <Input label="Incidencia" register={register} errors={errors} className="w-100 my-2" name={"incidencia"} defaultValue={incidencia?.incidencia ?? ""} multiline minRows={6} maxRows={10} uppercase />
            </Grid>

            <Grid item xs={4}>
                <Checkbox name={"solucionada"} label={"¿La incidencia está solucionada?"} register={register} defaultChecked={incidencia?.solucionada ? true : false} />
            </Grid>

            {hideButton ? null : 
                (<Grid item xs={12} justifyContent="end" display={"flex"}>
                    <LoadingButton onClick={e => handleSubmit(e)} color={"success"}>
                        Guardar incidencia
                    </LoadingButton>
                </Grid>)
            }
        </Grid>
    )
}
