import RequestMaster from '../RequestMaster'

export default class RequestTempCL extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/temp-cl'
    }


    /**
     *
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    importEmpleados(callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "import-empleados"
        this.post(path, new FormData(), callback, callbackError, callbackFinally)
    }
}
