import axios from 'axios'
import options from './options'
import store from 'store/store'
import { v4 as uuid } from 'uuid'
import { API_URL } from 'constants/Api'
import { useQuery } from "@tanstack/react-query"
import { SearcheableSelect, Select } from 'components/forms/components'
import React, { useState, useEffect, useRef } from 'react'

const getFinalDataArray = (obj, path) => {
    const keys = path.split('.')
    let value = obj

    for (let key of keys) {
        value = value[key]
        if (value === undefined)
            break
    }

    return value
}

const ItemsSelect = ({
    register,
    defaultValue,
    className,
    name,
    errors,
    label,
    width,
    disabled,
    initialLabel,
    keyValue,
    wantToReset,
    url,
    renderItem,
    initialOption,
    initialOptionByLabel,
    onDefaultValueLoaded,
    onFetchedFinished = () => { },
    onChange,
    goToPreviousOption,
    setValue,
    errorLabel,
    allOption,
    params,
    searcheable = false,
}) => {
    const [items, setItems] = useState([{ value: '', label: initialLabel ?? 'Cargando...' }])
    const [rawItems, setRawItems] = useState([])

    const selectRef = useRef()

    const getData = (url) => {
        const { userState } = store.getState()
        return axios.get(`${API_URL}/${url}`, { params, responseType: 'json', headers: { Authorization: `Bearer ${userState.token}` } }).then(response => { return response.data })
    }

    const query = useQuery({ queryKey: [options[keyValue] ? options[keyValue].queryKey : ""], queryFn: () => getData(options[keyValue] ? options[keyValue].url : url), enabled: false })

    const onFetched = (res) => {
        const arrayName = options[keyValue].arrayName
        const campoName = options[keyValue].campoName

        const finalDataArray = getFinalDataArray(res, arrayName)

        const fetchedItems = renderItem ? finalDataArray.map(renderItem) : finalDataArray.map(item => ({ value: item.id, label: item[campoName] }))

        if (allOption)
            fetchedItems.unshift({ value: 0, label: options[keyValue].allLabel ?? "Todos" })

        if (initialOption !== undefined) {
            const value = fetchedItems[initialOption].value
            selectRef.current.setValue(value, () => {
                if (setValue) setValue(name ?? "", value)
                if (onChange) onChange({ target: { value } })
            })
        }

        if (initialOptionByLabel !== undefined) {
            const item = fetchedItems.find(item => item.label == initialOptionByLabel)
            if (item) {
                selectRef.current.setValue(item.value, () => {
                    if (setValue) setValue(name ?? "", item.value)
                    if (onChange) onChange({ target: { value: item.value } })
                })
            }
        }

        setItems(fetchedItems)
        setRawItems(finalDataArray)
    }

    const onError = () => {
        setItems([{ value: "", label: errorLabel ?? options[keyValue].errorLabel ?? "" }])
        setRawItems([])
    }

    useEffect(() => {
        if (query.isFetched && !query.isError) {
            onFetched(query.data)
            onFetchedFinished(query.data)
        }
        if (query.isError) onError()
        if (!query.isFetched && !query.isFetching) query.refetch()
    }, [query.isFetched])

    useEffect(() => {
        if (goToPreviousOption)
            selectRef.current.goToPreviousOption()
    }, [goToPreviousOption])

    const onChangeSelect = (event) => {
        if (onChange) {
            const itemSelected = rawItems.find(item => item.id == event.target.value)
            onChange(event, itemSelected)
        }
    }

    const labelId = name + "_select-id-" + uuid()
    const SelectToRender = searcheable ? SearcheableSelect : Select
    return (
        <SelectToRender
            ref                  = {selectRef}
            width                = {width ?? "100%"}
            items                = {items}
            className            = {className}
            disabled             = {disabled}
            defaultValue         = {defaultValue}
            onDefaultValueLoaded = {onDefaultValueLoaded}
            register             = {register}
            onChange             = {onChangeSelect}
            name                 = {name}
            errors               = {errors}
            labelId              = {labelId}
            wantToReset          = {wantToReset}
            label                = {label ?? options[keyValue]?.label ?? ""}
            native />
    )
}

export default ItemsSelect