import { H3, Text } from "components/texts"
import { enqueueSnackbar } from 'notistack'
import { RequestAuth } from 'objects/requests'
import { Grid, Box } from "@mui/material"
import React, { useEffect, useState } from 'react'
import { Checkbox } from 'components/forms/components'
import { LoadingScreen } from 'components/data_display'

export default function RolesList ({ setValue, userRoles, loading, name = "roles" }) {
    const [roles,         setRoles]         = useState([])
    const [selectedRoles, setSelectedRoles] = useState([])

    useEffect(() => {
        new RequestAuth().allRoles(setRoles, (res) => {
            enqueueSnackbar(<Text>No se han podido recuperar los roles: {res}.</Text>, { variant: "error" })
        })
    }, [])

    useEffect(() => {
        const defaultRoles = userRoles.map(item => item.id)
        setValue(name, defaultRoles)
        setSelectedRoles(defaultRoles)
    }, [userRoles])

    const onCheckboxClick = (checked, role) => {
        const roleId = role.id;

        if (checked && !selectedRoles.includes(roleId)) {
            selectedRoles.push(roleId);
        } else if (!checked && selectedRoles.includes(roleId)) {
          const indexToRemove = selectedRoles.indexOf(roleId);
          if (indexToRemove !== -1) {
            selectedRoles.splice(indexToRemove, 1);
          }
        }

        setValue(name, selectedRoles)
        setSelectedRoles(selectedRoles)
    }

    return (
        <Box sx={{
            p: 5,
            pt: 2,
            width: "100%",
            maxHeight: 500,
            overflowY: "auto"
        }}>
                {loading ?
                    <LoadingScreen />
                    :
                    <Grid container my={2}>
                        {roles.map(role => {
                            if(role.name == 'alumno' || role.name == 'responsable')
                                return null
                            
                            return (
                                <Grid key={role.id} item xs={4}>
                                    <Checkbox 
                                        label          = {formatName(role.name)} 
                                        defaultChecked = {selectedRoles.includes(role.id)}
                                        onClick        = {(checked) => onCheckboxClick(checked, role)} />
                                </Grid>
                            )
                        })}
                    </Grid>
                }
        </Box>
    )
}

function formatName(name) {
    const formattedName = name.replace(/-/g, ' ').replace(/^\w/, c => c.toUpperCase())
    return formattedName
}