import { Box } from '@mui/material'
import React, { Component } from 'react'
import { Modal } from 'components/modals'
import { Button } from 'components/forms/components'
import { RequestAlumnoResponsable } from 'objects/requests'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Modal con el formulario de añadir responsable
 */
export default class ChooseTipoRelacion extends Component {

    constructor(props) {
        super(props)

        this.state = {
            relationSelected: null,
            responsable: null,
            alumno: null,
        }
    }


    /**
     * Actualiza el estado y abre el modal
     * 
     * @param {Object} alumno 
     * @param {Object} responsable 
     */
    open = (alumno, responsable) => {
        this.setState({alumno, responsable}, () => {
            this.childModal.open()
        })
    }


    /**
     * Hace la petición para guardar la relación de ambos modelos
     */
    saveRelation = () => {
        var formData = new FormData()
        formData.append('id_responsable',   this.state.responsable.id)
        formData.append('id_alumno',        this.state.alumno.id)
        formData.append('id_tipo_relacion', this.state.relationSelected)

        new RequestAlumnoResponsable().create(formData, () => {
            this.childModal.close()
            if(this.props.callbackOk)
                this.props.callbackOk()
        }, (res) => {
            if(this.props.callbackError)
                this.props.callbackError(res)
        })
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Modal ref={(ref) => this.childModal = ref} ariaLabel={"child-modal-title"} ariaDescribe={"child-modal-description"} title={"Elige el tipo de relación del tutor con el alumno"} style={{minWidth: "50%"}}>
                <Box p={10}>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <ItemsSelect name={"id_tipo_relacion"} keyValue={"tipo_relacion"} onChange={(e) => {this.setState({relationSelected: e.target.value})}}/>
                    </Box>
                    <Box display={"flex"} justifyContent={"end"} alignItems={"center"}>
                        <Button 
                            className = "my-5" 
                            onClick   = {this.saveRelation}
                            disabled  = {!this.state.relationSelected}>
                                
                            Aceptar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        )
        
    }
}