import React from 'react'
import { Input } from 'components/forms/components';
import { H3 } from 'components/texts';
import { Grid } from '@mui/material';
/**
 * Formulario con los datos del alumno
 */
export default function DataDomiciliacionBancariaForm({ domiciliacion_bancaria, register, errors }) {

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <H3 className={"m-0"}>Datos bancarios</H3>
            </Grid>

            <Grid item xs={4}>
                <Input label="Código de país" register={register} errors={errors} className="w-100" name={"codigo_pais"} defaultValue={domiciliacion_bancaria?.codigo_pais ?? ""}/>
            </Grid>

            <Grid item xs={4}>
                <Input label="Número de control" register={register} errors={errors} className="w-100" name={"numero_control"} defaultValue={domiciliacion_bancaria?.numero_control ?? ""}/>
            </Grid>

            <Grid item xs={4}>
                <Input label="Cuenta" register={register} errors={errors} className="w-100" name={"cuenta1"} defaultValue={domiciliacion_bancaria?.cuenta1 ?? ""}/>
            </Grid>

            <Grid item xs={4}>
                <Input label="IBAN" register={register} errors={errors} className="w-100" name={"iban"} defaultValue={domiciliacion_bancaria?.iban ?? ""} uppercase/>
            </Grid>

            <Grid item xs={4}>
                <Input label="Nombre deudor" register={register} errors={errors} className="w-100" name={"nombre_deudor"} defaultValue={domiciliacion_bancaria?.nombre_deudor ?? ""}/>
            </Grid>

            <Grid item xs={4}>
                <Input label="Referencia mandato" register={register} errors={errors} className="w-100" name={"referencia_mandato"} defaultValue={domiciliacion_bancaria?.referencia_mandato ?? ""}/>
            </Grid>
        </Grid>
    )
}
