import React from 'react'
import { Grid } from '@mui/material'
import { ItemsSelect } from 'components/forms/components/Select/custom'


export default function CursosVeranoTemplate({ register, errors, reset }) {

    return (
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={4}>

            <Grid item xs={3} border={'1px solid red'}>
                Año
            </Grid>

            <Grid item xs={3} border={'1px solid black'}>
                Tipo de Curso
            </Grid>

            <Grid item xs={3} border={'1px solid red'}>
                <ItemsSelect name={"id_curso_escolar"} keyValue={"curso_escolar"} register={register} errors={errors} wantToReset={reset} />
            </Grid>

            <Grid item xs={3} border={'1px solid black'}>
                Opción
            </Grid>

        </Grid>
    )
}
