import Skeleton from './skeleton'
import React, { Component } from 'react'
import { ASSETS } from 'constants/Assets'
import { H3, Text } from 'components/texts'
import { Link } from 'components/data_display'
import { Button } from 'components/forms/components'
import { Alert, Avatar, Grid, Paper, Tooltip, Box } from '@mui/material'
import { AddResponsableModal, DeleteRelacionModal } from 'components/modals'
import { Visibility as SeeIcon, Add as AddIcon, OpenInNewOff as RemoveAsociationIcon } from '@mui/icons-material'

/**
 * Informacion de los responsables del alumno
 */
export default class ResponsableData extends Component {

  constructor(props) {
    super(props)

    this.state = {
      responsables: this.props.responsables ?? null
    }
  }


  /**
   * ComponentDidUpdate
   * 
   * @param {Object} prevProps 
   */
  componentDidUpdate(prevProps) {
    if (this.props.responsables != prevProps.responsables)
      this.setState({ responsables: this.props.responsables })
  }

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    let { responsables } = this.state
    return (
      <Grid container>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <H3 className={"m-0"}>Tutores:</H3>
        </Grid>

        <Grid item xs={6} display={"flex"} justifyContent={"end"} alignItems={"center"}>
          {responsables ? (
            <Button variant={"outlined"} startIcon={<AddIcon />} onClick={() => { this.addResponsableModal.modal.open() }}>
              Crear tutor
            </Button>) : null}
        </Grid>

        {!responsables ? <Skeleton /> : (
          <Grid container mt={2} spacing={2}>
            {responsables.map(item => (<ResponsableItem key={Math.random()} responsable={item} openDeleteRelacionModal={(responsable) => {this.deleteRelacionModal.open(responsable, this.props.alumno)}}/>))}

            {responsables.length == 0 ? <Grid item xs={12}><Alert severity="info" className='mt-10 w-100'><H3 className="standard">Este alumno no tiene ningún tutor.</H3></Alert></Grid> : null}
          </Grid>
        )}

        <AddResponsableModal ref={(ref) => this.addResponsableModal = ref} alumno={this.props.alumno} onCreateCallback={this.props.reload} />
        <DeleteRelacionModal ref={(ref) => this.deleteRelacionModal = ref} onDeleteCallback={this.props.reload} />
      </Grid>
    )
  }
}


/**
 * Item que muestra la información de un responsable
 */
class ResponsableItem extends Component {

  constructor(props) {
    super(props)

    this.state = {
      responsable: this.props.responsable ?? null
    }
  }


  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    let { responsable } = this.state
    return (
      <Grid item xs={6} xl={4}>
        <Paper elevation={0} sx={{px: 3, pt: 2, pb: 3, borderRadius: 2, border: "1px solid var(--greyLight)"}}>
            <Box justifyContent={"end"} alignItems={"center"} display={"flex"}>
              <Link to={"./../tutores/" + responsable.id}>
                <Tooltip title={<Text color={"white"}>Ver tutor</Text>}>
                  <SeeIcon color={"standard"} className={"mx-2"}/>
                </Tooltip>
              </Link>

              <Tooltip title={<Text color={"white"}>Borrar relación</Text>}>
                  <div onClick={() => {this.props.openDeleteRelacionModal(responsable)}}>
                    <RemoveAsociationIcon color={"error"} className={"clickable mx-2"}/>
                  </div>
              </Tooltip>
            </Box>

            <Box alignItems={"center"} display={"flex"}>
                <Avatar alt={responsable.nombre ?? ""} src={`${ASSETS.images + (responsable.foto_logo ? responsable.foto_logo : 'private/responsable/perfiles/0/default.png')}`} sx={{ width: 75, height: 75 }}/>

                <Box mx={3} display={"flex"} flexDirection={"column"}>
                  <Text>{(responsable.nombre ?? "") + " " + (responsable.apellido1 ?? "") + " " + (responsable.apellido2 ?? "")}</Text>
                  <Text>{(responsable.numero_documento ?? "") + " - " + (responsable.tipo_documento ? responsable.tipo_documento.nombre : "")}</Text>
                  <Text>{"Relación: " + (responsable.tipo_relacion ?? "")}</Text>
                </Box>
            </Box>
        </Paper>
      </Grid>
    )
  }
}
