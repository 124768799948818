

import './style.css'
import { v4 as uuid } from 'uuid';
import { months } from './constants'
import { H3, Text } from 'components/texts';
import Statics from 'objects/common/Statics';
import { Checkbox } from 'components/forms/components/';
import React, { Component, useRef, useState } from 'react';

const pagoInscripcionObject = {
    id:                     null,
    mes:                    null,
    fecha:                  null,
    cantidad:               null,
    pagado:                 0,
    otros_cargos:           null,
    total:                  null,
    id_inscripcion_colegio: null, 
}

const initializeArray = () => {
    return months.map(month => ({...pagoInscripcionObject, mes: month.value}))
}

const TableRow = ({pagos, backgroundClass, name, arrayItemName, formatter = null, type="number", updateState = () => {}}) => {

    const onEdit = (newValue, month) => {
        const index = pagos.findIndex(item => item.mes == month.value)
        if(index != -1) {
            pagos[index][arrayItemName] = newValue
            updateState(pagos)
        }
    }

    return (
        <tr className={backgroundClass}>
            <td style={{minWidth: 150}}><Text fontWeight={"bold"}>{name}</Text></td>
            {months.map(month => {
                const value = pagos.find(item => item.mes == month.value)[arrayItemName]
                return <TableColumn key={uuid()} defaultValue={formatter && value ? formatter(value) : value} onEdit={(newValue) => onEdit(newValue, month)} type={type}/>
            })} 
        </tr>
    )
}

const TableColumn = ({defaultValue, onEdit, type, disabled=false}) => {
    const input             = useRef()
    const [value, setValue] = useState(defaultValue ?? "")

    return (
        <td>
            <input 
                ref      = {input} 
                type     = {type} 
                value    = {value} 
                onChange = {(e) => {
                    setValue(e.target.value)

                    if(type === "date")
                        onEdit(e.target.value)
                }} 
                onBlur    = {() => onEdit(value) } 
                onKeyDown ={(event) => {
                    if(event.code === 'Enter')
                        input.current.blur()
                    
                    if(["e", "E", "+", "-"].includes(event.key))
                        event.preventDefault()
                }}
                disabled={disabled}/>
        </td>
    )
}

export default class MonthsTable extends Component {

    constructor(props){
        super(props)

        this.state = {
            pagos: this.props.inscripcion !== undefined ? this.props.inscripcion.pagos && this.props.inscripcion.pagos.length > 0 ? this.props.inscripcion.pagos : initializeArray() : initializeArray()
        }
    }


    getPagos = () => {
        return this.state.pagos
    }

    
    updatePagos = (pagos) => {
        this.setState({pagos})
    }
    

    render() {
        const { pagos } = this.state
        return (
            <table className='meses-table'>
    
                <tbody className='no-border'>
                    <tr>
                        <td className='no-border' style={{minWidth: 150}}><Text fontWeight={"bold"}>Mes pagado:</Text></td>
                        {months.map(month => (
                            <td className='no-border' key={uuid()}>
                                <Checkbox 
                                    name            = {month.label}
                                    register        = {this.props.register} 
                                    defaultChecked  = {pagos.find(item => item.mes == month.value).pagado ? true : false}
                                    onClick         = {(checked) => {
                                        const index = pagos.findIndex(item => item.mes == month.value)
                                        if(index != -1)
                                            pagos[index]['pagado'] = checked ? 1 : 0
                                    }}/>
                            </td>  
                        ))} 
                    </tr>
    
                    <tr>
                        <td className='no-border'></td>
                        {months.map(month => (
                            <td key={uuid()}><H3>{month.label}</H3></td>  
                        ))} 
                    </tr>
    
                    <TableRow pagos={pagos} backgroundClass="red-bg"    name="Mensualidad"  arrayItemName={"cantidad"}     formatter={Statics.changeDotAndComma} updateState={this.updatePagos}/> 
                    <TableRow pagos={pagos} backgroundClass="green-bg"  name="Otros cargos" arrayItemName={"otros_cargos"} formatter={Statics.changeDotAndComma} updateState={this.updatePagos}/> 

                    <tr className={"yellow-bg"}>
                        <td style={{minWidth: 150}}><Text fontWeight={"bold"}>Total</Text></td>
                        {months.map(month => {
                            const pago = pagos.find(item => item.mes == month.value)
                            
                            let value = pago["cantidad"] ? parseFloat(pago["cantidad"]) : 0
                            if(pago["otros_cargos"])
                                value += parseFloat(pago["otros_cargos"])
                                
                            pago.total = value

                            return <TableColumn key={uuid()} defaultValue={value ? Statics.changeDotAndComma(value) : ""} type={"number"} disabled={true}/>
                        })} 
                    </tr>

                    <tr>
                        <td colSpan={13} className='no-border' style={{ paddingTop: 10, paddingBottom: 10 }}/>
                    </tr>
    
                    <TableRow pagos={pagos} name="Fecha de pago" arrayItemName={"fecha"} type={"date"}/> 
                </tbody>
    
            </table>
        ); 
    }
}; 