import { Grid, Stack } from "@mui/material"
import { Line } from "components/data_display"
import { DatePicker, Input, Checkbox } from "components/forms/components"
import { ItemsSelect } from "components/forms/components/Select/custom"
import { AlumnoPicker } from 'components/forms/components/custom'
import { H3 } from "components/texts"
import React, { useState, useEffect } from "react"

/**
 * Formulario con los datos de las inscripciones de los grupos-academia
 */
export default function DataFormInscripcionAcademias({ onChangeActividad, onChangeCursoEscolar, inscripcion, register, errors, setValue, clearErrors }) {
    const [actividad, setActividad] = useState()

    useEffect(() => {
        setValue("precio", actividad?.precio)
    }, [actividad?.precio]);

    return (
        <Grid container>
            <Grid container px={5}>
                <Grid item xs={6} py={3} pr={2} >
                    <AlumnoPicker
                        label={"Alumno"}
                        editLabel={"Cambiar alumno"}
                        addLabel={"Añadir alumno"}
                        deleteLabel={"Borrar alumno"}
                        relations={"academia"}
                        register={register}
                        errors={errors}
                        alumno={inscripcion?.alumno ?? null}
                        setValue={setValue}
                        name={'alumno_id'}
                        clearErrors={clearErrors}
                        // Si está editando ya no se puede cambiar el alumno
                        canEdit={inscripcion ? false : true} />
                </Grid>

                <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9" }} py={3} pl={3}>
                    <Stack spacing={2}>
                        <ItemsSelect name={"curso_escolar_id"} onChange={(_, cursoEscolar) => {
                            onChangeCursoEscolar(cursoEscolar)
                        }} keyValue={"curso_escolar"} register={register} errors={errors} defaultValue={inscripcion?.curso_escolar_id ?? ""} />
                        <ItemsSelect name={"forma_recogida_id"} keyValue={"forma_recogida"} register={register} errors={errors} defaultValue={inscripcion?.forma_recogida_id ?? ""} />
                        <ItemsSelect name={"forma_conocimiento_id"} keyValue={"forma_conocimiento"} register={register} errors={errors} defaultValue={inscripcion?.forma_conocimiento_id ?? ""} />
                    </Stack>

                    <Grid item mt={1} xs={6}>
                        <Stack>
                            <Input label="Edad" width={"100%"} name={"edad"} register={register} errors={errors} uppercase />
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container>
                <Line />
            </Grid>

            <Grid container px={5} mt={2} spacing={2} >
                <Grid item xs={12}>
                    <H3>Preferencia de Horario</H3>
                </Grid>

                <Grid item xs={2}>
                    <Stack>
                        <Checkbox name={"alumno_colectivo"} label={"Alumno colectivo"}
                            register={register} defaultChecked={inscripcion?.encuestado ? true : false}
                        />
                    </Stack>
                </Grid>

                <Grid item xs={3} >
                    <ItemsSelect name={"empresa_colectivo"} keyValue={"empresa"} register={register} errors={errors} defaultValue={inscripcion?.empresa_id ?? ""} />
                </Grid>

                <Grid item xs={3} >
                    <ItemsSelect
                        name={"registro_actividad_id"}
                        searcheable
                        keyValue={"registro_actividad_academia"}
                        register={register}
                        errors={errors}
                        onChange={(_, actividad) => {
                            setActividad(actividad)
                            onChangeActividad(actividad)
                        }}
                        params={{ relations: "modalidad_cursos" }}
                        defaultValue={inscripcion?.registro_actividad_id ?? ""} />

                </Grid>
                <Grid item xs={3} md={4}>
                    <ItemsSelect name={"preferencia_horario_id"} keyValue={"preferencia_horario"} register={register} errors={errors} defaultValue={inscripcion?.id ?? ""} />
                </Grid>
            </Grid>

            <Grid container px={5} mt={2} spacing={2} >
                <Grid item xs={7}>
                    <Input placeholder={"Comentarios Horario"} register={register} errors={errors} className="w-100" name={"comentarios_horario"} defaultValue={inscripcion?.observaciones ?? null} multiline minRows={6} maxRows={10} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Stack>
                        <Checkbox name={"alumno_colectivo"} label={"Alumno de Colectivo"} register={register} defaultChecked={inscripcion?.alumno_colectivo ? true : false} />
                        <Checkbox name={"avisado_comienzo_clases"} label={"Avisado del comienzo de las clases"} register={register} defaultChecked={inscripcion?.avisado_inicio_clases ? true : false} />
                    </Stack>
                </Grid>
            </Grid>

            <Grid container mt={4}>
                <Line />
            </Grid>

            <Grid container mt={4} spacing={1} px={5} justifyContent={'space-between'} >

                <Grid item xs={12}>
                    <H3>Reserva de plaza</H3>
                </Grid>

                <Grid item xs={2}>
                    <Input label="Test de Nivel" width={"100%"} name={"test_nivel"} register={register} errors={errors} uppercase />
                </Grid>


                <Grid item xs={2}>
                    <Stack>
                        <Checkbox name={"hermanos"} label={"Hermanos"} register={register} defaultChecked={inscripcion?.hermanos ? true : false} />
                    </Stack>
                </Grid>

                <Grid item xs={2}>
                    <Stack>
                        <Checkbox name={"antiguo_alumno"} label={"Antiguo Alumno"} register={register} defaultChecked={inscripcion?.encuestado ? true : false} />
                    </Stack>
                </Grid>

                <Grid item xs={2} >
                    <Input label="Nº de reserva" width={"100%"} name={"n_reserva"} register={register} errors={errors} uppercase />
                </Grid>

            </Grid>

            <Grid container px={5} mt={2} spacing={2}>

                <Grid item xs={2.5} >
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_inscripcion"} label={"Fecha de inscripción"} defaultValue={inscripcion?.fecha_alta} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2.5} >
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_incorporacion"} label={"Fecha de incorporación"} defaultValue={inscripcion?.fecha_incorporacion ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2.5} >
                    <DatePicker register={register} errors={errors} uppercase className="w-100" name={"fecha_baja"} label={"Fecha baja"} defaultValue={inscripcion?.fecha_baja ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={4.5} >
                    <ItemsSelect
                        name={"motivo_baja_id"}
                        keyValue={"motivo_baja"}
                        register={register}
                        errors={errors}
                        defaultValue={inscripcion?.motivo_baja_id ?? ""} />
                </Grid>
            </Grid>
        </Grid>
    )
}