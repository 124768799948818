import React, { Component } from 'react'
import { Modal } from '../../../../../../../../components/modals'
import DataFormEvent from '../form'
import Requests from '../../Requests'

/**
 * Modal para editar eventos
 */
export default class EditEventModal extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            evento: null
        }

        this.requests = new Requests()
    }


    /**
     * Abre el modal y actualiza la evento
     * 
     * @param {String} event
     */
    open = (evento) => {
        this.setState({evento}, () => {
            this.modal.open()
        })
    }


    /**
     * Cierra el modal
     */
    close = () => {
        this.setState({evento: null}, () => {
            this.modal.close()
        })
    }

    
    /**
     * Callback para cuando se vaya a crear un evento
     * 
     * @param {Array} values
     */
    onEdit = (values) => {
        this.requests.editEvento(this.state.evento.id, values, (res) => {
            this.close()
            this.props.showSnackBar("Se ha editado el evento correctamente.")

            if(this.props.onEditEvento)
                this.props.onEditEvento(res.evento)
        }, (res) => {
            this.props.showSnackBar("No se ha podido editar el evento: " + res, "error")
        })
    }


    /**
     * Render
     * 
     * TODO: HACER LA PETICION PARA EDITARLO Y AÑADIR EL BOTÓN DE COMPARTIR
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Modal ref={(ref) => this.modal = ref} title={"Editar evento del día: " + (this.state.evento ? this.state.evento.date.split('-').reverse().join('/') : "")} style={{minWidth: "60%"}}>
                <DataFormEvent onClick={this.onEdit} btnText={"Editar evento"} evento={this.state.evento}/>
            </Modal>
        )
    }
}
