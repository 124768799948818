import React from 'react'
import { Grid, Stack } from '@mui/material'
import { Text } from 'components/texts'

export default function LibroData({ libro }) {

  return (
    <React.Fragment>
        <Grid container mt={5}>
            <Grid item xs={4}>
                <Stack>
                    <Text fontWeight={"bold"}>Editorial:</Text>
                    <Text>{libro?.editorial?.nombre ?? ""}</Text>
                </Stack>
            </Grid>

            <Grid item xs={4}>
                <Stack>
                    <Text fontWeight={"bold"}>Abreviatura:</Text>
                    <Text>{libro?.abreviatura ?? ""}</Text>
                </Stack>
            </Grid>

            <Grid item xs={4}>
                <Stack>
                    <Text fontWeight={"bold"}>Libro asociado:</Text>
                    <Text>{libro?.libro_asociado?.nombre ?? ""}</Text>
                </Stack>
            </Grid>
        </Grid>

        <Grid container mt={5}>
            <Grid item xs={4}>
                <Stack>
                    <Text fontWeight={"bold"}>ISBN:</Text>
                    <Text>{libro?.isbn ?? ""}</Text>
                </Stack>
            </Grid>

            <Grid item xs={4}>
                <Stack>
                    <Text fontWeight={"bold"}>Precio editorial:</Text>
                    <Text>{libro?.precio_editorial ?? ""}</Text>
                </Stack>
            </Grid>

            <Grid item xs={4}>
                <Stack>
                    <Text fontWeight={"bold"}>PVP:</Text>
                    <Text>{libro?.pvp ?? ""}</Text>
                </Stack>
            </Grid>
        </Grid>
    </React.Fragment>
    
  )
}
