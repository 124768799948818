import { DataFormInscripcionAcademias, DataFormGrupoAcademias, PagosAcademiaDataForm, LibroAcademiaDataForm } from './parts'
import React, { useState, useEffect, useContext } from 'react'
import { Box, Container, Paper, Tab, Tabs, Stack, Grid } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { BreadcrumbsList, MoveButtons } from 'components/data_display'
import { COLOR } from 'theme/Colors.js'
import { Button } from 'components/forms/components';
import { Text } from 'components/texts/index.js'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { Edit as EditIcon } from '@mui/icons-material'
import { TabContext } from '@mui/lab'
import Requests from './Request/Request'
import { enqueueSnackbar } from 'notistack'

import { EmpleadoListContext } from 'contexts/employee/ListContext'

export default function SingleInscripcion() {
    const navigationParams = useParams()
    const navigate = useNavigate()
    const [tab, setTab] = useState("1")

    const { items } = useContext(EmpleadoListContext)

    const [id_inscripcion, setIdInscripcion] = useState(navigationParams.id_inscripcion)
    const [inscripcion, setInscripcion] = useState(null)

    const updateId = (id) => {
        navigate(`../academias/inscripciones/${id}`);
        setIdInscripcion(id);
    }

    useEffect(() => {
        const inscripcion = items.find(item => item?.id == id_inscripcion)
        if (items.length == 0 || !inscripcion) {
            new Requests().getInscripcion(id_inscripcion, setInscripcion, () => enqueueSnackbar(<Text>No se ha podido obtener la inscripción.</Text>, { variant: "error" }))
        } else {
            setInscripcion(inscripcion)
        }
    }, [id_inscripcion])

    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <Stack direction="row" justifyContent={"space-between"} marginX={8}>
                <BreadcrumbsList mx={0} breadcrumbsList={[
                    { to: "/", label: "Inicio" },
                    { to: "./../", label: "Inscripciones" },
                    { to: "./", label: "Inscripción #" + (id_inscripcion ?? "0000"), actualItem: true },
                ]} />

                <MoveButtons
                    actualItemId={id_inscripcion}
                    beforeFetchCallback={() => setInscripcion(null)}
                    onUpdateActualItem={updateId} />
            </Stack>

            <Paper elevation={2} sx={{ m: 2, mx: 8, my: 2, pb: 5 }}>
                <Grid container display={"flex"} justifyContent={"end"} alignItems={"start"}>
                    <Grid item xs={2} sx={{ marginTop: 3 }}>
                        <Button variant={"outlined"} startIcon={<EditIcon />} onClick={() => { navigate('./editar') }}>
                            Editar
                        </Button>
                    </Grid>
                </Grid>

                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                        <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                            <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS DE LA INSCRIPCIÓN</Text>} value={"1"} />
                            <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>DATOS DEL GRUPO</Text>} value={"2"} />
                            <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>DATOS DE LOS PAGOS</Text>} value={"3"} />
                            <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>LIBROS</Text>} value={"4"} />
                        </Tabs>
                    </Box>

                    <TabPanel value={"1"}>
                        <DataFormInscripcionAcademias inscripcion={inscripcion} />
                    </TabPanel>

                    <TabPanel value={"2"}>
                        <DataFormGrupoAcademias inscripcion={inscripcion} />
                    </TabPanel>

                    <TabPanel value={"3"}>
                        <PagosAcademiaDataForm inscripcion={inscripcion} />
                    </TabPanel>

                    <TabPanel value={"4"}>
                        <LibroAcademiaDataForm inscripcion={inscripcion} />
                    </TabPanel>

                </TabContext>
            </Paper>
        </Container>
    )
}
