import moment from 'moment'
import styles from './styles.js'
import { Grid } from '@mui/material'
import React, { useState } from 'react'
import { SnackbarProvider } from 'notistack'
import { Line } from 'components/data_display'
import { LibrosPedidos, LibrosDevueltos, Almacen } from './parts'
import { ItemsSelect } from 'components/forms/components/Select/custom'

const cursoActual = moment().subtract(1, 'year').format('YYYY') + "-" + moment().format('YYYY')

/**
 * Formulario con los datos de un libro
 */
export default function DataFormLibroControlAlmacen({ libro }) {

  const [cursoEscolar,    setCursoEscolar]    = useState(null)
  const [librosDevueltos, setLibrosDevueltos] = useState(libro?.devoluciones_libro ?? [])
  const [librosPedidos,   setLibrosPedidos]   = useState(libro?.pedidos_libro      ?? [])

  /**
   * Render
   *
   * @returns {Component}
   */
  return (
      <Grid container px={3}>

          <Grid container mb={2} mt={4}>
            <ItemsSelect 
              name                 = {"id_curso_escolar"} 
              keyValue             = {"curso_escolar"}
              className            = {"w-50"}
              initialOptionByLabel = {cursoActual}
              onChange             = {({target}) => setCursoEscolar(target.value)} />
          </Grid>

          <Line className={"mt-5"}/>

          <Grid container my={3}>
            <Grid item xs={12} xl={6}>
              <LibrosPedidos cursoEscolar={cursoEscolar} librosPedidos={librosPedidos} onUpdate={setLibrosPedidos}/>
            </Grid>

            <Grid item xs={12} xl={6} sx={styles.librosDevueltosGrid}>
              <LibrosDevueltos cursoEscolar={cursoEscolar} librosDevueltos={librosDevueltos} onUpdate={setLibrosDevueltos}/>
            </Grid>
          </Grid>

          <Line />

          <Grid container my={3}>
            <Almacen cursoEscolar={cursoEscolar} librosDevueltos={librosDevueltos} librosPedidos={librosPedidos} libro={libro}/>
          </Grid>

          <SnackbarProvider autoHideDuration={5000} />
    </Grid>
  )
}