
export const formatearDefinicionesColegios = (objeto) => {
    const definicionesTotales = []

    objeto.forEach(item => {
        const clave = item.clave ?? ""
        const actividad = item.actividad?.nombre ?? ""
        const definiciones = item.definiciones

        definiciones.forEach(definicion => {
            definicion.clave = clave
            definicion.actividad = actividad
            definicionesTotales.push(definicion)
        })
    })

    return definicionesTotales
}

export const formatearDefiniciones = (objeto) => {
    const definicionesTotales = []

    objeto.forEach(item => {
        const lugar_clase = item.horario_formatted.split("[")[1].split("]")[0]
        const definiciones = item.definiciones

        definiciones.forEach(definicion => {
            definicion.lugar_clase = lugar_clase
            definicionesTotales.push(definicion)
        })
    })

    return definicionesTotales
}