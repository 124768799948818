import * as Yup from 'yup'

const Regex = "^[A-Za-z0-9]+$"
const validationSchema = Yup.object().shape({
    apellido1:           Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    apellido2:           Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    nombre:              Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    nombre_colegio:      Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    nombre_curso:        Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    clave_grupo_colegio: Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    clave_grupo_cl:      Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
})

export default validationSchema