

import './style.css'
import React from 'react'
import { v4 as uuid } from 'uuid'
import Box from '@mui/material/Box'
import TableColumn from '../TableColumn'
import { H3, Text } from 'components/texts'
import Statics from 'objects/common/Statics'

export default function LibrosDevueltos({ librosDevueltos, cursoEscolar }) {
    const librosToRender = cursoEscolar ? librosDevueltos.filter(item => item.id_curso_escolar == cursoEscolar && item.deleted != true) ?? [] : []
    const sumDevueltas   = Statics.sumParamsOfObjectArray(librosToRender, "cantidad_devuelta")

    return (
        <Box px={2}>
            <H3>Libros devueltos: </H3>
            <table className='libros-devueltos-table'>
                <thead>
                    <tr>
                        <th><Text fontWeight={"bold"}>Fecha</Text></th>
                        <th><Text fontWeight={"bold"}>Unidades devueltas</Text></th>
                    </tr>
                </thead>
                <tbody className='no-border'>
                    {librosToRender.map((item, index, array) => (
                        <tr key={uuid()}>
                            <TableColumn defaultValue={item.fecha} type={"date"} disabled/>

                            <TableColumn defaultValue={item.cantidad_devuelta} disabled/>
                        </tr>
                    ))}

                    <tr className="total-unidades">
                        <td className='bg-white'><Text fontWeight="bold">Total devueltas</Text></td>
                        <td><Text>{sumDevueltas}</Text></td>
                    </tr>
                </tbody>
            </table>

        </Box>
    )
}