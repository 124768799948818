import React, { useEffect, useState, useRef } from 'react'
import { TextField } from '@mui/material'
import { DatePicker as MuiDatePicker  } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/es'

const CustomDatePicker = ({ name = 'fecha', label = 'Fecha', defaultValue, clearable, disabled, size = 'small', className = '', register, errors, setValue, wantToReset }) => {
  const [selectedDate, setSelectedDate] = useState(defaultValue !== undefined ? defaultValue : dayjs())
  const [isMounted,    setIsMounted]    = useState(false)

  const previousWantToResetRef = useRef(null)

  useEffect(() => {
    if (wantToReset && !previousWantToResetRef.current) {
      setSelectedDate(null)
    }
    previousWantToResetRef.current = wantToReset
  }, [wantToReset])

  useEffect(() => {
    setIsMounted(true)
	  setValue(name, defaultValue)
  }, [])

  const componentsProps = clearable ? { actionBar: { lang: 'es', actions: ['clear'] } } : {}
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <MuiDatePicker
        label={label}
        value={selectedDate}
        onChange={setSelectedDate}
        componentsProps={componentsProps}
        disabled={disabled}
        
        inputFormat="DD/MM/YYYY"
        className={className}
        renderInput={(props) => {
          if(isMounted)
            setValue(name, props.inputProps.value && props.inputProps.value != "" ? dayjs(props.inputProps.value, 'DD/MM/YYYY').format('YYYY-MM-DD') : "")

          return (
            <TextField
              {...props}
              name={name}
              size={size}
              error={errors && errors[name] ? true : false}
              helperText={errors && errors[name]?.message}
              {...(register ? register(name) : {})}/>
          )
        }}
      />
    </LocalizationProvider>
  )
}

export default CustomDatePicker
