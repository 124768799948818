import { createTheme } from "@mui/material/styles";
import { COLOR } from "./Colors.js";

export const THEME = createTheme({
  palette: {
    standard: {
      main: COLOR.standard.main,
      dark: COLOR.standard.dark,
      contrastText: COLOR.white.main
    },

    cancel: {
      main: COLOR.cancel.main,
      contrastText: COLOR.white.main
    },

    error: {
      main: COLOR.error.main,
      contrastText: COLOR.white.main
    },

    success: {
      main: COLOR.success.main,
      contrastText: COLOR.white.main
    },

    grey: {
      main: COLOR.grey.main,
      light: COLOR.grey.light,
      dark: COLOR.grey.dark,
      contrastText: COLOR.white.main
    },

    white: {
      main: COLOR.white.main,
    },

    yellow: {
      main: COLOR.yellow.main,
      contrastText: COLOR.white.main
    },

    infoBox: {
      main: COLOR.infoBox.main,
    }
  },

  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#F7F9FA',
          color: '#404A56',
          border: '1px solid #D9D9D9'
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: COLOR.standard.main,
            color: COLOR.white.main
          },
          '&.Mui-selected:hover': {
            backgroundColor: COLOR.standard.main,
            color: COLOR.white.main
          },
          '&.Mui-disabled': {
            opacity: 0.70,
          }
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        row: {
          "&.Mui-selected": {
            backgroundColor: "rgba(56, 70, 255, 0.4)",
            color: "black",
            "&:hover": {
              backgroundColor: "rgba(56, 70, 255, 0.4)",
            }
          }
        }
      }
    }
  },
})