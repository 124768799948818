import React, { useState } from 'react'
import { Grid, FormControlLabel, RadioGroup, Radio, FormGroup, Box } from '@mui/material'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { Checkbox } from "components/forms/components"


export default function ProfesoradoTemplate({ register, errors, reset }) {

    const [opcionSeleccionada, setOpcionSeleccionada] = useState('adulto');
    const fieldsetStyles = {
        position: 'relative',
        border: '1px solid #7F8790',
        borderRadius: '4px',
        padding: '30px',
    };

    const legendStyles = {
        position: 'absolute',
        top: '-18px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        width: 'auto',
    };
    const destinatario = "profesorado"

    return (
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={4}>

            <Grid item xs={3}>
                <ItemsSelect name={`${destinatario}.id_curso_escolar`} keyValue={"curso_escolar"} register={register} errors={errors} wantToReset={reset} />
            </Grid>

            <Grid item xs={3}>
                <ItemsSelect name={`${destinatario}.id_tipo_actividadr`} keyValue={"tipo_actividad"} register={register} errors={errors} />
            </Grid>

            <Grid item xs={3}>
                <ItemsSelect name={`${destinatario}.id_actividades`} keyValue={"actividad"} register={register} errors={errors} wantToReset={reset} searcheable />
            </Grid>

            <Grid item xs={3}>
                <ItemsSelect name={`${destinatario}.id_modalidad_cursos`} keyValue={"modalidad_cursos"} register={register} errors={errors} wantToReset={reset} searcheable />
            </Grid>

            <Grid item xs={5} mt={4}>
                <Box component="fieldset" display="flex" sx={fieldsetStyles} flexDirection={"row"}>
                    <FormGroup>
                        <legend style={legendStyles} >
                            <FormControlLabel sx={{ marginLeft: 1 }}
                                control={<Checkbox name={"academia"} label={"Academia"} register={register} defaultChecked={null} />} />
                        </legend>
                        <RadioGroup
                            onChange={({ target }) => setOpcionSeleccionada(target.value)}
                            value={opcionSeleccionada}
                            sx={{ flexWrap: 'initial' }}
                            row>
                            <FormControlLabel value="niño" control={<Radio />} label="Niños" />
                            <FormControlLabel value="adulto" control={<Radio />} label="Adultos" />
                        </RadioGroup>
                    </FormGroup>
                    <ItemsSelect
                        key={opcionSeleccionada}
                        register={register}
                        errors={errors}
                        name={`${destinatario}.nivel_grupo_id`}
                        keyValue={opcionSeleccionada === 'adulto' ? "nivel_grupo_adulto" : "nivel_grupo_niño"} />
                </Box>
            </Grid>

            <Grid item xs={5} mt={4}>
                <Box component="fieldset" display="flex" sx={fieldsetStyles} flexDirection={"row"}>
                    <FormGroup sx={{ mr: 2 }}>
                        <legend style={legendStyles} >
                            <FormControlLabel sx={{ marginLeft: 1 }}
                                control={<Checkbox name={"colegios"} label={"Colegios"} register={register} defaultChecked={null} />} />
                        </legend>
                        <ItemsSelect
                            label={"Colegio donde se imparte"}
                            name={`${destinatario}.id_colegio`}
                            keyValue={"colegio"}
                            register={register}
                            errors={errors}
                            searcheable />
                    </FormGroup>
                    <ItemsSelect
                        register={register}
                        errors={errors}
                        name={`${destinatario}.nivel_grupo_id`}
                        keyValue={"nivel_grupo"} />
                </Box>
            </Grid>

            <Grid item xs={2} mt={2}>
                <Box mb={1}>
                    <Checkbox name={"empresas"} label={"Empresas"} register={register} defaultChecked={null} />
                    <ItemsSelect
                        register={register}
                        errors={errors}
                        name={`${destinatario}.empresas`}
                        keyValue={"empresa"} />
                </Box>
                <Checkbox name={"particulares"} label={"Particulares"} register={register} defaultChecked={null} />
            </Grid>
        </Grid>
    )
}
