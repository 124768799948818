import { H3 } from 'components/texts'
import React, { useEffect, useState } from 'react'
import { Datagrid } from 'components/data_display'
import { Button } from 'components/forms/components'
import { Grid, Stack } from '@mui/material'
import { columns } from './columns'
import { SeguimientoTrimestralGrupoModal } from 'components/modals'

export default function AlumnosInfo({ grupo, tipoGrupo }) {

    const [inscripcion, setInscripcion] = useState(null)
    const [open,        setOpen]        = useState(false)

    useEffect(() => {
        if(open)
            setOpen(false)
    }, [open])

    return (
        <Grid container>
            <Grid item xs={12}>
                <Stack display="flex" flexDirection={"row"} justifyContent={"space-between"}>
                    <H3 className="m-0">Alumnos que integran el grupo: </H3>
                    <Button onClick={() => setOpen(true)} disabled={inscripcion && inscripcion.alumno ? false : true}>Seguimiento trimestral</Button>
                </Stack>
            </Grid>

            <Grid item xs={12} mt={2}>
                <Datagrid
                    rows               = {grupo?.inscripciones ?? []}
                    columns            = {columns}
                    pageSize           = {100}
                    rowsPerPageOptions = {[100]}
                    onRowClick         = {({row}) => setInscripcion(row)}/>
            </Grid>

            <SeguimientoTrimestralGrupoModal grupo={grupo} inscripcion={inscripcion} open={open} tipoGrupo={tipoGrupo}/>
        </Grid>
    )
}