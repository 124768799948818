import React, { useRef } from "react";
import { publicRoutes } from "../../../../router/routes";
import { COLOR } from "../../../../theme/Colors";
import { H3, Text } from "../../../texts";
import { LoadingButton, Input } from "../../components";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RequestAuth } from "../../../../objects/requests";
import { SnackbarAlert, Link } from "../../../data_display";
//VALIDACION FORMULARIO
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import validationSchema from "./validation"

/**
 * Formulario de recuperación de contraseña
 */
export default function RecoverPassForm() {

	const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) })

	const buttonRef = useRef(null);
	const snackBarAlertRef = useRef(null);

	/**
	 * Obtiene el email y hace la petición para recuperar la contraseña
	 */
	const recoverPass = () => {
		buttonRef.current.setLoading(true)

		var formData = new FormData()
		formData.append("numero_documento", getValues('numero_documento'))

		var requestAuth = new RequestAuth()
		requestAuth.recoverPass(formData, (res) => {
			snackBarAlertRef.current.updateState({ message: "Se ha enviado el correo para cambiar la contraseña.", severity: "success", open: true })
			reset()
			buttonRef.current.setLoading(false)
		}, (res) => {
			snackBarAlertRef.current.updateState({ message: res, severity: "error", open: true })

			buttonRef.current.setLoading(false)
		})
	}


	//RETURN
	return (
		<form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
			<H3>Recuperar contraseña</H3>
			<Text>Inserte su número de documento para recuperar la contraseña.</Text>

			<div className="d-flex flex-column my-2">
				<Input label="Número de documento" className="my-2" register={register} errors={errors} name={"numero_documento"} />


				<LoadingButton
					ref={buttonRef}
					className={"mt-5 align-self-start"}
					size="large"
					onClick={handleSubmit(recoverPass)}>

					Recuperar contraseña
				</LoadingButton>

				<Text fontWeight={"bold"} className="mt-2">Recibirás un correo con el enlace para cambiar la contraseña.</Text>

				<div className="mt-10 d-flex">
					<ArrowBackIcon htmlColor={COLOR.standard.main} className={"mx-2"} />

					<Link to={publicRoutes.HOME} size={"large"}>Volver al inicio</Link>
				</div>

				<SnackbarAlert ref={snackBarAlertRef} />
			</div>
		</form>
	);
}
