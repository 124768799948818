import { H3 } from 'components/texts';
import React, { useState, useEffect } from 'react'
import { Grid, TextField } from '@mui/material';
import { Input, Checkbox, DatePicker, Button } from 'components/forms/components';
import { useNavigate } from 'react-router-dom';

/**
 * Formulario con los datos del libro de la inscripcion a un Academia
 */
export default function LibroInscripcionAcademiaDataForm({ setValue, errors, inscripcion, register, actividad, grupo }) {

    const [solicitado, setSolicitado] = useState();
    const [solicitado_asociado, setSolicitado_asociado] = useState();

    //STATE
    const [libro, setLibro] = useState(grupo?.libro ?? undefined)
    const [libroAsociado, setLibroAsociado] = useState()
    const navigate = useNavigate()


    useEffect(() => {
        updateLibroSolicitado();
    }, []);


    const updateLibroSolicitado = () => {
        if (grupo?.libro_id) {
            // navigate(`../libro/${grupo?.libro?.id}`, { replace: true });
            window.open(`/empleado/libros/${grupo?.libro_id}`, '_blank', 'noopener,noreferrer')
        } else {
            console.error('ERROR, NO SE HA ENCONTRADO EL ID PARA EL LIBRO SELECCIONADO')
        }
    }

    const updateLibroAsociado = () => {
        if (grupo?.libro?.libro_asociado) {
            navigate(`libros/${grupo?.libro?.libro_asociado?.id}`, { replace: true });
        }
        setLibroAsociado(false)
    }

    return (
        <Grid container p={3}>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <H3>Libro del grupo: </H3>
                </Grid>

                <Grid item xs={8}>
                    <Input setValue={setValue} key={Math.random()} className="w-100" defaultValue={grupo?.libro?.nombre ?? '...'} uppercase disabled useValue />
                    <TextField type={"hidden"} value={grupo?.libro_id ?? ''} variant={"standard"} {...register ? register("libro_id") : {}} />
                </Grid>

                <Grid item xs={2}>
                    <Checkbox name={"libro_solicitado"} label={"Libro solicitado"} register={register} errors={errors}
                        defaultChecked={grupo?.libro?.usar_libro_asociado ? true : false}
                        onClick={(e) => { setSolicitado(e) }} />
                </Grid>

                <Grid item xs={2}>
                    <DatePicker disabled={!solicitado} register={register} errors={errors} className="w-100" name={"fecha_peticion"}
                        label={"Fecha solicitado"} defaultValue={undefined} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2}>
                    <Input key={Math.random()} register={register} disabled={!solicitado} errors={errors} label={"Precio solicitado"} name={"precio_solicitado"}
                        className="w-100" defaultValue={grupo?.pvp ?? ""} type={"number"} />
                    <TextField type={"hidden"} value={grupo?.libro?.libro_id ?? ''} variant={"standard"} {...register ? register("libro_id") : {}} />


                </Grid>

                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_pago"} disabled={!solicitado}
                        label={"Fecha de pago"} defaultValue={inscripcion?.fecha_pago ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_entrega"} disabled={!solicitado}
                        label={"Fecha de entrega"} defaultValue={inscripcion?.fecha_entrega ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2}>
                    <Input
                        ref={(ref) => inscripcion = ref}
                        register={register}
                        errors={errors}
                        label={"Unidades en el almacén"}
                        name={"unidades"}
                        className="w-100"
                        defaultValue={grupo?.libro?.unidades_disponibles ?? ""}
                        disabled
                        useValue />
                </Grid>
                <Grid item xs={2} onClick={updateLibroSolicitado} >
                    <Button>
                        Detalles
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={5}>
                <Grid item xs={12}>
                    <H3>Libro asociado: </H3>
                </Grid>

                <Grid item xs={8}>
                    <Input key={Math.random()} label={"Libro asociado"} defaultValue={grupo?.libro?.libro_asociado?.nombre ?? "..."} className="w-100" uppercase disabled />
                    <TextField
                        type={"hidden"}
                        defaultValue={grupo?.libro?.libro_asociado?.id ?? null}
                        variant={"standard"}
                        {...register ? register("libro_asociado_id") : {}} />
                </Grid>

                <Grid item xs={2}>
                    <Checkbox onClick={(e) => { setSolicitado_asociado(e) }} name={"libro_asociado_solicitado"} label={"Libro solicitado"} register={register} errors={errors}
                        defaultChecked={grupo?.libro?.libro_asociado} />
                </Grid>

                <Grid item xs={2}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_asociado_peticion"} label={"Fecha solicitado"}
                        defaultValue={undefined} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2}>
                    <Input key={Math.random()} register={register} disabled={!solicitado_asociado} errors={errors} label={"Precio asociado"} name={"precio_asociado"}
                        className="w-100" defaultValue={grupo?.libro?.libro_asociado?.pvp ?? ""} type={"number"} />
                    <TextField type={"hidden"} value={grupo?.libro?.id ?? ''} variant={"standard"} {...register ? register("libro_id") : {}} />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker disabled={!solicitado_asociado} register={register} errors={errors} className="w-100"
                        name={"fecha_pago_asociado"} label={"Fecha de pago"} defaultValue={inscripcion?.fecha_pago ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker disabled={!solicitado_asociado} register={register} errors={errors} className="w-100"
                        name={"fecha_entrega_asociado"} label={"Fecha de entrega"} defaultValue={inscripcion?.fecha_entrega ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2}>
                    <Input
                        register={register}
                        errors={errors}
                        label={"Unidades en el almacén"}
                        name={"unidades_asociado"}
                        className="w-100"
                        defaultValue={libroAsociado?.unidades_disponibles ?? ""}
                        uppercase
                        disabled
                        useValue />
                </Grid>
                <Grid item xs={2} onClick={() => updateLibroAsociado()}>
                    <Button disabled={!solicitado_asociado}>
                        Detalles
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

