import { TabContext } from '@mui/lab'
import { COLOR } from 'theme/Colors.js'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { useNavigate } from "react-router-dom"
import Statics from 'objects/common/Statics.js'
import { Text } from 'components/texts/index.js'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangesContext } from 'contexts/ChangesContext'
import { CreateHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { BreadcrumbsList, Line } from 'components/data_display'
import { Box, Container, Paper, Tab, Tabs } from '@mui/material'
import { TabPanel } from 'components/modifications/mui/TabPanel'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { validationSchema } from 'components/forms/display/colegio/grupos/DataForm/validation.js'
import { DataFormGeneralGrupoColegio, DataFormIdiomaGrupoColegio, DataFormSeguimientoTrimestralGrupoColegio, DataFormProgramacionesGrupoColegio } from 'components/forms/display/index.js'

export default function CreateGrupo() {
  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, control, setValue } = useForm({ resolver: yupResolver(validationSchema) })
  const navigate = useNavigate()

  const headerRef = useRef()

  const { updateChanges } = useContext(ChangesContext)

  const [isMounted, setIsMounted] = useState(false)
  const [tab,       setTab]       = useState("1")

  useEffect(() => setIsMounted(true), [])
  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])

  const promises = (id_grupo) => {
    const request = new Requests()

    const promiseCreateEmpleadoGrupo = new Promise((resolve, reject) => {
      request.createEmpleadoGrupo(id_grupo, getValues, resolve, reject)
    })

    const promiseCreateSeguimientoTrimestral = new Promise((resolve, reject) => {
      request.createSeguimientoTrimestralGrupo(id_grupo, getValues, resolve, reject)
    })

    return [promiseCreateEmpleadoGrupo, promiseCreateSeguimientoTrimestral]
  }

  const createGrupo = () => {
    headerRef.current.button.setLoading(true)
 
    new Requests().createGrupo(getValues, async (res) => {
      headerRef.current.button.setLoading(false)

      Promise.allSettled(promises(res.grupo.id))
          .then(async (values) => {
            const errors = values.filter(item => item.status == "rejected")
            for (const fail of errors)
              enqueueSnackbar(<Text>{fail.reason}</Text>, {variant: "error"})

            updateChanges(false)
            enqueueSnackbar(<Text>Se ha creado el grupo. Redirigiendo a la pagina de edición..</Text>, {variant: "success"})
            await Statics.delay(1500).then(() => {navigate('./../' + res.grupo.id)})
        })
    }, (res) => {
      headerRef.current.button.setLoading(false)
      enqueueSnackbar(<Text>{res}</Text>, {variant: "error"})
    })
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <BreadcrumbsList breadcrumbsList={[
          { to: "/",        label: "Inicio" },
          { to: "./../../", label: "Colegios" },
          { to: "./../",    label: "Grupos" },
          { to: "./",       label: "Crear grupo", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{mx: 8, my: 2}}>

          <CreateHeader
            ref          = {headerRef}
            title        = {"Crear grupo"}
            handleSubmit = {handleSubmit(createGrupo)} />

          <Line className={"my-5"} />

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
              <Tabs value={tab} onChange={(_, tab) => setTab(tab)} TabIndicatorProps={{style: {backgroundColor: COLOR.standard.main}}}>
                <Tab label={<Text fontWeight={tab == "1" ? "bold" : null} color={tab == "1" ? COLOR.standard.main : null}>DATOS GENERALES</Text>}         value={"1"}/>
                <Tab label={<Text fontWeight={tab == "2" ? "bold" : null} color={tab == "2" ? COLOR.standard.main : null}>IDIOMAS</Text>}                 value={"2"}/>
                <Tab label={<Text fontWeight={tab == "3" ? "bold" : null} color={tab == "3" ? COLOR.standard.main : null}>SEGUIMIENTO TRIMESTRAL</Text>}  value={"3"}/>
                <Tab label={<Text fontWeight={tab == "4" ? "bold" : null} color={tab == "4" ? COLOR.standard.main : null}>PROGRAMACIONES</Text>}          value={"4"}/>
              </Tabs>
            </Box>

            <TabPanel value={"1"}>
              <DataFormGeneralGrupoColegio isMounted={isMounted} control={control} register={register} setValue={setValue} errors={errors}/>
            </TabPanel>
            <TabPanel value={"2"}>
              <DataFormIdiomaGrupoColegio isMounted={isMounted} control={control} register={register} setValue={setValue} />
            </TabPanel>
            <TabPanel value={"3"}>
              <DataFormSeguimientoTrimestralGrupoColegio isMounted={isMounted} control={control} register={register} setValue={setValue}/>
            </TabPanel>
            <TabPanel value={"4"}>
              <DataFormProgramacionesGrupoColegio isMounted={isMounted} control={control} register={register} setValue={setValue}/>
            </TabPanel>
          </TabContext>

        </Paper>

        <SnackbarProvider autoHideDuration={3000}/>
      </form>
    </Container>
  )
}