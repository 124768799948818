import React, { Component, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { EmployeeRouter } from './employee'
import { NotLoggedRouter } from './not_logged'
import { StudentRoutes, StudentRouter } from './student'
import { publicRoutes, employeeRoutes, alumnoRoutes } from './routes.js'
import { ChangesProvider } from 'contexts/ChangesContext'
import { LoadingScreen } from '../components/data_display'

const NotLoggedRoutes = React.lazy(() => import("./not_logged/NotLoggedRoutes.jsx"));
const EmployeeRoutes  = React.lazy(() => import("./employee/EmployeeRoutes.jsx"));

export default class MainRouter extends Component {

  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    return (
        <Routes>
            {/* NOT LOGGED */}
            <Route path={publicRoutes.MAIN} element={<NotLoggedRouter><Suspense fallback={<LoadingScreen />}><NotLoggedRoutes /></Suspense></NotLoggedRouter>}/>

            {/* EMPLEADO */}
            <Route path={employeeRoutes.MAIN} element={<EmployeeRouter><Suspense fallback={<LoadingScreen />}><ChangesProvider><EmployeeRoutes /></ChangesProvider></Suspense></EmployeeRouter>} />

            {/* ALUMNO */}
            <Route path={alumnoRoutes.MAIN} element={<StudentRouter><StudentRoutes /></StudentRouter>} />
        </Routes>
    )
  }
}
