import { RequestAlumno } from 'objects/requests'
import React, { useState, useEffect } from 'react'
import { Container, Paper, Stack } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { AlumnoData, DomiciliacionData, ResponsableData } from './parts'
import { BreadcrumbsList, Line, MoveButtons} from 'components/data_display'

export default function SingleAlumnos() {
  const navigationParams = useParams()
  const navigate = useNavigate()

  const [id_alumno, setIdAlumno] = useState(navigationParams.id_alumno)
  const [alumno,    setAlumno]   = useState(null)

  /**
   * Hace la petición para obtener el usuario
   */
  const getAlumno = () => {
    const params = { relations: 'sexo,tipo_documento,provincia,tipo_via,provincia_nacimiento,responsables,domiciliacion_bancaria' }

    new RequestAlumno().get(id_alumno, params, (res) => {
      if (res.alumno)
        setAlumno(res.alumno)
    })
  }

  const updateIdAlumno = (id) => {
    navigate(`../alumnos/${id}`);
    setIdAlumno(id);
  }

  useEffect(getAlumno, [id_alumno])

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/",     label: "Inicio" },
            { to: "./../", label: "Alumnos" },
            { to: "./",    label: "Alumno #" + (alumno ? alumno.id : "0000"), actualItem: true },
          ]} />

          <MoveButtons 
            actualItemId        = {id_alumno}
            beforeFetchCallback = {() => setAlumno(null)} 
            onUpdateActualItem  = {updateIdAlumno} />
        </Stack>
        
        <Paper elevation={2} sx={{mx: 8, my: 2, p: 5}}>
          <AlumnoData alumno={alumno} />

          <Line className={"my-10"}/>

          <ResponsableData responsables={alumno && alumno.responsables ? alumno.responsables : null} alumno={alumno} reload={getAlumno} />
          
          <Line className={"my-10"}/>
          
          <DomiciliacionData domiciliacionBancaria={alumno && alumno.domiciliacion_bancaria ? alumno.domiciliacion_bancaria : null} />
        </Paper>
      </Container>
  )
}
