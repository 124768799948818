import React, { Component } from 'react'
import { ChooseTipoRelacionModal } from 'components/modals'
import { ResponsableListSelector } from 'components/custom_selectors'

/**
 * Modal con el formulario de añadir responsable
 */
export default class ChooseList extends Component {

    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <React.Fragment>
                <ResponsableListSelector onSelectedCallback={(responsable) => { this.chooseTipoRelacionModal.open(this.props.alumno, responsable) }}/>

                <ChooseTipoRelacionModal ref={(ref) => this.chooseTipoRelacionModal = ref} callbackOk={this.props.callbackOk} callbackError={this.props.callbackError}/>
            </React.Fragment>
        )
    }
}