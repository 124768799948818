import { COLOR } from 'theme/Colors'
import { TabContext } from '@mui/lab'
import { Text } from 'components/texts'
import Requests from './Requests/Requests'
import { useNavigate, useParams } from 'react-router'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import React, { useState, useEffect, useContext} from 'react'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { BreadcrumbsList, MoveButtons } from 'components/data_display'
import { Box, Container, Paper, Stack, Tab, Tabs } from '@mui/material'
import { TabPanel } from 'components/modifications/mui/TabPanel'
import { GrupoData, Header, IdiomaData, SeguimientoTrimestralData } from './parts/'
import { DataFormProgramacionesGrupoColegio } from 'components/forms/display'
//SKELETON
import SkeletonDataForm from 'components/forms/display/colegio/grupos/DataForm/GeneralDataForm/skeleton'
import SkeletonIdiomaForm from 'components/forms/display/colegio/grupos/DataForm/IdiomaDataForm/skeleton'
import SkeletonProgramacionesForm from 'components/forms/display/colegio/grupos/DataForm/ProgramacionesDataForm/skeleton'
import SkeletonSeguimientoTrimestralForm from 'components/forms/display/colegio/grupos/DataForm/SeguimientoTrimestralDataForm/skeleton'

const Wrapper = () => {
  const navigationParams = useParams()

  const { items } = useContext(EmpleadoListContext)

  const [grupo,    setGrupo]   = useState(null)
  const [id_grupo, setIdGrupo] = useState(navigationParams.id_grupo)
  const [tab,      setTab]     = useState("1")

  const updateAlert = (message, variant = "error") => {
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }

  const getGrupo = () => {
    const item = items.find(item => item?.id == id_grupo)
    if(items.length == 0 || !item) {
      new Requests().getGrupo(id_grupo, setGrupo, (res) => updateAlert("No se ha podido cargar el grupo: " + res))
    } else {
        setGrupo(item)
    }
  }

  useEffect(getGrupo, [id_grupo])

  return (
    <React.Fragment>
      <SingleGrupo key={grupo?.id ?? 0} tab={tab} setTab={setTab} grupo={grupo} id_grupo={id_grupo} setIdGrupo={setIdGrupo} setGrupo={setGrupo} />

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}
export default Wrapper

function SingleGrupo({ grupo, id_grupo, setIdGrupo, setGrupo, tab, setTab }) {
  const navigate = useNavigate()

  const updateId = (id) => {
    navigate(`../colegios/grupos/${id}`)
    setIdGrupo(id)
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <Stack direction="row" justifyContent={"space-between"} alignItems="center" marginX={8}>
        <BreadcrumbsList mx={0} breadcrumbsList={[
          { to: "/",        label: "Inicio" },
          { to: "./../../", label: "Colegios" },
          { to: "./../",    label: "Grupos" },
          { to: "./",       label: "Grupo #" + id_grupo, actualItem: true },
        ]} />

        <MoveButtons 
          actualItemId        = {id_grupo}
          beforeFetchCallback = {() => setGrupo(null)} 
          onUpdateActualItem  = {updateId} />
      </Stack>

      <Paper elevation={2} sx={{mx: 8, mt: 2, p: 2}}>
        <Header grupo={grupo} />

        <TabContext value={tab}>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
            <Tabs value={tab} onChange={(_, tab) => setTab(tab)} TabIndicatorProps={{style: {backgroundColor: COLOR.standard.main}}}>
              <Tab label={<Text fontWeight={tab == "1" ? "bold" : null} color={tab == "1" ? COLOR.standard.main : null}>DATOS GENERALES</Text>}        value={"1"}/>
              <Tab label={<Text fontWeight={tab == "2" ? "bold" : null} color={tab == "2" ? COLOR.standard.main : null}>IDIOMAS</Text>}                value={"2"}/>
              <Tab label={<Text fontWeight={tab == "3" ? "bold" : null} color={tab == "3" ? COLOR.standard.main : null}>SEGUIMIENTO TRIMESTRAL</Text>} value={"3"}/>
              <Tab label={<Text fontWeight={tab == "4" ? "bold" : null} color={tab == "4" ? COLOR.standard.main : null}>PROGRAMACIONES</Text>}         value={"4"}/>
            </Tabs>
          </Box>

          <TabPanel value={"1"}>
            {grupo ? <GrupoData grupo={grupo} navigate={navigate}/> : <SkeletonDataForm />}
          </TabPanel>
          <TabPanel value={"2"}>
            {grupo ? <IdiomaData grupo={grupo} /> : <SkeletonIdiomaForm/>}
          </TabPanel>
          <TabPanel value={"3"}>
            {grupo ? <SeguimientoTrimestralData grupo={grupo} /> : <SkeletonSeguimientoTrimestralForm/>}
          </TabPanel>
          <TabPanel value={"4"}>
            {grupo ? <DataFormProgramacionesGrupoColegio grupo={grupo} /> : <SkeletonProgramacionesForm/>}
          </TabPanel>

        </TabContext>
      </Paper>

    </Container>
  )
}