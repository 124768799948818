import React from 'react'
import { Grid, Skeleton } from '@mui/material'
import { Line } from 'components/data_display'
import TableMesesPagados from './parts/TableMesesPagados'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { Input, Checkbox } from 'components/forms/components'

export default function  DataFormPagoClasesParticulares({ claseParticular, setValue, getValues, isMounted, register, errors }) {
    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container py={3}>
            <Grid item xs={6} px={3}>
                {isMounted ?
                    <ItemsSelect name={"id_forma_pago"} keyValue={"forma_pago"} className={"w-100"} register={register} errors={errors} defaultValue={claseParticular?.forma_pago_id ?? ""} />
                    : <Skeleton variant="rounded" width={"100%"} height={40} />}
            </Grid>

            <Grid item xs={6} px={3}>
                <Input label={"Precio Mensual Alumno"} register={register} errors={errors} className="w-100" name={"precio_hora"} defaultValue={claseParticular?.precio_hora} type = {"number"} />
            </Grid>

            <Grid item xs={12} mt={3} px={3}>
                <Line />
            </Grid>

            <Grid container mx={3} mt={5} pb={2} sx={{ overflowX: "auto" }} className={"darker-scrollbar"}>
                <TableMesesPagados setValue={setValue} getValues={getValues} claseParticular={claseParticular} register={register} />
            </Grid>

            <Grid container px={3} mt={5}>
                <Input placeholder={"Observaciones de los pagos..."} register={register} errors={errors} className="w-100" name={"observaciones_pagos"} defaultValue={claseParticular?.observaciones_pagos ?? null} multiline minRows={6} maxRows={10} uppercase />
            </Grid>

            <Grid item xs={12} px={3} mt={5}>
                <Checkbox name={"contrato_emitido"} label={"Contrato emitido"} register={register} errors={errors} defaultChecked={claseParticular?.contrato_emitido == 1 ? true : false} />
            </Grid>

            <Grid item xs={12} px={3}>
                <Checkbox name={"contrato_recibido"} label={"Contrato recibido"} register={register} errors={errors} defaultChecked={claseParticular?.contrato_recibido == 1 ? true : false} />
            </Grid>
        </Grid>
    )
}