import { H3 } from 'components/texts'
import { Box, Container, Paper } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import React, { useState, useEffect, useContext } from 'react'
import { ListTableHeader } from 'pages/logged/admin/components'
import { columns as allColumns, basicColumns } from './columns.js'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { Accordion, BreadcrumbsList, Datagrid } from 'components/data_display'
import SearchFormInscripcionesColegio, { search } from 'components/forms/display/colegio/inscripciones/SearchForm'

export default function ListadoInscripcionesColegio() {
  const [data,         setData]         = useState({data: [], rowCount: 0})
  const [loadingTable, setLoadingTable] = useState(false)
  const [columns,      setColumns]      = useState(allColumns.alumnos)
  const [params,       setParams]       = useState(null)

  const { update, resetContextState } = useContext(EmpleadoListContext)

  useEffect(() => {
    setLoadingTable(false)
  }, [data])

  useEffect(resetContextState, [])

  const updateTable = (data, rowCount, page, params) => {
    setData({data, rowCount})
    update(rowCount, data, params, page)
    setParams(params)
  }

  const onChangeSearchIndex = (_, keyColumn) => {
    setColumns(columns[keyColumn] ?? basicColumns)
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { to: "/",     label: "Inicio" },
        { to: "./../", label: "Colegios" },
        { to: "./",    label: "Inscripciones", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar inscripciones</H3></Box>}>
        <SearchFormInscripcionesColegio
          searchCallback      = {updateTable}
          onChangeSearchIndex = {onChangeSearchIndex}
          onLoadingCallback   = {setLoadingTable} />
      </Accordion>

      <Paper elevation={2} sx={{mx: 8, my: 2, p: 5}}>
        <ListTableHeader rowCount={data.rowCount} params={params} keyValue={"inscripcion_colegio"}/>

        <Datagrid
          rows         = {data.data}
          columns      = {columns}
          loading      = {loadingTable}
          rowCount     = {data.rowCount}
          onPageChange = {(page) => { search(page, () => setLoadingTable(true), updateTable) }}
          serverSide />
      </Paper>
    </Container>
  )
}