import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import React, { Component } from 'react'
import { Input, DatePicker } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Esta es la plantilla para cuando se busque por los datos basicos del alumno
 */
export default class FichajeTemplate extends Component {
    
    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)
    }


    /**
     * Render
     *
     * @returns {Component}
     */
    render() {
        const { register, errors, reset, setValue } = this.props
        return (
            <Grid container>
                <Grid container mt={2} mb={1}>
                    <Text fontWeight={'bold'}>Según datos de:</Text><br />
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Input label="Nombre" width={"100%"} name={"nombre"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={4}>
                        <Input label="Primer apellido" width={"100%"} name={"apellido1"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={4}>
                        <Input label="Segundo apellido" width={"100%"} name={"apellido2"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect name={"id_tipo_documento"} keyValue={"tipo_documento"} register={register} errors={errors} wantToReset={reset} />
                    </Grid>

                    <Grid item xs={4}>
                        <Input label="Número de documento" width={"100%"} name={"numero_documento"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect name={"id_sexo"} keyValue={"sexo"} register={register} errors={errors} wantToReset={reset} />
                    </Grid>

                    <Grid item xs={4}>
                        <DatePicker register={register} errors={errors} className="w-100" name={"fecha_inicio"} label={"Fecha inicio"} defaultValue="" setValue={setValue} wantToReset={reset}/>
                    </Grid>

                    <Grid item xs={4}>
                        <DatePicker register={register} errors={errors} className="w-100" name={"fecha_fin"} label={"Fecha fin"} defaultValue="" setValue={setValue} wantToReset={reset}/>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
