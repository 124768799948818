import { RequestDetalleRegistroActividad } from 'objects/requests'

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Devuelve las actividades de un colegio a partir de un curso
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getActividadesByCurso(id_curso_escolar, id_colegio, callbackOk = () => {}, callbackError = () => {}) {
        const params = { id_curso_escolar, entity_id: id_colegio, relations: 'actividad,tipo_condicion'}
        
        new RequestDetalleRegistroActividad().search(params, (res) => {
            res.detalles ? callbackOk(res.detalles.data ?? res.detalles) : callbackError(res)
        }, callbackError)
    }


    /**
     * Devuelve las actividades de un colegio que aun no se han guardado
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getNewActividades(id_curso_escolar, id_colegio, callbackOk = () => {}, callbackError = () => {}) {
        const params = { id_curso_escolar, entity_id: id_colegio, relations: 'modalidad_cursos'}
        
        new RequestDetalleRegistroActividad().getNewActividades(params, (res) => {
            res.items ? callbackOk(res.items) : callbackError(res)
        }, callbackError)
    }

        
    /**
     * Devuelve las actividades de un colegio usadas el año anterior
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getActividadesCursoAnterior(id_curso_escolar, id_colegio, callbackOk = () => {}, callbackError = () => {}) {
        const params = { id_curso_escolar, entity_id: id_colegio, relations: 'modalidad_cursos'}
        
        new RequestDetalleRegistroActividad().getActividadesCursoAnterior(params, (res) => {
            res.items ? callbackOk(res.items) : callbackError(res)
        }, callbackError)
    }


    /**
     * Devuelve las actividades de un colegio usadas el año anterior
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    createOrUpdateMultiple(detalles, callbackOk = () => {}, callbackError = () => {}, callbackFinally = () => {}) {
        const formData = new FormData()

        for(const index in detalles) {
            formData.append(`detalles[${index}][id_curso_escolar]`,            detalles[index].id_curso_escolar)
            formData.append(`detalles[${index}][id_registro_actividad]`,       detalles[index].actividad.id)
            formData.append(`detalles[${index}][activa]`,                      detalles[index].activa)
            formData.append(`detalles[${index}][precio]`,                      detalles[index].precio ?? "")
            formData.append(`detalles[${index}][matricula]`,                   detalles[index].matricula ?? "")
            formData.append(`detalles[${index}][id_tipo_condicion_actividad]`, detalles[index].id_tipo_condicion_actividad ?? "")
            formData.append(`detalles[${index}][condiciones]`,                 detalles[index].condiciones ?? "")
        }

        const formDataArray = Array.from(formData.values());
        
        if(formDataArray.length > 0) {
            new RequestDetalleRegistroActividad().storeUpdateMultiple(formData, (res) => {
                res.items ? callbackOk(res.items) : callbackError(res)
            }, callbackError, callbackFinally)
        } else {
            callbackFinally()
        }
    }
        
    /**
     * Devuelve las actividades de un colegio usadas el año anterior
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    deleteMultiple(detalles, callbackOk = () => {}, callbackError = () => {}, callbackFinally = () => {}) {
        const formData = new FormData()

        for(const detalle of detalles)
            formData.append(`detalles[]`,  detalle.id)
        
        const formDataArray = Array.from(formData.values());
        
        if(formDataArray.length > 0) {
            new RequestDetalleRegistroActividad().deleteMultiple(formData, callbackOk, callbackError, callbackFinally)
        } else {
            callbackOk()
            callbackFinally()
        }
    }

    //--------------------------------------- SIN USAR, SE USAN EN EL ANTIGUO ---------------------------------------

    /**
     * Actualiza las condiciones de las actividades
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    updateCondicionActividades(getValues, actividades, callbackOk = () => {}, callbackError = () => {}) {
        const detallesActividad = actividades.map(item => (item.id))

        const formData = new FormData()
        formData.append('id_curso_escolar',             getValues('id_curso_escolar'))
        formData.append('id_tipo_condicion_actividad',  getValues('id_tipo_condicion'))
        formData.append('condiciones',                  getValues('condicion'))
        formData.append('detalles_ids',                 JSON.stringify(detallesActividad))

        new RequestDetalleRegistroActividad().updateMultiple(formData, callbackOk, callbackError)
    }


    /**
     * Hace las importaciones de las actividades que no estén importadas para este año
     * 
     * @param {Object}   params 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    importActividades(params, callbackOk = () => {}, callbackError = () => {}) {
        const formData = new FormData()
        formData.append('id_curso_escolar', params.id_curso_escolar)
        formData.append('id_colegio',       params.id_colegio)

        new RequestDetalleRegistroActividad().importActividades(formData, callbackOk, callbackError)
    }

    /**
     * Hace las importaciones de las actividades que no estén importadas para este año del año anterior
     * 
     * @param {Object}   params 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    importActividadesCursoAnterior(params, callbackOk = () => {}, callbackError = () => {}) {
        const formData = new FormData()
        formData.append('id_curso_escolar', params.id_curso_escolar)
        formData.append('id_colegio',       params.id_colegio)

        new RequestDetalleRegistroActividad().importActividadesCursoAnterior(formData, callbackOk, callbackError)
    }


    /**
     * Hace el update de un detalle
     * 
     * @param {Int}      id 
     * @param {Object}   params 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    updateDetalleActividades(id, formData, callbackOk = () => {}, callbackError = () => {}) {
        new RequestDetalleRegistroActividad().edit(id, formData, callbackOk, callbackError)
    }
}
