import React, { Component } from 'react'
import { Button } from 'components/forms/components'


export default class TopMenuButton extends Component {
  render() {
    return (
      <Button
        variant="outlined"
        className={`py-3 mx-10 ${this.props.className ?? ""}`}
        onClick={this.props.onClick}>

        {this.props.children}
      </Button>
    )
  }
}
