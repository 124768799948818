import React from "react"
import { Grid, Stack } from "@mui/material"
import { Line } from "components/data_display"
import { Checkbox } from "components/forms/components"
import { H3, Text } from "components/texts"
import SkeletonInscripcionAcademiaData from "./SkeletonInscripcionAcademiaData"

/**
 * Formulario con los datos de las inscripciones academia
 */
export default function DataFormInscripcionAcademias({ inscripcion, register }) {
    return (
        <Grid container p={3}>
            {!inscripcion ?
                <SkeletonInscripcionAcademiaData />
                : (
                    <>
                        <Grid container px={5}>
                            <Grid item xs={6} py={3} pr={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Datos del Alumno:</Text>
                                    <Text>{inscripcion?.alumno?.nombre + ' ' + inscripcion?.alumno?.apellido1 + ' ' + inscripcion?.alumno?.apellido2 ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9" }} py={3} pl={3}>
                                <Stack spacing={2}>
                                    <Stack>
                                        <Text fontWeight={"bold"}>Curso Escolar:</Text>
                                        <Text>{inscripcion?.curso_escolar?.valor ?? ""}</Text>
                                    </Stack>

                                    <Stack>
                                        <Text fontWeight={"bold"}>Forma de recogida:</Text>
                                        <Text>{inscripcion?.forma_recogida?.nombre ?? ""}</Text>
                                    </Stack>

                                    <Stack>
                                        <Text fontWeight={"bold"}>Forma de conocimiento:</Text>
                                        <Text>{inscripcion?.forma_conocimiento?.nombre ?? ""}</Text>
                                    </Stack>
                                </Stack>

                                <Grid item mt={1} xs={4}>
                                    <Stack>
                                        <Text fontWeight={"bold"}>Edad:</Text>
                                        <Text>{inscripcion?.alumno?.edad ?? ""}</Text>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Line />
                        </Grid>

                        <Grid container px={5} mt={2} spacing={2} justifyContent={'space-between'}>
                            <Grid item xs={12}>
                                <H3>Preferencia de Horario</H3>
                            </Grid>

                            <Grid item xs={3} >
                                <Stack>
                                    <Text fontWeight={"bold"}>Empresa:</Text>
                                    <Text>{inscripcion?.empresa_colectivo ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={3} >
                                <Stack>
                                    <Text fontWeight={"bold"}>Actividad:</Text>
                                    <Text>{inscripcion?.actividad?.actividad?.valor ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={3} md={4}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Preferencia Horario:</Text>
                                    <Text>{inscripcion?.preferencia_horario?.valor ?? ""}</Text>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid container px={5} mt={2} spacing={2} justifyContent={'space-between'}>
                            <Grid item xs={4}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Comentarios Horarios:</Text>
                                    <Text>{inscripcion?.comentarios_horario ?? ""}</Text>

                                </Stack>
                            </Grid>

                            <Grid item xs={2}>
                                <Stack>
                                    <Checkbox disabled name={"alumno_colectivo"} label={"Alumno de Colectivo"} register={register} defaultChecked={inscripcion?.alumno_colectivo ? true : false} />
                                </Stack>
                            </Grid>

                            <Grid item xs={2}>
                                <Stack>
                                    <Checkbox disabled name={"avisado_inicio_clases"} label={"Avisado del comienzo de las clases"}
                                        register={register} defaultChecked={inscripcion?.avisado_inicio_clases ? true : false} />
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid container mt={4}>
                            <Line />
                        </Grid>

                        <Grid container mt={4} spacing={1} px={5} justifyContent={'space-between'}>

                            <Grid item xs={12}>
                                <H3>Reserva de plaza</H3>
                            </Grid>

                            <Grid item xs={4}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Test de Nivel:</Text>
                                    <Text>{inscripcion?.nivel ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2}>
                                <Stack>
                                    <Checkbox disabled name={"hermanos"} label={"Hermanos"} register={register} defaultChecked={inscripcion?.hermanos ? true : false} />
                                </Stack>
                            </Grid>

                            <Grid item xs={2}>
                                <Stack>
                                    <Checkbox disabled name={"antiguo_alumno"} label={"Antiguo Alumno"} register={register} defaultChecked={inscripcion?.encuestado ? true : false} />
                                </Stack>
                            </Grid>

                        </Grid>

                        <Grid container px={5} mt={2} spacing={2}>

                            <Grid item xs={2.5} >
                                <Stack>
                                    <Text fontWeight={"bold"}>Fecha de inscripción:</Text>
                                    <Text>{inscripcion?.fecha_inscripcion ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2.5} >
                                <Stack>
                                    <Text fontWeight={"bold"}>Fecha de inicio:</Text>
                                    <Text>{inscripcion?.fecha_incorporacion ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2.5} >
                                <Stack>
                                    <Text fontWeight={"bold"}>Fecha baja: </Text>
                                    <Text>{inscripcion?.fecha_baja ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={4.5} >
                                <Stack>
                                    <Text fontWeight={"bold"}>Motivo de la baja:</Text>
                                    <Text>{inscripcion?.motivo_baja?.nombre ?? ""}</Text>
                                </Stack>
                            </Grid>
                        </Grid>
                    </>
                )
            }
        </Grid>
    )
}