import { columns } from './columns.js'
import { H3 } from 'components/texts'
import { Box, Container, Paper } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import React, { useEffect, useState, useContext } from 'react'
import { ListTableHeader } from 'pages/logged/admin/components'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { Accordion, BreadcrumbsList, Datagrid } from 'components/data_display'
import SearchFormResponsable, { search } from 'components/forms/display/responsable/SearchForm'

/**
 * Listado de los responsables/tutores
 */
export default function ListadoResponsables() {
  const [data,         setData]         = useState({data: [], rowCount: 0})
  const [loadingTable, setLoadingTable] = useState(false)
  const [params,       setParams]       = useState(null)

  const { update, resetContextState } = useContext(EmpleadoListContext)

  useEffect(() => {
    setLoadingTable(false)
  }, [data])

  useEffect(resetContextState, [])

  /**
   * Callback para actualizar la tabla una vez hecha la petición
   * 
   * @param {Array} data 
   * @param {Int}   rowCount 
   */
  const updateTable = (data, rowCount, page, params) => {
    setData({data, rowCount})
    update(rowCount, data, params, page)
    setParams(params)
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
        <BreadcrumbsList breadcrumbsList={[
          { to: "/",  label: "Inicio" },
          { to: "./", label: "Tutores", actualItem: true },
        ]} />

        <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar tutores</H3></Box>}>
          <SearchFormResponsable
            searchCallback    = {updateTable}
            onLoadingCallback = {setLoadingTable} />
        </Accordion>

        <Paper elevation={2} sx={{mx: 8, my: 2, p: 5}}>
          <ListTableHeader rowCount={data.rowCount} params={params} keyValue={"tutores"}/>

          <Datagrid
            rows         = {data.data}
            columns      = {columns}
            loading      = {loadingTable}
            rowCount     = {data.rowCount}
            onPageChange = {(page) => { search(page, () => setLoadingTable(true), updateTable) }}
            serverSide />
        </Paper>
      </Container>
  )
}