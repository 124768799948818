import React, { Component } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'
import listWeek from '@fullcalendar/list'
import './style.css'


/**
 * Calendario.
 *
 * @param {String}      [locale="es"]                   Codigo de idioma para la tabla.
 * @param {String}      [initialView="dayGridMonth"]    Codigo de como se va a mostrar (por mes, semana, dia o agenda).
 *      @example Mes: "dayGridMonth", Semana: "timeGridWeek", Dia: "timeGridDay", Agenda: "listWeek"'
 *
 * @param {Boolean}     [weekends=true]                 Establece si se van a ver los findes de semana.
 * @param {Function}    [dateClick]                     Funcion que se ejecuta cuando clickas en una fecha
 * @param {Object}      [headerToolbar]                 Sirve para establecer que opciones quieres ver en el header
 *      @example { left: 'prev,next,today', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek' }
 *
 * @param {Array}       [events]                        Un array que contiene objetos con la descripcion de los eventos.
 *       @example [{ title: 'Evento 1', date: '2021-07-21 10:00:00' }, { title: 'Evento 2', date: '2021-07-22' }]
 */
export default class Calendar extends Component {

    constructor(props) {
        super(props)
    }

    /* =====================================================================================================================
    ================================================== RENDER FUNCTIONS ====================================================
    ===================================================================================================================== */

    /**
     * Render
     */
    render() {
        const defaultHeaderToolBar = this.props.headerToolbar ?? { left: 'prev,next today', center: 'title', right: 'dayGridMonth,dayGridWeek,dayGridDay' }
        return (
            <FullCalendar
                ref             = {(ref) => this.calendar = ref}
                locales         = {allLocales}
                locale          = {this.props.locale ?? 'es'}
                plugins         = {[dayGridPlugin, interactionPlugin]}
                eventDisplay    = {"list-item"}
                navLinks        = {true}
                events          = {this.props.events ?? []}
                customButtons   = {this.props.customButtons ??
                    {
                        prev: {
                            click: () => {
                                const api = this.calendar.getApi()
                                if(this.props.onPrevClick)
                                    this.props.onPrevClick(api.getDate())

                                api.prev()
                            }
                        },
                        next: {
                            click: () => {
                                const api = this.calendar.getApi()
                                if(this.props.onNextClick)
                                    this.props.onNextClick(api.getDate())

                                api.next()
                            }
                        }
                    }
                }
                eventTimeFormat = {{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: false
                  }
                }
                headerToolbar = {defaultHeaderToolBar}
                {...this.props} />
        )
    }
}
