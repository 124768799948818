import React, { Component } from 'react'
import { SnackbarAlert } from 'components/data_display'
import { Text } from 'components/texts'
import { Button } from 'components/forms/components/index.js'
import { RequestAlumnoResponsable } from 'objects/requests/index.js'
import { Box } from '@mui/material'
import { Modal } from 'components/modals'

/**
 * Modal para confirmar el borrado de la relación de un alumno con un responsable
 */
export default class DeleteRelacionModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            responsable: null,
            alumno: null,
        }
    }  


    /**
     * Abre el modal actualizando el estado
     * 
     * @param {Object} responsable
     * @param {Object} alumno
     */
    open = (responsable, alumno) => {
        this.setState({responsable, alumno}, () => {
            this.modal.open()
        })
    }


    /**
     * Hace la petición para el borrado de la relacion
     */
    erase = () => {
        let pivot_id = this.state.responsable.pivot ? this.state.responsable.pivot.id : this.state.alumno.pivot.id
        new RequestAlumnoResponsable().erase(pivot_id, new FormData(), () => {
            this.snackBarAlertRef.updateState({ message: "Se ha borrado la relación correctamente.", severity: "success", open: true })
            this.modal.close()
            if(this.props.onDeleteCallback)
                this.props.onDeleteCallback()

        }, (res) => {
            this.snackBarAlertRef.updateState({ message: "Error al borrar la relación: " + res, severity: "error", open: true })
        })
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <React.Fragment>
                <Modal ref={(ref) => this.modal = ref} title={"Eliminar relación"}>
                    <Box py={10} px={5}>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Text>¿Estás seguro de que quieres borrar la relación entre el/la alumno/a: </Text>
                            <Text fontWeight={"bold"} className={"mx-2"}> {this.state.alumno ? (this.state.alumno.nombre ?? "") + " " + (this.state.alumno.apellido1 ?? "") + " " + (this.state.alumno.apellido2 ?? "") : ""}</Text>
                            <Text className="me-2"> y el/la tutor/a:</Text>
                            <Text fontWeight={"bold"}> {this.state.responsable ? (this.state.responsable.nombre ?? "") + " " + (this.state.responsable.apellido1 ?? "") + " " + (this.state.responsable.apellido2 ?? "") : ""}</Text>
                            <Text>?</Text>
                        </Box>
                        
                        <Box display={"flex"} justifyContent={"center"} mt={2}>
                            <Button onClick={this.erase} color={"error"}>
                                Eliminar relación
                            </Button>
                        </Box>
                    </Box>
                </Modal>
                <SnackbarAlert ref={(ref) => this.snackBarAlertRef = ref} />
            </React.Fragment>
        )
    }
}
