import { H3 } from 'components/texts'
import React, { Component, useEffect, useRef, useState } from 'react'
import { Grid, TextField } from '@mui/material'
import { Input, Checkbox, DatePicker, Button } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

export default function DataFormLibroClasesParticulares({ claseParticular, setValue, register, errors }) {
    //CURRENT DATE
    const currentDate = new Date().toISOString().split('T')[0]

    //STATE
    const [libro, setLibro]                 = useState(claseParticular?.libro ?? null)
    const [libroAsociado, setLibroAsociado] = useState(claseParticular?.libro?.libro_asociado ?? null)

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container p={3}>

            {/* LIBRO PRINCIPAL */}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <H3>Libro principal: </H3>
                </Grid>

                <Grid item xs={8}>
                    <ItemsSelect
                        label={"Libro principal"} 
                        name={"libro_id"} 
                        keyValue={"libro"} 
                        register={register} 
                        errors={errors} 
                        defaultValue={claseParticular?.libro_id ?? ""} 
                        onChange= {(_, libro) => {
                            if(libro && libro?.libro_asociado) setLibroAsociado(libro.libro_asociado ?? null)
                            if(libro) setLibro(libro ?? null)
                        }}
                        searcheable />
                </Grid>


                <Grid item xs={2}>
                    <Checkbox
                        name={"libro_solicitado"}
                        label={"Libro solicitado"}
                        register={register}
                        errors={errors}
                        defaultChecked={ claseParticular ? (claseParticular?.venta_libro?.libro?.id == claseParticular?.libro_id ? true : false) : false}
                     />
                </Grid>

                <Grid item xs={2}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_peticion"} label={"Fecha solicitado"} defaultValue={currentDate} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2}>
                    <Input register={register} errors={errors} label={"Precio"} name={"precio"} className="w-100" defaultValue={claseParticular?.venta_libro?.precio ?? ""} type={"number"} />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_pago"} label={"Fecha de pago"} defaultValue={claseParticular?.venta_libro?.fecha_pago ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_entrega"} label={"Fecha de entrega"} defaultValue={claseParticular?.venta_libro?.fecha_entrega ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2}>
                    <Input
                        register={register}
                        errors={errors}
                        label={"Unidades en el almacén"}
                        name={"unidades"}
                        className="w-100"
                        defaultValue={libro?.unidades_disponibles ?? ""}
                        uppercase
                        disabled
                        useValue />
                </Grid>
                <Grid item xs={2}>
                    <Button>
                        Detalles
                    </Button>
                </Grid>
            </Grid>

            {/* ******************************************************************************** */}
            {/* LIBRO ASOCIADO */}
            <Grid container spacing={2} mt={5}>
                <Grid item xs={12}>
                    <H3>Libro asociado: </H3>
                </Grid>

                <Grid item xs={8}>
                    <Input key={Math.random()} label={"Libro asociado"} defaultValue={libroAsociado?.nombre ?? "..."} className="w-100" uppercase disabled />
                    <Input key={Math.random()} type={"hidden"} name={"libro_asociado_id"} className="invisible" defaultValue={libroAsociado?.id ?? null} register={register} />
                </Grid>

                <Grid item xs={2}>
                    <Checkbox
                        name={"libro_asociado_solicitado"}
                        label={"Libro solicitado"}
                        register={register}
                        errors={errors}
                        defaultChecked={claseParticular ? (claseParticular?.venta_libro_asociado?.libro?.id == claseParticular?.libro?.id_libro ? true : false) : false} />
                </Grid>

                <Grid item xs={2}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_asociado_peticion"} label={"Fecha solicitado"} defaultValue={currentDate} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2}>
                    <Input register={register} errors={errors} label={"Precio"} name={"precio_asociado"} className="w-100" defaultValue={claseParticular?.venta_libro_asociado?.precio ?? ""} uppercase type={"number"} />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_pago_asociado"} label={"Fecha de pago"} defaultValue={claseParticular?.venta_libro_asociado?.fecha_pago ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_entrega_asociado"} label={"Fecha de entrega"} defaultValue={claseParticular?.venta_libro_asociado?.fecha_entrega ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2}>
                    <Input
                        register={register}
                        errors={errors}
                        label={"Unidades en el almacén"}
                        name={"unidades_asociado"}
                        className="w-100"
                        defaultValue={libroAsociado?.unidades_disponibles ?? ""}
                        uppercase
                        disabled
                        useValue />
                </Grid>

                <Grid item xs={2}>
                    <Button>
                        Detalles
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}