import { Text } from 'components/texts'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { useNavigate, useParams } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Paper, Stack } from '@mui/material'
import { ChangesContext } from 'contexts/ChangesContext'
import { EditHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { AlumnoFullItem } from 'components/data_display/users'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'

//FORM
import { DataFormTutoria } from 'components/forms/display'
import Skeleton from 'components/forms/display/tutoria/DataForm/skeleton.jsx'
import { validationSchema } from 'components/forms/display/tutoria/DataForm/validation.js'

const Wrapper = () => {
  const navigationParams = useParams()

  const { items, updateItem } = useContext(EmpleadoListContext)

  const [tutoria,    setTutoria]   = useState(null)
  const [id_tutoria, setIdTutoria] = useState(navigationParams.id_tutoria)

  const updateAlert = (message, variant = "error") => {
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }

  const getTutoria = (getFromServer = false) => {
    const item = items.find(item => item?.id == id_tutoria)
    if(items.length == 0 || !item || getFromServer) {
      new Requests().getTutoria(id_tutoria, (tutoria) => {
        setTutoria(tutoria)
        updateItem(tutoria)
      }, () => updateAlert("No se ha podido recuperar los datos del colegio."))
    } else {
      setTutoria(item)
    }
  }
    
    
  useEffect(getTutoria, [id_tutoria])

  return (
    <React.Fragment>
      <EditTutoria key={tutoria?.id ?? 0} tutoria={tutoria} id_tutoria={id_tutoria} setIdTutoria={setIdTutoria} setTutoria={setTutoria} updateAlert={updateAlert} onSaveFinish={() => getTutoria(true)}/>

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}
export default Wrapper

function EditTutoria({ tutoria, id_tutoria, setIdTutoria, setTutoria, updateAlert, onSaveFinish }) {
  const navigate = useNavigate()

  const { updateChanges } = useContext(ChangesContext)

  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, control, reset, setValue } = useForm({ resolver: yupResolver(validationSchema) })

  const headerRef = useRef()

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])

  const updateHeaderAndAlert = ({ message, severity = "error"}, loading = false) => {
    headerRef.current.button.setLoading(loading)
    updateAlert(message, severity)
  }

  /**
   * Hace la petición para editar los datos basicos del responsable
   */
  const editTutoria = () => {
    headerRef.current.button.setLoading(true)

    new Requests().editTutoria(id_tutoria, getValues, (res) => {
      updateHeaderAndAlert({message: "Se ha editado la tutoría correctamente.", severity: "success"})
      reset({}, {keepValues: true})
      updateChanges(false)
      onSaveFinish()
    }, (res) => {
      updateHeaderAndAlert({message: res})
    })
  }

  const updateId = (id) => {
    navigate(`../alumnos/tutorias/${id}/editar`)
    setIdTutoria(id)
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/",              label: "Inicio" },
            { to: "./../../../../", label: "Alumnos" },
            { to: "./../../../",    label: "Tutorias" },
            { to: "./../../",       label: "Tutoria del alumno #" + (tutoria?.id_alumno ?? "0000") },
            { to: "./",             label: "Editar tutoria #" + (id_tutoria ?? "0000"), actualItem: true  },
          ]} />

          <MoveButtons 
            actualItemId        = {id_tutoria}
            beforeFetchCallback = {() => setTutoria(null)} 
            onUpdateActualItem  = {updateId} />
        </Stack>

        <Paper elevation={2} sx={{mx: 8, my: 2, pb: 5}}>
          <EditHeader 
            ref          = {headerRef} 
            title        = {"Editar tutoria"}
            item         = {tutoria} 
            handleSubmit = {handleSubmit(editTutoria)} />

          <Line />

          {tutoria ? <DataFormTutoria 
                        tutoria   = {tutoria} 
                        register  = {register}
                        setValue  = {setValue}
                        errors    = {errors} 
                        control   = {control}
                        isMounted
                        hideButton /> : <Skeleton />}
        </Paper>

        <Paper elevation={2} sx={{mx: 8, my: 2, pb: 2}}>
          <EditHeader title="Alumno de la tutoría" />
            
          <Line />

          {tutoria?.alumno ? <AlumnoFullItem alumno={tutoria.alumno} containerClassName="m-5"/> : null}
        </Paper>
      </form>
    </Container>
  )
}