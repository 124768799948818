import { Grid, Stack } from "@mui/material";
import { H3, Text } from "components/texts";
import { Line } from "components/data_display";
import Skeleton from './skeleton'

/**
 * Pagina con los datos de los libros de la clase particular
 */
export default function ClaseParticularLibros({ claseParticular }) {
    //STATE
    const libro = claseParticular?.libro ?? null
    const libroAsociado = claseParticular?.libro?.libro_asociado ?? null

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container p={5}>
            {claseParticular ?
                <>
                    {/* **************************************************************************** */}
                    {/* LIBRO */}

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <H3>Libro principal: </H3>
                        </Grid>

                        <Grid item xs={6}>
                            <Text>{claseParticular?.libro?.nombre}</Text>
                        </Grid>

                        <Grid item xs={3}>
                            <Text fontWeight={"bold"}>Solicitado: </Text>
                            <Text>{claseParticular ? (claseParticular?.venta_libro?.libro?.id == claseParticular?.libro_id ? "Sí" : "No") : "Sin rellenar"}</Text>
                        </Grid>

                        <Grid item xs={3}>
                            <Stack>
                                <Text fontWeight={"bold"}>Fecha solicitado: </Text>
                                <Text>{claseParticular?.venta_libro?.fecha_peticion ?? "Sin rellenar"}</Text>
                            </Stack>
                        </Grid>


                        <Grid item xs={3}>
                            <Text fontWeight={"bold"}>Precio: </Text>
                            <Text>{claseParticular?.venta_libro?.precio ?? "Sin rellenar"}</Text>
                        </Grid>

                        <Grid item xs={3}>
                            <Stack>
                                <Text fontWeight={"bold"}>Fecha de pago: </Text>
                                <Text>{claseParticular?.venta_libro?.fecha_pago ?? "Sin rellenar"}</Text>
                            </Stack>
                        </Grid>

                        <Grid item xs={3}>
                            <Stack>
                                <Text fontWeight={"bold"}>Fecha de entrega: </Text>
                                <Text>{claseParticular?.venta_libro?.fecha_entrega ?? "Sin rellenar"}</Text>
                            </Stack>
                        </Grid>

                        <Grid item xs={3}>
                            <Stack>
                                <Text fontWeight={"bold"}>Unidades en el almacén: </Text>
                                <Text>{libro?.unidades_disponibles ?? "Sin rellenar"}</Text>
                            </Stack>
                        </Grid>
                        {/* <Grid item xs={2}> */}
                        {/* <Button>
                                    Detalles
                                </Button> */}
                        {/* </Grid> */}
                    </Grid>

                    <Grid container my={4}>
                        <Line />
                    </Grid>

                    {/* ******************************************************************************** */}
                    {/* LIBRO ASOCIADO */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <H3>Libro asociado: </H3>
                        </Grid>

                        <Grid item xs={6}>
                            <Text>{libroAsociado?.nombre ?? "Sin rellenar"}</Text>
                        </Grid>

                        <Grid item xs={3}>
                            <Text fontWeight={"bold"}>Solicitado: </Text>
                            <Text>{claseParticular ? (claseParticular?.venta_libro_asociado?.libro?.id == claseParticular?.libro?.id_libro ? "Sí" : "No") : "Sin rellenar"}</Text>
                        </Grid>

                        <Grid item xs={3}>
                            <Stack>
                                <Text fontWeight={"bold"}>Fecha solicitado: </Text>
                                <Text>{claseParticular?.venta_libro_asociado?.fecha_peticion ?? "Sin rellenar"}</Text>
                            </Stack>
                        </Grid>

                        <Grid item xs={3}>
                            <Text fontWeight={"bold"}>Precio: </Text>
                            <Text>{claseParticular?.venta_libro_asociado?.precio ?? "Sin rellenar"}</Text>
                        </Grid>

                        <Grid item xs={3}>
                            <Stack>
                                <Text fontWeight={"bold"}>Fecha de pago: </Text>
                                <Text>{claseParticular?.venta_libro_asociado?.fecha_pago ?? "Sin rellenar"}</Text>
                            </Stack>
                        </Grid>

                        <Grid item xs={3}>
                            <Stack>
                                <Text fontWeight={"bold"}>Fecha de entrega: </Text>
                                <Text>{claseParticular?.venta_libro_asociado?.fecha_entrega ?? "Sin rellenar"}</Text>
                            </Stack>
                        </Grid>

                        <Grid item xs={3}>
                            <Stack>
                                <Text fontWeight={"bold"}>Fecha de entrega: </Text>
                                <Text>{claseParticular?.venta_libro_asociado?.libro?.unidades_disponibles ?? "Sin rellenar"}</Text>
                            </Stack>
                        </Grid>

                        {/* <Grid item xs={3}> */}
                        {/* <Button>
                                    Detalles
                                </Button> */}
                        {/* </Grid> */}
                    </Grid>

                </>
                : <Skeleton />}
        </Grid>
    )
}