import React, { Component } from 'react'
import { Button as MuiButton, Typography } from '@mui/material'

/**
 * Custom Button
 */
export default class Button extends Component {

  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    const iconType = this.props.iconType ? {width: 20, height: 30, minWidth: 0} : {}
    const style = this.props.style ?? {}

    return (
      <MuiButton
        sx        = {this.props.sx ?? null}
        variant   = {this.props.variant ?? "contained"}
        className = {"py-2 " + (this.props.className ?? "")}
        disabled  = {this.props.disabled ?? false}
        style     = {{...iconType,...style}}
        color     = {this.props.color ?? "standard"}
        size      = {this.props.size ?? "medium"}
        startIcon = {this.props.startIcon ?? null}
        onClick   = {this.props.onClick ?? null}
        endIcon   = {this.props.endIcon ?? null}>

        <Typography variant="button">{this.props.children}</Typography>
      </MuiButton>
    )
  }
}
