import React, { Component } from 'react'
import { LoadingButton } from 'components/forms/components';
import { H3 } from 'components/texts';

/**
 * Header para la creacion
 */
export default class CreateHeader extends Component {

  constructor(props) {
    super(props)
  }


  /**
   * Callback para el envío del formulario
   * 
   * @param {Event} e 
   */
  handleSubmit = (e) => {
    this.props.handleSubmit(e)
  }

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
      <div className='container-fluid px-10 pt-5'>
        <div className="row">
          <div className="col-md-10 d-flex align-items-center">
            <H3 className={"m-0"}>{this.props.title ?? ""}</H3>
          </div>

          <div className="col-md-2 d-flex justify-content-end align-items-center">
            <LoadingButton ref={(ref) => this.button = ref} color={'success'} size={"small"} onClick={(e) => {this.handleSubmit(e)}}>
              {this.props.btnText ?? "Guardar cambios"}
            </LoadingButton>
          </div>
        </div>
      </div>
    )
  }
}
