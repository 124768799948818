import { RequestDatosColegio } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para editar los datos del colegio
     * 
     * @param {Int} id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editDatosColegio(id, getValues, callbackOk = () => {}, callbackError = () => {}) {
        const formData = new FormData()

        formData.append("nota_final",       getValues("nota_final").replace(',', "."))
        formData.append("num_curso",        getValues("num_curso").toUpperCase())
        formData.append("id_colegio",       getValues("id_colegio"))
        formData.append("id_curso_colegio", getValues("id_curso_colegio"))
        formData.append("id_curso_escolar", getValues("id_curso_escolar"))

        new RequestDatosColegio().edit(id, formData, callbackOk, callbackError)
    }


    /**
     * Devuelve los datos en el colegio
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getDatosColegio(id, callbackOk = () => {}, callbackError = () => {}) {
        const params = { relations: 'alumno'}
        new RequestDatosColegio().get(id, params, (res) => {
            res.item ? callbackOk(res.item) : callbackError(res)
        })
    }
}
