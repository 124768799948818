import React, { useState, useEffect } from "react";
import {
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    Container
} from "@mui/material";
import { Button, Input } from "components/forms/components";
import { COLOR } from "theme/Colors";
import { IconButton } from "@mui/material";
import { Delete, Edit, Done, CreateNewFolder, Search } from "@mui/icons-material";
import { H3 } from "components/texts";

const containerStyle = {
    textAlign: "center",
    marginTop: 20,
};

const titles = {
    color: COLOR.white.main,
};

const inputStyles = {
    padding: '7px 14px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    margin: '16px'
};

const handleAddArchivo = () => {
    // Función para añadir archivo
    console.log('Agregando nuevo archivo:');
};

function DataCurriculumTable({ register, errors }) {
    const [descripcion, setDescripcion] = useState("");
    const [observaciones, setObservaciones] = useState("");
    const [fecha_inicio, setFecha_inicio] = useState("");
    const [fecha_fin, setFecha_fin] = useState("");
    const [todos, setTodos] = useState([]);
    const [editingRow, setEditingRow] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        // Este código se ejecutará cada vez que 'todos' se actualice
        console.log("Nuevos 'todos':", todos);
    }, [todos]);

    const handleDescripcionChange = (e) => {
        setDescripcion(e.target.value);
    };

    const handleObservacionesChange = (e) => {
        setObservaciones(e.target.value);
    };

    const handleFechaInicioChange = (e) => {
        setFecha_inicio(e.target.value);
    };
    const handleFechaFinChange = (e) => {
        setFecha_fin(e.target.value);
    };

    const handleEditField = (index, field, value) => {
        const newTodos = [...todos];
        newTodos[index][field] = value;
        setTodos(newTodos);
    };

    const handleSaveEdit = (index) => {
        setEditingRow(null);
        setSelectedRow(index);
    };

    const handleStartEdit = (index) => {
        setEditingRow(index);
    };

    const handleAddTodo = () => {
        const fechaInicioObj = new Date(fecha_inicio);
        const fechaFinObj = new Date(fecha_fin);

        if (descripcion && observaciones && (fechaInicioObj < fechaFinObj)) {
            setTodos([...todos, { descripcion, observaciones, fecha_inicio, fecha_fin }]);
            setDescripcion("");
            setObservaciones("");
            setFecha_inicio("");
            setFecha_fin("");
        } else {
            <p style={{ color: 'red', margin: 0 }}>Los campos son obligatorios</p>
        }
    };
    const handleDelete = (index) => {
        const newTodos = [...todos];
        newTodos.splice(index, 1);
        setTodos(newTodos);
    };

    return (
        <Container component="main" style={containerStyle}>
            <TableContainer component={Paper} style={{ marginTop: 20, maxHeight: '350px' }}>
                <Table>
                    <TableHead style={{ backgroundColor: COLOR.cancel.main }}>
                        <TableRow>
                            <TableCell style={titles}>Descripción</TableCell>
                            <TableCell style={titles}>Observaciones</TableCell>
                            <TableCell style={titles}>Fecha Inicio</TableCell>
                            <TableCell style={titles}>Fecha Fin</TableCell>
                            <TableCell style={titles}>Editar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {todos.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={5} style={{ textAlign: 'center', minHeight: '50px' }}>
                                    La tabla está vacía.
                                </TableCell>
                            </TableRow>
                        ) : (
                            todos.map((todo, index) => (
                                <TableRow key={index}
                                    onClick={() => setSelectedRow(index)} style={{
                                        backgroundColor: index % 2 === 0 ? COLOR.white.main : COLOR.grey.light,
                                        ...selectedRow === index ? { fontStyle: 'italic' } : {},
                                    }}>
                                    <TableCell style={selectedRow === index ? { fontStyle: 'italic' } :
                                        { color: COLOR.black.main, fontSize: '12px', fontWeight: 'normal' }}>
                                        {editingRow === index ? (
                                            <Input register={register}
                                                onInput={(e) => handleEditField(index, "descripcion", e.target.value)}
                                                errors={errors} className="w-100" name={"nombre"}
                                                defaultValue={todo.descripcion ?? ""} uppercase />
                                        ) : (
                                            todo.descripcion
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {editingRow === index ? (
                                            <Input
                                                type="text"
                                                errors={errors}
                                                name={"observaciones"}
                                                register={register}
                                                defaultValue={todo.observaciones ?? ""} uppercase
                                                value={todo.observaciones}
                                                onInput={(e) => handleEditField(index, "observaciones", e.target.value)} />
                                        ) : (
                                            todo.observaciones
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {editingRow === index ? (
                                            <>
                                                <label htmlFor={`fechaInicio-${index}`}>
                                                    Fec. de Inicio
                                                </label>
                                                <input
                                                    type="date"
                                                    id={`fechaInicio-${index}`}
                                                    style={inputStyles}
                                                    value={todo.fecha_inicio}
                                                    onInput={(e) => handleEditField(index, "fecha_inicio", e.target.value)}
                                                /></>
                                        ) : (
                                            new Date(todo.fecha_inicio).toLocaleDateString("es-ES")
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {editingRow === index ? (
                                            <>
                                                <label htmlFor={`fechaFin-${index}`}>
                                                    Fec. de Fin
                                                </label>
                                                <input
                                                    type="date"
                                                    id={`fechaFin-${index}`}
                                                    style={inputStyles}
                                                    value={todo.fecha_fin}
                                                    onInput={(e) => handleEditField(index, "fecha_fin", e.target.value)}
                                                /></>
                                        ) : (
                                            new Date(todo.fecha_fin).toLocaleDateString("es-ES")
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        {editingRow === index ? (
                                            <>
                                                <IconButton color="success" onClick={() => handleSaveEdit(index)}>
                                                    <Done />
                                                </IconButton>
                                                <IconButton color="warning" onClick={() => handleDelete(index)}>
                                                    <Delete />
                                                </IconButton>
                                            </>
                                        ) : (
                                            <IconButton color="primary" onClick={() => handleStartEdit(index)}>
                                                <Edit />
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container mt={4} sx={{ bottom: 0 }} >
                <Grid item sx={9} >
                    <TextField
                        sx={{ m: 2 }}
                        variant="outlined"
                        fullWidth
                        label="Descripción"
                        name="descripcion"
                        clearable="true"
                        value={descripcion}
                        size="small"
                        onChange={handleDescripcionChange}
                    />
                    <TextField
                        sx={{ m: 2 }}
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Observaciones"
                        clearable="true"
                        value={observaciones}
                        onChange={handleObservacionesChange}
                    />
                </Grid>

                <Grid item xs={6}>
                    <label htmlFor="fechaInicio">
                        Fec. de Inicio
                    </label>
                    <input
                        type="date"
                        id="fechaInicio"
                        style={inputStyles}
                        value={fecha_inicio}
                        onChange={handleFechaInicioChange}
                    />
                    <label htmlFor="fechaFin">
                        Fec. Fin
                    </label>
                    <input
                        style={inputStyles}
                        type="date"
                        value={fecha_fin}
                        onChange={handleFechaFinChange} />
                </Grid>

                <Grid item xs={1.5}>
                    <Button
                        sx={{ mt: 2 }}
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={handleAddTodo}
                        disabled={!descripcion || !observaciones}>
                        Añadir
                    </Button>
                </Grid>

            </Grid>
            {fecha_inicio && new Date(fecha_inicio) >= new Date(fecha_fin) && (
                <p style={{ color: 'red', margin: 0 }}>Fechas incorrectas</p>
            )}

            {/* {(!fecha_inicio || !fecha_fin) && (
                <p style={{ color: 'red', margin: 0 }}>Los campos son obligatorios</p>
            )} */}
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" textAlign="left">
                <Grid item xs={12} ml={2} mb={2}>
                    <H3> Archivo: </H3>
                </Grid>

                <Grid container ml={2}>
                    <Grid item xs={2.5}>
                        <TextField
                            sx={{ mr: 2 }}
                            fullWidth
                            size="small"
                            variant="outlined"
                            label="Archivo"
                            clearable="true" />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            color="standard"
                            aria-label="Agregar Archivo"
                            onClick={handleAddArchivo} >
                            <CreateNewFolder fontSize="large" />
                        </IconButton>
                    </Grid>

                    <Grid item>
                        <IconButton
                            color="standard"
                            aria-label="Ver Curriculum"
                        // onClick={handleVerCurriculum}
                        >
                            <Search fontSize="small" />
                            Ver Curriculum
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default DataCurriculumTable;
