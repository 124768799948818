import Skeleton from './skeleton'
import React, { Component } from 'react'
import { ASSETS } from 'constants/Assets'
import { H1, H3, Text } from 'components/texts'
import { Avatar, Grid, Stack, Box } from '@mui/material'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Informacion de los datos básicos del alumno
 */
export default class AlumnoData extends Component {

  constructor(props) {
    super(props)

    this.state = {
      alumno: this.props.alumno ?? null
    }
  }


  /**
   * ComponentDidUpdate
   * 
   * @param {Object} prevProps 
   */
  componentDidUpdate(prevProps) {
    if (this.props.alumno != prevProps.alumno)
      this.setState({ alumno: this.props.alumno })
  }

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    let { alumno } = this.state
    return (
      <Grid container px={5} pt={5}>
        {!alumno ? <Skeleton /> : (
          <>
              <Grid item xs={2}>
                <Avatar
                    alt = {"Foto de: " + (alumno?.nombre ?? "")}
                    src = {`${ASSETS.images + (alumno.foto_logo ? alumno.foto_logo : 'private/alumno/perfiles/0/default.png')}`}
                    sx  ={{ width: "80%", height: "100%", borderRadius: 2}}/>
              </Grid>

              <Grid item xs={7}>
                <H1 className="profile-id-text">{"#" + alumno.id}</H1>

                <Stack spacing={1}>
                  <H3>{alumno.nombre + " " + (alumno.apellido1 ?? "") + " " + (alumno.apellido2 ?? "")}</H3>

                  <Box display={"flex"}>
                    <Text fontWeight={"bold"}>Documento de identidad:</Text>
                    <Text className="mx-2">{alumno.numero_documento + " - " + alumno.tipo_documento?.nombre}</Text>
                  </Box>

                  <Box display={"flex"}>
                    <Text fontWeight={"bold"}>Género:</Text>
                    <Text className="mx-2">{alumno.sexo?.valor}</Text>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={3} display={"flex"} alignItems={"end"}>
                <ItemsSelect 
                  name         = {"id_curso_escolar"} 
                  keyValue     = {"curso_escolar"} 
                  onChange     = {(event) => {this.props.onSelectChange(event.target.value, 1)}} 
                  defaultValue = {0} 
                  allOption />
              </Grid>
          </>
        )}
      </Grid>
    )
  }
}
