import { responses } from 'constants/Responses'
import React, { useState, useEffect } from 'react'
import { AlumnoData, IncidenciaData } from './parts'
import { RequestIncidencia } from 'objects/requests'
import { Container, Paper, Stack } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { BreadcrumbsList, Line, MoveButtons} from 'components/data_display'

export default function SingleIncidenciaAlumno() {
  const navigationParams = useParams()
  const navigate = useNavigate()

  const [id_incidencia, setIdIncidencia] = useState(navigationParams.id_incidencia)
  const [incidencia,    setIncidencia]   = useState(null)

  /**
   * Hace la petición para obtener el usuario
   */
  const getIncidencia = () => {
    const params = { relations: 'alumno' }

    new RequestIncidencia().get(id_incidencia, params, (res) => {
      if (res[responses.incidencias.singleName])
        setIncidencia(res[responses.incidencias.singleName])
    })
  }

  const updateId = (id) => {
    navigate(`../alumnos/incidencias/${id}`);
    setIdIncidencia(id);
  }

  useEffect(getIncidencia, [id_incidencia])

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/",     label: "Inicio" },
            { to: "./../", label: "Incidencias" },
            { to: "./",    label: "Incidencia #" + (id_incidencia ?? "0000"), actualItem: true },
          ]} />

          <MoveButtons 
            actualItemId        = {id_incidencia}
            beforeFetchCallback = {() => setIncidencia(null)} 
            onUpdateActualItem  = {updateId} />
        </Stack>
        
        <Paper elevation={2} sx={{mx: 8, my: 2, p: 5}}>
          
          <IncidenciaData incidencia={incidencia} />

          <Line className={"my-10"}/>

          <AlumnoData incidencia={incidencia} />
          
        </Paper>
      </Container>
  )
}
