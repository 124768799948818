import Statics from 'objects/common/Statics'

export const columns = [
    { field: "id",                  headerName: "ID",          maxWidth: 65, valueGetter: (params) => { return params.row.alumno?.id ?? "" }  },
    { field: "nombre",              headerName: "Nombre",      flex: 1, valueGetter: (params) => { return params.row.alumno?.nombre ?? "" } },
    { field: "apellido1",           headerName: "Apellido 1",  flex: 1, valueGetter: (params) => { return params.row.alumno?.apellido1 ?? "" }},
    { field: "apellido2",           headerName: "Apellido 2",  flex: 1, valueGetter: (params) => { return params.row.alumno?.apellido2 ?? "" }},
    { field: "fecha_incorporacion", headerName: "Fecha Inc.",  flex: 1, valueGetter: (params) => { return params.row.fecha_incorporacion ? Statics.formatDate(params.row.fecha_incorporacion) : "" }},
    { field: "fecha_baja",          headerName: "Fecha Baja",  flex: 1, valueGetter: (params) => { return params.row.fecha_baja ? Statics.formatDate(params.row.fecha_baja) : "" } },
]
