import React from 'react'
import { useNavigate } from 'react-router'
import { Grid, Stack } from '@mui/material'
import Requests from '../../Requests/Requests'
import { H2, H3 } from 'components/texts/index.js'
import { Button } from 'components/forms/components'
import { Groups as GroupsIcon, InsertDriveFile as FileIcon, Edit as EditIcon } from '@mui/icons-material'

export default function Header({ grupo }){
    const navigate = useNavigate()

    const onPrintGroupData = () => {
        new Requests().getPreliminar(grupo.id)
    }

    return (
        <Grid container px={5} py={3} alignItems={"center"}>
            <Grid item xs={6}>
                <Stack>
                    <H2 className="profile-id-text">{"#" + (grupo?.id ?? "0000")}</H2>
                    <H3>{grupo?.clave ?? ""}</H3>
                </Stack>
            </Grid>

            <Grid item xs={6} display={"flex"} justifyContent={"end"}>
                {grupo ?
                    (<Stack spacing={2} direction={"row"}>
                        <Button color={"success"} startIcon={<GroupsIcon />} onClick={onPrintGroupData}>
                            Imprimir datos del grupo
                        </Button>

                        <Button startIcon={<FileIcon />}>
                            Generar informe
                        </Button>

                        <Button startIcon={<EditIcon/>} variant={"outlined"}onClick={() => navigate('./editar') }>
                            Editar grupo
                        </Button>
                    </Stack>) : null}
            </Grid>
        </Grid>
    )
}