import { Link } from 'components/data_display'
import { Button } from 'components/forms/components'

export const columns = [
    {
        field: "actions",
        type: 'actions',
        headerName: "Acciones",
        minWidth: 200,
        getActions: (params) => [<Link to={"" + params.row.id}><Button size={"small"}>Ver</Button></Link>, <Link to={"" + params.row.id + "/editar"}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>]
    },
    { field: "id", headerName: "ID", maxWidth: 65 },
    { field: "tipo_documento", headerName: "Tipo de documento", minWidth: 100, valueGetter: (params) => { return params.row.tipo_documento?.nombre } },
    { field: "numero_documento", headerName: "Número", minWidth: 100 },
    { field: "apellido1", headerName: "1. Apellido", minWidth: 100, flex: 1 },
    { field: "apellido2", headerName: "2. Apellido", minWidth: 100, flex: 1 },
    { field: "nombre", headerName: "Nombre", minWidth: 100, flex: 1 },
    { field: "email", headerName: "Email", minWidth: 100, flex: 1 },
]