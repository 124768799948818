import { responses as itemsResponses } from 'constants/Responses'
import React, { createContext, useEffect, useState } from 'react'
import {
  RequestAlumno, RequestEmpleado, RequestClasesParticulares, RequestAula,
  RequestGrupoAcademia, RequestIncidencia, RequestResponsable, RequestTutoria, RequestDatosColegio, RequestRegistroActividad,
  RequestInscripcionAcademia, RequestColegio, RequestLibro, RequestGrupoColegio, RequestInscripcionColegio
} from 'objects/requests'

const requests = {
  "alumnos": RequestAlumno,
  "empleados": RequestEmpleado,
  "responsables": RequestResponsable,
  "tutorias": RequestTutoria,
  "incidencias": RequestIncidencia,
  "datos-colegio": RequestDatosColegio,
  "colegios": RequestColegio,
  "libros": RequestLibro,
  "aulas": RequestAula,
  "inscripcionesAcademias": RequestInscripcionAcademia,
  "actividad": RequestRegistroActividad,
  "actividades-colegio": RequestRegistroActividad,
  "grupos-colegio": RequestGrupoColegio,
  "inscripciones-colegio": RequestInscripcionColegio,
  'clases-particulares': RequestClasesParticulares,
  "grupo-academia": RequestGrupoAcademia
}

const responses = {
  "alumnos": itemsResponses.alumnos.arrayName,
  "empleados": itemsResponses.empleados.arrayName,
  "responsables": itemsResponses.responsables.arrayName,
  "tutorias": itemsResponses.tutorias.arrayName,
  "incidencias": itemsResponses.incidencias.arrayName,
  "datos-colegio": itemsResponses.datosColegio.arrayName,
  "colegios": itemsResponses.colegios.arrayName,
  "libros": itemsResponses.libros.arrayName,
  "aulas": itemsResponses.aulas.arrayName,
  "inscripcionesAcademias": itemsResponses.inscripcionesAcademias.arrayName,
  "actividad": itemsResponses.actividadAcademia.arrayName,
  "actividades-colegio": itemsResponses.actividad.arrayName,
  "grupos-colegio": itemsResponses.gruposColegio.arrayName,
  "inscripciones-colegio": itemsResponses.inscripcionesColegio.arrayName,
  'clases-particulares': "items",
  "grupo-academia": itemsResponses.grupoAcademia.arrayName,
}



// Crea el contexto
const EmpleadoListContext = createContext();

// Proveedor del contexto
const EmpleadoListProvider = ({ children, tipoContexto }) => {
  // Estado para almacenar los datos de los items
  const [rowCount, setRowCount] = useState(0)
  const [items, setItems] = useState([])
  const [params, setParams] = useState('')
  const [page, setPage] = useState(1)

  // Función para actualizar los datos de los items
  const actualizarItems = (rowCount, lista, params, page) => {
    setRowCount(rowCount)
    setParams(params)
    setPage(page)

    let newItems = [...items]
    //Si esta vacio es que es la primera vez que lo actualiza
    if (items.length == 0)
      newItems = Array.from({ length: rowCount })

    const itemsPerPage = 25;
    const startIndex = (page - 1) * itemsPerPage;

    lista.forEach((item, index) => {
      const arrayIndex = startIndex + index;
      newItems[arrayIndex] = item;
    })

    setItems(newItems)
  }

  const actualizarItem = (updatedItem) => {
    const index = items.findIndex(item => item.id == updatedItem.id)
    if (index != -1) {
      items[index] = updatedItem
      setItems(items)
    }
  }

  const fetchByPage = (newPage, onFetch = () => { }) => {
    let contextClass = requests[tipoContexto]

    if (contextClass) {
      let request = new contextClass()
      request.search({ ...params, page: newPage }, (res) => {
        const queryItems = res[responses[tipoContexto]]
        actualizarItems(queryItems.total, queryItems.data, params, newPage);
        onFetch(queryItems.data);
      })
    }
  }

  const resetContextState = () => {
    setRowCount(1)
    setParams("")
    setPage(0)
    setItems([])
  }

  // Resetea el estado del contexto cuando el tipo de contexto cambia
  useEffect(resetContextState, [tipoContexto]);

  // Objeto de contexto que contiene los datos y funciones
  const contextValue = {
    rowCount,
    items,
    params,
    page,
    update: actualizarItems,
    updateItem: actualizarItem,
    setPage,
    resetContextState,
    fetchByPage,
  }

  // Renderiza el proveedor del contexto
  return (
    <EmpleadoListContext.Provider value={contextValue}>
      {children}
    </EmpleadoListContext.Provider>
  )
}

export { EmpleadoListProvider, EmpleadoListContext };
