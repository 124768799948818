import { Component } from 'react'
import Statics from 'objects/common/Statics'
import { RequestIncidencia } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar en la pagina
 */
export default class Requests extends Component {

    /**
     * Hace la petición para crear una incidencia
     * 
     * @param {Function} getValues 
     * @param {Int}      id_alumno 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createIncidencia(getValues, id_alumno, callbackOk = () => { }, callbackError = () => { }) {
        var formData = new FormData()
        formData.append("incidencia",            getValues("incidencia").toUpperCase())
        formData.append("fecha",                 Statics.datePickerTimeFormat(getValues("fecha")))
        formData.append("solucionada",           getValues("solucionada") ? 1 : 0)
        formData.append("id_curso_escolar",      getValues("id_curso_escolar"))
        formData.append("id_tipo_incidencia",    getValues("id_tipo_incidencia"))
        formData.append("id_alumno",             id_alumno)

        new RequestIncidencia().create(formData, callbackOk, callbackError)
    }
}
