import { RequestLibro, RequestPedidoLibro, RequestNivelLibro, RequestDevolucionLibro } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Devuelve el libro
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getLibro(id, callbackOk = () => { }, callbackError = () => { }) {
        new RequestLibro().get(id, { relations: 'pedidos_libro,devoluciones_libro,niveles_libro', atributtes: 'numVentasCurso' }, (res) => {
            res.libro ? callbackOk(res.libro) : callbackError(res)
        })
    }


    /**
     * Hace la petición para editar el libro
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editLibro(id_libro, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append("nombre", getValues("nombre").toUpperCase())
        formData.append("isbn", getValues("isbn").toUpperCase())
        formData.append("id_editorial", getValues("id_editorial"))
        formData.append("abreviatura", getValues("abreviatura").toUpperCase())
        formData.append("activo", getValues("activo") ? 1 : 0)
        formData.append("id_libro", getValues("id_libro"))
        formData.append("precio_editorial", getValues("precio_editorial"))
        formData.append("pvp", getValues("pvp"))

        formData.append("programacion_1t", getValues("programacion_1t")[0] ?? "")
        formData.append("programacion_2t", getValues("programacion_2t")[0] ?? "")
        formData.append("programacion_3t", getValues("programacion_3t")[0] ?? "")

        new RequestLibro().put(id_libro, formData, callbackOk, callbackError)
    }


    /**
     * Hace la petición para crear los libros pedidos
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createLibrosPedidos(id_libro, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const librosPedidos = getValues("librosPedidos").filter(item => ((item.cantidad_llegada || item.cantidad_pedida) && item.deleted != true && item.new == true))
        if (librosPedidos.length > 0) {
            const formData = new FormData()
            formData.append("id_libro", id_libro)

            for (const libro of librosPedidos)
                formData.append("pedidos[]", JSON.stringify(libro))

            new RequestPedidoLibro().createMultiple(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
    }


    /**
     * Hace la petición para crear los libros pedidos
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editLibrosPedidos(getValues, callbackOk = () => { }, callbackError = () => { }) {
        const librosPedidos = getValues("librosPedidos").filter(item => ((item.cantidad_llegada || item.cantidad_pedida) && item.deleted != true && item.new != true && item.edit == true))
        if (librosPedidos.length > 0) {
            const formData = new FormData()
            for (const libro of librosPedidos)
                formData.append("pedidos[]", JSON.stringify(libro))

            new RequestPedidoLibro().editMultiple(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
    }


    /**
     * Hace la petición para crear los libros pedidos
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    deleteLibrosPedidos(getValues, callbackOk = () => { }, callbackError = () => { }) {
        const librosPedidos = getValues("librosPedidos").filter(item => (item.deleted == true && item.new != true))
        if (librosPedidos.length > 0) {
            const formData = new FormData()
            for (const libro of librosPedidos)
                formData.append("pedidos[]", JSON.stringify(libro))

            new RequestPedidoLibro().deleteMultiple(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
    }


    /**
     * Hace la petición para crear los libros devueltos
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createLibrosDevueltos(id_libro, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const librosDevueltos = getValues("librosDevueltos").filter(item => (item.cantidad_devuelta && item.deleted != true && item.new == true))
        if (librosDevueltos.length > 0) {
            const formData = new FormData()
            formData.append("id_libro", id_libro)

            for (const libro of librosDevueltos)
                formData.append("devoluciones[]", JSON.stringify(libro))

            new RequestDevolucionLibro().createMultiple(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
    }


    /**
     * Hace la petición para crear los libros pedidos
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editLibrosDevueltos(getValues, callbackOk = () => { }, callbackError = () => { }) {
        const librosDevueltos = getValues("librosDevueltos").filter(item => (item.cantidad_devuelta && item.deleted != true && item.new != true && item.edit == true))
        if (librosDevueltos.length > 0) {
            const formData = new FormData()
            for (const libro of librosDevueltos)
                formData.append("devoluciones[]", JSON.stringify(libro))

            new RequestDevolucionLibro().editMultiple(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
    }


    /**
     * Hace la petición para crear los libros pedidos
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    deleteLibrosDevueltos(getValues, callbackOk = () => { }, callbackError = () => { }) {
        const librosDevueltos = getValues("librosDevueltos").filter(item => (item.deleted == true && item.new != true))
        if (librosDevueltos.length > 0) {
            const formData = new FormData()
            for (const libro of librosDevueltos)
                formData.append("devoluciones[]", JSON.stringify(libro))

            new RequestDevolucionLibro().deleteMultiple(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
    }


    /**
     * Hace la petición para actualizar los niveles
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    updateNiveles(id_libro, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append("id_libro", id_libro)

        formData.append("ids_niveles[]", "")
        for (const nivel of getValues("niveles"))
            formData.append("ids_niveles[]", nivel)

        new RequestNivelLibro().updateMultiple(formData, callbackOk, callbackError)
    }
}
