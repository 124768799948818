import { 
    DatosAlumnosTemplate, 
    DatosAlumnoColegiosTemplate,
    DatosAlumnoGrupoColegioTemplate,
    DatosAlumnoGrupoCLTemplate,
} from "./parts"

const alumnoOrder = '{"alumno.apellido1": "ASC", "alumno.apellido2": "ASC", "alumno.nombre": "ASC"}'

export const itemsSearch = [
    { value: 0,  columnKey: "alumnos",        label: "Datos del Alumno en el Colegio por Alumnos",            template: DatosAlumnosTemplate },
    { value: 1,  columnKey: "colegios",       label: "Datos del Alumno en el Colegio por Colegios",           template: DatosAlumnoColegiosTemplate },
    { value: 2,  columnKey: "grupos_colegio", label: "Datos del Alumno en el Colegio por Grupos de Colegios", template: DatosAlumnoGrupoColegioTemplate },
    { value: 3,  columnKey: "grupos_cl",      label: "Datos del Alumno en el Colegio por Grupos de CL",       template: DatosAlumnoGrupoCLTemplate },
]

export const itemsOrder = [
    [
        { value: alumnoOrder,  label: "Alumnos" },
    ],
    [
        { value: alumnoOrder,                       label: "Alumnos" },
        { value: '{"colegio.nombre": "ASC"}',       label: "Colegios" },
        { value: '{"curso_colegio.valor": "ASC"}',  label: "Cursos" },
    ],
    [
        { value: alumnoOrder,                       label: "Alumnos" },
        { value: '{"grupo_colegio.clave": "ASC"}',  label: "Grupos" },
    ],
    [
        { value: alumnoOrder,                        label: "Alumnos" },
        { value: '{"grupo_academia.clave": "ASC"}',  label: "Grupos" },
    ],
]
