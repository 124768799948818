import { Box, Grid, Stack } from "@mui/material";
import { Button } from "components/forms/components";
import { H3, Text } from "components/texts";
import { Edit as EditIcon } from '@mui/icons-material'
import Request from "../../Requests/Requests";
import { LoadingButton } from 'components/forms/components';
import { Line } from "components/data_display";

export default function SingleHeader({ claseParticular, navigate }) {
    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <>
            <Box px={4} py={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Stack direction="row">
                    <H3 className={"m-0"}>Clase particular</H3>
                    <Text className="standard mx-5">{"#" + claseParticular.id}</Text>
                </Stack>

                <Stack direction="row" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {claseParticular ? (
                        <Grid item xs={2} mr={2} display={"flex"} justifyContent={"end"}>
                            <LoadingButton color="success" startIcon={<EditIcon />} onClick={() => { new Request().getPreliminar(claseParticular.id) }}>Vista preliminar</LoadingButton>
                        </Grid>
                    ) : null}

                    {claseParticular ? (
                        <Grid item xs={1} display={"flex"} justifyContent={"end"} alignItems={"start"}>
                            <Button variant={"outlined"} startIcon={<EditIcon />} onClick={() => { navigate('./editar') }}>Editar</Button>
                        </Grid>
                    ) : null}
                </Stack>
            </Box>
            
            <Line />
        </>
    )
}