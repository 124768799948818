import RequestMaster from '../RequestMaster'

export default class RequestSubvencionPPAA extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/subvencion-ppaa'
    }


    /**
     * Crea una subvencion
     *
     * @param {Int}      id
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    create(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Edita un grupo
     *
     * @param {Int}      id
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    edit(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "" + id
        this.put(path, params, callback, callbackError, callbackFinally)
    }
}
