import { Grid } from '@mui/material';
import React, { Component } from 'react'
import { Button } from 'components/forms/components';
import { SearchAlumnoModal } from 'components/modals';
import { H3 } from 'components/texts';

/**
 * Header para el crear de cosas relacionadas con el alumno
 */
export default class CreateHeader extends Component {

  constructor(props) {
    super(props)
  }

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
      <Grid container alignItems={"center"} px={4} py={2}>
        <Grid item xs={6}>
          <H3 className={"m-0"}>{this.props.title ?? ""}</H3>
        </Grid>
        
        <Grid item xs={6} display={"flex"} justifyContent={"end"}>
          {!this.props.disableButton ?
            <Button ref={(ref) => this.button = ref} size={"small"} onClick={() => {this.addAlumnoModal.modal.open()}}>
              {this.props.btnText ?? "Botón"}
            </Button>
          : null}
        </Grid>

        <SearchAlumnoModal 
          ref   = {(ref) => this.addAlumnoModal = ref}
          title = {this.props.modalTitle ?? ""}
          onSelectedCallback={(alumno) => {
            this.props.navigate('./' + alumno.id)
          }} />
      </Grid>
    )
  }
}
