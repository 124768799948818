import { RequestDatosColegio } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para editar el usuario
     * 
     * @param {Function} getValues 
     * @param {Int}      id_alumno 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createDatosColegio(getValues, id_alumno, callbackOk = () => { }, callbackError = () => { }) {
        var formData = new FormData()

        formData.append("nota_final",       getValues("nota_final").replace(',', "."))
        formData.append("num_curso",        getValues("num_curso").toUpperCase())
        formData.append("id_colegio",       getValues("id_colegio"))
        formData.append("id_curso_colegio", getValues("id_curso_colegio"))
        formData.append("id_curso_escolar", getValues("id_curso_escolar"))
        formData.append("id_alumno",        id_alumno)

        new RequestDatosColegio().create(formData, callbackOk, callbackError)
    }
}
