import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import React, { Component } from 'react'
import { Input, Checkbox } from 'components/forms/components/'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Esta es la plantilla para cuando se busque por los datos basicos de libro
 */
export default class LibroTemplate extends Component {
    
    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)
    }

    //-------------------------------------------------------
    //-------------------- CLASS FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    render() {
        const { register, errors } = this.props
        return (
            <>
                <Grid container mt={2} mb={1}>
                    <Text fontWeight={'bold'}>Según datos de:</Text><br />
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Input label="Libro" width={"100%"} name={"nombre"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={6}>
                        <ItemsSelect 
                            name         = {"id_editorial"}
                            keyValue     = {"editorial"}
                            register     = {register} 
                            errors       = {errors}
                            wantToReset  = {this.props.reset} />
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="ISBN" width={"100%"} name={"isbn"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={6}>
                        <Checkbox name={"activo"} label={"Activo"} register={register}  />
                    </Grid>
                </Grid>
            </>
        )
    }
}
