import Statics from "objects/common/Statics"

export const edit = (itemToEdit, value, field, array) => {
    const index = array.findIndex(item => item == itemToEdit)

    if(index != -1) {
        array[index][field] = value
        array[index].edit = true
    }

    return array
}


export const add = (arrayRendered, array, id_curso_escolar, defaultItem) => {
    const lastLibro = arrayRendered.at(-1)
    if(arrayRendered.length == 0 || Statics.checkIfOneElementIsNotEmpty(lastLibro, ['id_curso_escolar', 'id', 'new', 'edit', 'fecha'])) {
        const newElement = {...defaultItem, id_curso_escolar, new: true}
        array.push(newElement)

        return array
    }

    return null
}


export const erase = (itemToDelete, array) => {
    const index = array.findIndex(item => item == itemToDelete)

    if(index != -1)
        array[index].deleted = true

    return array
}