import React, { useState } from 'react';
import { CardButton } from 'components/forms/components'
import { Grid, Stack } from '@mui/material'
import { ItemsSelect } from "components/forms/components/Select/custom"
import { H3 } from 'components/texts';
import { Line } from 'components/data_display';
import validationSchema from "./validation"
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { RequestListadosLibro } from "objects/requests"

const cardData = [
	{ title: 'Inventario de los Libros', id: 1, action: "inventario" },
	{ title: 'Detalle de Libros', id: 2, action: "DetalleLibros" },
	{ title: 'Libros pendientes entregar', id: 3, action: "PendientesEntregar" },
	{ title: 'Stock de Libros', id: 4, action: "StockLibros" },
	{ title: 'Contabilidad Libros', id: 5, action: "ContabilidadLibros" },
	{ title: 'Pagos pendientes Libros', id: 6, action: "PagosPendientesLibros" },
	{ title: 'Estadísticas libros pedidos', id: 7, action: "EstadisticasLibrosPedidos" },
	{ title: 'Devolución de Libros', id: 8, action: "DevolucionLibros" },
	{ title: 'Resumen de Cuentas', id: 9, action: "ResumenCuentas" },
	{ title: 'Precio de Libros Activos', id: 10, action: "PrecioLibrosActivos" },
];

export default function ConsultasData() {

	const [isLoading, setIsLoading] = useState(false)

	const { register, getValues, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });

	const handleClick = (action) => {

		const formData = new FormData()
		formData.append("curso_escolar_id", getValues("curso_escolar_id"))

		new RequestListadosLibro()[action](formData, (res) => {
			setIsLoading(false);
		}, (res) => {
			setIsLoading(true);
			console.log(isLoading, 'cargando...')
		})
	};

	return (
		<form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">

			<Grid container spacing={2} p={2} >

				<Grid item xs={12} >
					<H3>Listados Libros: </H3>
				</Grid>

				<Grid item xs={12} md={4} py={3} pl={3}>
					<Stack spacing={2}>
						<ItemsSelect name={"curso_escolar_id"} keyValue={"curso_escolar"} register={register} errors={errors} />
					</Stack>
				</Grid>

				<Grid item xs={12} >
					<H3>Consultas: </H3>
				</Grid>

				<Grid item xs={12} >
					<Line />
				</Grid>

				{cardData.map((card, index) => (
					<Grid item key={index} >
						<CardButton
							// disabled={isLoading}
							title={card.title}
							buttonOnClick={handleSubmit(() => { handleClick(card.action) })}
						/>
					</Grid>
				))}
			</Grid >
		</form>
	)
}

