import React, { Component } from 'react'
import { Grid, Stack } from '@mui/material'
import { Line } from 'components/data_display'
import Skeleton from '@mui/material/Skeleton'

/**
 * Skeleton para programacion academia data
 */
export default class SkeletonProgramacionesData extends Component {

    /**
     * Render
     *
     * @returns {Component}
     */
    render() {

        return (

            <Grid container px={5}>
                <Grid container spacing={1} mt={2} >

                    <Grid item xs={12}>
                        <Grid item container spacing={1} justifyContent="space-between">

                            <Grid item xs={6}>
                                <Stack>
                                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                                </Stack>
                            </Grid>

                            <Grid item xs={6}>
                                <Stack>
                                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                                    <Stack>
                                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"10%"} />
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container mt={4}>
                    <Line />
                </Grid>
                <Grid container mt={4} justifyContent="space-between">

                    <Grid item xs={4}>
                        <Stack>
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"} />
                            <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"10%"} />
                        </Stack>
                    </Grid>

                </Grid>

                <Grid container mt={4}>

                    <Grid item xs={12}>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} height={'200px'} width={"90%"} />
                    </Grid>
                </Grid>

            </Grid>

        )
    }
}
