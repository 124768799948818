import React, { useState, useEffect } from 'react'
import { Box, Container, Paper, Tab, Tabs, Grid } from '@mui/material'
import { BreadcrumbsList } from 'components/data_display'
import { COLOR } from 'theme/Colors.js'
import { Text } from 'components/texts/index.js'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { TabContext } from '@mui/lab'
import { InscripcionesAcademiaTemplate, InscripcionesColegioTemplate, ClasesParticularesTemplate, CursosInmersionTemplate, ExamenesOficialesTemplate } from './parts'
import { AlumnoPicker } from 'components/forms/components/custom'
import { useForm } from 'react-hook-form'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { RequestAlumno } from 'objects/requests'


export default function SingleHistorico() {
    const [tab, setTab] = useState("1")
    const [historico, setHistorico] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [selectedAlumno, setSelectedAlumno] = useState(null);

    const { register, formState: { errors }, getValues, setValue, clearErrors } = useForm()

    useEffect(() => {
        if (selectedAlumno) { doQuery() }
    }, [selectedAlumno])

    const doQuery = () => {
        setHistorico({})

        const params = {}
        if (getValues('curso_escolar_id'))
            params.curso_escolar_id = getValues('curso_escolar_id')

        new RequestAlumno().historico(selectedAlumno.id, params, setHistorico, (error) => enqueueSnackbar(<Text>{error}</Text>, { variant: "error" }))
    };

    const handleCursoChange = () => {
        if (selectedAlumno !== null) {
            doQuery()
        } else { enqueueSnackbar(<Text>Debe indicar el alumno</Text>, { variant: "error" }) }
    };

    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <BreadcrumbsList mx={8} breadcrumbsList={[
                { to: "/", label: "Inicio" },
                { to: "./../", label: "Alumnos" },
                { to: "./", label: "Histórico", actualItem: true },
            ]} />
            <Paper elevation={2} sx={{ m: 2, mx: 8, my: 2, pb: 5, pt: 2 }}>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                        <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                            <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>INSCRIPCIONES EN LA ACADEMIA</Text>} value={"1"} />
                            <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>INSCRIPCIONES EN COLEGIO</Text>} value={"2"} />
                            {/* <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>EXÁMENES OFICIALES</Text>} value={"3"} /> */}
                            <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>CLASES PARTICULARES</Text>} value={"4"} />
                            {/* <Tab label={<Text fontWeight={tab === "5" ? "bold" : null} color={tab === "5" ? COLOR.standard.main : null}>CURSOS DE INMERSIÓN</Text>} value={"5"} /> */}
                        </Tabs>
                    </Box>

                    <Grid container direction="row" justifyContent="space-between" alignItems="center" px={3} py={2} mb={2} mt={4}>
                        <Grid item xs={4} height={180}>
                            <AlumnoPicker
                                editLabel={"Cambiar alumno"}
                                addLabel={"Añadir alumno"}
                                deleteLabel={"Borrar alumno"}
                                relations={"academia"}
                                register={register}
                                errors={errors}
                                onAlumnoSelected={setSelectedAlumno}
                                setValue={setValue}
                                name={'alumno_id'}
                                clearErrors={clearErrors}
                                canEdit />
                        </Grid>

                        <Grid item xs={4} height={180}>
                            <ItemsSelect
                                label={"Año"}
                                name={"curso_escolar_id"}
                                register={register}
                                errors={errors}
                                keyValue={"curso_escolar"}
                                onChange={() => handleCursoChange()}
                                allOption />
                        </Grid>
                    </Grid>

                    <TabPanel value={"1"}>
                        <InscripcionesAcademiaTemplate isLoading={isLoading} historico={historico['academia']} />
                    </TabPanel>

                    <TabPanel value={"2"}>
                        <InscripcionesColegioTemplate historico={historico['colegio']} isLoading={isLoading} />
                    </TabPanel>

                    {/* <TabPanel value={"3"}>
                        <ExamenesOficialesTemplate />
                    </TabPanel> */}

                    <TabPanel value={"4"}>
                        <ClasesParticularesTemplate historico={historico['clase-particular']} isLoading={isLoading} />
                    </TabPanel>

                    {/* <TabPanel value={"5"}>
                        <CursosInmersionTemplate />
                    </TabPanel> */}
                </TabContext>
            </Paper>

            <SnackbarProvider autoHideDuration={5000} />
        </Container>
    )
}
