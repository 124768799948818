import React from 'react'

import Grid from '@mui/material/Grid'
import { Text } from 'components/texts'
import { Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Esta es la plantilla para cuando se busque por los datos basicos de la actividad
 */
export default function ProfesorTemplate({ register, errors, reset }) {

    return (
        <>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Input label="Primer apellido" width={"100%"} name={"apellido1"} register={register} errors={errors} />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Segundo apellido" width={"100%"} name={"apellido2"} register={register} errors={errors} />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Nombre" width={"100%"} name={"nombre"} register={register} errors={errors} />
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"id_curso_escolar"} keyValue={"curso_escolar"} register={register} errors={errors} wantToReset={reset} />
                </Grid>
            </Grid>
        </>
    )
}

