import React, { useEffect } from 'react'
import { Text } from 'components/texts'
import { Select } from 'components/forms/components'
import Statics from 'objects/common/Statics'
import { RequestGrupoColegio } from 'objects/requests'
import Grid from '@mui/material/Grid'
//VALIDACION FORMULARIO
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import validationSchema from "./validation"
import { SearchButtons } from '../../../components'
import { PROFESORES_VALUE, ACTIVIDADES_VALUE, itemsSearch, itemsOrder } from './constants.js'
import { v4 as uuid } from 'uuid'
import { ActividadTemplate, ProfesorTemplate } from './parts/'

let prepareParamsGlobal = null

//Formulario de busqueda de alumnos
export default function SearchFormGruposColegio({ relations, onChangeSearchIndex, onLoadingCallback, searchCallback, showIdField=false }) {

    //Form
	const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) })

    //State
    const [searchIndex,    setSearchIndex]    = React.useState(ACTIVIDADES_VALUE)
    const [resetFormState, setResetFormState] = React.useState(false)

    useEffect(() => {
        if(resetFormState == true) {
            resetForm(reset)
            setResetFormState(false)
        }
    })

    //-------------------------------------------------------
    //---------------------- FUNCTIONS ----------------------
    //-------------------------------------------------------

    /**
     * Controlla cuando se hace un cambio en el select de busqueda
     * 
     * @param {Object} event
     */
    const handleChange = (event) => {
        let index = event.target.value

        setSearchIndex(index)
        setResetFormState(true)

        if(onChangeSearchIndex)
            onChangeSearchIndex(index)
    }

      
    /**
     * Prepara los parametros que se van a usar
     * 
     * @param {Int} page
     */
    const prepareParams = (page) => {
        const params     = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()))
        params.relations = relations ?? 'seguimiento_trimestral,profesor_actual,libro,colegio,actividad,profesores,horario,curso_escolar,registro_actividad,descripcion_horario,nivel'
        params.page      = page

        return params
    }

    prepareParamsGlobal = prepareParams

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    return (
        <Grid container p={2}>
            <Grid container>
                <Grid item xs={8} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Buscar por:</Text><br />
                    <Select width={"60%"} items={itemsSearch} defaultValue={ACTIVIDADES_VALUE} onChange={handleChange}/>
                </Grid>

                <Grid item xs={4} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Ordenar resultados por:</Text><br />
                    <Select key={uuid()} width={"60%"} name={"order"} items={itemsOrder[searchIndex]} defaultValue={itemsOrder[searchIndex][0].value} register={register} errors={errors}/>
                </Grid>
            </Grid>

            <Grid container>
                {searchIndex == ACTIVIDADES_VALUE ? 
                    <ActividadTemplate reset={resetFormState} register={register} errors={errors} />
                    :
                    <ProfesorTemplate reset={resetFormState} register={register} errors={errors} />
                }
            </Grid>

            <SearchButtons searchText={"Buscar grupos"} onResetClick={() => {setResetFormState(true)}} onSubmitClick={handleSubmit(() => {search(1, onLoadingCallback, searchCallback)})}/>
        </Grid>
    )
}



/**
 * Hace la busqueda de alumnos
 * 
 * @param {Int}      page
 * @param {Function} onLoadingCallback
 * @param {Function} searchCallback
 * @param {Function} prepareParams
 */
export const search = (page = 1, onLoadingCallback, searchCallback) => {
    //Callback para controlar cuando esta cargando
    if(onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal(page)
    new RequestGrupoColegio().search(params, (res) => {
        if(searchCallback)
            searchCallback(res.grupos.data, res.grupos.total, page, params)
    }, () => {
        searchCallback([], 0, page, params)
    })
}


/**
 * Resetea todo el formulario
 * 
 * @param {Function} reset
 */ 
const resetForm = (reset) => {
    reset({
        nombre_colegio: "",
        clave: "",
        nombre: "",
        apellido1: "",
        apellido2: "",
        id_grupo: "",
    })
}