import { useNavigate } from "react-router-dom"
import React, { useContext, useRef } from "react"
import { H3, Text, Mini } from "components/texts"
import { ChangesContext } from "contexts/ChangesContext"
import { WarningNotSavedModal } from "components/modals"

/**
 * CustomLink, utiliza link de react-router-dom pero le aplicamos nuestros estilos
 */
export default function CustomLink({ to, children, className, size = "standard", color }) {

    const { hasChanges, updateChanges } = useContext(ChangesContext) || { hasChanges: false }
    const navigate = useNavigate()

    const modalRef = useRef()

    const handleClick = () => {
        hasChanges ? modalRef.current.modal.open() : navigate(to)
    }

    return (
        <React.Fragment>
            <div className={"custom-link " + (className ?? "")} onClick={handleClick}>
                {size == "small" ? <Mini color={color}>{children ?? ""}</Mini> : null}
                {size == "standard" ? <Text color={color}>{children ?? ""}</Text> : null}
                {size == "large" ? <H3 color={color}>{children ?? ""}</H3> : null}
            </div>

            <WarningNotSavedModal 
                ref        = {modalRef} 
                onGoBack   = {() => {modalRef.current.modal.close()}}
                onContinue = {() => {
                    modalRef.current.modal.close()
                    updateChanges(false)
                    navigate(to)
                }}/>
        </React.Fragment>
    )
}
