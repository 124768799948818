import { TabContext } from '@mui/lab'
import { COLOR } from 'theme/Colors.js'
import { useForm } from 'react-hook-form'
import { Text } from 'components/texts'
import Statics from 'objects/common/Statics'
import Requests from './Requests/Requests.js'
import { useNavigate } from "react-router-dom"
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangesContext } from 'contexts/ChangesContext'
import { CreateHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { BreadcrumbsList, Line } from 'components/data_display'
import { Box, Container, Paper, Tab, Tabs } from '@mui/material'
import React, { useRef, useEffect, useState, useContext } from 'react'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { validationSchema } from 'components/forms/display/colegio/inscripciones/DataForm/validation.js'
import { DataFormGeneralInscripcionColegio, DataFormGrupoInscripcionColegio, DataFormPagoInscripcionColegio, DataFormLibroInscripcionColegio } from 'components/forms/display/index.js'

export default function CreateInscripcion() {
  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, control, setValue, clearErrors } = useForm({ resolver: yupResolver(validationSchema) })
  const navigate = useNavigate()

  const headerRef = useRef()

  const { updateChanges } = useContext(ChangesContext)

  const [isMounted,  setIsMounted] = useState(false)
  const [grupo,      setGrupo]     = useState(null)
  const [id_horario, setHorario]   = useState(-1)
  const [actividad,  setActividad] = useState(-1)
  const [tab,        setTab]       = useState("1")

  useEffect(() => setIsMounted(true), [])
  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])

  const onError = (errors) => {
    const tabPagos = ["id_forma_pago", "cantidad_matricula", "cantidad"]
    let tab = "3"

    for(const key of Object.keys(errors)) {
      if(!tabPagos.find(item => item == key)) {
        tab = "1"
        break
      }
    }

    setTab(tab)
  }

  const promises = (res) => {
    const request = new Requests()

    const promisePagos = new Promise((resolve, reject) => {
      request.createPagos(res.inscripcion.id, getValues("pagos"), resolve, reject)
    })

    const promiseSubvencion = new Promise((resolve, reject) => {
      request.createSubvencion(getValues, resolve, reject)
    })

    const promiseLibro = new Promise((resolve, reject) => {
      const libroSolicitado = getValues('libro_solicitado')
      libroSolicitado ? request.createVentaLibro(res.inscripcion.id, getValues, resolve, reject) : resolve()
    })

    const promiseLibroAsociado = new Promise((resolve, reject) => {
      const libroSolicitadoAsociado = getValues('libro_asociado_solicitado')
      libroSolicitadoAsociado ? request.createVentaLibroAsociado(res.inscripcion.id, getValues, resolve, reject) : resolve()
    })

    return [promisePagos, promiseSubvencion, promiseLibro, promiseLibroAsociado]
  }

  const createInscripcion = () => {
    headerRef.current.button.setLoading(true)

    new Requests().createInscripcion(getValues, (res) => {
      if(res.inscripcion) {

        Promise.allSettled(promises(res))
          .then(async (values) => {
            for (const fail of values.filter(item => item.status == "rejected"))
              enqueueSnackbar(<Text>{fail.reason}</Text>, { variant: "error" })

          updateChanges(false)
          enqueueSnackbar(<Text>Se ha guardado la inscripción correctamente. Redirigiendo a la página de edición.. </Text>, { variant: "success" })
          await Statics.delay(1500).then(() => {navigate('./../' + res.inscripcion.id)})
        })
      }
    }, (res) => {
      headerRef.current.button.setLoading(false)
      enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
    })
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <BreadcrumbsList breadcrumbsList={[
          { to: "/",        label: "Inicio" },
          { to: "./../../", label: "Colegios" },
          { to: "./../",    label: "Inscripciones" },
          { to: "./",       label: "Crear inscripción", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{mx: 8, my: 2}}>

          <CreateHeader
            ref          = {headerRef}
            title        = {"Crear inscripción"}
            handleSubmit = {handleSubmit(createInscripcion, onError)} />

          <Line className={"my-5"} />

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
              <Tabs value={tab} onChange={(_, tab) => setTab(tab)} TabIndicatorProps={{style: {backgroundColor: COLOR.standard.main}}}>
                <Tab label={<Text fontWeight={tab == "1" ? "bold" : null} color={tab == "1" ? COLOR.standard.main : null}>DATOS DE LA INSCRIPCIÓN</Text>} value={"1"}/>
                <Tab label={<Text fontWeight={tab == "2" ? "bold" : null} color={tab == "2" ? COLOR.standard.main : null}>DATOS DEL GRUPO</Text>}         value={"2"}/>
                <Tab label={<Text fontWeight={tab == "3" ? "bold" : null} color={tab == "3" ? COLOR.standard.main : null}>DATOS DE LOS PAGOS</Text>}      value={"3"}/>
                <Tab label={<Text fontWeight={tab == "4" ? "bold" : null} color={tab == "4" ? COLOR.standard.main : null}>LIBROS</Text>}                  value={"4"}/>
              </Tabs>
            </Box>

            <TabPanel value={"1"}>
              <DataFormGeneralInscripcionColegio 
                setValue                  = {setValue} 
                isMounted                 = {isMounted} 
                getValues                 = {getValues} 
                errors                    = {errors} 
                register                  = {register}
                clearErrors               = {clearErrors}
                control                   = {control} 
                onHorarioChange           = {setHorario}
                onActividadChangeCallback = {setActividad} />
            </TabPanel>

            <TabPanel value={"2"}>
              <DataFormGrupoInscripcionColegio
                setValue      = {setValue} 
                isMounted     = {isMounted} 
                getValues     = {getValues} 
                errors        = {errors} 
                register      = {register} 
                control       = {control} 
                onGrupoChange = {setGrupo}
                id_horario    = {id_horario} />
            </TabPanel>

            <TabPanel value={"3"}>
              <DataFormPagoInscripcionColegio
                setValue  = {setValue} 
                isMounted = {isMounted}
                getValues = {getValues} 
                errors    = {errors} 
                register  = {register} 
                control   = {control}
                actividad = {actividad} />
            </TabPanel>
            
            <TabPanel value={"4"}>
              <DataFormLibroInscripcionColegio
                  setValue  = {setValue} 
                  isMounted = {isMounted} 
                  getValues = {getValues} 
                  errors    = {errors} 
                  register  = {register} 
                  control   = {control}
                  grupo     = {grupo} />
            </TabPanel>
          </TabContext>

        </Paper>

        <SnackbarProvider autoHideDuration={3000}/>
      </form>
    </Container>
  )
}