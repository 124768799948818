import Grid from '@mui/material/Grid'
import { Text } from 'components/texts'
import React, { Component } from 'react'
import { Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Esta es la plantilla para cuando se busque por los datos basicos de la actividad
 */
export default class ActividadTemplate extends Component {
    
    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)
    }

    /**
     * Render
     *
     * @returns {Component}
     */
    render() {
        const { register, errors } = this.props
        return (
            <>
                <Grid container mt={2} mb={1}>
                    <Text fontWeight={'bold'}>Según datos de:</Text><br />
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Input label="Id del grupo" width={"100%"} name={"id_grupo"} register={register} errors={errors} />
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect name={"id_tipo_actividad"} keyValue={"tipo_actividad"} register={register} errors={errors} wantToReset={this.props.reset}/>
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect name={"id_registro_actividad"} keyValue={"actividad"} register={register} errors={errors} wantToReset={this.props.reset}/>
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect name={"id_modalidad_cursos"} keyValue={"modalidad_cursos"} register={register} errors={errors}  wantToReset={this.props.reset}/>
                    </Grid>

                    <Grid item xs={4}>
                        <Input label="Clave Grupo" width={"100%"} name={"clave"} register={register} errors={errors} />
                    </Grid>

                    <Grid item xs={4}>
                        <Input label="Colegio" width={"100%"} name={"nombre_colegio"} register={register} errors={errors} />
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect name={"id_curso_escolar"} keyValue={"curso_escolar"} register={register} errors={errors} wantToReset={this.props.reset}/>
                    </Grid>
                </Grid>
            </>
        )
    }
}
