import React, { Component } from "react";
import { RecoverPassForm } from "components/forms/display";

export default class RecoverPassword extends Component {
  
  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
      <div>
        <RecoverPassForm />
      </div>
    );
  }
}
