import * as Yup from 'yup'
import moment from 'moment'
import Statics from 'objects/common/Statics'

const numberValidation = (oldValue, value) => {
    if (value == "")
        return null

    const intValue = parseInt(value)
    return isNaN(intValue) ? "" : intValue
}

export const validation = {
    clave_grupo: Yup.string().required('La clave del grupo es obligatoria'),
    curso_escolar_id: Yup.string().required('El año es obligatorio'),
    registro_actividad_id: Yup.string().required('La actividad es obligatoria'),
    fecha_inicio: Yup.string().typeError('La fecha de inicio es obligatoria').required('La fecha de inicio es obligatoria'),
    fecha_fin: Yup.string().nullable()
        .test("is-greater", "La hora de fin no puede ser menor que la hora de inicio", function (value) {
            if (!value || !this.parent.fecha_inicio)
                return true

            let fecha_inicio = this.parent.fecha_inicio
            let fecha_fin = value
            if (!Statics.checkDateFormat(fecha_inicio)) {
                fecha_inicio = new Date(fecha_inicio)
                fecha_inicio.setDate(fecha_inicio.getDate() + 1);
                fecha_inicio = fecha_inicio.toISOString().split('T')[0]
            }

            if (fecha_fin && !Statics.checkDateFormat(fecha_fin)) {
                fecha_fin = new Date(fecha_fin)
                fecha_fin.setDate(fecha_fin.getDate() + 1);
                fecha_fin = fecha_fin.toISOString().split('T')[0]
            }

            return moment(fecha_inicio, "YYYY-MM-DD").isSameOrBefore(moment(fecha_fin, "YYYY-MM-DD"));
        }),


    num_grupo: Yup.number().typeError("El número del grupo tiene que ser un número").required('El número del grupo es obligatorio'),
    min_alumnos: Yup.number().transform(numberValidation).nullable().typeError("El mínimo de alumnos debe ser un número"),
    max_alumnos: Yup.number().transform(numberValidation).nullable().typeError("El máximo de alumnos tiene que ser un número")
        .test("is-greater", "El máximo de alumnos no puede ser menor que el mínimo de alumnos", function (value) {
            const { min_alumnos } = this.parent;
            if (!min_alumnos || !value) return true

            return min_alumnos <= value;
        }),
}

export const validationSchema = Yup.object().shape(validation)