import { Text } from 'components/texts'
import { useForm } from 'react-hook-form'
import Statics from 'objects/common/Statics'
import Requests from './Requests/Requests.js'
import { useNavigate } from "react-router-dom"
import { responses } from 'constants/Responses'
import { Container, Paper } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangesContext } from 'contexts/ChangesContext'
import { DataFormTutoria } from 'components/forms/display'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { BreadcrumbsList, Line } from 'components/data_display'
import { CreateHeader, AlumnoSection } from 'pages/logged/admin/components'
import { validationSchema } from 'components/forms/display/tutoria/DataForm/validation.js'

export default function CreateTutoria() {
  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, control, setValue } = useForm({ resolver: yupResolver(validationSchema) })
  const navigate = useNavigate()

  const headerRef        = useRef()
  const alumnoSectionRef = useRef()

  const [isMounted, setIsMounted] = useState(false)

  const { updateChanges } = useContext(ChangesContext)

  useEffect(() => setIsMounted(true), [])

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])

  const onValid = () => {
    if (alumnoSectionRef.current.isValid())
      createTutoria()
  }

  const onError = () => {
    alumnoSectionRef.current.isValid()
  }

  const createTutoria = () => {
    headerRef.current.button.setLoading(true)

    new Requests().createTutoria(getValues, alumnoSectionRef.current.getAlumno().id, async (res) => {
      const { id } = res[responses.tutorias.singleName]
      enqueueSnackbar(<Text>Se ha creado la tutoría correctamente. Redirigiendo a la pagina de edición..</Text>, { variant: "success" })
      await Statics.delay(1500).then(() => {navigate('./../' + id + "/editar")})
    }, (res) => {
      headerRef.current.button.setLoading(false)
      enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
    })
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <BreadcrumbsList breadcrumbsList={[
          { to: "/",        label: "Inicio" },
          { to: "./../../", label: "Alumnos" },
          { to: "./../",    label: "Tutorías"},
          { to: "./",       label: "Crear", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{mx: 8, my: 2, pb: 5}}>
          <CreateHeader
            ref          = {headerRef}
            title        = {"Crear tutoría"}
            handleSubmit = {handleSubmit(onValid, onError)} />

          <Line className="mt-5"/>

          <AlumnoSection 
            ref        = {alumnoSectionRef}
            title      = {"Escoge un alumno para crearle una tutoría: "}
            buttonText = {"Elegir alumno para la nueva tutoría"}
            onSelect   = {() => updateChanges(true)} />

          <DataFormTutoria 
            register  = {register} 
            setValue  = {setValue}
            errors    = {errors} 
            isMounted = {isMounted} 
            control   = {control} 
            hideButton />
        </Paper>

        <SnackbarProvider autoHideDuration={3000}/>
      </form>
    </Container>
  )
}