import { Grid, Stack } from "@mui/material";
import { Text } from "components/texts";
import TableMesesPagados from './parts/TableMesesPagados'
import { useRef } from "react";
import Skeleton from './skeleton'
import { Line } from "components/data_display";

/**
 * Pagina con los datos de los pagos de la clase particular
 */
export default function ClaseParticularPagos({ claseParticular }) {
    //REF
    const tablaMesesPagados = useRef()

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container px={5} pt={5} pb={2}>
            {claseParticular ?
                <>
                    <Grid item xs={3}>
                        <Stack>
                            <Text fontWeight={"bold"}>Forma de pago</Text>
                            <Text>{claseParticular.forma_pago?.valor ?? "Sin rellenar"}</Text>
                        </Stack>
                    </Grid>

                    <Grid item xs={3}>
                        <Stack>
                            <Text fontWeight={"bold"}>Precio mensual del alumno</Text>
                            <Text>{ (claseParticular.precio_hora + " €") ?? "Sin rellenar" }</Text>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} py={5}>
                        <Line />
                    </Grid>

                    <Grid container sx={{ overflowX: "auto" }} className={"darker-scrollbar"}>
                        <TableMesesPagados ref={tablaMesesPagados} claseParticular={claseParticular} />
                    </Grid>

                    <Grid item xs={12} pt={5}>
                        <Line />
                    </Grid>

                    <Grid item xs={3} pt={5}>
                        <Stack>
                            <Text fontWeight={"bold"}>Contrato emitido</Text>
                            <Text>{claseParticular.contrato_emitido == 1 ? "Sí" : "No"}</Text>
                        </Stack>
                    </Grid>

                    <Grid item xs={3} pt={5}>
                        <Stack>
                            <Text fontWeight={"bold"}>Contrato recibido</Text>
                            <Text>{claseParticular.contrato_recibido == 1 ? "Sí" : "No"}</Text>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} pt={5}>
                        <Stack>
                            <Text fontWeight={"bold"}>Observaciones de los pagos</Text>
                            <Text>{claseParticular.observaciones_pagos ?? "Sin rellenar"}</Text>
                        </Stack>
                    </Grid>

                </>
                : <Skeleton />}
        </Grid>
    )
}