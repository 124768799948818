import RequestMaster from '../RequestMaster'

export default class RequestAgenda extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/agenda'
    }


    /**
     * Crea un evento
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    create(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    
    /**
     * Edita un evento
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    edit(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "" + id
        this.put(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Peticion para obtener los eventos del usuario
     * 
     * @param {Function} callback 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    getUserEvents(params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "getLoggedUserEvents"
        this.get(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Peticion para borrar la relacion de un empleado a un evento
     * 
     * @param {Function} callback 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    deleteEventoEmpleado(id, params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "eventoEmpleado/" + id
        this.delete(path, params, callback, callbackError, callbackFinally)
    }

    
    /**
     * Peticion para borrar la relacion de un empleado a un evento
     * 
     * @param {Object}   params 
     * @param {Function} callback 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    share(params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "share"
        this.post(path, params, callback, callbackError, callbackFinally)
    }
}
