import { TabContext } from '@mui/lab'
import { COLOR } from 'theme/Colors.js'
import { useNavigate, useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { Text } from 'components/texts/index.js'
import { yupResolver } from '@hookform/resolvers/yup'
import { EditHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import React, { useState, useEffect, useContext, useRef } from 'react'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { Box, Container, Paper, Stack, Tab, Tabs } from '@mui/material'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { ChangesContext } from 'contexts/ChangesContext'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { validationSchema } from 'components/forms/display/colegio/inscripciones/DataForm/validation.js'
import { DataFormGeneralInscripcionColegio, DataFormGrupoInscripcionColegio, DataFormPagoInscripcionColegio, DataFormLibroInscripcionColegio } from 'components/forms/display/index.js'

export default function Wrapper() {
  const navigationParams = useParams()

  const { items, updateItem } = useContext(EmpleadoListContext)

  const [inscripcion,    setInscripcion]   = useState(null)
  const [tab,            setTab]           = useState("1")
  const [id_inscripcion, setIdInscripcion] = useState(navigationParams.id_inscripcion)

  const updateAlert = (message, variant = "error") => {
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }

  const getInscripcion = (getFromServer = false) => {
    const item = items.find(item => item?.id == id_inscripcion)
    if(items.length == 0 || !item || getFromServer) {
      new Requests().getInscripcion(id_inscripcion, (inscripcion) => {
        setInscripcion(inscripcion)
        updateItem(inscripcion)
      }, () => updateAlert("No se ha podido recuperar la inscripción."))
    } else {
      setInscripcion(item)
    }
  }
  
  useEffect(getInscripcion, [id_inscripcion])

  return (
    <React.Fragment>
      <EditInscripcionColegio key={inscripcion?.id ?? 0} inscripcion={inscripcion} id_inscripcion={id_inscripcion} setIdInscripcion={setIdInscripcion} setInscripcion={setInscripcion} tab={tab} setTab={setTab} updateAlert={updateAlert} onSaveFinish={() => getInscripcion(true)}/>

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}

function EditInscripcionColegio({ inscripcion, id_inscripcion, setIdInscripcion, setInscripcion, updateAlert, tab, setTab, onSaveFinish }) {
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, control, setValue, clearErrors, reset } = useForm({ resolver: yupResolver(validationSchema) })

  const { updateChanges } = useContext(ChangesContext)

  const [grupo,      setGrupo]     = useState(inscripcion?.grupo                  ?? null)
  const [id_horario, setHorario]   = useState(inscripcion?.id_descripcion_horario ?? -1)
  const [actividad,  setActividad] = useState(inscripcion?.id_registro_actividad  ?? -1)

  const headerRef = useRef()
  
  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])

  const onError = (errors) => {
    const tabPagos = ["id_forma_pago", "cantidad_matricula", "cantidad"]
    let tab = "3"

    for(const key of Object.keys(errors)) {
      if(!tabPagos.find(item => item == key)) {
        tab = "1"
        break
      }
    }

    setTab(tab)
  }

  const promises = () => {
    const request = new Requests()

    const promisePagos = new Promise((resolve, reject) => {
      request.createPagos(inscripcion.id, getValues("pagos"), resolve, reject)
    })

    const promiseSubvencion = new Promise((resolve, reject) => {
      request.createSubvencion(getValues, resolve, reject)
    })

    const promiseLibro = new Promise((resolve, reject) => {
      request.crudLibro(inscripcion, getValues, resolve, reject)
    })

    const promiseLibroAsociado = new Promise((resolve, reject) => {
      request.crudLibroAsociado(inscripcion, getValues, resolve, reject)
    })

    return [promisePagos, promiseSubvencion, promiseLibro, promiseLibroAsociado]
  }

  const editInscripcion = () => {
    headerRef.current.button.setLoading(true)

    new Requests().editInscripcion(id_inscripcion, getValues, (res) => {
      if(res.inscripcion) {
        updateAlert("Se ha actualizado la inscripción correctamente.", "success")

        Promise.allSettled(promises())
          .then((values) => {
            for (const fail of values.filter(item => item.status == "rejected"))
              updateAlert(fail.reason)

            headerRef.current.button.setLoading(false)
            reset({}, {keepValues: true})
            updateChanges(false)
            onSaveFinish()
          })
      }
    }, (res) => {
      updateAlert(res)
      headerRef.current.button.setLoading(false)
    })
  }

  const updateId = (id) => {
    navigate(`../colegios/inscripciones/${id}`)
    setIdInscripcion(id)
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/",        label: "Inicio" },
            { to: "./../../", label: "Colegios" },
            { to: "./../",    label: "Inscripciones" },
            { to: "./",       label: "Crear inscripción", actualItem: true },
          ]} />

          <MoveButtons 
            actualItemId        = {id_inscripcion}
            beforeFetchCallback = {() => setInscripcion(null)} 
            onUpdateActualItem  = {updateId} />
        </Stack>

        <Paper elevation={2} sx={{mx: 8, my: 2}}>

          <EditHeader 
            ref          = {headerRef} 
            title        = {"Editar inscripción"}
            item         = {inscripcion} 
            handleSubmit = {handleSubmit(editInscripcion, onError)} />

          <Line className={"mb-5"} />

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
              <Tabs value={tab} onChange={(_, tab) => setTab(tab)} TabIndicatorProps={{style: {backgroundColor: COLOR.standard.main}}}>
                <Tab label={<Text fontWeight={tab == "1" ? "bold" : null} color={tab == "1" ? COLOR.standard.main : null}>DATOS DE LA INSCRIPCIÓN</Text>} value={"1"}/>
                <Tab label={<Text fontWeight={tab == "2" ? "bold" : null} color={tab == "2" ? COLOR.standard.main : null}>DATOS DEL GRUPO</Text>}         value={"2"}/>
                <Tab label={<Text fontWeight={tab == "3" ? "bold" : null} color={tab == "3" ? COLOR.standard.main : null}>DATOS DE LOS PAGOS</Text>}      value={"3"}/>
                <Tab label={<Text fontWeight={tab == "4" ? "bold" : null} color={tab == "4" ? COLOR.standard.main : null}>LIBROS</Text>}                  value={"4"}/>
              </Tabs>
            </Box>

            <TabPanel value={"1"}>
              {inscripcion ? 
                <DataFormGeneralInscripcionColegio 
                  setValue                  = {setValue}
                  getValues                 = {getValues}
                  errors                    = {errors}
                  register                  = {register}
                  inscripcion               = {inscripcion}
                  clearErrors               = {clearErrors}
                  control                   = {control}
                  onHorarioChange           = {setHorario}
                  onActividadChangeCallback = {setActividad} 
                  isMounted /> : null }
            </TabPanel>

            <TabPanel value={"2"}>
              {inscripcion ? 
                <DataFormGrupoInscripcionColegio
                    setValue      = {setValue} 
                    getValues     = {getValues} 
                    errors        = {errors} 
                    inscripcion   = {inscripcion}
                    register      = {register} 
                    control       = {control} 
                    onGrupoChange = {setGrupo}
                    id_horario    = {id_horario}
                    isMounted /> : null}
            </TabPanel>

            <TabPanel value={"3"}>
              {inscripcion ?
                <DataFormPagoInscripcionColegio
                    setValue    = {setValue} 
                    getValues   = {getValues} 
                    errors      = {errors} 
                    inscripcion = {inscripcion}
                    register    = {register} 
                    control     = {control}
                    actividad   = {actividad}
                    isMounted /> : null}
            </TabPanel>
            
            <TabPanel value={"4"}>
            {inscripcion ?
              <DataFormLibroInscripcionColegio
                  setValue    = {setValue} 
                  getValues   = {getValues} 
                  inscripcion = {inscripcion}
                  errors      = {errors} 
                  register    = {register} 
                  control     = {control}
                  grupo       = {grupo}
                  isMounted /> : null}
            </TabPanel>
          </TabContext>

        </Paper>

        <SnackbarProvider autoHideDuration={3000}/>
      </form>
    </Container>
  )
}