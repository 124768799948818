import { Grid } from '@mui/material'
import React, { Component } from 'react'
import Skeleton from '@mui/material/Skeleton'

/**
 * SKELETON
 */
export default class skeletonActividadesAcademia extends Component {

    render() {
        return (
            <Grid container spacing={2} mt={2}>
                <Grid item xs={8}>
                    <Skeleton variant="rounded" width={"40%"} height={30} className={"my-2"} />
                </Grid>

                <Grid item xs={4} display={"flex"} justifyContent={"end"} alignItems={"start"}>
                    <Skeleton variant="rounded" width={"40%"} height={30} className={"my-2"} />
                </Grid>

                <Grid item xs={12} mt={3}>
                    <Skeleton variant="rounded" width={"40%"} height={30} className={"my-2"} />
                </Grid>

                <Grid container mt={2} spacing={2} columns={6}>
                    <Grid item xs={3} display={"flex"} flexDirection={"column"}>
                        <Skeleton variant="rounded" width={"80%"} height={30} className={"my-2"} />
                    </Grid>

                    <Grid item xs={3} display={"flex"} flexDirection={"column"}>
                        <Skeleton variant="rounded" width={"80%"} height={30} className={"my-2"} />
                    </Grid>

                    <Grid item xs={4} display={"flex"} flexDirection={"column"}>
                        <Skeleton variant="rounded" width={"80%"} height={30} className={"my-2"} />

                    </Grid>

                    <Grid item xs={2} display={"flex"} flexDirection={"column"} >
                        <Skeleton variant="rounded" width={"50%"} height={30} className={"my-2"} />
                    </Grid>

                    <Grid item xs={2} display={"flex"} flexDirection={"column"}>
                        <Skeleton variant="rounded" width={"60%"} height={30} className={"my-2"} />
                    </Grid>

                    <Grid item xs={2} display={"flex"} flexDirection={"column"}>
                        <Skeleton variant="rounded" width={"60%"} height={30} className={"my-2"} />
                    </Grid>

                    <Grid item xs={2} display={"flex"} flexDirection={"column"}>
                        <Skeleton variant="rounded" width={"60%"} height={30} className={"my-2"} />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
