import { TabContext } from '@mui/lab'
import { Text } from 'components/texts'
import { COLOR } from 'theme/Colors.js'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { useNavigate } from "react-router-dom"
import Statics from 'objects/common/Statics.js'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { CreateHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { BreadcrumbsList, Line } from 'components/data_display'
import { Box, Container, Paper, Tab, Tabs } from '@mui/material'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import validationSchema from 'components/forms/display/colegio/colegio/DataForm/validation.js'
import { DataFormLibro, DataFormLibroControlAlmacen, DataFormLibroNiveles, DataFormLibroProgramaciones } from 'components/forms/display'
import { ChangesContext } from 'contexts/ChangesContext/index.jsx'


export default function CreateLibros(){
  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, setValue } = useForm({ resolver: yupResolver(validationSchema), defaultValues: { niveles: [] }})
  const navigate = useNavigate()

  const headerRef = useRef()

  const { updateChanges } = useContext(ChangesContext)

  const [tab,       setTab]       = useState("1")
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => setIsMounted(true), [])
  
  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])
  
  const requests = new Requests()

  const promises = (libro_id) => {
    const promiseLibrosPedidos = new Promise((resolve, reject) => {
      requests.createLibrosPedidos(libro_id, getValues, resolve, reject)
    })

    const promiseLibrosDevueltos = new Promise((resolve, reject) => {
      requests.createLibrosDevueltos(libro_id, getValues, resolve, reject)
    })

    const promiseNiveles = new Promise((resolve, reject) => {
      requests.createNiveles(libro_id, getValues, resolve, reject)
    })

    return [promiseLibrosPedidos, promiseLibrosDevueltos, promiseNiveles]
  }

  const createLibro = () => {
    headerRef.current.button.setLoading(true)
    
    requests.createLibro(getValues, (res) => {
      Promise.allSettled(promises(res.libro.id))
          .then(async (values) => {
            enqueueSnackbar(<Text>Se ha creado el libro. Redirigiendo a la página de edición...</Text>, { variant: "success" })

            for (const fail of values.filter(item => item.status == "rejected"))
              enqueueSnackbar(<Text>{fail.reason}</Text>, { variant: "error" })

            updateChanges(false)
            await Statics.delay(1500).then(() => navigate('./../' + res.libro.id + "/editar"))
      })
    }, (res) => {
      headerRef.current.button.setLoading(false)
      enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
    })
  }


  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
        <BreadcrumbsList breadcrumbsList={[
          { to: "/",     label: "Inicio" },
          { to: "./../", label: "Libros" },
          { to: "./",    label: "Crear libro", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{mx: 8, my: 2, pb: 5}}>
          <CreateHeader 
            ref          = {headerRef} 
            title        = {"Crear libro"}
            handleSubmit = {handleSubmit(createLibro)} />

          <Line className={"my-5"} />

          <DataFormLibro register={register} errors={errors} />

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2} mt={5}>
              <Tabs value={tab} onChange={(_, tab) => setTab(tab)} TabIndicatorProps={{style: {backgroundColor: COLOR.standard.main}}}>
                <Tab label={<Text fontWeight={tab == "1" ? "bold" : null} color={tab == "1" ? COLOR.standard.main : null}>CONTROL DEL AMACÉN</Text>} value={"1"}/>
                <Tab label={<Text fontWeight={tab == "2" ? "bold" : null} color={tab == "2" ? COLOR.standard.main : null}>NIVELES</Text>}            value={"2"}/>
                <Tab label={<Text fontWeight={tab == "3" ? "bold" : null} color={tab == "3" ? COLOR.standard.main : null}>PROGRAMACIONES</Text>}     value={"3"}/>
              </Tabs>
            </Box>

            <TabPanel value={"1"}>
              <DataFormLibroControlAlmacen
                setValue  = {setValue} 
                isMounted = {isMounted}
                errors    = {errors} 
                register  = {register} />
            </TabPanel>

            <TabPanel value={"2"}>
              <DataFormLibroNiveles
                setValue  = {setValue} 
                register  = {register}
                getValues = {getValues} />
            </TabPanel>

            <TabPanel value={"3"}>
              <DataFormLibroProgramaciones 
                register  = {register}
                getValues = {getValues} />
            </TabPanel>
          </TabContext>
        </Paper>

        <SnackbarProvider autoHideDuration={5000}/>
      </form>
    </Container>
  )
}